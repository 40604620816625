import axios from "axios";

export default ({ app }, inject) => {
  const http = axios.create({
    baseURL: process.env.API_URL || "http://localhost:8000"
  });

  http.interceptors.request.use(async config => {
    const userToken = await app.$fire.auth.currentUser?.getIdToken();
    config.headers.Authorization = `Bearer ${userToken}`;
    return config;
  });

  http.interceptors.response.use(
    response => response.data,
    error => Promise.reject(error)
  );

  const api = {
    async uploadPhotos({ photoOwnerId, cropIds, files }) {
      const formData = new FormData();
      formData.append("photoOwnerId", photoOwnerId);
      formData.append("cropIds", cropIds.join(","));
      files.forEach(file => formData.append("files", file));
      return http.post("/add-image", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });
    },
    async getTaxonomy(term) {
      if (term?.trim()?.length >= 2) {
        try {
          const response = await http.get("/search", {
            params: { q: term.trim().toLowerCase() }
          });
          return response.matches;
        } catch (e) {
          console.error(e);
          return [];
        }
      }
    },
    async getTaxonomies(terms) {
      const response = await http.get("/search-multiple", {
        params: { q: JSON.stringify(terms) }
      });
      return response.matches;
    },
    async searchProducts({ ids, type = null, limit = null, text = null }) {
      const params = {};
      if (Array.isArray(ids)) {
        params.ids = JSON.stringify(ids);
      }
      if (text) {
        params.q = text;
      }
      if (type) params.type = type;
      if (limit) params.limit = limit;

      return http.get("/search-product", { params });
    },
    fetchSheetData(rowId) {
      return http.get("/googleapis", { params: { rowId } });
    },
    uploadSheetData(file) {
      const formData = new FormData();
      formData.append("file", file);
      return http.post("/googleapis", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });
    },

    validateLabId({ labId, cropId }) {
      return http.get("/labdata/validate-lab-id", {
        params: { id: labId, crop: cropId }
      });
    },

    uploadLabData(url, data) {
      return http.post(url, data, {
        headers: { "Content-Type": "multipart/form-data" }
      });
    },

    updateSensorsSubjects(type, companyId) {
      if (type === "tentacles") {
        return http.post("/jobs/tentacles-collect-dashboard", { companyId });
      }
      return Promise.resolve();
    }
  };

  inject("api", api);
};
