export default {
  chemicalProtection: [
    { id: "abamectineHf", active: true },
    { id: "abir", active: true },
    { id: "aCSzwavel800", active: true },
    { id: "admiral", active: true },
    { id: "alibiflora", active: true },
    { id: "altacor", active: true },
    { id: "applaud", active: true },
    { id: "asperello", active: true },
    { id: "attracker", active: true },
    { id: "azatin", active: true },
    { id: "AZ500", active: true },
    { id: "batavia", active: true },
    { id: "bifasto", active: true },
    { id: "biosoap", active: true },
    { id: "biosweet", active: true },
    { id: "bitterzout", active: true },
    { id: "bonzi", active: true },
    { id: "botanigardWP", active: true },
    { id: "boxer", active: true },
    { id: "butisanS", active: true },
    { id: "captosan500sc", active: true },
    { id: "carex10sc", active: true },
    { id: "cecece", active: true },
    { id: "centurionplus", active: true },
    { id: "closer", active: true },
    { id: "collis", active: true },
    { id: "configuresc", active: true },
    { id: "conserve", active: true },
    { id: "contans", active: true },
    { id: "dazideenhance", active: true },
    { id: "decis", active: true },
    { id: "dipel", active: true },
    { id: "dimanin", active: true },
    { id: "dolokal", active: true },
    { id: "ecomist", active: true },
    { id: "ethrelA", active: true },
    { id: "fado", active: true },
    { id: "falgro", active: true },
    { id: "flint", active: true },
    { id: "flipper", active: true },
    { id: "floramite", active: true },
    { id: "frupica", active: true },
    { id: "fulvic", active: true },
    { id: "fungaflash", active: true },
    { id: "gazelle", active: true },
    { id: "geoxe", active: true },
    { id: "gnatrolSc", active: true },
    { id: "hasten", active: true },
    { id: "hfOnkruidVrij", active: true },
    { id: "hiCure", active: true },
    { id: "hiwett", active: true },
    { id: "inssimo", active: true },
    { id: "karateZeon", active: true },
    { id: "karma", active: true },
    { id: "kumar", active: true },
    { id: "kumulusS", active: true },
    { id: "limocide", active: true },
    { id: "lunaPrivilige", active: true },
    { id: "mainspring", active: true },
    { id: "MCPA500", active: true },
    { id: "meltatox", active: true },
    { id: "microsulfo", active: true },
    { id: "mirageElan", active: true },
    { id: "miragePlus", active: true },
    { id: "motto", active: true },
    { id: "movento", active: true },
    { id: "mycotal", active: true },
    { id: "nectarMagnwsiumDuo", active: true },
    { id: "neemazal", active: true },
    { id: "nissorun", active: true },
    { id: "nocturn", active: true },
    { id: "nomolt", active: true },
    { id: "oberon", active: true },
    { id: "oroganic", active: true },
    { id: "ortiva", active: true },
    { id: "ortivaTop", active: true },
    { id: "paraat", active: true },
    { id: "pedimentrookontwikkelaar", active: true },
    { id: "pirimor", active: true },
    { id: "pironpushbox", active: true },
    { id: "previcurEnergy", active: true },
    { id: "prolong", active: true },
    { id: "proplant", active: true },
    { id: "ranman", active: true },
    { id: "ranmanTop", active: true },
    { id: "rhizopon", active: true },
    { id: "ridomilGold", active: true },
    { id: "rizolex", active: true },
    { id: "rocket", active: true },
    { id: "rodilinHavermix", active: true },
    { id: "rodilonsoftblock", active: true },
    { id: "rovral", active: true },
    { id: "runner", active: true },
    { id: "saponin", active: true },
    { id: "scelta", active: true },
    { id: "score250EC", active: true },
    { id: "serenade", active: true },
    { id: "signum", active: true },
    { id: "siltac", active: true },
    { id: "silwetgold", active: true },
    { id: "sivantoPrime", active: true },
    { id: "sluxx", active: true },
    { id: "sonata", active: true },
    { id: "sporgon", active: true },
    { id: "steward", active: true },
    { id: "suikerlokstof", active: true },
    { id: "suitzwavel", active: true },
    { id: "sumicidinSuper", active: true },
    { id: "switch", active: true },
    { id: "taegro", active: true },
    { id: "takumi", active: true },
    { id: "teldor", active: true },
    { id: "teppeki", active: true },
    { id: "tomCatRTU", active: true },
    { id: "topaz", active: true },
    { id: "topsinmutra", active: true },
    { id: "tracer", active: true },
    { id: "trianium", active: true },
    { id: "turexPowder", active: true },
    { id: "tzwavel", active: true },
    { id: "vacciplant", active: true },
    { id: "valiosoSt", active: true },
    { id: "vertimec", active: true },
    { id: "vivando", active: true },
    { id: "winner", active: true },
    { id: "fusiladeMax", active: true },
    { id: "wingP", active: true },
    { id: "agralGold", active: true },
    { id: "goltixWG", active: true },
    { id: "roundupDynamic", active: true },
    { id: "roundupEvolution", active: true },
    { id: "concreteHF", active: true },
    { id: "corzalSE", active: true },
    { id: "calciumShuttle", active: true },
    { id: "basfoliar36Extra", active: true },
    { id: "calciumChoride", active: true },
    { id: "ridemilGold", active: true },
    { id: "pergado", active: true },
    { id: "abemectineHF", active: false },
    { id: "captosan500Sc", active: true },
    { id: "xentarie", active: true }
  ],
  organicProtection: [
    { id: "adaliaBipunctata", active: true },
    { id: "amblydromalusLimonicus", active: true },
    { id: "amblyseiusSwirskii", active: true },
    { id: "anagyrusVladimiri", active: true },
    { id: "aphelinusAbdomalis", active: true },
    { id: "aphidiusColemani", active: true },
    { id: "aphidiusErvi", active: true },
    { id: "aphidiusMatricariae", active: true },
    { id: "aphidoletesAphidimyza", active: true },
    { id: "athetaCoriaria", active: true },
    { id: "azatin", active: false },
    { id: "biosweet", active: false },
    { id: "bitterzout", active: false },
    { id: "bombusTerrestris", active: true },
    { id: "carpoglyphusLactis", active: true },
    { id: "chrysoperlaCarnea", active: true },
    { id: "contans", active: false },
    { id: "cryptolaemusMontrouzieri", active: true },
    { id: "dacnusaSibirica", active: true },
    { id: "delphastusCatalinae", active: true },
    { id: "diglyphusIsaea", active: true },
    { id: "dipel", active: false },
    { id: "dolokal", active: false },
    { id: "encarsiaFormosa", active: true },
    { id: "enermix", active: true },
    { id: "ephestiaKuehniella", active: true },
    { id: "eretmocerusEremicus", active: true },
    { id: "feltiellaAcarisuga", active: true },
    { id: "hollandFytozide", active: true },
    { id: "hypoaspisMiles", active: true },
    { id: "kumulusS", active: false },
    { id: "macrochelesRobustulus", active: true },
    { id: "macrolophusPygameus", active: true },
    { id: "microsulfo", active: false },
    { id: "nectarMagnwsiumDuo", active: false },
    { id: "neemazal", active: false },
    { id: "neoseiulusCalifornicus", active: true },
    { id: "neoseiulusCucumeris", active: true },
    { id: "oriusLaevigatus", active: true },
    { id: "pekelkreeftcystenArtemiaspp", active: true },
    { id: "phytoseiulusPersimilis", active: true },
    { id: "steinernemaCarpocapsae", active: true },
    { id: "steinernemaFeltiae", active: true },
    { id: "suitzwavel", active: false },
    { id: "Teppeki", active: false },
    { id: "transeiusMontdorensis", active: true },
    { id: "trianium", active: false },
    { id: "trichogrammaArchaeae", active: true }
  ],
  growthRegulation: [
    { id: "alar", active: true },
    { id: "bonzi", active: true },
    { id: "calciumchloride", active: true },
    { id: "chryzopon", active: true },
    { id: "controlGrowdesign", active: true },
    { id: "cycocel", active: true },
    { id: "dazide", active: true },
    { id: "elastoG5", active: true },
    { id: "koper", active: true },
    { id: "magnesiumchloride", active: true },
    { id: "pirouette", active: true },
    { id: "questuran", active: true },
    { id: "regalis", active: true },
    { id: "rhizoponAa", active: true },
    { id: "uplccc750", active: true }
  ],
  floweringTreatment: [
    { id: "auxine", active: true },
    { id: "bonzi", active: true },
    { id: "carousel", active: true },
    { id: "configureSc", active: true },
    { id: "ethylene", active: true },
    { id: "florgib", active: true },
    { id: "gibbereline", active: true },
    { id: "valiosoSt", active: true }
  ],
  fertilizationSpreadable: [
    { id: "dcmMix1", active: true },
    { id: "dcmMix2", active: true },
    { id: "dcmMix3", active: true },
    { id: "dcmMix4", active: true },
    { id: "dcmMix5", active: true },
    { id: "dcmMix6", active: true },
    { id: "dolokal", active: true },
    { id: "innogreenecogroei", active: true },
    { id: "kalkalmmonsalpeter", active: true },
    { id: "magnesamonsalpeter", active: true },
    { id: "patentkali", active: true },
    { id: "protixflytilizer", active: true },
    { id: "tripelsuperfosfaat", active: true },
    { id: "lokdoosMetGif", active: true },
    { id: "lokdoosZonderGif", active: true },
    { id: "vivikali", active: true }
  ],
  fertilizationFoliar: [
    { id: "bitterzout", active: true },
    { id: "calsal", active: true },
    { id: "calciumchloride", active: true },
    { id: "cuprum", active: true },
    { id: "espotop", active: true },
    { id: "fertigroMSN", active: true },
    { id: "fertigroCN", active: true },
    { id: "kalksalpeter", active: true },
    { id: "kalksalpeterCalcinite", active: true },
    { id: "kalksalpeterTropicote", active: true },
    { id: "powerP", active: true },
    { id: "salifort", active: true },
    { id: "siliforce", active: true },
    { id: "spuitzwavel", active: true },
    { id: "superFK", active: true },
    { id: "universolgroen", active: true },
    { id: "ureumprills", active: true },
    { id: "vitalance", active: true },
    { id: "yaraNitromag", active: true },
    { id: "soluplantSF31661414CaO", active: true },
    { id: "olfAg", active: true },
    { id: "HuwaSan", active: true },
    { id: "acsKoper500", active: true },
    { id: "yaraSulfan", active: true }
  ],
  foliar: [
    { id: "cuprum", active: true },
    { id: "salifort", active: true },
    { id: "siliforce", active: true }
  ],
  plague: [
    { id: "aaltjes", active: true },
    { id: "bladluizen", active: true },
    { id: "cicaden", active: true },
    { id: "dopluizen", active: true },
    { id: "schildluizen", active: true },
    { id: "kevers", active: true },
    { id: "mineervliegen", active: true },
    { id: "mole", active: true },
    { id: "muggenVliegen", active: true },
    { id: "mice", active: true },
    { id: "weeds", active: true },
    { id: "rupsen", active: true },
    { id: "slugs", active: true },
    { id: "springtail", active: true },
    { id: "mijten", active: true },
    { id: "trips", active: true },
    { id: "wantsen", active: true },
    { id: "whiteFly", active: true },
    { id: "wolluizen", active: true },
    { id: "symphyla", active: true },
    { id: "noplagues", active: true },
    { id: "otherInsects", active: true }
  ],
  fungus: [
    { id: "altenaria", active: true },
    { id: "bladvlekkenziekte", active: true },
    { id: "bloemrot", active: true },
    { id: "botrytis", active: true },
    { id: "didymella", active: true },
    { id: "fusarium", active: true },
    { id: "japanseRoest", active: true },
    { id: "echteMeeldauw", active: true },
    { id: "valseMeeldauw", active: true },
    { id: "phytophthora", active: true },
    { id: "pythium", active: true },
    { id: "roetdauw", active: true },
    { id: "sclerotinia", active: true },
    { id: "stengelbasisrot", active: true },
    { id: "verticillium", active: true },
    { id: "nofungus", active: true },
    { id: "zwarteVlekkenziekte", active: true }
  ],
  defectPhenomenon: [
    { id: "bladvlekkenGeel", active: true },
    { id: "bladvlekkenBruin", active: true },
    { id: "bladrandGeel", active: true },
    { id: "BladrandBruin", active: true },
    { id: "krullendBlad", active: true },
    { id: "bladval", active: true },
    { id: "knopval", active: true }
  ],
  causesCropcontrol: [
    { id: "aaltjes", active: true },
    { id: "altenaria", active: true },
    { id: "bacterienov", active: false },
    { id: "beworteling", active: false },
    { id: "bladvlekkenziekte", active: false },
    { id: "bloeibehandeling", active: false },
    { id: "bloemrot", active: true },
    { id: "botrytis", active: true },
    { id: "bladluizen", active: true },
    { id: "cicaden", active: true },
    { id: "erwinia", active: true },
    { id: "kevers", active: true },
    { id: "mineervliegen", active: true },
    { id: "muggenVliegen", active: true },
    { id: "rupsen", active: true },
    { id: "mijten", active: true },
    { id: "trips", active: true },
    { id: "wantsen", active: true },
    { id: "wolluizen", active: true },
    { id: "didymella", active: true },
    { id: "fusarium", active: true },
    { id: "japanseRoest", active: true },
    { id: "echteMeeldauw", active: true },
    { id: "valseMeeldauw", active: true },
    { id: "phytophthora", active: true },
    { id: "pythium", active: true },
    { id: "roetdauw", active: true },
    { id: "sclerotinia", active: true },
    { id: "stengelbasisrot", active: true },
    { id: "verticillium", active: true },
    { id: "zwarteVlekkenziekte", active: true },
    { id: "otherBacteria", active: true },
    { id: "rooting", active: true },
    { id: "leafSpot", active: true },
    { id: "floweringTreatment", active: true },
    { id: "centerRot", active: true },
    { id: "adhesive", active: true },
    { id: "otherInsects", active: true },
    { id: "beetles", active: true },
    { id: "tuberRot", active: true },
    { id: "attractant", active: true },
    { id: "aphids", active: true },
    { id: "mole", active: true },
    { id: "mice", active: true },
    { id: "shoreFly", active: true },
    { id: "disinfection", active: true },
    { id: "misk", active: true },
    { id: "plantResistance", active: true },
    { id: "weeds", active: true },
    { id: "remmen", active: true },
    { id: "rhizoctonia", active: true },
    { id: "caterpillars", active: true },
    { id: "otherFungus", active: true },
    { id: "slugs", active: true },
    { id: "spiders", active: false },
    { id: "springtail", active: true },
    { id: "sugarRot", active: true },
    { id: "whiteFly", active: true },
    { id: "rootRot", active: true },
    { id: "symphyla", active: true },
    { id: "dopluizen", active: true },
    { id: "schildluizen", active: true },
    { id: "branchMortality", active: true },
    { id: "tensides", active: true }
  ],
  rating: [
    { id: "germRate", active: true },
    { id: "leafColor", active: true },
    { id: "leafGloss", active: true },
    { id: "shootFormation", active: true },
    { id: "flowerSize", active: true },
    { id: "flowerFirmness", active: true },
    { id: "floridity", active: true },
    { id: "rooting", active: true },
    { id: "uniformity", active: true },
    { id: "moisture", active: true },
    { id: "branching", active: true },
    { id: "plantFirmness", active: true },
    { id: "plantStructure", active: true },
    { id: "compactness", active: true },
    { id: "plantQuality", active: true },
    { id: "leafQuality", active: true },
    { id: "stemDiameter", active: true },
    { id: "tipSize", active: true },
    { id: "plantVolume", active: true },
    { id: "growthOfPlantHead", active: true },
    { id: "plantGrowthLengthOfThePlant", active: true },
    { id: "plantGrowthInternodes", active: true },
    { id: "clusterFormation", active: true },
    { id: "bloomNumberOfOpenFlowers", active: true },
    { id: "cutlings", active: true }
  ]
};
