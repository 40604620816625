import { convertUnixTime } from "@/utils/date";
import { convertFromLegacy } from "@/services/legacy-notes";
import { toDate } from "@/utils/firebase-utils";
import { isNaN } from "lodash";

export const toDates = notes => {
  // fix firebase dates, convert them to native date objects
  return notes.map(note => {
    if (note.date instanceof Date) {
      return note;
    }
    note.date = toDate(note.date);
    return note;
  });
};

export const calculateFailureRates = notes => {
  let totalFailureUnits = 0;
  for (const note of notes.reverse()) {
    if (note.branch === "observation" && note.observation.type === "failure") {
      totalFailureUnits += note.observation.fields.failureUnits;
      note.observation.fields.totalFailureUnits = totalFailureUnits;
    }
  }
  return notes;
};

export const convertNotes = notes => {
  return notes.map(note =>
    note.schema !== "v2" ? convertFromLegacy(note) : note
  );
};

export const genLabDataNotes = labData => {
  return labData
    .filter(log => log.date && !isNaN(log.date))
    .map(log => {
      const date = convertUnixTime(log.date);
      return {
        date,
        branch: "labData",
        note: {},
        schema: "v2",
        action: {},
        measurement: { fields: { labEc: log.ec, labPh: log.ph } },
        observation: {}
      };
    });
};

export const defaultNote = () => ({
  branch: null,
  action: {
    type: null,
    value: null,
    unit: null,
    cause: null,
    product: {
      name: null,
      cause: null,
      applied: { total: { value: null, unit: null }, fields: {} }
    },
    multiproduct: {
      id: null,
      tankage: null,
      numberOfUnits: null,
      products: []
    },
    fields: {}
  },
  measurement: { type: null, value: null, unit: null, fields: {} },
  observation: { type: null, value: null, fields: {} },
  note: {
    date: new Date(),
    text: null,
    photoType: null,
    photos: null
  },
  schema: "v2"
});

export const findProductTranslation = (note, product, { $t, $te }) => {
  // product applied, look for translation
  if (product?.name) {
    let type;
    if (note.action?.fields?.fertilizationType) {
      type = `fertilization.${note.action.fields.fertilizationType}`;
    } else {
      type = note.action.type;
    }
    return $te(`cropForm.${type}.options.${product.name}`)
      ? $t(`cropForm.${type}.options.${product.name}`)
      : product.name;
  }
  return "";
};

export const findCauseTranslation = (cause, { $t, $te }) => {
  let val = cause;
  if (!val) return "";
  if (val === "plantGrowthRegulator") val = "remmen"; // patch the renamed legacy field

  if ($te(`notesFeed.${val}`)) {
    return $t(`notesFeed.${val}`);
  } else if ($te(`cropForm.plague.options.${val}`)) {
    return $t(`cropForm.plague.options.${val}`);
  } else if ($te(`cropForm.fungus.options.${val}`)) {
    return $t(`cropForm.fungus.options.${val}`);
  } else if ($te(`cropForm.defectPhenomenon.options.${val}`)) {
    return $t(`cropForm.defectPhenomenon.options.${val}`);
  } else if ($te(`cropForm.causesCropcontrol.options.${val}`)) {
    return $t(`cropForm.causesCropcontrol.options.${val}`);
  } else {
    return val;
  }
};
