import Vue from "vue";
import VueBottomSheet from "@webzlodimir/vue-bottom-sheet";
import Hammer from "hammerjs";

Vue.use(VueBottomSheet);

const original = Vue.component("VueBottomSheet");
Vue.component("VueBottomSheet", {
  name: "VueBottomSheet",
  extends: original,
  props: {
    clickToClose: {
      type: Boolean,
      default: function() { return !this.$device.isMobile } // impractical and not needed for mobile
    }
  },
  beforeDestroy() {
    this.releaseHammerjs();
  },
  data() {
    return {
      adjusted: false,
      velocityY: 0
    };
  },
  watch: {
    async inited(val) {
      if (val && !this.adjusted) {
        this.adjusted = true;
        await this.$nextTick();
        this.enhanceHammerjs();
      }
    }
  },
  methods: {
    async onPanEnd(event) {
      if (this.opened) {
        this.velocityY = event.velocityY;
        await this.$nextTick();
        this.updateInertia();
      } else {
        this.velocityY = 0;
      }
    },
    async updateInertia() {
      const contentEl = this.$refs.bottomSheetCardContent;

      const distance = this.velocityY * 100;
      const startPosition = contentEl.scrollTop;
      const targetPosition = parseFloat(startPosition) + distance;
      
      const maxPosition = this.opened ? (contentEl.scrollHeight - contentEl.offsetHeight - 1) : 0;
      const clampedTargetPosition = Math.min(Math.max(targetPosition, 1), maxPosition);

      if (
        (startPosition !== clampedTargetPosition) &&
        (clampedTargetPosition >= 0 && clampedTargetPosition <= maxPosition)
      ) {
        contentEl.scroll({
          top: clampedTargetPosition,
          behavior: "smooth"
        });
      }
    },
    enhanceHammerjs() {
      const config = { direction: Hammer.DIRECTION_VERTICAL, enable: true };
      for (const [_, cmp] of Object.entries(this.hammer)) {
        cmp.get("pan").set({ threshold: 50 });
        cmp.add(new Hammer.Pinch(config));
        cmp.add(new Hammer.Swipe(config));
        // cmp.on("panend", this.onPanEnd);
      }
    },
    releaseHammerjs() {
      for (const [_, cmp] of Object.entries(this.hammer)) {
        // cmp.off("panend", this.onPanEnd);
      }
    }
  }
});