<template>
  <button
    :class="{
      'padding-small': icon || vIcon,
      'padding-large': !icon && !vIcon,
      [color]: true,
      [`text-${color}`]: color.includes('brand-'),
      selected,
      circular: false,
      outlined: outlined,
      dense: dense
    }"
    :disabled="disabled || !allowed"
    :type="type"
    v-bind="$attrs"
    v-on="$listeners"
    @click="handleClick"
  >
    <spinner v-if="isLoading" outer-size="w-6 h-6 mx-5" inner-size="w-5 h-5" />
    <template v-else>
      {{ iconAlignment == "right" ? text : "" }}
      <img
        v-if="icon"
        :src="iconUrl"
        :class="iconAlignment === 'left' ? 'mr-3' : 'ml-3'"
        class="w-5"
      />
      <client-only>
        <v-icon
          v-if="vIcon"
          :class="[
            text ? (iconAlignment === 'left' ? 'mr-2' : 'ml-2') : '',
            { [iconClass]: true }
          ]"
          :name="vIcon"
        />
      </client-only>
      <span class="button-text" v-if="text.length">
        {{ iconAlignment == "left" ? text : "" }}
      </span>
      <div
        v-if="counter"
        class="rounded-full absolute w-4 h-4"
        :class="{
          'bg-brand-green': !color.includes('brand-'),
          [`bg-${color}`]: color.includes('brand-')
        }"
        :style="{ top: '-4px', right: '-4px' }"
      >
        <div class="flex justify-center items-center">
          <div
            :style="{ paddingTop: '3px', fontSize: '10px' }"
            class="text-white"
          >
            {{ counter }}
          </div>
        </div>
      </div>
    </template>
  </button>
</template>

<script>
import spinner from "./spinner";

export default {
  components: {
    spinner
  },
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    allowed: {
      type: Boolean,
      required: false,
      default: true
    },
    outlined: {
      type: Boolean,
      required: false,
      default: false
    },
    type: {
      type: String,
      required: false,
      default: ""
    },
    text: {
      type: String,
      required: false,
      default: ""
    },
    icon: {
      type: String,
      required: false,
      default: ""
    },
    color: {
      type: String,
      required: false,
      default: "green"
    },
    iconAlignment: {
      type: String,
      required: false,
      default: "left"
    },
    textSize: {
      type: String,
      required: false,
      default: "base"
    },
    vIcon: {
      type: String,
      required: false,
      default: null
    },
    to: {
      type: String,
      required: false,
      default: null
    },
    selected: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    iconClass: {
      type: String,
      default: ""
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    counter: {
      type: Number | String,
      default: 0
    }
  },
  data() {
    return {
      buttonText: {
        small: "text-sm",
        base: "text-base"
      }
    };
  },
  computed: {
    iconUrl() {
      if (this.icon) {
        return require(`~/assets/images/icons/${this.icon}.svg`);
      }
      return "";
    }
  },
  methods: {
    handleClick(e) {
      if (this.to) {
        e.preventDefault();
        e.stopPropagation();
        this.$router.push(this.to);
        return false;
      }
    }
  }
};
</script>

<style scoped lang="postcss">
button {
  @apply relative flex justify-center items-center font-default leading-none;
  @apply font-medium text-xs rounded-md outline-none;

  &:not(.dense) {
    min-height: 36px;
    &.padding-small {
      @apply px-4;
    }
    &.padding-large {
      @apply px-10;
    }
  }
  &.dense {
    min-height: 36px;
    &.padding-small {
      @apply px-3;
    }
    &.padding-large {
      @apply px-3;
    }
  }

  .button-text {
    @apply mx-auto;
  }

  &:disabled {
    @apply opacity-50 cursor-not-allowed;
  }
  &.circular {
    @apply bg-brand-mineShaft text-white;
    .button-text {
      display: none;
    }
  }
  &:hover {
    box-shadow: inset 0 0 100px 100px rgba(0, 0, 0, 0.04);
  }
  &.selected {
    box-shadow: inset 0 0 100px 100px rgba(0, 0, 0, 0.2);
  }
}

.grey {
  @apply bg-brand-athensGray text-brand-mineShaft;
}
.green {
  @apply bg-brand-willowBrook text-brand-green;
}
.red {
  @apply bg-brand-pinkSalmonLight text-brand-wildWatermelon;
}
.blue {
  @apply bg-brand-solitude text-black;
}
.brandGreen {
  @apply bg-brand-green text-white;
}

.grey.outlined {
  @apply border rounded border-brand-silver bg-transparent;
}
</style>
