<template>
  <div class="alert-item">
    <div class="alert-details">
      <div class="font-medium">
        <span @click="gotoAlertEntry(alert)" class="cursor-pointer">
          {{ $t(`tableComponent.alert.${alert.type}`) }}
        </span>
        <span
          v-if="alert.forCrop"
          @click="gotoAlertEntry(alert, true)"
          class="cursor-pointer"
          >⁠—
          {{ getCropName(alert.forCrop) }}
        </span>
        <span
          v-if="alert.data && alert.data.title"
          @click="gotoAlertEntry(alert, true)"
          class="cursor-pointer"
        >
          <br />
          {{ alert.data.title }}
          <div
            v-if="
              alert.data &&
              alert.data.description &&
              alert.data.description.length &&
              expanded
            "
            class="font-normal py-2"
          >
            {{ alert.data.description }}
          </div>
        </span>
      </div>
      <span class="mt-px cursor-pointer" @click="gotoAlertEntry(alert)">
        <span v-if="alert.user">{{ alert.user.name }} • </span>
        <span>{{ date }}</span>
      </span>
    </div>
    <div class="flex-1 px-1"></div>
    <div class="flex flex-col">
      <button
        v-if="!alert.seenBy.includes(userId) || !alert.isSeen"
        @click="
          alert.type === 'protocol'
            ? markProtocolAlertAsSeen(alert)
            : markAsSeen(alert.id)
        "
        class="action-button"
      >
        <v-icon name="bell" class="text-brand-pinkSalmon" scale="0.7" />
      </button>
      <button
        v-if="
          alert.data && alert.data.description && alert.data.description.length
        "
        @click="toggleExpand"
        :class="expandButtonClass"
      >
        <v-icon name="arrow-down" scale="0.7" />
      </button>
    </div>
  </div>
</template>
<script>
import {
  defineComponent,
  getCurrentInstance,
  useStore
} from "@nuxtjs/composition-api";
import { format } from "date-fns";
import Crop from "@/models/crop";
import { toDate } from "@/utils/date";
export default defineComponent({
  props: {
    alert: {
      type: Object,
      default: () => {}
    }
  },
  setup() {
    const instance = getCurrentInstance().proxy;
    const $db = instance.$db;
    const store = useStore();
    const userId = store.getters.currentUserId;
    const markAsSeen = async alertId => {
      await $db.alerts.markAlertAsSeen(alertId, userId);
      store.commit("MARK_ALERT_AS_SEEN", { alertId, userId });
    };
    const alertEntryPaths = {
      crop: "user-crops-crop",
      "new-task-due": "user-tasks",
      "lab-data": "user-crops-crop-lab",
      "lab-data-comment": "user-crops-crop-lab",
      "lab-data-pending": "general-labdata",
      "new-document": "general-documents",
      "new-advice": "general-advices",
      "new-sensor-trigger": "user-sensors",
      "new-note-mention": "user-crops-crop",
      protocol: "user-crops-crop",
    };
    const alertEntryQuery = {
      "new-task-due": ["id"],
      "lab-data-pending": ["id"],
      "new-document": ["id"],
      "new-advice": ["id"],
      "new-sensor-trigger": ["id"],
      "new-note-mention": ["id"],
    };
    const getAlertEntryPath = alert => {
      const route = alertEntryPaths[alert.type];
      const entryId = alert.entryId || alert.id;
      if (!route || !entryId) return null;
      const params = {
        id: entryId
      };
      if (alert.forCrop) params.crop = alert.forCrop.id;
      const query = alertEntryQuery[alert.type] || [];
      const queryParams = query.reduce((acc, queryParam) => {
        acc[queryParam] = entryId;
        return acc;
      }, {});
      return {
        name: route,
        params,
        query: queryParams
      };
    };
    const markProtocolAlertAsSeen = async alert => {
      await $db.markProtocolAlertAsSeen(alert.protocolId, alert.id, userId);
      store.commit("MARK_ALERT_AS_SEEN", { alertId: alert.id, userId });
    };
    const getCropName = crop => new Crop(crop, store).displayName;
    const formatDate = (...args) => format(toDate(...args), "MMM dd yyyy");
    return {
      getAlertEntryPath,
      getCropName,
      markAsSeen,
      userId,
      formatDate,
      markProtocolAlertAsSeen
    };
  },
  data() {
    return {
      expanded: false
    };
  },
  computed: {
    expandButtonClass() {
      return `action-button ${this.expanded ? `action-button-expanded` : ""}`;
    },
    date() {
      return this.formatDate(this.alert?.data?.date ?? this.alert.created);
    }
  },
  methods: {
    toggleExpand() {
      this.expanded = !this.expanded;
    },
    async gotoAlertEntry(alert, forCrop) {
      const alertObj = { ...alert };
      if (forCrop) alertObj.type = "crop";
      const pathParams = this.getAlertEntryPath(alertObj);

      if (pathParams) {
        if (alert.id) {
          if (alert.type === "protocol") {
            await this.markProtocolAlertAsSeen(alert);
          } else await this.markAsSeen(alert.id);
        }

        this.$router.push(this.localePath(pathParams));
        this.$emit("opened");
      }
    }
  }
});
</script>
<style lang="postcss" scoped>
.alert-item {
  @apply flex items-center pt-1 px-1;
  &:not(:last-child) {
    @apply mb-1 pb-2;
    @apply border-b border-solid border-brand-silver;
  }
  .alert-details {
    @apply flex flex-col flex-wrap mt-px text-xxs font-normal text-brand-manatee;
  }
}
.action-button:focus {
  outline: none !important;
  @apply transition ease-linear duration-75;
}
.action-button-expanded {
  transform: rotate(-180deg);
}
</style>
