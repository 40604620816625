import { BaseAction } from "./base-action";

export class CropsAction extends BaseAction {
  async getsById(ids) {
    return await Promise.all(
      ids.map(async id => {
        return await this.get(id);
      })
    );
  }
}
