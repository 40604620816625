import {
  sortBy,
  isArray,
  isObject,
  isEqual,
  isEqualWith,
  transform,
  cloneDeep
} from "lodash";
import getYear from "date-fns/getYear";
import getISOWeek from "date-fns/getISOWeek";
import endOfISOWeek from "date-fns/endOfISOWeek";
import startOfISOWeek from "date-fns/startOfISOWeek";
import { toDate } from "@/utils/date";

const getBusinessLocationFromLocation = (location, locations) => {
  if (!location || !locations) return null;

  const businessLocationId =
    location.values[0]?.parents?.businessLocation?.id || location.values[0]?.id;
  if (!businessLocationId) return null;

  const businessLoc = locations.find(loc => loc?.id === businessLocationId);
  return businessLoc;
};

const getLocationForDate = (crop, date) => {
  const locs = crop.profile?.locations || crop.locations || [];
  // only one location
  if (locs.length === 1) {
    return locs[0];
  }
  for (let i = 0; i < locs.length; i++) {
    const loc = locs[i];
    const match = loc.values.find(
      l => date >= toDate(l.startDate) && date <= toDate(l.endDate)
    );
    if (match) return loc;
  }
  return locs[locs.length - 1];
};

const getLocationFromIndex = (crop, index = 0) => {
  const locs = crop.profile?.locations || crop.locations || [];
  return locs?.slice(index)[0];
};

const getInitialLocation = crop => {
  return getLocationFromIndex(crop, 0);
};

const getActiveLocation = crop => {
  // gets the last location in the list
  return getLocationFromIndex(crop, -1);
};

const getWeatherDataForDate = (crop, date, $store) => {
  const location = getLocationForDate(crop, date);
  const { businessLocation = {} } = getNamesFromLocation(location, $store);
  const weather = businessLocation?.weatherData || null;

  if (!weather) return null;
  const key = `${getYear(date)}-${getISOWeek(date)
    .toString()
    .padStart(2, "0")}`;
  return weather[key];
};

const getNamesFromLocation = (location, $store) => {
  if (!location) return {};
  const idMap = $store.getters.getLocationMap;

  const locationsResult = {
    level2Locations: [],
    level1Location: [],
    businessLocation: null
  };
  for (const val of location.values) {
    const topLevelId = val.id;
    const level1Id = val.parents?.locationLevel1?.id;
    const businessId = val.parents?.businessLocation?.id || topLevelId;

    locationsResult.businessLocation = {
      id: businessId,
      ...idMap[businessId]
    };
    if (level1Id) {
      if (!locationsResult.level1Location.find(x => x.id === level1Id)) {
        locationsResult.level1Location.push({
          id: level1Id,
          ...idMap[level1Id]
        });
      }
      if (!locationsResult.level2Locations.find(x => x.id === topLevelId)) {
        locationsResult.level2Locations.push({
          id: topLevelId,
          ...idMap[topLevelId]
        });
      }
    } else if (businessId !== topLevelId) {
      if (!locationsResult.level1Location.find(x => x.id === topLevelId)) {
        locationsResult.level1Location.push({
          id: topLevelId,
          ...idMap[topLevelId]
        });
      }
    }
  }
  return locationsResult;
};

const getAllCurrentLocations = (crop, $store, offset = 0) => {
  // use offset to get the previous location if exists
  const location = getActiveLocation(crop, offset);
  return getNamesFromLocation(location, $store);
};

// groups active crop locations by level
const getParentsLocation = (currentCropLocations, locations) => {
  let parentsLocation = {
    businessLoc: null,
    level1Loc: [],
    level2Locs: []
  };

  const lastLocation = currentCropLocations?.slice(-1)[0];
  if (!lastLocation) return parentsLocation;

  const businessLoc = getBusinessLocationFromLocation(lastLocation, locations);
  if (!businessLoc) return parentsLocation;
  parentsLocation.businessLoc = businessLoc;

  const locationLevel1Ids = lastLocation.values.map(
    x => x.parents?.locationLevel1?.id || x.id
  );
  if (locationLevel1Ids.length) {
    parentsLocation.level1Loc = businessLoc.locationsLevel1.filter(location =>
      locationLevel1Ids.includes(location.id)
    );

    const locationLevel2Ids = lastLocation.values
      .map(x => x.parents?.locationLevel1?.id && x.id)
      .filter(x => x);
    if (locationLevel2Ids.length) {
      parentsLocation.level2Locs = parentsLocation.level1Loc
        .map(x => x.locationsLevel2)
        .flat()
        .filter(location => locationLevel2Ids.includes(location.id));
    }
  }
  return parentsLocation;
};

const hasLocationChanged = (loc1, loc2) => {
  const ignoreKeys = ["startDate", "endDate"];

  const sanitizeLocation = (value, key) => {
    if (ignoreKeys.includes(key)) {
      return true;
    } else if (isArray(value)) {
      return value.map((item) => sanitizeLocation(item, key));
    } else if (isObject(value)) {
      return transform(value, (result, val, k) => {
        result[k] = sanitizeLocation(val, k);
      });
    } else {
      return value;
    }
  };

  const sanitizedLoc1 = sanitizeLocation(loc1);
  const sanitizedLoc2 = sanitizeLocation(loc2);

  return !isEqualWith(sanitizedLoc1, sanitizedLoc2, (val1, val2) => {
    if (isArray(val1) && isArray(val2)) {
      const val = val1.length === val2.length && isEqual(val1, val2);
      return val;
    }
  });
};

const setStartDate = (loc, now) => {
  loc.startDate = now;
  loc.values = loc.values.map(locLevel2 => ({
    ...locLevel2,
    startDate: now
  }));
  return loc;
};

const expireLocation = loc => {
  // expire location by setting its endDate
  const now = new Date();
  loc.endDate = now;
  loc.values = loc.values.map(locLevel2 => ({
    ...locLevel2,
    endDate: now
  }));
  return loc;
};

const updateLocations = (oldLocations, newLocation, startDate) => {
  const newLocations = cloneDeep(oldLocations || []);
  const newLoc = setStartDate({ ...newLocation }, startDate || new Date());

  if (newLocations.length) {
    const lastIndex = newLocations.length - 1;
    const oldLoc = newLocations[lastIndex];
    const hasChanged = hasLocationChanged(oldLoc, newLoc);
    if (hasChanged) {
      newLocations[lastIndex] = expireLocation(oldLoc);
      newLocations.push(newLoc);
    }
    return newLocations;
  } else {
    return [newLoc];
  }
};

const getMiddleLocationName = (location, $store) => {
  const locs = getNamesFromLocation(location, $store);
  return [
    (locs.level2Locations || []).map(l => l.name?.trim()).join("•"),
    (locs.level1Location || []).map(l => l.name?.trim()).join("•")
  ]
    .filter(x => x)
    .reverse()
    .join("→");
};

const getLongLocationName = (location, $store) => {
  const locs = getNamesFromLocation(location, $store);
  return [
    locs.businessLocation?.name,
    (locs.level1Location || []).map(l => l.name?.trim()).join(", "),
    (locs.level2Locations || []).map(l => l.name?.trim()).join(", ")
  ]
    .filter(x => x)
    .join(" > ");
};

const getLongLocationNameWithSurface = (location, $store) => {
  const locs = getNamesFromLocation(location, $store);
  const formatSurface = location =>
    `${location.name?.trim()}${
      location.surfaceArea
        ? ` (${Math.round(location.surfaceArea * 100) / 100}m²)`
        : ""
    }`;
  return [
    locs.businessLocation?.name,
    (locs.level1Location || []).map(formatSurface).join(", "),
    (locs.level2Locations || []).map(formatSurface).join(", ")
  ]
    .filter(x => x)
    .join(" > ");
};

const genCropLine2 = crop => {
  const date = crop.profile?.duration?.pottingStart;
  const pottingDate = date && (date.toDate?.() || new Date(date));
  return [
    `${crop.profile?.potSize || ""}`,
    `${(pottingDate && formatWeekNr(pottingDate)) || ""}`
  ]
    .filter(e => e.trim())
    .join(" - ");
};

const genCropLine3 = (crop, $store) => {
  const location = getActiveLocation(crop);
  const maxLevelChars = [15, null, null];
  return getLongLocationName(location, $store)
    .split(" > ")
    .map((name, idx) =>
      maxLevelChars[idx] ? name.substring(0, maxLevelChars[idx]) : name
    )
    .join(" > ");
};

// return formatted objects (first line & secondary line)
// as options for the "select-crop" menu
const formatCropsOptions = (crops, $store) => {
  return sortBy(
    crops.map(crop => {
      const potDate = crop.profile?.duration?.pottingStart;
      const startDate = potDate
        ? potDate.toDate?.() || new Date(potDate)
        : null;
      return {
        // ...crop,
        id: crop.id,
        label: getDisplayName(crop),
        name: getDisplayName(crop),
        secondaryLine: genCropLine2(crop),
        tertiaryLine: genCropLine3(crop, $store),
        pottingDate: startDate,
        genus: crop.family?.genus?.name || crop.name,
        species: crop.family?.species?.name,
        cultivar: crop.family?.cultivar?.name || crop.label,
        species: crop.family?.species?.name,
        location: crop.profile?.location || null,
        locations: crop.profile?.locations || null,
        potSize: crop.profile?.potSize
      };
    }),
    "name"
  );
};

const isWithinRange = (date, start, end) => date >= start && date <= end;

const formatWeekNr = date => {
  const week = getISOWeek(date);
  const fourthOfJanNextYear = new Date(getYear(date) + 1, 0, 4);
  const year = isWithinRange(
    fourthOfJanNextYear,
    startOfISOWeek(date),
    endOfISOWeek(date)
  )
    ? getYear(date) + 1
    : getYear(date);
  return date && `${("0" + week).slice(-2)}/${year}`;
};

const getDisplayName = crop => {
  const genus = crop?.family?.genus?.name || crop?.name;
  const species = crop?.family?.species?.name || "";
  const cultivar = crop?.family?.cultivar?.name || crop?.label;
  return `${genus || ""}${species ? " " + species : ""} ${cultivar || ""}`;
};

export {
  hasLocationChanged,
  updateLocations,
  formatWeekNr,
  formatCropsOptions,
  getParentsLocation,
  getLocationForDate,
  getNamesFromLocation,
  getWeatherDataForDate,
  getDisplayName,
  getInitialLocation,
  getActiveLocation,
  getBusinessLocationFromLocation,
  getAllCurrentLocations,
  getMiddleLocationName,
  getLongLocationName,
  getLongLocationNameWithSurface,
  genCropLine3
};
