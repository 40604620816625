import { difference } from "lodash";

const PUBLIC_PATHS = [
  "sign-out",
  "sign-in",
  "sign-up",
  "third-party",
  "reset-password",
  "asuser",
  "substrate"
];

export default async function({ store, route, query, redirect }) {
  const needsAuth = PUBLIC_PATHS.every(
    publicPath => !route.path.includes(publicPath)
  );

  const authState = new Promise((resolve) => {
    if (store.state.rules) return resolve("auth");

    const unauth_CLIENT = () => process.client && !store.state.user?.id;
    if (unauth_CLIENT()) return resolve("unauth");

    const unauth_SERVER = () => {
      const unauthCriteria = [
        "nuxtServerInit",
        "STORAGE_READY"
      ].sort();
      const unauthQuery = store.state.unauth.slice().sort();
      return process.server && difference(unauthCriteria, unauthQuery).length === 0;
    };
    if (unauth_SERVER()) return resolve("unauth");

    let resolved = false;
    store.subscribe((mutation) => {
      if (mutation.type === "SET_RULES") {
        resolved = true;
        return resolve("auth");
      } else if (mutation.type === "SET_UNAUTH") {
        resolved = true;
        if (unauth_SERVER()) return resolve("unauth");
      }
    });

    setTimeout(() => {
      if (!resolved) resolve("bail"); // just in case
    }, 10000);
  })

  const isAuth = await authState;
  if (isAuth === "unauth") {
    if (needsAuth) {
      redirect({
        path: "/sign-in",
        query: {
          path: route.fullPath
        }
      });
    }
  } else if (needsAuth) {
    // sometimes we have a scenario where setting viewCompany will be omitted
    // so safest way to go is to redirect to /overview again
    // > also set viewCompanyId if applicable
    const { user: { type: userType } } = store.state;
    if (["advisor", "third-party"].includes(userType)) {
      if (query.companyId) {
        await store.dispatch("setViewCompanyId", query.companyId);
      }

      const { viewCompany } = store.state;
      const isOnOverview = route.path.includes("user/overview");
      const isOnAgreement = route.path.includes("agreement");

      if (!viewCompany && !isOnOverview && !isOnAgreement) {
        redirect({
          path: "/user/overview",
          query: {
            path: route.fullPath
          }
        });
      }
    }
  }
};