<template>
  <div class="layout-main-container">
    <main-navigation :disabled="needsUpdateAgreement !== false" />
    <div class="container layout-content-container">
      <Nuxt />
    </div>
    <footer-component v-if="$route.path !== '/user/crop-form'" />
    <confirmation-modal />
  </div>
</template>

<script>
import mainNavigation from "../components/main-navigation-v2.vue";
import footerComponent from "../components/footer-component";
import { mapGetters } from "vuex";

export default {
  components: {
    mainNavigation,
    footerComponent
  },
  middleware: ["authenticated"],
  computed: {
    ...mapGetters(["needsUpdateAgreement"])
  },
  mounted() {
    this.$sentry?.setUser({
      id: this.$store.getters.currentUserId
    });
    this.toggleOmnichannelWidget(this.$route);
  },
  watch: {
    $route: {
      handler: function(route) {
        this.toggleOmnichannelWidget(route);
      }
    }
  },
  methods: {
    toggleOmnichannelWidget(route) {
      // ensure omnichannel widget is visible only on 'user-crops-list' route
      const shouldDisplayOmnichannelWidget = route.name?.includes("user-crops-list");
      try {
        window.MessageBirdChatWidget[shouldDisplayOmnichannelWidget ? "show" : "hide"]();
      } catch (e) {}
    }
  }
};
</script>

<style lang="postcss">
.layout-main-container {
  @apply flex flex-col bg-layout-bg min-h-screen;
}
.layout-content-container {
  @apply flex flex-grow flex-col mt-0 mx-auto;
}
</style>
