import Vue from "vue";
import VueEasyLightbox from "vue-easy-lightbox";

Vue.use(VueEasyLightbox);

const original = Vue.component("vue-easy-lightbox");
Vue.component("vue-easy-lightbox", {
  name: "vue-easy-lightbox",
  extends: original,
  methods: {
    recenter() {
      this.top = 0
      this.left = 0
    },
    zoomOut() {
      const newScale = this.scale - (this.scale < 0.7 ? 0.1 : 0.12)
      if (newScale > 0.1) {
        this.zoom(newScale)
      }
      if (newScale <= 1) {
        this.$nextTick(() => {
          this.recenter()
        })
      }
    },
    handleTouchEnd(e) {
      cancelAnimationFrame(this.rafId)
      if (this.isGesturing && this.scale <= 1) {
        this.$nextTick(() => {
          this.recenter()
        })
      }

      this.isDraging = false
      this.isGesturing = false
      this.isTicking = false
    },
    handleDblClick() {
      if (this.scale !== this.imgBaseInfo.maxScale) {
        this.lastScale = this.scale
        this.scale = this.imgBaseInfo.maxScale
      } else {
        this.scale = this.lastScale
        this.$nextTick(() => {
          this.recenter()
        })
      }
    }
  }
});