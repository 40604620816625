import { isNumber } from "lodash";

const getFireStoreProp = value => {
  const props = { 'arrayValue': 1, 'bytesValue': 1, 'booleanValue': 1, 'doubleValue': 1, 'geoPointValue': 1, 'integerValue': 1, 'mapValue': 1, 'nullValue': 1, 'referenceValue': 1, 'stringValue': 1, 'timestampValue': 1 };
  return Object.keys(value).find(k => props[k] === 1);
};

export const firestoreParser = value => {
  const prop = getFireStoreProp(value);
  if (prop === 'doubleValue' || prop === 'integerValue') {
    value = Number(value[prop]);
  }
  else if (prop === 'arrayValue') {
    value = (value[prop] && value[prop].values || []).map(v => firestoreParser(v));
  }
  else if (prop === 'mapValue') {
    value = firestoreParser(value[prop] && value[prop].fields || {});
  }
  else if (prop === 'geoPointValue') {
    value = { latitude: 0, longitude: 0, ...value[prop] };
  }
  else if (prop === 'timestampValue') {
    value = value[prop] ? new Date(value[prop]) : null;
  }
  else if (prop) {
    value = value[prop];
  }
  else if (typeof value === 'object') {
    Object.keys(value).forEach(k => value[k] = firestoreParser(value[k]));
  }
  return value;
};

export const toDate = (dateish) => {
  if (dateish instanceof Date) {
    return dateish;
  }
  return dateish && dateish.toDate
    ? dateish.toDate()
    : isNumber(dateish)
    ? new Date(dateish)
    : null;
};
