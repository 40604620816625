import { defaultNote } from "@/services/notes";
import { get, set } from "lodash";

const getPrimaryValue = (data, $t, $te) => {
  const paths = [
    {
      path: "cropControl.chemical.name",
      translateKey: () => "cropForm.chemicalProtection.options"
    },
    {
      path: "cropControl.organic.name",
      translateKey: () => "cropForm.organicProtection.options"
    },
    {
      path: "cropControl.growthRegulator.name",
      translateKey: () => "cropForm.remmen.options"
    },
    {
      path: "growthRegulator.name",
      translateKey: () => "cropForm.remmen.options"
    },
    {
      path: "cropControl.floweringTreatment.name",
      translateKey: () => "cropForm.floweringTreatment.options"
    },
    {
      path: "floweringTreatment.name",
      translateKey: () => "cropForm.floweringTreatment.options"
    },
    {
      path: "waterFertilization.fertilization.name",
      translateKey: data =>
        `cropForm.fertilization.${data.waterFertilization?.fertilization?.type}.options.${value}`
    },
    // legacy
    {
      path: "waterFertilization.fertilization.spreadible",
      translateKey: () => "cropForm.fertilization.spreadible.options"
    },
    {
      path: "waterFertilization.fertilization.foliar",
      translateKey: () => "cropForm.fertilization.foliar.options"
    }
  ];

  let value;
  let foundPath;
  for (const path of paths) {
    value = get(data, path.path);
    foundPath = path;
    if (value) break;
  }

  if (value) {
    // try and find translated key
    const localeBaseKey = foundPath.translateKey(data);
    const localeKey = `${localeBaseKey}.${value}`;
    if ($te(localeKey)) {
      return {
        id: value,
        label: $t(localeKey)
      };
    } else {
      return {
        id: value,
        label: value
      };
    }
  }
};

const genCauseValue = (val, $t, $te) => {
  if (!val) return null;
  if ($te(`notesFeed.${val}`)) {
    return $t(`notesFeed.${val}`);
  } else if ($te(`cropForm.plague.options.${val}`)) {
    return $t(`cropForm.plague.options.${val}`);
  } else if ($te(`cropForm.fungus.options.${val}`)) {
    return $t(`cropForm.fungus.options.${val}`);
  } else if ($te(`cropForm.defectPhenomenon.options.${val}`)) {
    return $t(`cropForm.defectPhenomenon.options.${val}`);
  }
};

export const convertFromLegacy = note => {
  console.debug("legacy note", note);
  // mappings from new object path -> old object path[]
  // walk through the old objects paths to set on the new one
  const measurementMapping = {
    branch: ["branch"],
    // action
    "action.type": [
      "cropControl.id",
      "cropIntervention",
      "waterFertilization.id"
    ],
    "action.cause": ["observation.cause"],
    "action.value": [
      "waterFertilization.measurement.EC",
      "waterFertilization.measurement.water" // needs extra value: action.unit: min
    ],
    "action.fields.fertilizationType": [
      "waterFertilization.fertilization.type"
    ],
    // action product
    "action.product.name": [
      "waterFertilization.fertilization.name",
      "cropControl.chemical.name",
      "cropControl.organic.name",
      "cropControl.growthRegulator.name",
      "cropControl.floweringTreatment.name",
      "waterFertilization.fertilization.spreadible"
    ],
    "action.product.applied.total.value": [
      "waterFertilization.fertilization.productTotal.totalApplied",
      "cropControl.spreading.productTotal.totalApplied",
      "waterFertilization.fertilization.productTotal.amountAgent",
      "cropControl.chemical.productTotal.amountAgent",
      "cropControl.growthRegulator.productTotal.amountAgent",
      "cropControl.floweringTreatment.productTotal.amountAgent",
      "cropControl.chemical.productTotal.amount",
      "cropControl.organic.productTotal.totalApplied"
    ],
    // action - product fields
    "action.product.applied.fields.numberOfUnits.value": [
      "waterFertilization.fertilization.productTotal.numberOf",
      "cropControl.spreading.productTotal.numberOf",
      "cropControl.organic.productTotal.numberOf"
    ],
    "action.product.applied.fields.kgPerUnit.value": [
      "waterFertilization.fertilization.productTotal.kgPer"
    ],
    "action.product.applied.fields.tankage.value": [
      "waterFertilization.fertilization.productTotal.amount",
      "cropControl.growthRegulator.productTotal.tankage.size",
      "cropControl.chemical.tankage.size",
      "cropControl.chemical.productTotal.amount",
      "cropControl.growthRegulator.productTotal.amount",
      "cropControl.floweringTreatment.productTotal.amount"
    ],
    "action.product.applied.fields.concentration.value": [
      "waterFertilization.fertilization.productTotal.concentration",
      "cropControl.chemical.productTotal.concentration",
      "cropControl.growthRegulator.productTotal.concentration",
      "cropControl.floweringTreatment.productTotal.concentration",
      "cropControl.organic.productTotal.concentration"
    ],

    "action.product.applied.fields.pieces.value": [
      "cropControl.spreading.productTotal.pieces",
      "cropControl.organic.productTotal.pieces"
    ],
    "action.product.applied.surface.value": [
      "waterFertilization.fertilization.productTotal.surface",
      "cropControl.chemical.productTotal.surface",
      "cropControl.chemical.tankage.surface",
      "cropControl.growthRegulator.productTotal.surface",
      "cropControl.floweringTreatment.productTotal.surface"
    ],

    // units
    "action.product.applied.fields.concentration.unit": [
      "waterFertilization.fertilization.productTotal.concentrationUnit",
      "cropControl.chemical.productTotal.concentrationUnit",
      "cropControl.floweringTreatment.productTotal.concentrationUnit",
      "cropControl.growthRegulator.productTotal.concentrationUnit",
      "cropControl.organic.productTotal.concentrationUnit"
    ],
    "action.product.applied.fields.tankage.unit": [
      "waterFertilization.fertilization.productTotal.amountUnit",
      "cropControl.chemical.productTotal.amountUnit",
      "cropControl.floweringTreatment.productTotal.amountUnit",
      "cropControl.growthRegulator.productTotal.amountUnit"
    ],
    "action.product.applied.total.unit": [
      "waterFertilization.fertilization.productTotal.amountAgentUnit",
      "cropControl.chemical.productTotal.amountAgentUnit",
      "cropControl.floweringTreatment.productTotal.amountAgentUnit",
      "cropControl.organic.productTotal.totalAppliedUnit"
    ],

    // observation
    "observation.type": ["observation.name"],
    "observation.value": [
      "observation.defects",
      "observation.fungi",
      "observation.plague"
    ],
    "observation.fields.leaves": "observation.fields.leavesType",

    // measurement
    "measurement.type": [
      "measurement.name",
      "waterFertilization.fertilization.id"
    ],
    "measurement.value": [
      "measurement.ph",
      "measurement.ph.substrate",
      "measurement.length",
      "measurement.diameter",
      "measurement.numberOfBuds",
      "measurement.ec.substrate",
      "measurement.ec"
    ]
  };

  const newNote = defaultNote();
  const data = note.origData;
  if (!data) return note;

  for (const key in measurementMapping) {
    let val;
    for (const valueKey of measurementMapping[key]) {
      val = get(data, valueKey);
      if (val != null) {
        set(newNote, key, val);
        break;
      }
    }
  }
  // special cases
  if (get(data, "measurement.ec.substrate")) {
    set(newNote, "measurement.fields.sourceType", "substrate");
  }
  if (get(data, "measurement.ph.substrate")) {
    set(newNote, "measurement.fields.sourceType", "substrate");
  }
  if (get(data, "waterFertilization.fertilization.type") === "foliar") {
    set(newNote, "action.type", "fertilization-fertilization");
  }

  // add default units
  if (
    get(newNote, "action.product.applied.surface.value") &&
    !get(newNote, "action.product.applied.surface.unit")
  ) {
    set(newNote, "action.product.applied.surface.unit", "m2");
  }

  if (
    get(newNote, "measurement.type") === "length" &&
    !get(newNote, "measurement.unit")
  ) {
    set(newNote, "measurement.unit", "cm");
  }

  if (
    get(newNote, "action.type") === "fertilization-Water" &&
    get(newNote, "action.value") &&
    !get(newNote, "action.unit")
  ) {
    set(newNote, "action.unit", "min");
  }

  // weird case when there's only a concentration set (no total)
  // and unit is unknown
  if (
    ["chemicalProtection", "remmen"].includes(get(newNote, "action.type")) &&
    get(newNote, "action.product.applied.fields.concentration.value") &&
    !get(newNote, "action.product.applied.total.value")
  ) {
    set(
      newNote,
      "action.product.applied.fields.concentration.unit",
      "ml / 100 l"
    );
  }

  if (get(newNote, "measurement.value.substrate")) {
    set(
      newNote,
      "measurement.value",
      get(newNote, "measurement.value.substrate")
    );
  }

  const fields = {
    id: note.id,
    cropId: note.cropId,
    date: new Date(note.date * 1000),
    uid: note.uid,
    user: note.user,
    note: data.note || note.note || {},
    convertedFromLegacy: true,
    isFavorite: note.isFavorite
  };
  Object.assign(newNote, fields);
  return newNote;
};
