const alphanumNameSorter = (a, b) =>
  a.name.localeCompare(b.name, undefined, { numeric: true });

const REGION = {
  NL: "nl",
  BE: "be",
  DE: "de",
  UK: "uk",
  FR: "fr",
  NA: "na",
  SA: "sa",
  AS: "as",
  ES: "es",
  PL: "pl",
  CH: "ch",
  DK: "dk",
  PT: "pt",
  ALL: "all"
};

const potOptions = [
  {
    name: "Tray",
    slug: "tray",
    regions: [REGION.ALL]
  },
  {
    name: "P6,5",
    slug: "P6,5",
    regions: [REGION.ALL]
  },
  {
    name: "P7",
    slug: "P7",
    regions: [REGION.ALL]
  },
  {
    name: "P8",
    slug: "P8",
    regions: [REGION.ALL]
  },
  {
    name: "P8,5",
    slug: "P8,5",
    regions: [REGION.ALL]
  },
  {
    name: "P9",
    slug: "P9",
    regions: [REGION.ALL]
  },
  {
    name: "P9,5",
    slug: "P9,5",
    regions: [REGION.ALL]
  },
  {
    name: "P10",
    slug: "P10",
    regions: [REGION.ALL]
  },
  {
    name: "P10,5",
    slug: "P10,5",
    regions: [REGION.ALL]
  },
  {
    name: "P11",
    slug: "P11",
    regions: [REGION.ALL]
  },
  {
    name: "P12",
    slug: "P12",
    regions: [REGION.ALL]
  },
  {
    name: "C1",
    slug: "C1",
    regions: [REGION.ALL]
  },
  {
    name: "P13",
    slug: "P13",
    regions: [REGION.ALL]
  },
  {
    name: "P14 - C1,5",
    slug: "P14 / C1,5",
    regions: [REGION.ALL]
  },
  {
    name: "P15",
    slug: "P15",
    regions: [REGION.ALL]
  },
  {
    name: "P16",
    slug: "P16",
    regions: [REGION.ALL]
  },
  {
    name: "P17 - C2",
    slug: "P15 / C2",
    regions: [REGION.ALL]
  },
  {
    name: "P18 - C2,5",
    slug: "P18",
    regions: [REGION.ALL]
  },
  {
    name: "P19 - C3",
    slug: "P17 / C3",
    regions: [REGION.ALL]
  },
  {
    name: "C3,6",
    slug: "C3,6",
    regions: [REGION.ALL]
  },
  {
    name: "P20",
    slug: "P20",
    regions: [REGION.ALL]
  },
  {
    name: "P21 - C4",
    slug: "P19 / C4",
    regions: [REGION.ALL]
  },
  {
    name: "C4,5",
    slug: "C4,5",
    regions: [REGION.ALL]
  },
  {
    name: "C4,6",
    slug: "C4,6",
    regions: [REGION.ALL]
  },
  {
    name: "P23 - C5",
    slug: "C5",
    regions: [REGION.ALL]
  },
  {
    name: "P24",
    slug: "P24",
    regions: [REGION.ALL]
  },
  {
    name: "P25",
    slug: "P25",
    regions: [REGION.ALL]
  },
  {
    name: "P26 - C7,5",
    slug: "C7,5",
    regions: [REGION.ALL]
  },
  {
    name: "P27",
    slug: "P27",
    regions: [REGION.ALL]
  },
  {
    name: "P28 - C10",
    slug: "C10",
    regions: [REGION.ALL]
  },
  {
    name: "P30 - C12",
    slug: "C12",
    regions: [REGION.ALL]
  },
  {
    name: "C13",
    slug: "C13",
    regions: [REGION.ALL]
  },
  {
    name: "C15",
    slug: "C15",
    regions: [REGION.ALL]
    },
  {
    name: "C43",
    slug: "C43",
    regions: [REGION.ALL]
   },
  {
    name: "P40 - C30",
    slug: "P40C30",
    regions: [REGION.ALL]
  },
  {
    name: "P29",
    slug: "P29",
    regions: [REGION.ALL]
  },
  {
    name: "4-Pack",
    slug: "4P",
    regions: [REGION.ALL]
  },
  {
    name: "6-Pack",
    slug: "6P",
    regions: [REGION.ALL]
  },
  {
    name: "10-Pack",
    slug: "10P",
    regions: [REGION.ALL]
  },
  {
    name: "P30",
    slug: "P30",
    regions: [REGION.ALL]
  },
  {
    name: "P38 - C18",
    slug: "C18",
    regions: [REGION.ALL]
  },
  {
    name: "P34 - C20",
    slug: "P34C20",
    regions: [REGION.ALL]
  },
  {
    name: "P38 - C25",
    slug: "C25",
    regions: [REGION.ALL]
  },
  {
    name: "P40",
    slug: "C30",
    regions: [REGION.ALL]
  },
  {
    name: "P35",
    slug: "P35",
    regions: [REGION.ALL]
  },
  {
    name: "P40 - C35",
    slug: "C35",
    regions: [REGION.ALL]
  },
  {
    name: "P48 - C40",
    slug: "C40",
    regions: [REGION.ALL]
  },
  {
    name: "P50 - C50",
    slug: "C50",
    regions: [REGION.ALL]
  },
  {
    name: "P54 - C65",
    slug: "C65",
    regions: [REGION.ALL]
  },
  {
    name: "P55 - C70",
    slug: "C70",
    regions: [REGION.ALL]
  },
  {
    name: "P63 - C85",
    slug: "C85",
    regions: [REGION.ALL]
  },
  {
    name: "P65 - C90",
    slug: "C90",
    regions: [REGION.ALL]
  },
  {
    name: "P60 - C100",
    slug: "C100",
    regions: [REGION.ALL]
    },
  {
    name: "P120 - C500",
    slug: "C500",
    regions: [REGION.ALL]
  },
  {
    name: "P60 - C110",
    slug: "C110",
    regions: [REGION.ALL]
  },
  {
    name: "P140 - C1000",
    slug: "C1000",
    regions: [REGION.ALL]
   },
  {
    name: "P145/C800",
    slug: "C800",
    regions: [REGION.ALL]
   },
  {
    name: "P32 - C15",
    slug: "P32C15",
    regions: [REGION.ALL]
    },
  {
    name: "P33 - C17",
    slug: "P33C17",
    regions: [REGION.ALL]
    },
  {
    name: "P33 - C20",
    slug: "P33C20",
    regions: [REGION.ALL]
    },
  {
    name: "P36 - C26",
    slug: "P36C26",
    regions: [REGION.ALL]
    },
  {
    name: "P42 - C40",
    slug: "P42C40",
    regions: [REGION.ALL]
    },
  {
    name: "P47 - C45",
    slug: "P47C45",
    regions: [REGION.ALL]
    },
  {
    name: "P45 - C35",
    slug: "P45C35",
    regions: [REGION.ALL]
    },
  {
    name: "P60 - C90",
    slug: "P60C90",
    regions: [REGION.ALL]
    },
  {
    name: "P50,5 - C55 ",
    slug: "P505C55",
    regions: [REGION.ALL]
    },
  {
    name: "P65 - C110",
    slug: "P65C110",
    regions: [REGION.ALL]
    },
  {
    name: "P70 - C130",
    slug: "P70C130",
    regions: [REGION.ALL]
    },
  {
    name: "P80 - C180",
    slug: "P80C180",
    regions: [REGION.ALL]
    },
  {
    name: "P85 - C230",
    slug: "P85C230",
    regions: [REGION.ALL]
    },
  {
    name: "P95 - C285",
    slug: "P95C285",
    regions: [REGION.ALL]
    },
  {
    name: "P22",
    slug: "p22",
    regions: [REGION.ALL]
    },
  {
    name: "Water",
    slug: "water",
    regions: [REGION.ALL]
  },
  {
    name: "Mat",
    slug: "mat",
    regions: [REGION.ALL]
  },
  {
    name: "Growbag",
    slug: "growbag",
    regions: [REGION.ALL]
  },
  {
    name: "Slab",
    slug: "slab",
    regions: [REGION.ALL]
  },
  {
    name: "substrate",
    slug: "substrate",
    regions: [REGION.ALL]
  },
  {
    name: "Vollegrond",
    slug: "vollegrond",
    regions: [REGION.ALL]
  },
  {
    name: "Growcoon",
    slug: "growcoon",
    regions: [REGION.ALL]
  },
  {
    name: "overig",
    slug: "Overig",
    regions: [REGION.ALL]
  }
].sort(alphanumNameSorter);

export default regions =>
  potOptions
    .filter(potSize => {
      if (!regions || !potSize.regions || potSize.regions.includes(REGION.ALL)) return true;
      return potSize.regions.some(potRegion => regions.includes(potRegion));
    })
    .map(p => ({ name: p.name, slug: p.slug }));
