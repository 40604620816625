import axios from "axios";
import {
  sortedIndexBy,
  camelCase,
  lowerCase,
  snakeCase,
  sortBy,
  groupBy,
  pick,
  uniq,
  round,
  isNil
} from "lodash";
import { getMetricType } from "../utils/sensor-graphs";

const API_KEY = "E3GlUegzkTDP28lYMgDSMGGrOrX9l1Su";

const unitMap = {
  C: "℃",
  lx: "Lux",
  battery_voltage_events: "V",
  geolocation_events: "",
  soil_electric_conductivity_events: "",
};
const allowedPropTypes = [
  // High soil moisture
  "53f374ae-1ba1-424f-8f97-d3631bcad44f",
  // Low soil moisture
  "6d6442dc-fb4d-465b-a749-fea69094200f",
  // High temperate
  "5adabe44-8b55-48a4-864d-7157300c57cf",
  // Low temperate
  "77c418b0-53f5-4943-a675-fec7eb4dfc7a"
];

const roundingRules = {
  conductivity: 3
};

const toEntries = (companyId, subject, metricId, measurements) => {
  const sensor = subject.sensors.find(x => x.id === metricId);
  const sensorObj = {
    companyId: companyId,
    subjectIdInt: subject.id,
    subjectId: subject.id,
    subjectType: subject.typeName,
    subjectName: subject.name,
    sensorId: sensor.sensorId,
    type: getMetricType(sensor.sensorId, true),
    sensorName: sensor.name,
    unit: unitMap[sensor.unit] || sensor.unit || unitMap[sensor.sensorId],
    source: "blockbax",
    crops: null,
    minValue: sensor.minCrit,
    maxValue: sensor.maxCrit
  };
  const roundingRule = roundingRules[sensorObj.type] || 2;

  return measurements.map(measurement => {
    const measurementObj = {
      value: round(measurement.number, roundingRule),
      status:
        measurement.number > sensorObj.maxValue
          ? "HIGH"
          : measurement.number < sensorObj.minValue
          ? "LOW"
          : "OK",
      date: new Date(measurement.date)
    };
    return Object.assign({}, sensorObj, measurementObj);
  });
};

const getSensorRange = (subject, metric) => {
  return subject.propertyTypes.reduce(
    (acc, propertyType) => {
      const metricName = lowerCase(metric.name);
      const [type, ...nameArr] = lowerCase(propertyType.name).split(" ");
      const name = nameArr.join(" ");

      const match = metricName.includes(name) || name.includes(metricName);
      if (match && propertyType.value != null) {
        if (type === "low") acc.minCrit = propertyType.value;
        if (type === "high") acc.maxCrit = propertyType.value;
      }
      return acc;
    },
    { minCrit: -Infinity, maxCrit: Infinity }
  );
};

const prepareEntry = (entry, { subjectsMap, metricsMap }) => {
  const { companyId, subjectId, metricId, measurements } = entry;
  const subject = subjectsMap[subjectId];
  const metric = metricsMap[metricId];
  const sensorRange = getSensorRange(subject, metric);

  const subjectObj = {
    id: subject.id,
    name: subject.name,
    external: subject.external,
    typeName: subject.subjectTypeId,
    sensors: [
      {
        id: metric.id,
        name: metric.name,
        sensorId: metric.key,
        unit: metric.unit,
        maxCrit: sensorRange.maxCrit,
        minCrit: sensorRange.minCrit
      }
    ]
  };
  return toEntries(companyId, subjectObj, metricId, measurements);
};

export const apiFactory = (sensorConfig, $db) => {
  const baseUrl =
    "https://api.blockbax.com/v1/projects/cd5207bc-5f9e-4aea-b2c7-0e9469972bc7";
  const http = axios.create({
    baseURL: baseUrl
  });

  http.interceptors.request.use(config => {
    config.headers.Authorization = `ApiKey ${API_KEY}`;
    return config;
  });

  const get = (() => {
    const cache = {};
    return async url => {
      if (cache[url]) return cache[url];
      const res = await http.get(url).then(res => res.data);
      cache[url] = res;
      return res;
    };
  })();
  const put = (url, body) => http.put(url, body).then(res => res.data);

  const processMetrics = (metrics) => {
    const mappedMetrics = metrics.map((metric) => {
      if (!metric.externalId) // please check if always correct
        metric.externalId = snakeCase(metric.name.replace("(", "").replace(")", ""));
      return metric;
    });
    return mappedMetrics.filter((metric) => {
      const allInputMetrics = mappedMetrics.map(m => !m.inputMetrics || m.inputMetrics.map(x => x.id)).flat();
      if (allInputMetrics.includes(metric.id)) return false;
      return true;
    });
  };

  return {
    get,
    async getSubjects(companyId) {
      if (!sensorConfig?.[companyId]) {
        const updatedSensorConfig = await $db.getSensorConfig("blockbax", companyId);
        sensorConfig = Object.assign({}, sensorConfig || {}, updatedSensorConfig);
      }

      const propId = sensorConfig[companyId];
      if (!propId) return [];

      const { result: subjectReq } = await get(
        `/subjects?size=200&propertyValueIds=${propId}`
      );

      const subjectTypeIds = uniq(subjectReq.map(x => x.subjectTypeId));
      const allMetrics = await this.getMetricsSti(subjectTypeIds);
      const processedMetrics = processMetrics(allMetrics);
      const groupedMetrics = groupBy(processedMetrics, "subjectTypeId");

      const subjectMappedReq = subjectReq.map(async subject => {
        subject.propertyTypes = await this.getPropertyTypes(subject);
        const metrics = [];
        for (const sensor of groupedMetrics[subject.subjectTypeId]) {
          // get rid of legacy "Light %" measurements, as we favor the Lux unit now.
          if (sensor.name === "Light (%)") continue;

          const lastMeasurement = await this.getLastStoredMeasurement({ subject, sensor });
          const metricType = getMetricType(sensor.externalId, true);
          if (!metricType) continue;

          const metric = {
            id: sensor.id,
            key: sensor.externalId,
            type: metricType,
            name: sensor.name,
            sensorId: sensor.id,
            unit: unitMap[sensor.unit] || sensor.unit || unitMap[sensor.externalId],
            lastValue: lastMeasurement?.value ?? null
          };

          const sensorRange = getSensorRange(subject, metric);
          Object.assign(metric, sensorRange);

          metrics.push(metric);
        }
        subject.metrics = sortBy(metrics, "name");
        subject.key = subject.id;
        subject.external = subject.externalId;
        subject.provider = "blockbax";
        return subject;
      });
      return Promise.all(subjectMappedReq);
    },
    async getPropertyTypes(subject) {
      const getExtraProps = prop => {
        const name = prop?.name?.toLowerCase() || "";
        const extraProps = {
          name: camelCase(name),
          unit: ""
        };

        if (name.toLowerCase().includes("moisture")) {
          extraProps.unit = "%";
          extraProps.minValue = 0;
        } else if (name.toLowerCase().includes("temperature")) {
          extraProps.unit = "℃";
        }
        return extraProps;
      };

      const { propertyTypes: propTypes } = await this.getSubjectType(
        subject.subjectTypeId
      );
      const propTypesData = await Promise.all(
        propTypes.map(t => this.getProperty(t.id))
      );
      const propertyTypes = propTypesData
        .filter(p => allowedPropTypes.includes(p.id))
        .map(p => {
          const prop = subject.properties.find(e => e.typeId === p.id);
          const val = prop?.number;
          const extraProps = getExtraProps(p);
          return { ...p, value: val ?? null, ...extraProps };
        });
      return propertyTypes;
    },
    getProperty(id) {
      return get(`/propertyTypes/${id}`);
    },
    getMetric(id) {
      return get(`/metrics/${id}`);
    },
    getMetricsSti(subjectTypeIds) {
      return get(`/metrics?subjectTypeIds=${subjectTypeIds.join(",")}`)
        .then(data => data.result)
        .then(data => data.filter(x => {
          return (!x.inputMetrics || x.inputMetrics.length) && x.visible;
        }));
    },
    async getSubject(id) {
      const subject = await get(`/subjects/${id}`);
      const allMetrics = await this.getMetricsSti([subject.subjectTypeId]);
      const processedMetrics = processMetrics(allMetrics);

      const metrics = [];
      for (const sensor of processedMetrics) {
        // get rid of legacy "Light %" measurements, as we favor the Lux unit now.
        if (sensor.name === "Light (%)") continue;

        metrics.push({
          id: sensor.id,
          key: sensor.externalId,
          name: sensor.name,
          sensorId: sensor.id,
          unit: unitMap[sensor.unit] || sensor.unit || unitMap[sensor.externalId]
        });
      }
      subject.metrics = sortBy(metrics, "name");
      subject.key = subject.id;
      subject.external = subject.externalId;
      subject.propertyTypes = await this.getPropertyTypes(subject);
      subject.provider = "blockbax";
      return subject;
    },
    getSubjectType(id) {
      return get(`/subjectTypes/${id}`);
    },
    getMeasurements(
      ids,
      fromDate = "2020-01-01T00:00:00.000Z",
      toDate = "2023-01-15T00:00:00.000Z",
      count = null,
      order = null
    ) {
      const proms = ids.map(({ subjectId, metricId }) =>
        this.getMeasurement(
          { subjectId, metricId },
          fromDate,
          toDate,
          count,
          order
        )
      );
      return Promise.all(proms);
    },
    getMeasurement(
      { subjectId, metricId },
      fromDate = "2020-01-01T00:00:00.000Z",
      toDate = "2023-01-15T00:00:00.000Z",
      count = null,
      order = null
    ) {
      let path = `/measurements?subjectIds=${subjectId}&metricIds=${metricId}`;

      if (fromDate) path = `${path}&fromDate=${fromDate}`;
      if (toDate) path = `${path}&toDate=${toDate}`;
      if (count) path = `${path}&size=${count}`;
      if (order) path = `${path}&order=${order}`;

      return get(path).then(res => {
        return { id: metricId, data: res.series[0] };
      });
    },
    async getStoredMeasurements(
      { companyId, subjects, metrics },
      fromDate = new Date(),
      toDate = new Date()
    ) {
      const ids = subjects
        .map(subject =>
          metrics.map(metric => ({
            subjectId: subject.id,
            metricId: metric.id
          }))
        )
        .flat();
      const subjectsMap = subjects.reduce((acc, subject) => {
        acc[subject.id] = subject;
        return acc;
      }, {});
      const metricsMap = metrics.reduce((acc, metric) => {
        acc[metric.id] = metric;
        return acc;
      }, {});

      const measurements = await this.getMeasurements(
        ids,
        fromDate.toISOString(),
        toDate.toISOString()
      );
      const measurementsP = measurements
        .map(x => x.data)
        .filter(x => x)
        .map(entry =>
          prepareEntry({ ...entry, companyId }, { subjectsMap, metricsMap })
        );
      const measurementsRes = await Promise.all(measurementsP);
      const measurementsArr = measurementsRes.flat();

      const dbMeasurements = await $db.getStoredMeasurements(
        { companyId, subjects, metrics },
        fromDate,
        toDate
      );
      for (const dbMeasurement of dbMeasurements) {
        measurementsArr.splice(
          sortedIndexBy(measurementsArr, dbMeasurement, "date"),
          0,
          dbMeasurement
        );
      }
      return measurementsArr;
    },
    getStoredMeasurement(measurementId) {
      return $db.getStoredMeasurement(measurementId, "blockbax");
    },
    async getLastStoredMeasurements({ companyId, subjects, metrics }) {
      const ids = subjects
        .map(subject => metrics.map(sensor => ({ companyId, subject, sensor })))
        .flat();
      const subjectsMap = subjects.reduce((acc, subject) => {
        acc[subject.id] = subject;
        return acc;
      }, {});
      const metricsMap = metrics.reduce((acc, metric) => {
        acc[metric.id] = metric;
        return acc;
      }, {});

      const proms = ids.map(async ({ subject, sensor }) => {
        const measurement = await this.getLastStoredMeasurement({
          companyId,
          subject,
          sensor
        });
        if (!measurement) return null;

        const [preparedMeasurement] = prepareEntry(
          {
            companyId,
            subjectId: subject.id,
            metricId: sensor.id,
            measurements: [measurement]
          },
          { subjectsMap, metricsMap }
        );
        return preparedMeasurement;
      });
      const measurements = await Promise.all(proms);
      return measurements.filter(x => x);
    },
    async getLastStoredMeasurement({ subject, sensor }) {
      const measurementData = await this.getMeasurement(
        {
          subjectId: subject.id,
          metricId: sensor.id
        },
        null,
        null,
        1
      );
      const measurement = measurementData?.data?.measurements.pop();
      if (!measurement) return null;

      measurement.value = round(measurement.number, 2);
      return measurement;
    },
    saveSubject(subjectObj) {
      // loop over the activeSubject.propTypes (temporarily holding the property types of the currently active subject)
      // reflect the new values on the active subject.properties
      const { properties, propertyTypes: propTypes } = subjectObj;
      propTypes
        .filter(p => !isNil(p.value))
        .forEach(prop => {
          // find property type index in the currnetly active subject.properties array
          const index = properties.findIndex(p => p.typeId === prop.id);
          // skip if not changed
          if (index >= 0 && properties[index].number === prop.value) {
            return;
          }
          // update if changed
          if (index >= 0) {
            properties[index] = { typeId: prop.id, number: prop.value };
          } else {
            // add new if doesn't exist
            properties.push({ typeId: prop.id, number: prop.value });
          }
        });
      const normalizedProps = properties.map(({ typeId, valueId, number }) =>
        !isNil(valueId) ? { typeId, valueId } : { typeId, number }
      );
      const subject = {
        ...subjectObj,
        properties: normalizedProps
      };

      const sub = {
        ...pick(subject, ["name", "subjectTypeId", "externalId"]),
        ingestionIds: subject.ingestionIds.map(
          ({ metricId, deriveIngestionId }) => ({
            metricId,
            deriveIngestionId
          })
        ),
        properties: subject.properties.map(({ typeId, valueId, number }) => ({
          typeId,
          ...(isNil(number) ? { valueId } : {}),
          ...(isNil(number) ? {} : { number })
        }))
      };
      return put(`/subjects/${subject.id}`, sub);
    }
  };
};

export default ({ $db }, inject) => {
  // Inject `api` key
  // -> app.blockbax
  // -> this.$blockbax in vue components
  // -> this.$blockbax in store actions/mutations
  const api = apiFactory(null, $db);
  inject("blockbax", api);
};
