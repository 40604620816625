<template>
  <div class="pdfmake">
    <iframe
      :width="svgedDoc.pageSize.width + svgedDoc.pageMargins[0]"
      :height="svgedDoc.pageSize.height + svgedDoc.pageMargins[1]"
      :src="pdfUrl || ''"
    />
    <div class="flex space-x-4 mt-4">
      <button-component
        :text="$t('pdfmake.regeneratePdf')"
        color="grey"
        :allowed="allowed"
        @click="generatePreview"
      />
      <button-component
        v-if="pdfInstance"
        :text="$t('pdfmake.downloadPdf')"
        :allowed="allowed"
        @click="startDownload"
      />
    </div>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import pdfmake from "@/services/pdfmake";

import buttonComponent from "./button-component";

const findSvgs = (object, arr = [], key = "svg") => {
  Object.keys(object).forEach(k => {
    if (k === key) {
      arr.push(object[k]);
    }
    if (object[k] && typeof object[k] === "object") {
      findSvgs(object[k], arr, key);
    }
  });
  return arr;
};

const insertSvgs = (object, svgMap = {}, key = "svg") => {
  Object.keys(object).forEach(k => {
    if (k === key) {
      let path = object[k];
      let svg = svgMap[path];
      if (svg) object[k] = svg;
    }
    if (object[k] && typeof object[k] === "object") {
      insertSvgs(object[k], svgMap, key);
    }
  });
  return object;
};

const getSvgXml = path => {
  return new Promise((resolve, reject) => {
    const alreadySvg = path.includes("<svg");
    const promise = !alreadySvg
      ? fetch(path).then(res => res.text())
      : Promise.resolve(path);
    promise
      .then(data => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(data, "image/svg+xml");
        const svg = doc.querySelector("svg").outerHTML;
        resolve({ path, xml: svg });
      })
      .catch(e => reject(e));
  });
};

export default {
  components: {
    buttonComponent
  },
  props: {
    doc: {
      type: Object,
      required: true
    },
    allowed: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      svgedDoc: cloneDeep(this.doc),
      svgCache: {},
      pdfInstance: null,
      pdfUrl: null
    };
  },
  computed: {
    svgsLoaded() {
      return !findSvgs(this.svgedDoc).find(x => !x.includes("<svg"));
    }
  },
  watch: {
    doc: {
      deep: true,
      handler: function (val) {
        this.svgedDoc = cloneDeep(val);
        this.generatePreview();
      }
    }
  },
  mounted() {
    this.generatePreview();
  },
  methods: {
    async generatePreview() {
      if (!this.svgsLoaded) {
        const svgs = await Promise.all(
          findSvgs(this.svgedDoc)
            .filter(path => !this.svgCache[path])
            .map(svg => getSvgXml(svg))
        );
        const svgsMap = svgs.reduce((acc, item) => {
          acc[item.path] = item.xml;
          return acc;
        }, {});
        this.svgCache = Object.assign({}, this.svgCache, svgsMap);
        this.svgedDoc = insertSvgs(cloneDeep(this.svgedDoc), this.svgCache);
      }

      this.pdfInstance = pdfmake.createPdf(this.svgedDoc);
      this.pdfInstance.getBlob(blob => {
        const url =
          URL.createObjectURL(blob) + "#toolbar=0&navpanes=0&scrollbar=0";
        this.pdfUrl = url;
      });
    },
    async startDownload() {
      this.pdfInstance.download(
        `${this.doc.info.title.toLowerCase().replaceAll(" ", "-")}.pdf`
      );
    }
  }
};
</script>
