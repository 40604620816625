<template>
  <nav class="main-navigation">
    <ul>
      <li>
        <nuxt-link
          :to="
            localePath({
              name: 'user-slug-crops-crop',
              params: { slug: userSlug }
            })
          "
        >
          {{ $t("dashboard.components.mainNavigation[0].text") }}
        </nuxt-link>
      </li>
      <li>
        <nuxt-link
          :to="
            localePath({
              name: 'user-slug-datastudio',
              params: { slug: userSlug }
            })
          "
        >
          {{ $t("dashboard.components.mainNavigation[1].text") }}
        </nuxt-link>
      </li>
      <li>
        <nuxt-link :to="localePath('weather')">
          {{ $t("dashboard.components.mainNavigation[2].text") }}
        </nuxt-link>
      </li>
      <li>
        <nuxt-link
          :to="
            localePath({
              name: 'user-slug-profile',
              params: { slug: userSlug }
            })
          "
        >
          {{ $t("dashboard.components.mainNavigation[3].text") }}
        </nuxt-link>
      </li>

      <li>
        <nuxt-link
          :to="
            localePath({
              name: 'user-slug-sensors',
              params: { slug: userSlug }
            })
          "
        >
          Sensors
        </nuxt-link>
      </li>
      <li>
        <a
          href="https://webshop.klasmann-deilmann.com/login"
          target="_blank"
          class="main-navigation__link--launch"
        >
          {{ $t("dashboard.components.mainNavigation[5].text") }}
        </a>
      </li>
      <li>
        <nuxt-link :to="localePath('faq')">
          FAQ
        </nuxt-link>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      userSlug: this.$store.state.user.slug
    };
  }
};
</script>

<style>
.main-navigation ul {
  list-style: none;
}

.main-navigation li {
  margin: 1rem;
  font-weight: 400;
}

.main-navigation a {
  color: hsl(0, 100%, 100%);
  position: relative;
  display: block;
  padding-left: 24px;
  white-space: nowrap;
}

.main-navigation a.nuxt-link-active,
.main-navigation .active {
  font-weight: 700;
  background-image: url("../assets/icons/chevron-right.svg");
  background-repeat: no-repeat;
  background-size: 24px 24px;
  background-position: left center;
}

.main-navigation__link--launch {
  padding-right: 24px;
  background-image: url("../assets/icons/external.svg");
  background-repeat: no-repeat;
  background-size: 16px 16px;
  background-position: right 4px;
}
</style>
