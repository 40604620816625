<template>
  <div id="scandit-barcode-picker" class="scanner-container" ref="scanner"></div>
</template>

<script>
import * as ScanditSDK from "scandit-sdk";

const LICENCE_KEY = "AaNBpg7pL16VN0WA9yMVWTsju2QaBoqiwn2E68Zd/D7UXKUe+FfFudp48stbMvRJEBAlEIRDDlGsVB2v+VWA8XAApoTiV45a4kH5EBV/bqNadXdFJ0PuMqRln+wgaQs/WAS3zIl0TTWRXlCYgHL1aXQqHVwQKdJfsSaF0iEQsTfSOSIHTQ/slfVsxxlrXNGjMXgA0WtXrv43CXvr44B3MoXewpC/UK3+i/ctg9A67Fh4tP7WTUWlHXv/0dOUZW5fjocEU5tt279frNlx+c2ODvUBBgjKuD5v98tjaELJX2eXKbcsabgPpnpIpISeCca3ny6N16SYCbzmqQyjMMNYz7kfMVCYRdS3QjxIHSoD9rC6OC6U6NdBk0oMuumxzlceBUOL7YsHM0h2dRfYpMy+WZxCGvAqXkQuWQNuAGLE9a6b73+5+iIqGf0t9hR7aP4yU2HMcrMUgRDj1zU/EkkR9ropcZaFGf0zeSwIrMwg4N7geCvdS5RBheyp8EWoJulEIxiy9/aLz/Fdaca2/0fvi2Kx/Bl2brO9iz/FW3LASUumaOj9PWuojWU2hqSijdRqv8ItCerR7sU5Y7+n1M9L148IR+GscwYKuClVDIQfJBeb8mqWk/9mTXzWw8V+6V8beq0x3aZcUQX+3iD15E+J8h+VdwA3APIPAfrqjJk+wi6zwmoPGFWxvbUc7hkxyZPO8KccPf6ppOcIVXZ3z8aBV0K1eIhnvqNF5EEvktHTRoV1FbaVHOow4XS86EjFvgrPjL9pqTOYv+gopxnUSYCfQEASQxecznwfgVlDglD5owcDz215Wo3LTITs6PT45kyNaIW+X7E1R+08aX+Gz0pPQqkH5WcObtueGStsYBw3ZqdhfVjRBRU1";

export default {
  mounted() {
    if (!this.isMediaStreamAPISupported) {
      throw new Error("Media Stream API is not supported");
    }
    
    ScanditSDK.configure(LICENCE_KEY, {
      engineLocation: "https://cdn.jsdelivr.net/npm/scandit-sdk@5.x/build/"
    })
      .then(() => {
        return ScanditSDK.BarcodePicker.create(this.$refs.scanner, {
          scanSettings: new ScanditSDK.ScanSettings({ enabledSymbologies: ["itf", "qr"], codeDuplicateFilter: -1 }),
          guiStyle: 1,
          videoFit: "cover"
        });
      })
      .then((barcodePicker) => {
        barcodePicker.on("scan", (scanResult) => {
          this.onScanSuccess(null, scanResult.barcodes[0]);
        });
        this.barcodePicker = barcodePicker;
      })
      .catch((e) => console.error(e));
  },
  beforeDestroy() {
    this.barcodePicker?.destroy();
  },
  data() {
    return {
      barcodePicker: null,
      isMediaStreamAPISupported:
        navigator &&
        navigator.mediaDevices &&
        "enumerateDevices" in navigator.mediaDevices
    };
  },
  methods: {
    onScanSuccess(_, result) {
      this.$emit("decode", result);
    }
  }
};
</script>