<template>
  <div class="alerts-container">
    <div v-if="alerts.length" class="-mt-1 mb-1 text-right">
      <span
        @click="markAllAsSeen(alerts)"
        class="border-b text-xs text-brand-manatee mx-px px-px cursor-pointer"
      >
        {{ $t("alerts.markAllAsRead") }}
      </span>
    </div>
    <alert-item v-for="alert in alerts" :key="alert.id" :alert="alert" />
    <div v-if="!alerts.length" class="alert-item text-sm">
      {{ $t("alerts.noAlerts") }}
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  getCurrentInstance,
  useStore
} from "@nuxtjs/composition-api";
import alertItem from "./item";

export default defineComponent({
  components: {
    alertItem
  },
  props: {
    alerts: {
      type: Array,
      default: () => []
    }
  },
  setup() {
    const instance = getCurrentInstance().proxy;
    const $db = instance.$db;
    const store = useStore();

    const userId = store.getters.currentUserId;

    const markAsSeen = async alert => {
      if (!alert.isSeen) {
        if (alert.type === "protocol") {
          await $db.markProtocolAlertAsSeen(alert.protocolId, alert.id, userId);
          store.commit("MARK_ALERT_AS_SEEN", { alertId: alert.id, userId });
        } else {
          await $db.alerts.markAlertAsSeen(alert.id, userId);
          store.commit("MARK_ALERT_AS_SEEN", { alertId: alert.id, userId });
        }
      }
    };

    const markAllAsSeen = alerts => alerts.map(alert => markAsSeen(alert));
    return {
      markAsSeen,
      markAllAsSeen
    };
  },
  methods: {
    async gotoAlertEntry(alert, forCrop) {
      const alertObj = { ...alert };
      if (forCrop) alertObj.type = "crop";
      const pathParams = this.getAlertEntryPath(alertObj);

      if (pathParams) {
        await this.markAsSeen(alert.id);
        this.$router.push(this.localePath(pathParams));
        this.$emit("opened");
      }
    }
  }
});
</script>

<style lang="postcss" scoped>
.alerts-container {
  user-select: none;
}
</style>
