<template>
  <div class="footer container">
    <p>&copy;{{ new Date().getFullYear() }} Klasmann-Deilmann</p>
    <ul class="list">
      <li>
        <nuxt-link
          class="link"
          :to="
            localePath({
              name: 'disclaimer'
            })
          "
        >
          {{ $t("footer.disclaimer") }}
        </nuxt-link>
      </li>
      <li>
        <a
          href="mailto:log.solve@klasmann-deilmann.com?subject=LogAndSolve-interest"
          class="link"
          ><span>{{ $t("footer.contact") }}</span></a
        >
      </li>
      <li>
        <nuxt-link class="link" :to="localePath('faq')">
          {{ $t("footer.faq") }}
        </nuxt-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {};
</script>

<style lang="postcss" scoped>
.footer {
  @apply flex pt-6 border-t border-solid border-brand-silver w-full mx-auto flex-col items-center;
  min-height: 8rem;
  @screen md {
    @apply flex-row items-baseline;
  }
}

.footer p {
  @apply font-default font-normal text-sm text-brand-mineShaft whitespace-nowrap;
  @screen md {
    @apply mr-6;
  }
}

.list {
  @apply flex justify-evenly flex-wrap mr-0 mt-8 w-11/12;
  @screen md {
    @apply w-2/5 mr-4 mt-0 justify-around;
  }
}

.link {
  @apply font-default text-sm font-medium text-brand-mineShaft;
}
</style>
