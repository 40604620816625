import config from "../../nuxt.config";

export default function({ app, store, redirect, route }) {
  if (!process.server && !config.dev && store.state.user) {
    const companyViewId = store.getters.companyViewId;
    const companyId = store.state.user?.companyId;

    if (companyId) {
      app.$db.addTrackingEvent(
        "pageView",
        { path: route.fullPath },
        store.state.user,
        companyId,
        companyViewId
      );
    } else {
      // @todo log
    }
  }
}
