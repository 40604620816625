var render = function () {
var _obj, _obj$1, _obj$2;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',_vm._g(_vm._b({class:( _obj = {
    'padding-small': _vm.icon || _vm.vIcon,
    'padding-large': !_vm.icon && !_vm.vIcon
  }, _obj[_vm.color] = true, _obj[("text-" + _vm.color)] = _vm.color.includes('brand-'), _obj.selected = _vm.selected, _obj.circular = false, _obj.outlined = _vm.outlined, _obj.dense = _vm.dense, _obj ),attrs:{"disabled":_vm.disabled || !_vm.allowed,"type":_vm.type},on:{"click":_vm.handleClick}},'button',_vm.$attrs,false),_vm.$listeners),[(_vm.isLoading)?_c('spinner',{attrs:{"outer-size":"w-6 h-6 mx-5","inner-size":"w-5 h-5"}}):[_vm._v("\n    "+_vm._s(_vm.iconAlignment == "right" ? _vm.text : "")+"\n    "),(_vm.icon)?_c('img',{staticClass:"w-5",class:_vm.iconAlignment === 'left' ? 'mr-3' : 'ml-3',attrs:{"src":_vm.iconUrl}}):_vm._e(),_vm._v(" "),_c('client-only',[(_vm.vIcon)?_c('v-icon',{class:[
          _vm.text ? (_vm.iconAlignment === 'left' ? 'mr-2' : 'ml-2') : '',
          ( _obj$1 = {}, _obj$1[_vm.iconClass] = true, _obj$1 )
        ],attrs:{"name":_vm.vIcon}}):_vm._e()],1),_vm._v(" "),(_vm.text.length)?_c('span',{staticClass:"button-text"},[_vm._v("\n      "+_vm._s(_vm.iconAlignment == "left" ? _vm.text : "")+"\n    ")]):_vm._e(),_vm._v(" "),(_vm.counter)?_c('div',{staticClass:"rounded-full absolute w-4 h-4",class:( _obj$2 = {
        'bg-brand-green': !_vm.color.includes('brand-')
      }, _obj$2[("bg-" + _vm.color)] = _vm.color.includes('brand-'), _obj$2 ),style:({ top: '-4px', right: '-4px' })},[_c('div',{staticClass:"flex justify-center items-center"},[_c('div',{staticClass:"text-white",style:({ paddingTop: '3px', fontSize: '10px' })},[_vm._v("\n          "+_vm._s(_vm.counter)+"\n        ")])])]):_vm._e()]],2)}
var staticRenderFns = []

export { render, staticRenderFns }