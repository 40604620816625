<template>
  <div class="flex text-gray-900">
    <aside class="layout-dashboard-aside bg-brand-green">
      <nuxt-link :to="localePath('index')">
        <img
          class="layout-dashboard-logo"
          src="~/assets/klasmann-deilmann-logo.svg"
          alt="Klasmann Deilman logo"
          width="80"
        />
      </nuxt-link>
      <main-navigation />

      <nuxt-link
        class="layout-dashboard-aside__disclaimer"
        :to="localePath('disclaimer')"
      >
        Disclaimer
      </nuxt-link>
    </aside>
    <nuxt class="layout-dashboard-main" />
  </div>
</template>

<script>
import mainNavigation from "../components/main-navigation";

export default {
  middleware: "authenticated",
  components: {
    mainNavigation
  }
};
</script>

<style>
.layout-dashboard-aside {
  position: relative;
  min-height: 100vh;
  flex-shrink: 0;
  flex-basis: 12em;
  font-size: 1.1rem;
}

.layout-dashboard-aside a {
  color: hsl(0, 100%, 100%);
}

.layout-dashboard-logo {
  margin-left: auto;
  margin-right: auto;
  display: block;
  padding-top: 2rem;
  padding-bottom: 1rem;
}

.layout-dashboard-main {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  margin-top: 1.6rem;
  margin-right: 3rem;
  margin-bottom: 1.6rem;
  margin-left: 3rem;
}

.layout-dashboard-aside__disclaimer {
  position: absolute;
  bottom: 2rem;
  width: 100%;
  text-align: center;
}
</style>
