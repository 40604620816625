const companiesToRedirect = [
  "itjxkhZnAMRp2SxfyVL6",
  "GqFWEIFKfVIj6tlB7I1V",
  "58xxoNDhnCtvKl1wsdD6",
  "6f2sTYjWUueqdLXL8z0a",
  "h9ToETS0Wep8PtsVCD4r",
  "FCR7DpOr1COleXvNjN1T",
  "VgazqK53vd6LWPig50tI",
  "SAeL8jL5eZa0OFLXfTBv",
  "db9ZSK1oYO2nzY3ZAnJu",
  "LkcrXOF6cHJlGc7h6oZn",
  "AKEPfR9V7uqMSIOfgr5o",
  "l5BVNvcWdzlwgnb2kith",
  "n7mRHw9UMwuOMwhl0cRC",
  "ardrLgj3rXYj8QUMbpn0",
  "iz08LcizdVBovQ1mMW5F",
  "vEJLpnG4sPCatTMBYh5T",
  "wB11L2KaoAN5GBxxUE7f",
  "6euwjznBLcfWdw5qXXds",
  "iz08LcizdVBovQ1mMW5F",
  "qDCDGbfbIjF3FTpPnnYC",
  "0LxvbfD7pku4cYYLSEOR",
  "EXPF88y1cZkAgKosqlg7",
  "Lib1NN1gLAiiRwO5Ox8k",
  "Y7w5w1GcOZSLh19B3CZB",
  "07LTfY30j1MAxxaGLYHd",
  "IDC7gZlweWRT2dQSuEoB",
  "QrbHeYLD34P5vAtvSpo1",
  "GGO0OnYH0Cm4BcceUSvG",
  "fQIcWfZZJOb2qkpzWwg9",
  "2zNj1YTdAFBI1jne6kbc",
  "JNKr03a2GXZc94laCEb3",
  "Y7aUZnuBmuEYsc8Yd3On",
  "kQEWU0vmx8d3gYDRfAPD",
  "sSVwFZBfdG5X0aX6tDcO",
  "ipsMBnneu1W6iPAGQc6g",
  "togZ9dF7SvpwX3UxuIK5",
  "65nH347Xa0DsLu38boeF",
  "22RXUY2ByXsCOwP8T3Ao",
  "XFa3LTfd7alYG2CbfTGj",
  "hEmscMKLnG6fkfLl2dtv",
  "aUEb0OMHAePqACgGGG1N",
  "dhJnYPrXN35pE7D1qIYZ",
  "DKiMzsBuuhLv5zDZEprH",
  "bCWqFw4vO1q7ZF5kX27P",
  "S2qprJZRb3Ca7p5dDqtk",
  "MQD9wbSRZrA4rFlkivGe",
  "uR4UEU2xofi0jZHy6c3a",
  "qeOl2DCFMmdKuPsuw3aK",
  "XCKEO52G6EYOSVGe6KIv",
  "u5HSC9XesGxOq5y6pPqo",
  "lcZM5NX7PUa2jagXfbtu",
  "my7qLUS9VjXEJTg2VmQS",
  "nfWgitYOnViTGL1eEzp8",
  "wFqr6mfiou189d4BdC76",
  "AuqWVyxFf2vIpD8xC9mE",
  "Sy8ibpDtrXeQC9vSAWmS",
  "0daC3UE2qrngiUwicb3g",
  "cidEFBb0E66nTIs9Vc6o",
  "lbCEl6UCvAp6hiO8tYUk",
  "RBdUHTSDqbB8AzZaNsw0",
  "ZUy7mdfqVJ02Zf3C0NPx",
  "zc0KrljEAyncJ70Mc1fB",
  "ZvIcdlfts2g21cGTmbaT",
  "0xaMBg1PWMhI4a5tsp53",
  "RPKVt3rPZV0qjekQxXXu",
  "TeZyqUobf4SrXWLPaUzq",
  "FLNCCwoG4hGOdT7NPpBg",
  "jhWRpwkvZc7YvbOvaxLA",
  "FlBIwXuWn3oBCfDRrcU2",
  "iurcT3cAmY8slIzSoNsD",
  "cBYne7ZvSasg6cMrXS1X",
  "eqSC3mHWDv9kMIqA2Glh",
  "30qm9kOUUuiPDsqPA9t9",
  "RRESjZTSQOXO6Fhykx7Z",
  "qVQ52idPOJUUSuyQdSLs",
  "IOwXZLMTzfTk9vXzig4y",
  "vvJpA6TiDeln7SPnz3Sc",
  "82mtd7RBTpto84symclc",
  "47mNnAvwnxKc2E0jB6kB",
  "uXDeXjxesFSqBZhRGIyq",
  "XWfS2ciiNLVDatBoBrFB",
  "ahZAGWORm7ylRBbCMekB",
  "xjE3MhpfPRyuhAe0KSgj",
  "Rop4FTDmhpGmqIJHYjGJ",
  "jaj1CZdgdDdwlYIrtK1h",
  "WhRYhz9mDuSEANLa5YlB",
  "dvV1362QVPPV5crhUTyg",
  "UVVLa73Evv77zNo47qB4",
  "TZBZlsnAzJYunRE6LtZO",
  "OXJZBuZopwDIFpyvrrUJ",
  "lbCEl6UCvAp6hiO8tYUk",
  "J8IRLkguE4FxFJCGQT6I",
  "ElXkyiZADxuE2I29wqqE",
  "J9wOVcINVCwFOp2HsBq2",
  "nOYgb7iXn6t4djEaiQJG",
  "Fmu0T6TD21gOrXhuDFBS",
  "Gv9SV6xnuwJaiSPlTQES",
  "ArsAXHHeXmjnGjqltjel",
  "sxaMpHvoAPnbhTD5fDT9",
  "RCoNQRab5EQSZ0Ci5kqy",
  "2GlkhUuZ37sKm88NUbBK",
  "I5TZrVujIAwQ0sUHNMFR",
  "WLz2Ruxoo06mZVzHkpow",
  "U2ZqHDBFUAyICAlZHmn8",
  "RRESjZTSQOXO6Fhykx7Z",
  "P6qSIvbWTfKh3gkE09ct",
  "wbtmX3sajumjUk6uEo5E",
  "ViauSV7aCGcWJtQgQWmD",
  "5D64dq85610OqVs55P1l",
  "GusjEhNH7hX9agmGWTEf",
  "tA7OuXVT4ObRC39Bv3Vf",
  "5a4Z433ARYxDrP8Nryzb",
  "BZ9HhhpZMaz34XYbOp14",
  "oFK21pWNrIyIYDLE3Id1",
  "epvQo3i1Vuqv1mGoKAGT",
  "e0ZisyPVEOAwjYdRcqXN",
  "eiLKu9canmWZXPmTN3Kt",
  "Kx8YTJoofTmoNtWph5Xf",
  "07LTfY30j1MAxxaGLYHd",
  "twgjceS1lWEtjnRuK6KQ",
  "62XKkuB2kfU4AWH8Zizr",
  "qzkRcTBg1NJUQV4tbFLF",
  "cNHfCiKveEdaE8Pk7fmz",
  "0sFffv8pQZyTh0Q0BvPN",
];

module.exports = {
  companiesToRedirect
};
