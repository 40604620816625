import { CropsAction } from "./crop";
import { TasksAction } from "./tasks";
import { AlertAction } from "./alerts";

export let tasks = new TasksAction();
export let crops = new CropsAction();
export let alerts = new AlertAction();

const dbActions = $fire => {
  tasks = new TasksAction($fire, "tasks");
  crops = new CropsAction($fire, "crops");
  alerts = new AlertAction($fire, "alerts");
  return {
    tasks,
    crops,
    alerts
  };
};
export default dbActions;
