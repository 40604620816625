var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"main-navigation"},[_c('ul',[_c('li',[_c('nuxt-link',{attrs:{"to":_vm.localePath({
            name: 'user-slug-crops-crop',
            params: { slug: _vm.userSlug }
          })}},[_vm._v("\n        "+_vm._s(_vm.$t("dashboard.components.mainNavigation[0].text"))+"\n      ")])],1),_vm._v(" "),_c('li',[_c('nuxt-link',{attrs:{"to":_vm.localePath({
            name: 'user-slug-datastudio',
            params: { slug: _vm.userSlug }
          })}},[_vm._v("\n        "+_vm._s(_vm.$t("dashboard.components.mainNavigation[1].text"))+"\n      ")])],1),_vm._v(" "),_c('li',[_c('nuxt-link',{attrs:{"to":_vm.localePath('weather')}},[_vm._v("\n        "+_vm._s(_vm.$t("dashboard.components.mainNavigation[2].text"))+"\n      ")])],1),_vm._v(" "),_c('li',[_c('nuxt-link',{attrs:{"to":_vm.localePath({
            name: 'user-slug-profile',
            params: { slug: _vm.userSlug }
          })}},[_vm._v("\n        "+_vm._s(_vm.$t("dashboard.components.mainNavigation[3].text"))+"\n      ")])],1),_vm._v(" "),_c('li',[_c('nuxt-link',{attrs:{"to":_vm.localePath({
            name: 'user-slug-sensors',
            params: { slug: _vm.userSlug }
          })}},[_vm._v("\n        Sensors\n      ")])],1),_vm._v(" "),_c('li',[_c('a',{staticClass:"main-navigation__link--launch",attrs:{"href":"https://webshop.klasmann-deilmann.com/login","target":"_blank"}},[_vm._v("\n        "+_vm._s(_vm.$t("dashboard.components.mainNavigation[5].text"))+"\n      ")])]),_vm._v(" "),_c('li',[_c('nuxt-link',{attrs:{"to":_vm.localePath('faq')}},[_vm._v("\n        FAQ\n      ")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }