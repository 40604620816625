import Vue from "vue";
import Icon from "vue-awesome/components/Icon";
import "vue-awesome/icons";

Icon.register({
  'regular/exclamation-triangle': {
    width: 492.426,
    height: 492.426,
    paths: [
      {
        d: `M485.013,383.313l-191.9-328.3c-9.8-16.8-27.4-26.8-46.9-26.8s-37,10-46.9,26.8l-191.9,328.3
          c-9.8,16.8-9.9,36.9-0.2,53.7c9.8,17,27.4,27.2,47.1,27.2h383.8c19.7,0,37.3-10.2,47.1-27.2
          C494.913,420.213,494.813,400.113,485.013,383.313z M441.413,411.913c-0.7,1.2-1.8,1.8-3.3,1.8h-383.8c-1.5,0-2.6-0.6-3.3-1.8
          c-0.9-1.5-0.3-2.6,0-3.1l191.9-328.3c0.7-1.2,1.8-1.8,3.3-1.8s2.6,0.6,3.3,1.8l191.9,328.3
          C441.713,409.313,442.313,410.413,441.413,411.913z`
      }, {
        fill: "white",
        d: `M49.998 410.413 L 242.612 80.651 L 246.059 78.612 C 246.061 78.61 249.67 80.206 249.392 80.484 L 440.763 407.836 L 441.707 410.69 L 440.243 413.001 L 56.796 413.293 L 52.542 413.438 L 49.998 410.371 Z`
      }, {
        d: `M222,365 a 25,25 0 1,1 50,0 a 25,25 0 1,1 -50,0`
      }, {
        d: `M 220.38 194.144 L 224.672 295.875 C 224.845 300.663 227.92 304.328 231.632 304.328 L 259.764 304.328 C 263.477 304.328 266.493 300.588 266.725 295.875 L 271.017 194.144 C 271.249 188.982 268.059 184.644 264.057 184.644 L 227.282 184.644 C 223.28 184.644 220.09 188.982 220.322 194.144 L 220.38 194.144 Z`
      }
    ]
  }
});

Vue.component("v-icon", Icon);
