import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _21be82e7 = () => interopDefault(import('../src/pages/agreement.vue' /* webpackChunkName: "pages/agreement" */))
const _b99bedcc = () => interopDefault(import('../src/pages/company.vue' /* webpackChunkName: "pages/company" */))
const _e43d216c = () => interopDefault(import('../src/pages/company/exports.vue' /* webpackChunkName: "pages/company/exports" */))
const _1aed5f2c = () => interopDefault(import('../src/pages/company/import.vue' /* webpackChunkName: "pages/company/import" */))
const _3b26cb29 = () => interopDefault(import('../src/pages/company/locations.vue' /* webpackChunkName: "pages/company/locations" */))
const _49c40475 = () => interopDefault(import('../src/pages/company/locations-qr.vue' /* webpackChunkName: "pages/company/locations-qr" */))
const _3a73d0d3 = () => interopDefault(import('../src/pages/company/note-checklists.vue' /* webpackChunkName: "pages/company/note-checklists" */))
const _144950b4 = () => interopDefault(import('../src/pages/company/profile.vue' /* webpackChunkName: "pages/company/profile" */))
const _1f2c9f60 = () => interopDefault(import('../src/pages/company/protocol/index.vue' /* webpackChunkName: "pages/company/protocol/index" */))
const _5f269bb4 = () => interopDefault(import('../src/pages/company/protocols.vue' /* webpackChunkName: "pages/company/protocols" */))
const _65cf4304 = () => interopDefault(import('../src/pages/company/sensors.vue' /* webpackChunkName: "pages/company/sensors" */))
const _713a0aac = () => interopDefault(import('../src/pages/company/tank-mixes.vue' /* webpackChunkName: "pages/company/tank-mixes" */))
const _86292e4e = () => interopDefault(import('../src/pages/company/tasks.vue' /* webpackChunkName: "pages/company/tasks" */))
const _3dc7dce0 = () => interopDefault(import('../src/pages/company/user-management.vue' /* webpackChunkName: "pages/company/user-management" */))
const _690cc9a6 = () => interopDefault(import('../src/pages/company/protocol/form.vue' /* webpackChunkName: "pages/company/protocol/form" */))
const _64d5c8ec = () => interopDefault(import('../src/pages/company/protocol/steps/actionStep.vue' /* webpackChunkName: "pages/company/protocol/steps/actionStep" */))
const _3a324d6e = () => interopDefault(import('../src/pages/company/protocol/steps/alertStep.vue' /* webpackChunkName: "pages/company/protocol/steps/alertStep" */))
const _7c90d390 = () => interopDefault(import('../src/pages/company/protocol/steps/climateSchemaStep.vue' /* webpackChunkName: "pages/company/protocol/steps/climateSchemaStep" */))
const _62db0b2d = () => interopDefault(import('../src/pages/company/protocol/steps/conditionStep.vue' /* webpackChunkName: "pages/company/protocol/steps/conditionStep" */))
const _9e46a074 = () => interopDefault(import('../src/pages/company/protocol/steps/cropStep.vue' /* webpackChunkName: "pages/company/protocol/steps/cropStep" */))
const _cf4a07da = () => interopDefault(import('../src/pages/company/protocol/steps/fertilizationSchemaStep.vue' /* webpackChunkName: "pages/company/protocol/steps/fertilizationSchemaStep" */))
const _abcf3194 = () => interopDefault(import('../src/pages/company/protocol/steps/kpiStep.vue' /* webpackChunkName: "pages/company/protocol/steps/kpiStep" */))
const _8b8e75e0 = () => interopDefault(import('../src/pages/company/protocol/steps/stageStep.vue' /* webpackChunkName: "pages/company/protocol/steps/stageStep" */))
const _23a6202f = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _f3ec8fe4 = () => interopDefault(import('../src/pages/disclaimer.vue' /* webpackChunkName: "pages/disclaimer" */))
const _7aa86db3 = () => interopDefault(import('../src/pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _143caff6 = () => interopDefault(import('../src/pages/general.vue' /* webpackChunkName: "pages/general" */))
const _f25a971e = () => interopDefault(import('../src/pages/general/advices.vue' /* webpackChunkName: "pages/general/advices" */))
const _19f9a51b = () => interopDefault(import('../src/pages/general/climate.vue' /* webpackChunkName: "pages/general/climate" */))
const _461e82a4 = () => interopDefault(import('../src/pages/general/documents.vue' /* webpackChunkName: "pages/general/documents" */))
const _260eb4b6 = () => interopDefault(import('../src/pages/general/fertilization.vue' /* webpackChunkName: "pages/general/fertilization" */))
const _21da16ad = () => interopDefault(import('../src/pages/general/labdata.vue' /* webpackChunkName: "pages/general/labdata" */))
const _17a3ed37 = () => interopDefault(import('../src/pages/general/notes.vue' /* webpackChunkName: "pages/general/notes" */))
const _3d913f8c = () => interopDefault(import('../src/pages/general/substrates.vue' /* webpackChunkName: "pages/general/substrates" */))
const _c8649168 = () => interopDefault(import('../src/pages/reset-password.vue' /* webpackChunkName: "pages/reset-password" */))
const _670be9d0 = () => interopDefault(import('../src/pages/s.vue' /* webpackChunkName: "pages/s" */))
const _268053d2 = () => interopDefault(import('../src/pages/sign-in.vue' /* webpackChunkName: "pages/sign-in" */))
const _12c9a21e = () => interopDefault(import('../src/pages/sign-out.vue' /* webpackChunkName: "pages/sign-out" */))
const _3b16aa48 = () => interopDefault(import('../src/pages/sign-up.vue' /* webpackChunkName: "pages/sign-up" */))
const _4d9e2e5e = () => interopDefault(import('../src/pages/substrate.vue' /* webpackChunkName: "pages/substrate" */))
const _44583410 = () => interopDefault(import('../src/pages/team.vue' /* webpackChunkName: "pages/team" */))
const _637213c5 = () => interopDefault(import('../src/pages/test.vue' /* webpackChunkName: "pages/test" */))
const _8ca858c6 = () => interopDefault(import('../src/pages/third-party.vue' /* webpackChunkName: "pages/third-party" */))
const _6de31dde = () => interopDefault(import('../src/pages/weather.vue' /* webpackChunkName: "pages/weather" */))
const _23720b04 = () => interopDefault(import('../src/pages/user/admin.vue' /* webpackChunkName: "pages/user/admin" */))
const _6118e313 = () => interopDefault(import('../src/pages/user/crop-form/index.vue' /* webpackChunkName: "pages/user/crop-form/index" */))
const _66cdb2be = () => interopDefault(import('../src/pages/user/daily-summary.vue' /* webpackChunkName: "pages/user/daily-summary" */))
const _5aced6e1 = () => interopDefault(import('../src/pages/user/datastudio.vue' /* webpackChunkName: "pages/user/datastudio" */))
const _093d5c5c = () => interopDefault(import('../src/pages/user/docs.vue' /* webpackChunkName: "pages/user/docs" */))
const _60dbac1a = () => interopDefault(import('../src/pages/user/overview.vue' /* webpackChunkName: "pages/user/overview" */))
const _41ffcbd0 = () => interopDefault(import('../src/pages/user/profile.vue' /* webpackChunkName: "pages/user/profile" */))
const _6ed29aec = () => interopDefault(import('../src/pages/user/protocols.vue' /* webpackChunkName: "pages/user/protocols" */))
const _30860c68 = () => interopDefault(import('../src/pages/user/sensors.vue' /* webpackChunkName: "pages/user/sensors" */))
const _295da000 = () => interopDefault(import('../src/pages/user/tasks/index.vue' /* webpackChunkName: "pages/user/tasks/index" */))
const _4cc2bd49 = () => interopDefault(import('../src/pages/user/crops/add-crop.vue' /* webpackChunkName: "pages/user/crops/add-crop" */))
const _8a58b2b8 = () => interopDefault(import('../src/pages/user/crops/compare-logs.vue' /* webpackChunkName: "pages/user/crops/compare-logs" */))
const _5f0c4f2b = () => interopDefault(import('../src/pages/user/crops/list.vue' /* webpackChunkName: "pages/user/crops/list" */))
const _2a0aaabe = () => interopDefault(import('../src/pages/user/tasks/create.vue' /* webpackChunkName: "pages/user/tasks/create" */))
const _51cfca32 = () => interopDefault(import('../src/pages/user/crops/_crop.vue' /* webpackChunkName: "pages/user/crops/_crop" */))
const _e3d73996 = () => interopDefault(import('../src/pages/user/crops/_crop/index.vue' /* webpackChunkName: "pages/user/crops/_crop/index" */))
const _1c8030e9 = () => interopDefault(import('../src/pages/user/crops/_crop/add-photo.vue' /* webpackChunkName: "pages/user/crops/_crop/add-photo" */))
const _8fb7f512 = () => interopDefault(import('../src/pages/user/crops/_crop/edit.vue' /* webpackChunkName: "pages/user/crops/_crop/edit" */))
const _641fe2c8 = () => interopDefault(import('../src/pages/user/crops/_crop/eoc.vue' /* webpackChunkName: "pages/user/crops/_crop/eoc" */))
const _2849bd30 = () => interopDefault(import('../src/pages/user/crops/_crop/lab.vue' /* webpackChunkName: "pages/user/crops/_crop/lab" */))
const _003d8f78 = () => interopDefault(import('../src/pages/user/crops/_crop/log-chart.vue' /* webpackChunkName: "pages/user/crops/_crop/log-chart" */))
const _01399215 = () => interopDefault(import('../src/pages/user/crops/_crop/pdf.vue' /* webpackChunkName: "pages/user/crops/_crop/pdf" */))
const _254c29a4 = () => interopDefault(import('../src/pages/user/crops/_crop/photos.vue' /* webpackChunkName: "pages/user/crops/_crop/photos" */))
const _540079cc = () => interopDefault(import('../src/pages/user/crops/_crop/profile.vue' /* webpackChunkName: "pages/user/crops/_crop/profile" */))
const _215586f6 = () => interopDefault(import('../src/pages/user/crops/_crop/protocol.vue' /* webpackChunkName: "pages/user/crops/_crop/protocol" */))
const _fcf10338 = () => interopDefault(import('../src/pages/user/crops/_crop/substrate.vue' /* webpackChunkName: "pages/user/crops/_crop/substrate" */))
const _b22641fe = () => interopDefault(import('../src/pages/user/tasks/_id/edit.vue' /* webpackChunkName: "pages/user/tasks/_id/edit" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/agreement",
    component: _21be82e7,
    meta: {"layout":"dashboard-layout-v2"},
    name: "agreement___nl"
  }, {
    path: "/company",
    component: _b99bedcc,
    meta: {"layout":"dashboard-layout-v2","fetchOnServer":false,"needsPermission":null},
    name: "company___nl",
    children: [{
      path: "exports",
      component: _e43d216c,
      meta: {"fetchOnServer":false,"needsPermission":"view-companyProfile-exports"},
      name: "company-exports___nl"
    }, {
      path: "import",
      component: _1aed5f2c,
      meta: {"fetchOnServer":false,"needsPermission":"view-companyProfile-cropsImport"},
      name: "company-import___nl"
    }, {
      path: "locations",
      component: _3b26cb29,
      meta: {"fetchOnServer":false,"needsPermission":"view-companyProfile-locationSettings"},
      name: "company-locations___nl"
    }, {
      path: "locations-qr",
      component: _49c40475,
      meta: {"fetchOnServer":false,"needsPermission":"view-companyProfile-qrCode"},
      name: "company-locations-qr___nl"
    }, {
      path: "note-checklists",
      component: _3a73d0d3,
      meta: {"fetchOnServer":false,"needsPermission":"view-companyProfile-noteChecklists"},
      name: "company-note-checklists___nl"
    }, {
      path: "profile",
      component: _144950b4,
      meta: {"layout":"dashboard-layout-v2","fetchOnServer":false,"needsPermission":"view-companyProfile-profile"},
      name: "company-profile___nl"
    }, {
      path: "protocol",
      component: _1f2c9f60,
      meta: {"layout":"dashboard-layout-v2"},
      name: "company-protocol___nl"
    }, {
      path: "protocols",
      component: _5f269bb4,
      meta: {"layout":"dashboard-layout-v2","needsPermission":"view-cropProtocol-all"},
      name: "company-protocols___nl"
    }, {
      path: "sensors",
      component: _65cf4304,
      meta: {"fetchOnServer":false,"needsPermission":"view-companyProfile-sensorSettings"},
      name: "company-sensors___nl"
    }, {
      path: "tank-mixes",
      component: _713a0aac,
      meta: {"fetchOnServer":false,"needsPermission":"view-companyProfile-tankMixes"},
      name: "company-tank-mixes___nl"
    }, {
      path: "tasks",
      component: _86292e4e,
      meta: {"fetchOnServer":false,"needsPermission":"view-companyProfile-tasks"},
      name: "company-tasks___nl"
    }, {
      path: "user-management",
      component: _3dc7dce0,
      meta: {"layout":"dashboard-layout-v2","fetchOnServer":false,"needsPermission":"view-companyProfile-userManagement"},
      name: "company-user-management___nl"
    }, {
      path: "protocol/form",
      component: _690cc9a6,
      meta: {},
      name: "company-protocol-form___nl"
    }, {
      path: "protocol/steps/actionStep",
      component: _64d5c8ec,
      meta: {"props":{"value":{},"actionOptions":{},"maxWeek":{"default":0},"minWeek":{"default":0}}},
      name: "company-protocol-steps-actionStep___nl"
    }, {
      path: "protocol/steps/alertStep",
      component: _3a324d6e,
      meta: {"props":{"value":{},"alertSecondStep":{},"alertThirdStep":{},"maxWeek":{"default":0},"minWeek":{"default":0}}},
      name: "company-protocol-steps-alertStep___nl"
    }, {
      path: "protocol/steps/climateSchemaStep",
      component: _7c90d390,
      meta: {"props":{"value":{},"classes":{"default":""}}},
      name: "company-protocol-steps-climateSchemaStep___nl"
    }, {
      path: "protocol/steps/conditionStep",
      component: _62db0b2d,
      meta: {"props":{"value":{}}},
      name: "company-protocol-steps-conditionStep___nl"
    }, {
      path: "protocol/steps/cropStep",
      component: _9e46a074,
      meta: {"props":{"value":{}}},
      name: "company-protocol-steps-cropStep___nl"
    }, {
      path: "protocol/steps/fertilizationSchemaStep",
      component: _cf4a07da,
      meta: {"props":{"value":{},"classes":{"default":""}}},
      name: "company-protocol-steps-fertilizationSchemaStep___nl"
    }, {
      path: "protocol/steps/kpiStep",
      component: _abcf3194,
      meta: {"props":{"value":{},"kpiSecondStep":{},"classes":{"default":""},"kpiOptions":{}}},
      name: "company-protocol-steps-kpiStep___nl"
    }, {
      path: "protocol/steps/stageStep",
      component: _8b8e75e0,
      meta: {"props":{"value":{},"minFromWeek":{"default":0}}},
      name: "company-protocol-steps-stageStep___nl"
    }]
  }, {
    path: "/de",
    component: _23a6202f,
    meta: {},
    name: "index___de"
  }, {
    path: "/disclaimer",
    component: _f3ec8fe4,
    meta: {"layout":"dashboard-layout-v2"},
    name: "disclaimer___nl"
  }, {
    path: "/en",
    component: _23a6202f,
    meta: {},
    name: "index___en"
  }, {
    path: "/faq",
    component: _7aa86db3,
    meta: {"layout":"dashboard-layout-v2"},
    name: "faq___nl"
  }, {
    path: "/fr",
    component: _23a6202f,
    meta: {},
    name: "index___fr"
  }, {
    path: "/general",
    component: _143caff6,
    meta: {"layout":"dashboard-layout-v2"},
    name: "general___nl",
    children: [{
      path: "advices",
      component: _f25a971e,
      meta: {"needsPermission":"view-general-advices"},
      name: "general-advices___nl"
    }, {
      path: "climate",
      component: _19f9a51b,
      meta: {"needsPermission":"view-general-climateData"},
      name: "general-climate___nl"
    }, {
      path: "documents",
      component: _461e82a4,
      meta: {"needsPermission":"view-general-documents"},
      name: "general-documents___nl"
    }, {
      path: "fertilization",
      component: _260eb4b6,
      meta: {"needsPermission":"view-general-fertilizationBins"},
      name: "general-fertilization___nl"
    }, {
      path: "labdata",
      component: _21da16ad,
      meta: {"needsPermission":"view-general-labAnalyses"},
      name: "general-labdata___nl"
    }, {
      path: "notes",
      component: _17a3ed37,
      meta: {"needsPermission":"view-general-notes"},
      name: "general-notes___nl"
    }, {
      path: "substrates",
      component: _3d913f8c,
      meta: {"name":"Substrates","needsPermission":"view-general-substrates"},
      name: "general-substrates___nl"
    }]
  }, {
    path: "/reset-password",
    component: _c8649168,
    meta: {},
    name: "reset-password___nl"
  }, {
    path: "/s",
    component: _670be9d0,
    meta: {},
    name: "s___nl"
  }, {
    path: "/sign-in",
    component: _268053d2,
    meta: {},
    name: "sign-in___nl"
  }, {
    path: "/sign-out",
    component: _12c9a21e,
    meta: {},
    name: "sign-out___nl"
  }, {
    path: "/sign-up",
    component: _3b16aa48,
    meta: {},
    name: "sign-up___nl"
  }, {
    path: "/substrate",
    component: _4d9e2e5e,
    meta: {},
    name: "substrate___nl"
  }, {
    path: "/team",
    component: _44583410,
    meta: {},
    name: "team___nl"
  }, {
    path: "/test",
    component: _637213c5,
    meta: {},
    name: "test___nl"
  }, {
    path: "/third-party",
    component: _8ca858c6,
    meta: {},
    name: "third-party___nl"
  }, {
    path: "/weather",
    component: _6de31dde,
    meta: {"layout":"dashboard-layout-v2"},
    name: "weather___nl"
  }, {
    path: "/de/agreement",
    component: _21be82e7,
    meta: {"layout":"dashboard-layout-v2"},
    name: "agreement___de"
  }, {
    path: "/de/company",
    component: _b99bedcc,
    meta: {"layout":"dashboard-layout-v2","fetchOnServer":false,"needsPermission":null},
    name: "company___de",
    children: [{
      path: "exports",
      component: _e43d216c,
      meta: {"fetchOnServer":false,"needsPermission":"view-companyProfile-exports"},
      name: "company-exports___de"
    }, {
      path: "import",
      component: _1aed5f2c,
      meta: {"fetchOnServer":false,"needsPermission":"view-companyProfile-cropsImport"},
      name: "company-import___de"
    }, {
      path: "locations",
      component: _3b26cb29,
      meta: {"fetchOnServer":false,"needsPermission":"view-companyProfile-locationSettings"},
      name: "company-locations___de"
    }, {
      path: "locations-qr",
      component: _49c40475,
      meta: {"fetchOnServer":false,"needsPermission":"view-companyProfile-qrCode"},
      name: "company-locations-qr___de"
    }, {
      path: "note-checklists",
      component: _3a73d0d3,
      meta: {"fetchOnServer":false,"needsPermission":"view-companyProfile-noteChecklists"},
      name: "company-note-checklists___de"
    }, {
      path: "profile",
      component: _144950b4,
      meta: {"layout":"dashboard-layout-v2","fetchOnServer":false,"needsPermission":"view-companyProfile-profile"},
      name: "company-profile___de"
    }, {
      path: "protocol",
      component: _1f2c9f60,
      meta: {"layout":"dashboard-layout-v2"},
      name: "company-protocol___de"
    }, {
      path: "protocols",
      component: _5f269bb4,
      meta: {"layout":"dashboard-layout-v2","needsPermission":"view-cropProtocol-all"},
      name: "company-protocols___de"
    }, {
      path: "sensors",
      component: _65cf4304,
      meta: {"fetchOnServer":false,"needsPermission":"view-companyProfile-sensorSettings"},
      name: "company-sensors___de"
    }, {
      path: "tank-mixes",
      component: _713a0aac,
      meta: {"fetchOnServer":false,"needsPermission":"view-companyProfile-tankMixes"},
      name: "company-tank-mixes___de"
    }, {
      path: "tasks",
      component: _86292e4e,
      meta: {"fetchOnServer":false,"needsPermission":"view-companyProfile-tasks"},
      name: "company-tasks___de"
    }, {
      path: "user-management",
      component: _3dc7dce0,
      meta: {"layout":"dashboard-layout-v2","fetchOnServer":false,"needsPermission":"view-companyProfile-userManagement"},
      name: "company-user-management___de"
    }, {
      path: "protocol/form",
      component: _690cc9a6,
      meta: {},
      name: "company-protocol-form___de"
    }, {
      path: "protocol/steps/actionStep",
      component: _64d5c8ec,
      meta: {"props":{"value":{},"actionOptions":{},"maxWeek":{"default":0},"minWeek":{"default":0}}},
      name: "company-protocol-steps-actionStep___de"
    }, {
      path: "protocol/steps/alertStep",
      component: _3a324d6e,
      meta: {"props":{"value":{},"alertSecondStep":{},"alertThirdStep":{},"maxWeek":{"default":0},"minWeek":{"default":0}}},
      name: "company-protocol-steps-alertStep___de"
    }, {
      path: "protocol/steps/climateSchemaStep",
      component: _7c90d390,
      meta: {"props":{"value":{},"classes":{"default":""}}},
      name: "company-protocol-steps-climateSchemaStep___de"
    }, {
      path: "protocol/steps/conditionStep",
      component: _62db0b2d,
      meta: {"props":{"value":{}}},
      name: "company-protocol-steps-conditionStep___de"
    }, {
      path: "protocol/steps/cropStep",
      component: _9e46a074,
      meta: {"props":{"value":{}}},
      name: "company-protocol-steps-cropStep___de"
    }, {
      path: "protocol/steps/fertilizationSchemaStep",
      component: _cf4a07da,
      meta: {"props":{"value":{},"classes":{"default":""}}},
      name: "company-protocol-steps-fertilizationSchemaStep___de"
    }, {
      path: "protocol/steps/kpiStep",
      component: _abcf3194,
      meta: {"props":{"value":{},"kpiSecondStep":{},"classes":{"default":""},"kpiOptions":{}}},
      name: "company-protocol-steps-kpiStep___de"
    }, {
      path: "protocol/steps/stageStep",
      component: _8b8e75e0,
      meta: {"props":{"value":{},"minFromWeek":{"default":0}}},
      name: "company-protocol-steps-stageStep___de"
    }]
  }, {
    path: "/de/disclaimer",
    component: _f3ec8fe4,
    meta: {"layout":"dashboard-layout-v2"},
    name: "disclaimer___de"
  }, {
    path: "/de/faq",
    component: _7aa86db3,
    meta: {"layout":"dashboard-layout-v2"},
    name: "faq___de"
  }, {
    path: "/de/general",
    component: _143caff6,
    meta: {"layout":"dashboard-layout-v2"},
    name: "general___de",
    children: [{
      path: "advices",
      component: _f25a971e,
      meta: {"needsPermission":"view-general-advices"},
      name: "general-advices___de"
    }, {
      path: "climate",
      component: _19f9a51b,
      meta: {"needsPermission":"view-general-climateData"},
      name: "general-climate___de"
    }, {
      path: "documents",
      component: _461e82a4,
      meta: {"needsPermission":"view-general-documents"},
      name: "general-documents___de"
    }, {
      path: "fertilization",
      component: _260eb4b6,
      meta: {"needsPermission":"view-general-fertilizationBins"},
      name: "general-fertilization___de"
    }, {
      path: "labdata",
      component: _21da16ad,
      meta: {"needsPermission":"view-general-labAnalyses"},
      name: "general-labdata___de"
    }, {
      path: "notes",
      component: _17a3ed37,
      meta: {"needsPermission":"view-general-notes"},
      name: "general-notes___de"
    }, {
      path: "substrates",
      component: _3d913f8c,
      meta: {"name":"Substrates","needsPermission":"view-general-substrates"},
      name: "general-substrates___de"
    }]
  }, {
    path: "/de/reset-password",
    component: _c8649168,
    meta: {},
    name: "reset-password___de"
  }, {
    path: "/de/s",
    component: _670be9d0,
    meta: {},
    name: "s___de"
  }, {
    path: "/de/sign-in",
    component: _268053d2,
    meta: {},
    name: "sign-in___de"
  }, {
    path: "/de/sign-out",
    component: _12c9a21e,
    meta: {},
    name: "sign-out___de"
  }, {
    path: "/de/sign-up",
    component: _3b16aa48,
    meta: {},
    name: "sign-up___de"
  }, {
    path: "/de/substrate",
    component: _4d9e2e5e,
    meta: {},
    name: "substrate___de"
  }, {
    path: "/de/team",
    component: _44583410,
    meta: {},
    name: "team___de"
  }, {
    path: "/de/test",
    component: _637213c5,
    meta: {},
    name: "test___de"
  }, {
    path: "/de/third-party",
    component: _8ca858c6,
    meta: {},
    name: "third-party___de"
  }, {
    path: "/de/weather",
    component: _6de31dde,
    meta: {"layout":"dashboard-layout-v2"},
    name: "weather___de"
  }, {
    path: "/en/agreement",
    component: _21be82e7,
    meta: {"layout":"dashboard-layout-v2"},
    name: "agreement___en"
  }, {
    path: "/en/company",
    component: _b99bedcc,
    meta: {"layout":"dashboard-layout-v2","fetchOnServer":false,"needsPermission":null},
    name: "company___en",
    children: [{
      path: "exports",
      component: _e43d216c,
      meta: {"fetchOnServer":false,"needsPermission":"view-companyProfile-exports"},
      name: "company-exports___en"
    }, {
      path: "import",
      component: _1aed5f2c,
      meta: {"fetchOnServer":false,"needsPermission":"view-companyProfile-cropsImport"},
      name: "company-import___en"
    }, {
      path: "locations",
      component: _3b26cb29,
      meta: {"fetchOnServer":false,"needsPermission":"view-companyProfile-locationSettings"},
      name: "company-locations___en"
    }, {
      path: "locations-qr",
      component: _49c40475,
      meta: {"fetchOnServer":false,"needsPermission":"view-companyProfile-qrCode"},
      name: "company-locations-qr___en"
    }, {
      path: "note-checklists",
      component: _3a73d0d3,
      meta: {"fetchOnServer":false,"needsPermission":"view-companyProfile-noteChecklists"},
      name: "company-note-checklists___en"
    }, {
      path: "profile",
      component: _144950b4,
      meta: {"layout":"dashboard-layout-v2","fetchOnServer":false,"needsPermission":"view-companyProfile-profile"},
      name: "company-profile___en"
    }, {
      path: "protocol",
      component: _1f2c9f60,
      meta: {"layout":"dashboard-layout-v2"},
      name: "company-protocol___en"
    }, {
      path: "protocols",
      component: _5f269bb4,
      meta: {"layout":"dashboard-layout-v2","needsPermission":"view-cropProtocol-all"},
      name: "company-protocols___en"
    }, {
      path: "sensors",
      component: _65cf4304,
      meta: {"fetchOnServer":false,"needsPermission":"view-companyProfile-sensorSettings"},
      name: "company-sensors___en"
    }, {
      path: "tank-mixes",
      component: _713a0aac,
      meta: {"fetchOnServer":false,"needsPermission":"view-companyProfile-tankMixes"},
      name: "company-tank-mixes___en"
    }, {
      path: "tasks",
      component: _86292e4e,
      meta: {"fetchOnServer":false,"needsPermission":"view-companyProfile-tasks"},
      name: "company-tasks___en"
    }, {
      path: "user-management",
      component: _3dc7dce0,
      meta: {"layout":"dashboard-layout-v2","fetchOnServer":false,"needsPermission":"view-companyProfile-userManagement"},
      name: "company-user-management___en"
    }, {
      path: "protocol/form",
      component: _690cc9a6,
      meta: {},
      name: "company-protocol-form___en"
    }, {
      path: "protocol/steps/actionStep",
      component: _64d5c8ec,
      meta: {"props":{"value":{},"actionOptions":{},"maxWeek":{"default":0},"minWeek":{"default":0}}},
      name: "company-protocol-steps-actionStep___en"
    }, {
      path: "protocol/steps/alertStep",
      component: _3a324d6e,
      meta: {"props":{"value":{},"alertSecondStep":{},"alertThirdStep":{},"maxWeek":{"default":0},"minWeek":{"default":0}}},
      name: "company-protocol-steps-alertStep___en"
    }, {
      path: "protocol/steps/climateSchemaStep",
      component: _7c90d390,
      meta: {"props":{"value":{},"classes":{"default":""}}},
      name: "company-protocol-steps-climateSchemaStep___en"
    }, {
      path: "protocol/steps/conditionStep",
      component: _62db0b2d,
      meta: {"props":{"value":{}}},
      name: "company-protocol-steps-conditionStep___en"
    }, {
      path: "protocol/steps/cropStep",
      component: _9e46a074,
      meta: {"props":{"value":{}}},
      name: "company-protocol-steps-cropStep___en"
    }, {
      path: "protocol/steps/fertilizationSchemaStep",
      component: _cf4a07da,
      meta: {"props":{"value":{},"classes":{"default":""}}},
      name: "company-protocol-steps-fertilizationSchemaStep___en"
    }, {
      path: "protocol/steps/kpiStep",
      component: _abcf3194,
      meta: {"props":{"value":{},"kpiSecondStep":{},"classes":{"default":""},"kpiOptions":{}}},
      name: "company-protocol-steps-kpiStep___en"
    }, {
      path: "protocol/steps/stageStep",
      component: _8b8e75e0,
      meta: {"props":{"value":{},"minFromWeek":{"default":0}}},
      name: "company-protocol-steps-stageStep___en"
    }]
  }, {
    path: "/en/disclaimer",
    component: _f3ec8fe4,
    meta: {"layout":"dashboard-layout-v2"},
    name: "disclaimer___en"
  }, {
    path: "/en/faq",
    component: _7aa86db3,
    meta: {"layout":"dashboard-layout-v2"},
    name: "faq___en"
  }, {
    path: "/en/general",
    component: _143caff6,
    meta: {"layout":"dashboard-layout-v2"},
    name: "general___en",
    children: [{
      path: "advices",
      component: _f25a971e,
      meta: {"needsPermission":"view-general-advices"},
      name: "general-advices___en"
    }, {
      path: "climate",
      component: _19f9a51b,
      meta: {"needsPermission":"view-general-climateData"},
      name: "general-climate___en"
    }, {
      path: "documents",
      component: _461e82a4,
      meta: {"needsPermission":"view-general-documents"},
      name: "general-documents___en"
    }, {
      path: "fertilization",
      component: _260eb4b6,
      meta: {"needsPermission":"view-general-fertilizationBins"},
      name: "general-fertilization___en"
    }, {
      path: "labdata",
      component: _21da16ad,
      meta: {"needsPermission":"view-general-labAnalyses"},
      name: "general-labdata___en"
    }, {
      path: "notes",
      component: _17a3ed37,
      meta: {"needsPermission":"view-general-notes"},
      name: "general-notes___en"
    }, {
      path: "substrates",
      component: _3d913f8c,
      meta: {"name":"Substrates","needsPermission":"view-general-substrates"},
      name: "general-substrates___en"
    }]
  }, {
    path: "/en/reset-password",
    component: _c8649168,
    meta: {},
    name: "reset-password___en"
  }, {
    path: "/en/s",
    component: _670be9d0,
    meta: {},
    name: "s___en"
  }, {
    path: "/en/sign-in",
    component: _268053d2,
    meta: {},
    name: "sign-in___en"
  }, {
    path: "/en/sign-out",
    component: _12c9a21e,
    meta: {},
    name: "sign-out___en"
  }, {
    path: "/en/sign-up",
    component: _3b16aa48,
    meta: {},
    name: "sign-up___en"
  }, {
    path: "/en/substrate",
    component: _4d9e2e5e,
    meta: {},
    name: "substrate___en"
  }, {
    path: "/en/team",
    component: _44583410,
    meta: {},
    name: "team___en"
  }, {
    path: "/en/test",
    component: _637213c5,
    meta: {},
    name: "test___en"
  }, {
    path: "/en/third-party",
    component: _8ca858c6,
    meta: {},
    name: "third-party___en"
  }, {
    path: "/en/weather",
    component: _6de31dde,
    meta: {"layout":"dashboard-layout-v2"},
    name: "weather___en"
  }, {
    path: "/fr/agreement",
    component: _21be82e7,
    meta: {"layout":"dashboard-layout-v2"},
    name: "agreement___fr"
  }, {
    path: "/fr/company",
    component: _b99bedcc,
    meta: {"layout":"dashboard-layout-v2","fetchOnServer":false,"needsPermission":null},
    name: "company___fr",
    children: [{
      path: "exports",
      component: _e43d216c,
      meta: {"fetchOnServer":false,"needsPermission":"view-companyProfile-exports"},
      name: "company-exports___fr"
    }, {
      path: "import",
      component: _1aed5f2c,
      meta: {"fetchOnServer":false,"needsPermission":"view-companyProfile-cropsImport"},
      name: "company-import___fr"
    }, {
      path: "locations",
      component: _3b26cb29,
      meta: {"fetchOnServer":false,"needsPermission":"view-companyProfile-locationSettings"},
      name: "company-locations___fr"
    }, {
      path: "locations-qr",
      component: _49c40475,
      meta: {"fetchOnServer":false,"needsPermission":"view-companyProfile-qrCode"},
      name: "company-locations-qr___fr"
    }, {
      path: "note-checklists",
      component: _3a73d0d3,
      meta: {"fetchOnServer":false,"needsPermission":"view-companyProfile-noteChecklists"},
      name: "company-note-checklists___fr"
    }, {
      path: "profile",
      component: _144950b4,
      meta: {"layout":"dashboard-layout-v2","fetchOnServer":false,"needsPermission":"view-companyProfile-profile"},
      name: "company-profile___fr"
    }, {
      path: "protocol",
      component: _1f2c9f60,
      meta: {"layout":"dashboard-layout-v2"},
      name: "company-protocol___fr"
    }, {
      path: "protocols",
      component: _5f269bb4,
      meta: {"layout":"dashboard-layout-v2","needsPermission":"view-cropProtocol-all"},
      name: "company-protocols___fr"
    }, {
      path: "sensors",
      component: _65cf4304,
      meta: {"fetchOnServer":false,"needsPermission":"view-companyProfile-sensorSettings"},
      name: "company-sensors___fr"
    }, {
      path: "tank-mixes",
      component: _713a0aac,
      meta: {"fetchOnServer":false,"needsPermission":"view-companyProfile-tankMixes"},
      name: "company-tank-mixes___fr"
    }, {
      path: "tasks",
      component: _86292e4e,
      meta: {"fetchOnServer":false,"needsPermission":"view-companyProfile-tasks"},
      name: "company-tasks___fr"
    }, {
      path: "user-management",
      component: _3dc7dce0,
      meta: {"layout":"dashboard-layout-v2","fetchOnServer":false,"needsPermission":"view-companyProfile-userManagement"},
      name: "company-user-management___fr"
    }, {
      path: "protocol/form",
      component: _690cc9a6,
      meta: {},
      name: "company-protocol-form___fr"
    }, {
      path: "protocol/steps/actionStep",
      component: _64d5c8ec,
      meta: {"props":{"value":{},"actionOptions":{},"maxWeek":{"default":0},"minWeek":{"default":0}}},
      name: "company-protocol-steps-actionStep___fr"
    }, {
      path: "protocol/steps/alertStep",
      component: _3a324d6e,
      meta: {"props":{"value":{},"alertSecondStep":{},"alertThirdStep":{},"maxWeek":{"default":0},"minWeek":{"default":0}}},
      name: "company-protocol-steps-alertStep___fr"
    }, {
      path: "protocol/steps/climateSchemaStep",
      component: _7c90d390,
      meta: {"props":{"value":{},"classes":{"default":""}}},
      name: "company-protocol-steps-climateSchemaStep___fr"
    }, {
      path: "protocol/steps/conditionStep",
      component: _62db0b2d,
      meta: {"props":{"value":{}}},
      name: "company-protocol-steps-conditionStep___fr"
    }, {
      path: "protocol/steps/cropStep",
      component: _9e46a074,
      meta: {"props":{"value":{}}},
      name: "company-protocol-steps-cropStep___fr"
    }, {
      path: "protocol/steps/fertilizationSchemaStep",
      component: _cf4a07da,
      meta: {"props":{"value":{},"classes":{"default":""}}},
      name: "company-protocol-steps-fertilizationSchemaStep___fr"
    }, {
      path: "protocol/steps/kpiStep",
      component: _abcf3194,
      meta: {"props":{"value":{},"kpiSecondStep":{},"classes":{"default":""},"kpiOptions":{}}},
      name: "company-protocol-steps-kpiStep___fr"
    }, {
      path: "protocol/steps/stageStep",
      component: _8b8e75e0,
      meta: {"props":{"value":{},"minFromWeek":{"default":0}}},
      name: "company-protocol-steps-stageStep___fr"
    }]
  }, {
    path: "/fr/disclaimer",
    component: _f3ec8fe4,
    meta: {"layout":"dashboard-layout-v2"},
    name: "disclaimer___fr"
  }, {
    path: "/fr/faq",
    component: _7aa86db3,
    meta: {"layout":"dashboard-layout-v2"},
    name: "faq___fr"
  }, {
    path: "/fr/general",
    component: _143caff6,
    meta: {"layout":"dashboard-layout-v2"},
    name: "general___fr",
    children: [{
      path: "advices",
      component: _f25a971e,
      meta: {"needsPermission":"view-general-advices"},
      name: "general-advices___fr"
    }, {
      path: "climate",
      component: _19f9a51b,
      meta: {"needsPermission":"view-general-climateData"},
      name: "general-climate___fr"
    }, {
      path: "documents",
      component: _461e82a4,
      meta: {"needsPermission":"view-general-documents"},
      name: "general-documents___fr"
    }, {
      path: "fertilization",
      component: _260eb4b6,
      meta: {"needsPermission":"view-general-fertilizationBins"},
      name: "general-fertilization___fr"
    }, {
      path: "labdata",
      component: _21da16ad,
      meta: {"needsPermission":"view-general-labAnalyses"},
      name: "general-labdata___fr"
    }, {
      path: "notes",
      component: _17a3ed37,
      meta: {"needsPermission":"view-general-notes"},
      name: "general-notes___fr"
    }, {
      path: "substrates",
      component: _3d913f8c,
      meta: {"name":"Substrates","needsPermission":"view-general-substrates"},
      name: "general-substrates___fr"
    }]
  }, {
    path: "/fr/reset-password",
    component: _c8649168,
    meta: {},
    name: "reset-password___fr"
  }, {
    path: "/fr/s",
    component: _670be9d0,
    meta: {},
    name: "s___fr"
  }, {
    path: "/fr/sign-in",
    component: _268053d2,
    meta: {},
    name: "sign-in___fr"
  }, {
    path: "/fr/sign-out",
    component: _12c9a21e,
    meta: {},
    name: "sign-out___fr"
  }, {
    path: "/fr/sign-up",
    component: _3b16aa48,
    meta: {},
    name: "sign-up___fr"
  }, {
    path: "/fr/substrate",
    component: _4d9e2e5e,
    meta: {},
    name: "substrate___fr"
  }, {
    path: "/fr/team",
    component: _44583410,
    meta: {},
    name: "team___fr"
  }, {
    path: "/fr/test",
    component: _637213c5,
    meta: {},
    name: "test___fr"
  }, {
    path: "/fr/third-party",
    component: _8ca858c6,
    meta: {},
    name: "third-party___fr"
  }, {
    path: "/fr/weather",
    component: _6de31dde,
    meta: {"layout":"dashboard-layout-v2"},
    name: "weather___fr"
  }, {
    path: "/user/admin",
    component: _23720b04,
    meta: {"layout":"dashboard-layout-v2"},
    name: "user-admin___nl"
  }, {
    path: "/user/crop-form",
    component: _6118e313,
    meta: {"layout":"dashboard-layout-v2","fetchOnServer":false,"needsPermission":"create-cropNotes-all"},
    name: "user-crop-form___nl"
  }, {
    path: "/user/daily-summary",
    component: _66cdb2be,
    meta: {"layout":"dashboard-layout-v2","fetchOnServer":false},
    name: "user-daily-summary___nl"
  }, {
    path: "/user/datastudio",
    component: _5aced6e1,
    meta: {"layout":"dashboard-layout-v2"},
    name: "user-datastudio___nl"
  }, {
    path: "/user/docs",
    component: _093d5c5c,
    meta: {"layout":"dashboard-layout-v2"},
    name: "user-docs___nl"
  }, {
    path: "/user/overview",
    component: _60dbac1a,
    meta: {"middleware":"authenticated","fetchOnServer":false},
    name: "user-overview___nl"
  }, {
    path: "/user/profile",
    component: _41ffcbd0,
    meta: {"layout":"dashboard-layout-v2","fetchOnServer":false,"needsPermission":"view-userProfile-all"},
    name: "user-profile___nl"
  }, {
    path: "/user/protocols",
    component: _6ed29aec,
    meta: {"layout":"dashboard-layout-v2"},
    name: "user-protocols___nl"
  }, {
    path: "/user/sensors",
    component: _30860c68,
    meta: {"layout":"dashboard-layout-v2","fetchOnServer":false,"needsPermission":"view-sensors-all"},
    name: "user-sensors___nl"
  }, {
    path: "/user/tasks",
    component: _295da000,
    meta: {"layout":"dashboard-layout-v2","needsPermission":"view-tasks-all"},
    name: "user-tasks___nl"
  }, {
    path: "/de/user/admin",
    component: _23720b04,
    meta: {"layout":"dashboard-layout-v2"},
    name: "user-admin___de"
  }, {
    path: "/de/user/crop-form",
    component: _6118e313,
    meta: {"layout":"dashboard-layout-v2","fetchOnServer":false,"needsPermission":"create-cropNotes-all"},
    name: "user-crop-form___de"
  }, {
    path: "/de/user/daily-summary",
    component: _66cdb2be,
    meta: {"layout":"dashboard-layout-v2","fetchOnServer":false},
    name: "user-daily-summary___de"
  }, {
    path: "/de/user/datastudio",
    component: _5aced6e1,
    meta: {"layout":"dashboard-layout-v2"},
    name: "user-datastudio___de"
  }, {
    path: "/de/user/docs",
    component: _093d5c5c,
    meta: {"layout":"dashboard-layout-v2"},
    name: "user-docs___de"
  }, {
    path: "/de/user/overview",
    component: _60dbac1a,
    meta: {"middleware":"authenticated","fetchOnServer":false},
    name: "user-overview___de"
  }, {
    path: "/de/user/profile",
    component: _41ffcbd0,
    meta: {"layout":"dashboard-layout-v2","fetchOnServer":false,"needsPermission":"view-userProfile-all"},
    name: "user-profile___de"
  }, {
    path: "/de/user/protocols",
    component: _6ed29aec,
    meta: {"layout":"dashboard-layout-v2"},
    name: "user-protocols___de"
  }, {
    path: "/de/user/sensors",
    component: _30860c68,
    meta: {"layout":"dashboard-layout-v2","fetchOnServer":false,"needsPermission":"view-sensors-all"},
    name: "user-sensors___de"
  }, {
    path: "/de/user/tasks",
    component: _295da000,
    meta: {"layout":"dashboard-layout-v2","needsPermission":"view-tasks-all"},
    name: "user-tasks___de"
  }, {
    path: "/en/user/admin",
    component: _23720b04,
    meta: {"layout":"dashboard-layout-v2"},
    name: "user-admin___en"
  }, {
    path: "/en/user/crop-form",
    component: _6118e313,
    meta: {"layout":"dashboard-layout-v2","fetchOnServer":false,"needsPermission":"create-cropNotes-all"},
    name: "user-crop-form___en"
  }, {
    path: "/en/user/daily-summary",
    component: _66cdb2be,
    meta: {"layout":"dashboard-layout-v2","fetchOnServer":false},
    name: "user-daily-summary___en"
  }, {
    path: "/en/user/datastudio",
    component: _5aced6e1,
    meta: {"layout":"dashboard-layout-v2"},
    name: "user-datastudio___en"
  }, {
    path: "/en/user/docs",
    component: _093d5c5c,
    meta: {"layout":"dashboard-layout-v2"},
    name: "user-docs___en"
  }, {
    path: "/en/user/overview",
    component: _60dbac1a,
    meta: {"middleware":"authenticated","fetchOnServer":false},
    name: "user-overview___en"
  }, {
    path: "/en/user/profile",
    component: _41ffcbd0,
    meta: {"layout":"dashboard-layout-v2","fetchOnServer":false,"needsPermission":"view-userProfile-all"},
    name: "user-profile___en"
  }, {
    path: "/en/user/protocols",
    component: _6ed29aec,
    meta: {"layout":"dashboard-layout-v2"},
    name: "user-protocols___en"
  }, {
    path: "/en/user/sensors",
    component: _30860c68,
    meta: {"layout":"dashboard-layout-v2","fetchOnServer":false,"needsPermission":"view-sensors-all"},
    name: "user-sensors___en"
  }, {
    path: "/en/user/tasks",
    component: _295da000,
    meta: {"layout":"dashboard-layout-v2","needsPermission":"view-tasks-all"},
    name: "user-tasks___en"
  }, {
    path: "/fr/user/admin",
    component: _23720b04,
    meta: {"layout":"dashboard-layout-v2"},
    name: "user-admin___fr"
  }, {
    path: "/fr/user/crop-form",
    component: _6118e313,
    meta: {"layout":"dashboard-layout-v2","fetchOnServer":false,"needsPermission":"create-cropNotes-all"},
    name: "user-crop-form___fr"
  }, {
    path: "/fr/user/daily-summary",
    component: _66cdb2be,
    meta: {"layout":"dashboard-layout-v2","fetchOnServer":false},
    name: "user-daily-summary___fr"
  }, {
    path: "/fr/user/datastudio",
    component: _5aced6e1,
    meta: {"layout":"dashboard-layout-v2"},
    name: "user-datastudio___fr"
  }, {
    path: "/fr/user/docs",
    component: _093d5c5c,
    meta: {"layout":"dashboard-layout-v2"},
    name: "user-docs___fr"
  }, {
    path: "/fr/user/overview",
    component: _60dbac1a,
    meta: {"middleware":"authenticated","fetchOnServer":false},
    name: "user-overview___fr"
  }, {
    path: "/fr/user/profile",
    component: _41ffcbd0,
    meta: {"layout":"dashboard-layout-v2","fetchOnServer":false,"needsPermission":"view-userProfile-all"},
    name: "user-profile___fr"
  }, {
    path: "/fr/user/protocols",
    component: _6ed29aec,
    meta: {"layout":"dashboard-layout-v2"},
    name: "user-protocols___fr"
  }, {
    path: "/fr/user/sensors",
    component: _30860c68,
    meta: {"layout":"dashboard-layout-v2","fetchOnServer":false,"needsPermission":"view-sensors-all"},
    name: "user-sensors___fr"
  }, {
    path: "/fr/user/tasks",
    component: _295da000,
    meta: {"layout":"dashboard-layout-v2","needsPermission":"view-tasks-all"},
    name: "user-tasks___fr"
  }, {
    path: "/user/crops/add-crop",
    component: _4cc2bd49,
    meta: {"layout":"dashboard-layout-v2","fetchOnServer":false,"needsPermission":"create-addCrop-all"},
    name: "user-crops-add-crop___nl"
  }, {
    path: "/user/crops/compare-logs",
    component: _8a58b2b8,
    meta: {"layout":"dashboard-layout-v2","fetchOnServer":false,"needsPermission":"view-compareTool-all"},
    name: "user-crops-compare-logs___nl"
  }, {
    path: "/user/crops/list",
    component: _5f0c4f2b,
    meta: {"layout":"dashboard-layout-v2","needsPermission":"view-cultivationOverview-all"},
    name: "user-crops-list___nl"
  }, {
    path: "/user/tasks/create",
    component: _2a0aaabe,
    meta: {"layout":"dashboard-layout-v2"},
    name: "user-tasks-create___nl"
  }, {
    path: "/de/user/crops/add-crop",
    component: _4cc2bd49,
    meta: {"layout":"dashboard-layout-v2","fetchOnServer":false,"needsPermission":"create-addCrop-all"},
    name: "user-crops-add-crop___de"
  }, {
    path: "/de/user/crops/compare-logs",
    component: _8a58b2b8,
    meta: {"layout":"dashboard-layout-v2","fetchOnServer":false,"needsPermission":"view-compareTool-all"},
    name: "user-crops-compare-logs___de"
  }, {
    path: "/de/user/crops/list",
    component: _5f0c4f2b,
    meta: {"layout":"dashboard-layout-v2","needsPermission":"view-cultivationOverview-all"},
    name: "user-crops-list___de"
  }, {
    path: "/de/user/tasks/create",
    component: _2a0aaabe,
    meta: {"layout":"dashboard-layout-v2"},
    name: "user-tasks-create___de"
  }, {
    path: "/en/user/crops/add-crop",
    component: _4cc2bd49,
    meta: {"layout":"dashboard-layout-v2","fetchOnServer":false,"needsPermission":"create-addCrop-all"},
    name: "user-crops-add-crop___en"
  }, {
    path: "/en/user/crops/compare-logs",
    component: _8a58b2b8,
    meta: {"layout":"dashboard-layout-v2","fetchOnServer":false,"needsPermission":"view-compareTool-all"},
    name: "user-crops-compare-logs___en"
  }, {
    path: "/en/user/crops/list",
    component: _5f0c4f2b,
    meta: {"layout":"dashboard-layout-v2","needsPermission":"view-cultivationOverview-all"},
    name: "user-crops-list___en"
  }, {
    path: "/en/user/tasks/create",
    component: _2a0aaabe,
    meta: {"layout":"dashboard-layout-v2"},
    name: "user-tasks-create___en"
  }, {
    path: "/fr/user/crops/add-crop",
    component: _4cc2bd49,
    meta: {"layout":"dashboard-layout-v2","fetchOnServer":false,"needsPermission":"create-addCrop-all"},
    name: "user-crops-add-crop___fr"
  }, {
    path: "/fr/user/crops/compare-logs",
    component: _8a58b2b8,
    meta: {"layout":"dashboard-layout-v2","fetchOnServer":false,"needsPermission":"view-compareTool-all"},
    name: "user-crops-compare-logs___fr"
  }, {
    path: "/fr/user/crops/list",
    component: _5f0c4f2b,
    meta: {"layout":"dashboard-layout-v2","needsPermission":"view-cultivationOverview-all"},
    name: "user-crops-list___fr"
  }, {
    path: "/fr/user/tasks/create",
    component: _2a0aaabe,
    meta: {"layout":"dashboard-layout-v2"},
    name: "user-tasks-create___fr"
  }, {
    path: "/de/user/crops/:crop?",
    component: _51cfca32,
    meta: {"layout":"dashboard-layout-v2","fetchOnServer":false},
    children: [{
      path: "",
      component: _e3d73996,
      meta: {"props":{"crop":{"default":null},"cropId":{"default":null},"labData":{},"labDataPick":{}}},
      name: "user-crops-crop___de"
    }, {
      path: "add-photo",
      component: _1c8030e9,
      meta: {"props":{"cropId":{"required":true}},"needsPermission":"create-cropNotes-all"},
      name: "user-crops-crop-add-photo___de"
    }, {
      path: "edit",
      component: _8fb7f512,
      meta: {"props":{"cropId":{"required":true},"crop":{}},"needsPermission":"view-cropProfile-all"},
      name: "user-crops-crop-edit___de"
    }, {
      path: "eoc",
      component: _641fe2c8,
      meta: {"props":{"crop":{}}},
      name: "user-crops-crop-eoc___de"
    }, {
      path: "lab",
      component: _2849bd30,
      meta: {"layout":"dashboard-layout-v2","props":{"cropId":{"required":true},"labId":{"default":null},"labDataNotes":{},"labData":{},"benchmarkRanges":{"default":null},"crop":{"default":null}},"needsPermission":"view-labAnalyses-all"},
      name: "user-crops-crop-lab___de"
    }, {
      path: "log-chart",
      component: _003d8f78,
      meta: {"directives":{},"props":{"crop":{"required":false,"default":null},"cropId":{"required":false,"default":null},"labData":{}},"fetchOnServer":false,"needsPermission":"view-compareTool-all"},
      name: "user-crops-crop-log-chart___de"
    }, {
      path: "pdf",
      component: _01399215,
      meta: {"props":{"crop":{}}},
      name: "user-crops-crop-pdf___de"
    }, {
      path: "photos",
      component: _254c29a4,
      meta: {"layout":"dashboard-layout-v2","props":{"crop":{},"cropId":{"required":true}},"fetchOnServer":false,"needsPermission":"view-cropPhotos-all"},
      name: "user-crops-crop-photos___de"
    }, {
      path: "profile",
      component: _540079cc,
      meta: {"layout":"dashboard-layout-v2"},
      name: "user-crops-crop-profile___de"
    }, {
      path: "protocol",
      component: _215586f6,
      meta: {"props":{"crop":{"default":null}},"needsPermission":"view-cropProtocol-all"},
      name: "user-crops-crop-protocol___de"
    }, {
      path: "substrate",
      component: _fcf10338,
      meta: {"props":{"crop":{"default":null}},"needsPermission":"view-cropSubstrate-all"},
      name: "user-crops-crop-substrate___de"
    }]
  }, {
    path: "/en/user/crops/:crop?",
    component: _51cfca32,
    meta: {"layout":"dashboard-layout-v2","fetchOnServer":false},
    children: [{
      path: "",
      component: _e3d73996,
      meta: {"props":{"crop":{"default":null},"cropId":{"default":null},"labData":{},"labDataPick":{}}},
      name: "user-crops-crop___en"
    }, {
      path: "add-photo",
      component: _1c8030e9,
      meta: {"props":{"cropId":{"required":true}},"needsPermission":"create-cropNotes-all"},
      name: "user-crops-crop-add-photo___en"
    }, {
      path: "edit",
      component: _8fb7f512,
      meta: {"props":{"cropId":{"required":true},"crop":{}},"needsPermission":"view-cropProfile-all"},
      name: "user-crops-crop-edit___en"
    }, {
      path: "eoc",
      component: _641fe2c8,
      meta: {"props":{"crop":{}}},
      name: "user-crops-crop-eoc___en"
    }, {
      path: "lab",
      component: _2849bd30,
      meta: {"layout":"dashboard-layout-v2","props":{"cropId":{"required":true},"labId":{"default":null},"labDataNotes":{},"labData":{},"benchmarkRanges":{"default":null},"crop":{"default":null}},"needsPermission":"view-labAnalyses-all"},
      name: "user-crops-crop-lab___en"
    }, {
      path: "log-chart",
      component: _003d8f78,
      meta: {"directives":{},"props":{"crop":{"required":false,"default":null},"cropId":{"required":false,"default":null},"labData":{}},"fetchOnServer":false,"needsPermission":"view-compareTool-all"},
      name: "user-crops-crop-log-chart___en"
    }, {
      path: "pdf",
      component: _01399215,
      meta: {"props":{"crop":{}}},
      name: "user-crops-crop-pdf___en"
    }, {
      path: "photos",
      component: _254c29a4,
      meta: {"layout":"dashboard-layout-v2","props":{"crop":{},"cropId":{"required":true}},"fetchOnServer":false,"needsPermission":"view-cropPhotos-all"},
      name: "user-crops-crop-photos___en"
    }, {
      path: "profile",
      component: _540079cc,
      meta: {"layout":"dashboard-layout-v2"},
      name: "user-crops-crop-profile___en"
    }, {
      path: "protocol",
      component: _215586f6,
      meta: {"props":{"crop":{"default":null}},"needsPermission":"view-cropProtocol-all"},
      name: "user-crops-crop-protocol___en"
    }, {
      path: "substrate",
      component: _fcf10338,
      meta: {"props":{"crop":{"default":null}},"needsPermission":"view-cropSubstrate-all"},
      name: "user-crops-crop-substrate___en"
    }]
  }, {
    path: "/fr/user/crops/:crop?",
    component: _51cfca32,
    meta: {"layout":"dashboard-layout-v2","fetchOnServer":false},
    children: [{
      path: "",
      component: _e3d73996,
      meta: {"props":{"crop":{"default":null},"cropId":{"default":null},"labData":{},"labDataPick":{}}},
      name: "user-crops-crop___fr"
    }, {
      path: "add-photo",
      component: _1c8030e9,
      meta: {"props":{"cropId":{"required":true}},"needsPermission":"create-cropNotes-all"},
      name: "user-crops-crop-add-photo___fr"
    }, {
      path: "edit",
      component: _8fb7f512,
      meta: {"props":{"cropId":{"required":true},"crop":{}},"needsPermission":"view-cropProfile-all"},
      name: "user-crops-crop-edit___fr"
    }, {
      path: "eoc",
      component: _641fe2c8,
      meta: {"props":{"crop":{}}},
      name: "user-crops-crop-eoc___fr"
    }, {
      path: "lab",
      component: _2849bd30,
      meta: {"layout":"dashboard-layout-v2","props":{"cropId":{"required":true},"labId":{"default":null},"labDataNotes":{},"labData":{},"benchmarkRanges":{"default":null},"crop":{"default":null}},"needsPermission":"view-labAnalyses-all"},
      name: "user-crops-crop-lab___fr"
    }, {
      path: "log-chart",
      component: _003d8f78,
      meta: {"directives":{},"props":{"crop":{"required":false,"default":null},"cropId":{"required":false,"default":null},"labData":{}},"fetchOnServer":false,"needsPermission":"view-compareTool-all"},
      name: "user-crops-crop-log-chart___fr"
    }, {
      path: "pdf",
      component: _01399215,
      meta: {"props":{"crop":{}}},
      name: "user-crops-crop-pdf___fr"
    }, {
      path: "photos",
      component: _254c29a4,
      meta: {"layout":"dashboard-layout-v2","props":{"crop":{},"cropId":{"required":true}},"fetchOnServer":false,"needsPermission":"view-cropPhotos-all"},
      name: "user-crops-crop-photos___fr"
    }, {
      path: "profile",
      component: _540079cc,
      meta: {"layout":"dashboard-layout-v2"},
      name: "user-crops-crop-profile___fr"
    }, {
      path: "protocol",
      component: _215586f6,
      meta: {"props":{"crop":{"default":null}},"needsPermission":"view-cropProtocol-all"},
      name: "user-crops-crop-protocol___fr"
    }, {
      path: "substrate",
      component: _fcf10338,
      meta: {"props":{"crop":{"default":null}},"needsPermission":"view-cropSubstrate-all"},
      name: "user-crops-crop-substrate___fr"
    }]
  }, {
    path: "/de/user/tasks/:id/edit",
    component: _b22641fe,
    meta: {"layout":"dashboard-layout-v2"},
    name: "user-tasks-id-edit___de"
  }, {
    path: "/en/user/tasks/:id/edit",
    component: _b22641fe,
    meta: {"layout":"dashboard-layout-v2"},
    name: "user-tasks-id-edit___en"
  }, {
    path: "/fr/user/tasks/:id/edit",
    component: _b22641fe,
    meta: {"layout":"dashboard-layout-v2"},
    name: "user-tasks-id-edit___fr"
  }, {
    path: "/user/crops/:crop?",
    component: _51cfca32,
    meta: {"layout":"dashboard-layout-v2","fetchOnServer":false},
    children: [{
      path: "",
      component: _e3d73996,
      meta: {"props":{"crop":{"default":null},"cropId":{"default":null},"labData":{},"labDataPick":{}}},
      name: "user-crops-crop___nl"
    }, {
      path: "add-photo",
      component: _1c8030e9,
      meta: {"props":{"cropId":{"required":true}},"needsPermission":"create-cropNotes-all"},
      name: "user-crops-crop-add-photo___nl"
    }, {
      path: "edit",
      component: _8fb7f512,
      meta: {"props":{"cropId":{"required":true},"crop":{}},"needsPermission":"view-cropProfile-all"},
      name: "user-crops-crop-edit___nl"
    }, {
      path: "eoc",
      component: _641fe2c8,
      meta: {"props":{"crop":{}}},
      name: "user-crops-crop-eoc___nl"
    }, {
      path: "lab",
      component: _2849bd30,
      meta: {"layout":"dashboard-layout-v2","props":{"cropId":{"required":true},"labId":{"default":null},"labDataNotes":{},"labData":{},"benchmarkRanges":{"default":null},"crop":{"default":null}},"needsPermission":"view-labAnalyses-all"},
      name: "user-crops-crop-lab___nl"
    }, {
      path: "log-chart",
      component: _003d8f78,
      meta: {"directives":{},"props":{"crop":{"required":false,"default":null},"cropId":{"required":false,"default":null},"labData":{}},"fetchOnServer":false,"needsPermission":"view-compareTool-all"},
      name: "user-crops-crop-log-chart___nl"
    }, {
      path: "pdf",
      component: _01399215,
      meta: {"props":{"crop":{}}},
      name: "user-crops-crop-pdf___nl"
    }, {
      path: "photos",
      component: _254c29a4,
      meta: {"layout":"dashboard-layout-v2","props":{"crop":{},"cropId":{"required":true}},"fetchOnServer":false,"needsPermission":"view-cropPhotos-all"},
      name: "user-crops-crop-photos___nl"
    }, {
      path: "profile",
      component: _540079cc,
      meta: {"layout":"dashboard-layout-v2"},
      name: "user-crops-crop-profile___nl"
    }, {
      path: "protocol",
      component: _215586f6,
      meta: {"props":{"crop":{"default":null}},"needsPermission":"view-cropProtocol-all"},
      name: "user-crops-crop-protocol___nl"
    }, {
      path: "substrate",
      component: _fcf10338,
      meta: {"props":{"crop":{"default":null}},"needsPermission":"view-cropSubstrate-all"},
      name: "user-crops-crop-substrate___nl"
    }]
  }, {
    path: "/user/tasks/:id/edit",
    component: _b22641fe,
    meta: {"layout":"dashboard-layout-v2"},
    name: "user-tasks-id-edit___nl"
  }, {
    path: "/",
    component: _23a6202f,
    meta: {},
    name: "index___nl"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
