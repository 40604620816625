import {
  addMinutes,
  differenceInMinutes,
  isWithinInterval,
} from "date-fns";
import { sortBy } from "lodash";
import { schemeAccent } from "d3-scale-chromatic";

const DEFAULT_LEFT_MARGIN = 75;
const DEFAULT_RIGHT_MARGIN = 50;
const MAX_GAP_MINUTES = 4 * 60 + 55;

const calcMedian = (values) => {
  if (values.length === 0) return null;
  values.sort((a, b) => a - b);

  const half = Math.floor(values.length / 2);
  if (values.length % 2) return values[half];
  
  return (values[half - 1] + values[half]) / 2.0;
};

const fillGaps = (data) => {
  const sortedMeasurements = sortBy(data, "date");
  const gapIntervals = sortedMeasurements.reduce((acc, _, idx, arr) => {
    const mA = arr[idx];
    const mB = arr[idx + 1];
    if (mA && mB) {
      const diff = differenceInMinutes(mB.date, mA.date);
      acc.push(diff);
    }
    return acc;
  }, []);
  const medianGap = Math.max(calcMedian(gapIntervals) ?? MAX_GAP_MINUTES, MAX_GAP_MINUTES);

  return sortedMeasurements.reduce((acc, d, i, arr) => {
    if (i > 0) {
      const date = d.date;
      const prev = arr[i - 1].date;
      const diff = differenceInMinutes(date, prev);
      if (diff > medianGap) {
        // diff in hours?
        acc.push({
          value: NaN,
          date: addMinutes(prev, 60),
        });
      }
    }
    acc.push(d);
    return acc;
  }, []);
};

const restrictToRange = (measurements, [start, end]) => measurements.filter(m => isWithinInterval(m.date, { start, end }));

const metricTypes = [
  "ec", "conductivity", "moist", "moisture", "humidity", "temp", "temperature",
  "light", "battery", "location", "par", "permittivity"
];

const adjustedSchemeAccent = (() => {
  const newSchemeAccent = schemeAccent.slice();
  newSchemeAccent.splice(3, 1);
  return [...newSchemeAccent, ...newSchemeAccent];
})();

const includesFn = (val, x) => {
  const reg = new RegExp(`\\b${x}\\b`, "i");
  const res = reg.test(val.replace(/_/g, " "));
  // const res = val.includes(x);
  // console.warn(val, x, res);
  return res;
};

const getMetricType = (metric, el = false) => {
  if (!el) {
    const val = metric.type?.toLowerCase();
    if (val == null) return -1;
    return metricTypes.findIndex(x => includesFn(val, x));
  } else {
    const val = metric?.toLowerCase();
    if (val == null) return null;
    const idx = metricTypes.findIndex(x => includesFn(val, x));
    return metricTypes[idx];
  }
};

const pairedMetrics = [
  ["ec", "soil_electric_conductivity_events"],
  ["soil_relative_permittivity_events", "soil_moisture_average"],
];

const constants = { DEFAULT_LEFT_MARGIN, DEFAULT_RIGHT_MARGIN };

export {
  fillGaps,
  restrictToRange,
  getMetricType,
  adjustedSchemeAccent,
  pairedMetrics,
  constants
};