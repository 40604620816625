import Vue from "vue";
import VModal from "vue-js-modal";
import ConfirmationModal, { eventbus } from "@/components/confirmation-modal";

Vue.use(VModal);

const original = Vue.component("Modal");
Vue.component("Modal", {
  name: "Modal",
  extends: original,
  beforeDestroy() {
    document.body.classList.remove("body-no-scroll");
  },
  watch: {
    visible(val) {
      if (val) {
        document.body.classList.add("body-no-scroll");
      } else {
        document.body.classList.remove("body-no-scroll");
      }
    }
  }
});

ConfirmationModal.install = (Vue) => {
  Vue.component(ConfirmationModal.name, ConfirmationModal);
  Vue.prototype.$confirm = (params) => {
    eventbus.$emit("show", params);
  };
};
Vue.use(ConfirmationModal);

Vue.directive("confirm", {
  bind(el, binding, vnode) {
    el.handleClick = () => {
      const value = binding.value;
      if (value) {
        const params = {
          message: value.title,
          callback: confirm => {
            if (confirm) value.handler?.()
          }
        };
        vnode.context.$confirm(params);
      }
    };
    el.addEventListener("click", el.handleClick);
  },
  unbind(el) {
    el.removeEventListener("click", el.handleClick);
  }
});