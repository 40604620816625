import firebase from "firebase/compat/app";
import axios from "axios";
import { kebabCaseIt } from "case-it";
import { addSeconds, subDays } from "date-fns";
import {
  chunk,
  intersection,
  uniqBy,
  sortBy,
  flatMap,
  pickBy,
  merge,
  get,
  set
} from "lodash";
import { zipObj } from "ramda";
import dbActions from "./db_actions";
import { convertNotes } from "@/services/notes";
import { firestoreParser } from "@/utils/firebase-utils";
import { defaultCompany } from "@/store";
import { dateToUnixTimestamp, toDate } from "@/utils/date";

const PROJECT_ID = process.env.FIREBASE_PROJECT;

const BASE_URL = `https://firestore.googleapis.com/v1/projects/${PROJECT_ID}/databases/(default)`;
const BASE_URL_BATCH = `https://firestore.googleapis.com/v1/projects/${PROJECT_ID}/databases/(default)/documents:batchGet`;
const DB_PATH = `projects/${PROJECT_ID}/databases/(default)/documents`;

const toData = snap => {
  const data = snap.data();
  data.id = snap.id;
  return data;
};

const populateReference = async ref => {
  const res = await ref.get().catch(e => console.error(e));
  if (!res || !res.exists) {
    console.debug(`found null reference at ${ref.path}`);
    return null;
  }
  const data = res.data();
  if (data && ref.id) {
    data.id = ref.id;
  }

  return data;
};

const pickSnapshot = keys => snapshot =>
  zipObj(keys)(keys.map(key => snapshot[key] || snapshot.get(key)));

const pickReference = keys => reference =>
  reference
    ? Promise.all(
        keys.map(key =>
          reference.get().then(snapshot => snapshot[key] || snapshot.get(key))
        )
      ).then(zipObj(keys))
    : Promise.reject(new Error("Undefined firestore reference"));

export const initModule = ($fire, { state }, $fireAuthStore, $i18n) => {
  const http = axios.create({});
  http.interceptors.response.use(
    response => {
      return response;
    },
    function (error) {
      if (error.response?.status === 401) {
        // console.warn(JSON.stringify(error.response.config.url, null, 2));
      }
      return Promise.reject(error.response);
    }
  );

  const firestore = $fire?.firestore;

  const getUserRef = userId => {
    return firestore.collection("users").doc(userId);
  };
  const getReference = (collection, id) => {
    return firestore.collection(collection).doc(id);
  };

  const callRestApiGet = async (
    [collection, docId, ...subcollections],
    rawparams = {},
    npToken = null
  ) => {
    const url = [collection, docId, subcollections.join("/")]
      .filter(x => x)
      .join("/");

    let userToken = await $fire.auth.currentUser?.getIdToken();
    if (!userToken) userToken = state.user?.idToken;

    const params = new URLSearchParams();
    for (const [rawparamkey, rawparamval] of Object.entries(rawparams)) {
      if (Array.isArray(rawparamval)) {
        for (const rawparamvalitem of rawparamval) {
          params.append(rawparamkey, rawparamvalitem);
        }
      } else {
        params.append(rawparamkey, rawparamval);
      }
    }
    if (npToken) params.append("pageToken", npToken);

    const data = await http
      .get(`${BASE_URL}/documents/${url}`, {
        headers: {
          Authorization: `Bearer ${userToken}`
        },
        params
      })
      .then(x => x.data)
      .then(data => firestoreParser(data))
      .catch(e => {
        if (e.response?.status === 404 && docId) {
          return null; // was requesting a single doc that was not found
        }
        throw e;
      });
    if (!data) return null;

    const { documents: rawDocuments, nextPageToken } = data;

    if (!rawDocuments) {
      if (subcollections.length) return [];
      return {
        id: docId,
        ...data.fields
      };
    }

    let nextPageDocs = [];
    if (nextPageToken) {
      nextPageDocs = await callRestApiGet(
        [collection, docId, ...(subcollections || [])],
        rawparams,
        nextPageToken
      );
    }

    const documents = rawDocuments.map(x => {
      const id = x.name.split("/").slice(-1)[0];
      return { id, ...x.fields };
    });

    return documents.concat(nextPageDocs);
  };

  const callRestApiPost = async (body, idmap = false) => {
    let userToken = await $fire.auth.currentUser?.getIdToken();
    if (!userToken) userToken = state.user?.idToken;

    return http
      .post(BASE_URL_BATCH, body, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      })
      .then(x => x.data)
      .then(data => firestoreParser(data))
      .then(data =>
        data
          .map(x => {
            const { found } = x;
            if (!found) return null;
            const { name, fields } = found;
            const id = name.split("/").slice(-1)[0];
            fields.id = id;
            if (!idmap) return fields;
            return [name, fields];
          })
          .filter(x => x)
      )
      .then(data => {
        if (idmap) return Object.fromEntries(data);
        return data;
      });
  };

  const callRestApiQuery = async (body, suburl = "") => {
    let userToken = await $fire.auth.currentUser?.getIdToken();
    if (!userToken) userToken = state.user?.idToken;

    return http
      .post(`${BASE_URL}/documents${suburl}:runQuery`, body, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      })
      .then(x => x.data)
      .then(data => firestoreParser(data))
      .then(data =>
        data
          .map(x => {
            if (!x.document) return null;
            const { name, fields } = x.document;
            const id = name.split("/").slice(-1)[0];
            return {
              ...fields,
              id
            };
          })
          .filter(x => x)
      );
  };

  const hydrate = async (document, paths = []) => {
    return Promise.all(
      paths.map(async path => {
        let ref = get(document, path);
        if (!ref) {
          return;
        }
        // uid might be stored as string
        if (path === "uid") {
          ref = getUserRef(ref);
          path = "user";
        }
        const result =
          ref instanceof Array
            ? await Promise.all(ref.map(r => populateReference(r)))
            : await populateReference(ref);
        set(document, path, result);
      })
    );
  };

  const hydrateAll = async (documents, paths = []) => {
    const hydrationMap = Object.fromEntries(paths.map(x => [x, []]));
    const addRef = (ref, path) => {
      if (!ref) return;
      if (ref instanceof Array) {
        for (const r of ref) {
          if (hydrationMap[path].some(x => x.id === r.id)) continue;
          hydrationMap[path].push(r);
        }
      } else {
        if (hydrationMap[path].some(x => x.id === ref.id)) return;
        hydrationMap[path].push(ref);
      }
    };

    for (const document of documents) {
      for (const path of paths) {
        const ref = get(document, path);
        addRef(ref, path);
      }
    }

    const hydrationsMap = {};
    for (const [path, refs] of Object.entries(hydrationMap)) {
      const hydrations = await Promise.all(
        refs.map(async ref => {
          const result = await populateReference(ref);
          return result;
        })
      );
      hydrationsMap[path] = hydrations;
    }

    for (const document of documents) {
      for (const [path, hydrations] of Object.entries(hydrationsMap)) {
        const ref = get(document, path);
        if (!ref) continue;
        if (ref instanceof Array) {
          const hydrated = ref.map(r => {
            const h = hydrations.find(x => x.id === r.id);
            if (!h) return r;
            return h;
          });
          set(document, path, hydrated);
        } else {
          const hydrated = hydrations.find(x => x.id === ref.id);
          if (!hydrated) continue;
          set(document, path, hydrated);
        }
      }
    }
  };

  const getCompany = async id => {
    const companies = await callRestApiPost(
      {
        documents: [[DB_PATH, "companies", id].join("/")]
      },
      true
    );
    return Object.values(companies)[0];
  };

  const createCompany = async ({
    name,
    contactPerson,
    language,
    companyLabels,
    businessLocation
  }) => {
    const slug = kebabCaseIt(name);
    const dbCompanyObj = merge(defaultCompany(), {
      name,
      slug,
      crops: [],
      protocols: [],
      profile: {
        companyName: name,
        contactPerson,
        preferredLanguage: language,
        locations: [businessLocation].filter(x => x),
        environment: {
          setupMethods: []
        },
        companyLabels,
        sensorSettings: {
          provider: "blockbax",
          connectedCrops: {
            blockbax: {},
            tentacles: {}
          }
        },
        settings: {
          cropForm: {
            cultivationActivity: [
              "pruning",
              "widening",
              "moveCultivationBatch",
              "changePotsize"
            ],
            measurement: [
              "ec",
              "ph",
              "length",
              "diameter",
              "numberOfBuds",
              "numberOfFlowers"
            ],
            photoLabels: []
          }
        }
      },
      created: new Date()
    });
    const newCompany = await firestore
      .collection("companies")
      .add(dbCompanyObj);
    return newCompany.id;
  };

  const getUserWithCompany = async (userId, viewCompany) => {
    const user = await callRestApiGet(["users", userId]);
    const isInOwnCompany = !viewCompany || viewCompany === user.companyId;
    if (!isInOwnCompany) {
      user.company = null; // don't hydrate unnecessarily
    }

    const hydrateFields = user.company ? ["company"] : [];
    const docsToHydrateIdArr = hydrateFields
      .map(field => get(user, field))
      .filter(x => x);

    if (docsToHydrateIdArr.length) {
      const docsToHydrate = await callRestApiPost(
        {
          documents: docsToHydrateIdArr
        },
        true
      );

      for (const field of hydrateFields) {
        const fieldName = get(user, field);
        if (!fieldName) continue;
        const fieldNameFin =
          field === "uid" ? `${DB_PATH}/users/${fieldName}` : fieldName;
        const fieldValue = get(docsToHydrate, fieldNameFin);
        set(user, field === "uid" ? "user" : field, fieldValue);
      }
    }

    let roleId = null;
    let allowedLocations = [];
    if (isInOwnCompany) {
      // in this case user.roleId is used directly
      roleId = user.roleId;
      allowedLocations = user.config.allowedLocations;
    } else {
      // in this case the relevant roleId is actually
      // user.config.managedCompanies.{companyId}.roleId
      const managedCompany = user.config.managedCompanies.find(
        x => x.companyId === viewCompany
      );

      roleId = managedCompany?.roleId || null;
      allowedLocations = managedCompany?.allowedLocations || [];
    }

    user.role = null;
    if (roleId) {
      const role = await getRole(roleId);
      user.role = role;
    }
    user.allowedLocations = allowedLocations;

    return user;
  };

  const getLabDataNotes = async cropRef => {
    const snap = await cropRef.collection("labDataNotes").get();
    const data = snap.docs.map(doc => toData(doc));
    await Promise.all(flatMap(data, note => hydrate(note, ["uid"])));
    return data;
  };

  const hydrateCrops = async crops => {
    const hydrateFields = [
      "cropBase",
      "family.cultivar",
      "family.genus",
      "family.species"
    ];
    const docsToHydrateIdArr = Array.from(
      new Set(
        crops
          .map(crop =>
            hydrateFields
              .map(field => get(crop, field)?.id ?? get(crop, field))
              .filter(x => x)
          )
          .flat()
      )
    );

    const docsToHydrate = await callRestApiPost(
      {
        documents: docsToHydrateIdArr
      },
      true
    );

    for (const crop of crops) {
      for (const field of hydrateFields) {
        const fieldName = get(crop, field);
        if (!fieldName) continue;
        const fieldNameFin =
          field === "uid" ? `${DB_PATH}/users/${fieldName}` : fieldName;
        const fieldValue = get(docsToHydrate, fieldNameFin);
        set(crop, field === "uid" ? "user" : field, fieldValue);
      }
    }
  };

  const getCompanyCrops = async companyId => {
    const { crops: cropsIdArr } = await callRestApiGet(
      ["companies", companyId],
      {
        "mask.fieldPaths": ["crops"],
        orderBy: "name",
        pageSize: 20000
      }
    );
    const crops = await callRestApiPost({
      documents: cropsIdArr
    });

    await hydrateCrops(crops);

    return { crops };
  };

  const upsertCompanyDoc = async companyDoc => {
    const existingDocId = companyDoc.id;
    if (!existingDocId) {
      const newCompanyDoc = await firestore.collection("docs").add(companyDoc);
      return {
        id: newCompanyDoc.id,
        ...companyDoc
      };
    } else {
      await firestore.collection("docs").doc(existingDocId).set(companyDoc);
      return companyDoc;
    }
  };

  const deleteCompanyDoc = async docId => {
    return await firestore.collection("docs").doc(docId).delete();
  };

  const getCompanyDocs = async (company, user) => {
    const companyLanguage = company.profile?.preferredLanguage?.code || "nl";
    const managedLanguages = [companyLanguage];
    const managedCompanies = [company.id, "all", ...managedLanguages];

    const filters = {
      platform: {
        advisor: managedCompanies.length
          ? chunk(managedCompanies, 10).map(managedCompaniesPart => ({
              fieldFilter: {
                field: { fieldPath: "accessCompanies" },
                op: "ARRAY_CONTAINS_ANY",
                value: {
                  arrayValue: {
                    values: managedCompaniesPart.map(x => ({ stringValue: x }))
                  }
                }
              }
            }))
          : [
              {
                fieldFilter: {
                  field: { fieldPath: "scope" },
                  op: "EQUAL",
                  value: { stringValue: "platform" }
                }
              }
            ],
        "third-party": managedCompanies.length
          ? chunk(managedCompanies, 10).map(managedCompaniesPart => [
              {
                compositeFilter: {
                  op: "AND",
                  filters: [
                    {
                      fieldFilter: {
                        field: { fieldPath: "accessCompanies" },
                        op: "ARRAY_CONTAINS_ANY",
                        value: {
                          arrayValue: {
                            values: managedCompaniesPart.map(x => ({
                              stringValue: x
                            }))
                          }
                        }
                      }
                    },
                    {
                      fieldFilter: {
                        field: { fieldPath: "uid" },
                        op: "EQUAL",
                        value: {
                          stringValue: user.id
                        }
                      }
                    }
                  ]
                }
              }
            ])
          : [
              {
                fieldFilter: {
                  field: { fieldPath: "uid" },
                  op: "EQUAL",
                  value: {
                    stringValue: user.id
                  }
                }
              }
            ],
        default: [
          {
            fieldFilter: {
              field: { fieldPath: "accessCompanies" },
              op: "ARRAY_CONTAINS_ANY",
              value: {
                arrayValue: {
                  values: [company.id, companyLanguage, "all"]
                    .filter(x => x)
                    .map(x => ({ stringValue: x }))
                }
              }
            }
          }
        ]
      },
      company: {
        "third-party": false,
        default: [
          {
            fieldFilter: {
              field: { fieldPath: "accessCompanies" },
              op: "ARRAY_CONTAINS",
              value: {
                stringValue: company.id
              }
            }
          }
        ]
      },
      crop: {
        "third-party": false,
        default: [
          {
            fieldFilter: {
              field: { fieldPath: "accessCompanies" },
              op: "ARRAY_CONTAINS",
              value: {
                stringValue: company.id
              }
            }
          }
        ]
      }
    };

    const getFiltersForScope = scope => {
      const filtersForScope =
        (filters[scope][user.type] ?? filters[scope].default)?.filter?.(
          x => x
        ) || false;
      if (filtersForScope === false) return false;

      return filtersForScope.map(filterItem => ({
        compositeFilter: {
          op: "AND",
          filters: [
            filterItem,
            {
              fieldFilter: {
                field: { fieldPath: "scope" },
                op: "EQUAL",
                value: { stringValue: scope }
              }
            }
          ]
        }
      }));
    };

    const getDocumentsForScope = async scope => {
      const filtersForScope = getFiltersForScope(scope);
      if (filtersForScope === false) return [scope, false];

      const documentsForScope = (
        await Promise.all(
          filtersForScope.map(filterForScope =>
            callRestApiQuery({
              structuredQuery: {
                from: {
                  collectionId: "docs",
                  allDescendants: false
                },
                where: filterForScope,
                limit: 1000
              }
            })
          )
        )
      )
        .flat()
        .sort((a, b) => b.created - a.created);
      return [scope, uniqBy(documentsForScope, "id")];
    };

    const docs = Object.fromEntries(
      await Promise.all([
        getDocumentsForScope("platform"),
        getDocumentsForScope("company"),
        getDocumentsForScope("crop")
      ])
    );

    if (docs.platform && managedCompanies.length) {
      docs.platform = docs.platform.filter(doc => {
        const isLanguageGroup = intersection(
          managedLanguages,
          doc.accessCompanies
        ).length;
        if (!isLanguageGroup) return true;
        return intersection(managedLanguages, doc.accessCompanies).length;
      });
    }

    docs.platform &&
      (await Promise.all(docs.platform.map(doc => hydrate(doc, ["uid"]))));
    docs.company &&
      (await Promise.all(docs.company.map(doc => hydrate(doc, ["uid"]))));
    docs.crop &&
      (await Promise.all(docs.crop.map(doc => hydrate(doc, ["uid"]))));

    return {
      platform: [],
      company: [],
      crop: [],
      ...docs
    };
  };

  const getAdvices = async (user, companyId) => {
    const managedCompanies = [companyId];

    const filters = {
      advisor: [
        ...(managedCompanies.length
          ? chunk(managedCompanies, 10)
              .map(managedCompaniesPart => ({
                fieldFilter: {
                  field: { fieldPath: "companyId" },
                  op: "IN",
                  value: {
                    arrayValue: {
                      values: managedCompaniesPart.map(x => ({
                        stringValue: x
                      }))
                    }
                  }
                }
              }))
              .concat([
                {
                  unaryFilter: {
                    field: { fieldPath: "companyId" },
                    op: "IS_NULL"
                  }
                }
              ])
          : [null])
      ],
      "third-party": [
        {
          fieldFilter: {
            field: { fieldPath: "uid" },
            op: "EQUAL",
            value: {
              stringValue: user.id
            }
          }
        }
      ],
      default: [
        {
          compositeFilter: {
            op: "AND",
            filters: [
              {
                fieldFilter: {
                  field: { fieldPath: "companyId" },
                  op: "EQUAL",
                  value: {
                    stringValue: companyId
                  }
                }
              },
              {
                fieldFilter: {
                  field: { fieldPath: "forUsers" },
                  op: "EQUAL",
                  value: {
                    arrayValue: {
                      values: []
                    }
                  }
                }
              }
            ]
          }
        },
        {
          compositeFilter: {
            op: "AND",
            filters: [
              {
                fieldFilter: {
                  field: { fieldPath: "companyId" },
                  op: "EQUAL",
                  value: {
                    stringValue: companyId
                  }
                }
              },
              {
                fieldFilter: {
                  field: { fieldPath: "forUsers" },
                  op: "ARRAY_CONTAINS",
                  value: {
                    stringValue: user.id
                  }
                }
              }
            ]
          }
        }
      ]
    };

    const getFiltersForScope = () => {
      const filtersForScope = (filters[user.type] ?? filters.default) || false;
      if (filtersForScope === false) return false;

      return filtersForScope.map(filterItem => ({
        compositeFilter: {
          op: "AND",
          filters: [filterItem].filter(x => x)
        }
      }));
    };

    const getAdvicesForScope = async () => {
      const filtersForScope = getFiltersForScope();
      if (filtersForScope === false) return false;

      const advicesForScope = (
        await Promise.all(
          filtersForScope.map(filterForScope =>
            callRestApiQuery({
              structuredQuery: {
                from: {
                  collectionId: "advices",
                  allDescendants: false
                },
                where: filterForScope,
                limit: 1000
              }
            })
          )
        )
      )
        .flat()
        .sort((a, b) => b.created - a.created);
      return uniqBy(advicesForScope, "id");
    };

    const hasSpecialPermission = () => {
      return [user.type === "advisor"].filter(x => x);
    };

    const advices = await getAdvicesForScope();
    const advicesForUser = hasSpecialPermission()
      ? advices
      : advices.filter(advice => {
          return advice.forUsers?.length
            ? advice.forUsers.includes(user.id) || advice.uid === user.id
            : true;
        });
    await Promise.all(advicesForUser.map(advice => hydrate(advice, ["uid"])));

    return advicesForUser;
  };

  const getAdvicesForCrop = async (cropId, companyId) => {
    const advicesForCrop = await callRestApiQuery({
      structuredQuery: {
        from: {
          collectionId: "advices",
          allDescendants: false
        },
        where: {
          compositeFilter: {
            op: "AND",
            filters: [
              {
                fieldFilter: {
                  field: { fieldPath: "companyId" },
                  op: "EQUAL",
                  value: {
                    stringValue: companyId
                  }
                }
              },
              {
                fieldFilter: {
                  field: { fieldPath: "forCrops" },
                  op: "ARRAY_CONTAINS",
                  value: {
                    stringValue: cropId
                  }
                }
              }
            ]
          }
        },
        limit: 1000
      }
    });
    await Promise.all(advicesForCrop.map(doc => hydrate(doc, ["uid"])));

    return advicesForCrop;
  };

  const getAdvicesForCrops = async ({ companyId, fromDate, toDate }) => {
    const documents = await firestore
      .collection("advices")
      .where("companyId", "==", companyId)
      .where("created", ">=", fromDate)
      .where("created", "<=", toDate)
      .get();

    const docs = documents.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));

    if (!docs.length) return [];

    const uids = docs.reduce((acc, doc) => {
      if (doc.uid && !acc.includes(doc.uid)) {
        acc.push(doc.uid);
      }
      return acc;
    }, []);

    const chunkedUsers = chunk(uids, 10)
      .map(chunkedUids => {
        return firestore
          .collection("users")
          .where(firebase.firestore.FieldPath.documentId(), "in", chunkedUids)
          .get();
      })
      .flat();

    const usersData = await Promise.all(chunkedUsers);

    const users = usersData
      .map(c => c.docs)
      .flat()
      .map(doc => {
        return { ...doc.data(), id: doc.id };
      });

    return docs.map(doc => {
      const user = users.find(u => u.id === doc.uid);
      return { ...doc, user };
    });
  };

  const getDocumentsForCrop = async (cropId, companyId) => {
    const documentsForCrop = await callRestApiQuery({
      structuredQuery: {
        from: {
          collectionId: "docs",
          allDescendants: false
        },
        where: {
          compositeFilter: {
            op: "AND",
            filters: [
              {
                fieldFilter: {
                  field: { fieldPath: "scope" },
                  op: "EQUAL",
                  value: {
                    stringValue: "crop"
                  }
                }
              },
              {
                fieldFilter: {
                  field: { fieldPath: "cropIds" },
                  op: "ARRAY_CONTAINS",
                  value: {
                    stringValue: cropId
                  }
                }
              }
            ]
          }
        },
        limit: 1000
      }
    });
    await Promise.all(documentsForCrop.map(doc => hydrate(doc, ["uid"])));
    return documentsForCrop;
  };

  const getDocumentsForCrops = async ({ companyId, fromDate, toDate }) => {
    const documents = await firestore
      .collection("docs")
      .where("scope", "==", "crop")
      .where("accessCompanies", "array-contains", companyId)
      .where("created", ">=", fromDate)
      .where("created", "<=", toDate)
      .get();

    const docs = documents.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));

    if (!docs.length) return [];

    const uids = docs.reduce((acc, doc) => {
      if (doc.uid && !acc.includes(doc.uid)) {
        acc.push(doc.uid);
      }
      return acc;
    }, []);

    const chunkedUsers = chunk(uids, 10)
      .map(chunkedUids => {
        return firestore
          .collection("users")
          .where(firebase.firestore.FieldPath.documentId(), "in", chunkedUids)
          .get();
      })
      .flat();

    const usersData = await Promise.all(chunkedUsers);

    const users = usersData
      .map(x => x.docs)
      .flat()
      .map(doc => {
        return { ...doc.data(), id: doc.id };
      });

    return docs.map(doc => {
      const user = users.find(u => u.id === doc.uid);
      return { ...doc, user };
    });
  };

  const upsertAdvice = async advice => {
    const existingAdviceId = advice.id;
    if (!existingAdviceId) {
      const newAdvice = await firestore.collection("advices").add(advice);
      return {
        id: newAdvice.id,
        ...advice
      };
    } else {
      await firestore.collection("advices").doc(existingAdviceId).set(advice);
      return advice;
    }
  };

  const deleteAdvice = async adviceId => {
    return await firestore.collection("advices").doc(adviceId).delete();
  };

  const getGeneralNotes = async (companyId, order = "DESCENDING") => {
    const generalNotes = await callRestApiQuery(
      {
        structuredQuery: {
          from: {
            collectionId: "generalNotes",
            allDescendants: false
          },
          orderBy: [
            {
              field: {
                fieldPath: "created"
              },
              direction: order
            }
          ],
          limit: 1000
        }
      },
      `/companies/${companyId}`
    );

    const hydrateFields = ["uid"];
    const docsToHydrateIdArr = Array.from(
      new Set(
        generalNotes
          .map(entry =>
            hydrateFields
              .map(field => {
                const val = get(entry, field);
                if (field === "uid") return `${DB_PATH}/users/${val}`;
                return val;
              })
              .filter(x => x)
          )
          .flat()
      )
    );
    const docsToHydrate = await callRestApiPost(
      {
        documents: docsToHydrateIdArr
      },
      true
    );

    for (const entry of generalNotes) {
      for (const field of hydrateFields) {
        const fieldName = get(entry, field);
        if (!fieldName) continue;
        const fieldNameFin =
          field === "uid" ? `${DB_PATH}/users/${fieldName}` : fieldName;
        const fieldValue = get(docsToHydrate, fieldNameFin);
        set(entry, field === "uid" ? "user" : field, fieldValue);
      }
    }

    return generalNotes;
  };

  const getGeneralNotesForCrop = async (companyId, allCropLocations) => {
    const res = await Promise.all(
      chunk(allCropLocations, 10).map(async cropLocations => {
        const generalRolesForCrop = await callRestApiQuery(
          {
            structuredQuery: {
              from: {
                collectionId: "generalNotes",
                allDescendants: false
              },
              where: {
                compositeFilter: {
                  op: "AND",
                  filters: [
                    {
                      fieldFilter: {
                        field: { fieldPath: "locations" },
                        op: "ARRAY_CONTAINS_ANY",
                        value: {
                          arrayValue: {
                            values: cropLocations.map(x => ({ stringValue: x }))
                          }
                        }
                      }
                    },
                    {
                      fieldFilter: {
                        field: { fieldPath: "includeInCrops" },
                        op: "EQUAL",
                        value: { booleanValue: true }
                      }
                    }
                  ]
                }
              },
              limit: 1000
            }
          },
          `/companies/${companyId}`
        );
        await Promise.all(
          generalRolesForCrop.map(doc => hydrate(doc, ["uid"]))
        );
        return generalRolesForCrop;
      })
    );

    return res.flat();
  };

  const upsertGeneralNote = async (companyId, generalNote) => {
    const existingNoteId = generalNote.id;
    if (!existingNoteId) {
      const newGeneralNote = await firestore
        .collection("companies")
        .doc(companyId)
        .collection("generalNotes")
        .add(generalNote);
      return {
        id: newGeneralNote.id,
        ...generalNote
      };
    } else {
      await firestore
        .collection("companies")
        .doc(companyId)
        .collection("generalNotes")
        .doc(existingNoteId)
        .set(generalNote);
      return generalNote;
    }
  };

  const deleteGeneralNote = async (companyId, noteId) => {
    return await firestore
      .collection("companies")
      .doc(companyId)
      .collection("generalNotes")
      .doc(noteId)
      .delete();
  };

  const getCompanyPendingLabAnalysis = async (
    companyId,
    order = "DESCENDING"
  ) => {
    const pendingLabAnalysis = await callRestApiQuery(
      {
        structuredQuery: {
          from: {
            collectionId: "pendingLabResults",
            allDescendants: false
          },
          orderBy: [
            {
              field: {
                fieldPath: "created"
              },
              direction: order
            }
          ],
          limit: 1000
        }
      },
      `/companies/${companyId}`
    );

    return pendingLabAnalysis;
  };

  const applyPendingLabAnalysis = async (cropId, companyId, data) => {
    if (cropId) {
      await firestore
        .collection("crops")
        .doc(cropId)
        .collection("labData")
        .doc(String(data.entryId))
        .set(
          pickBy(
            data,
            (val, key) =>
              !["id", "entryId", "labCustNumber"].includes(key) &&
              val !== undefined
          )
        );
    }

    await firestore
      .collection("companies")
      .doc(companyId)
      .collection("pendingLabResults")
      .doc(String(data.entryId))
      .delete();
  };

  const findClassification = async genus => {
    const snap = await firestore
      .collection("cropClassifications")
      .where("name", "==", genus)
      .limit(1)
      .get();
    if (snap.empty) return null;
    return snap.docs[0];
  };

  const getClassification = id => {
    return firestore.collection("cropClassifications").doc(id);
  };

  const getClassifications = () => {
    return firestore.collection("cropClassifications").orderBy("name").get();
  };

  const getSpeciesData = async () => {
    const [snapG, snapSpecies, snapCult] = await Promise.all([
      firestore.collection("genusCol").get(),
      firestore.collection("speciesCol").get(),
      firestore.collection("cultivarCol").get()
    ]);

    return {
      genus: snapG.docs.map(toData),
      species: snapSpecies.docs.map(toData),
      cultivar: snapCult.docs.map(toData)
    };
  };

  const createInviteToken = async data => {
    const {
      company,
      type,
      email,
      thirdPartyRequestId,
      preferredLanguage,
      templateType,
      contactPerson
    } = data;
    const newToken = await firestore.collection("inviteTokens").add({
      companyId: company.id,
      companyName: company.name,
      email,
      type,
      templateType: templateType || null,
      contactPerson: contactPerson || null,
      thirdPartyRequestId,
      preferredLanguage,
      created: new Date()
    });
    return newToken.id;
  };

  const getInviteToken = token => getReference("inviteTokens", token).get();

  const createCrop = async (fields, companyId) => {
    const created = await firestore.collection("crops").add(fields);
    const ref = getReference("crops", created.id);
    const companyRef = getReference("companies", companyId);
    await companyRef.update({
      crops: firebase.firestore.FieldValue.arrayUnion(ref)
    });
    return created;
  };

  const getFirstCropPhoto = async cropId => {
    return firestore
      .collection("crops")
      .doc(cropId)
      .collection("photos")
      .orderBy("date", "desc")
      .limit(1)
      .get()
      .then(snap => {
        if (!snap.empty) {
          // We know there is one doc in the querySnapshot
          const doc = snap.docs[0];
          const data = doc.data();
          data.id = doc.id;
          return data;
        } else {
          return null;
        }
      });
  };

  const getCropPhoto = async (cropId, photoId) => {
    return firestore
      .collection("crops")
      .doc(cropId)
      .collection("photos")
      .doc(photoId)
      .get()
      .then(doc => {
        if (!doc.exists) return null;
        return toData(doc);
      });
  };

  const updateCropPhoto = async (cropId, photoId, photoData) => {
    return firestore
      .collection("crops")
      .doc(cropId)
      .collection("photos")
      .doc(photoId)
      .update(photoData);
  };

  const getCrop = async cropId => {
    const crop = await callRestApiGet(["crops", cropId]);
    if (crop._deleted) return false;

    const hydrateFields = [
      "cropBase",
      "family.cultivar",
      "family.genus",
      "family.species"
    ];
    const docsToHydrateIdArr = hydrateFields
      .map(field => get(crop, field))
      .filter(x => x);
    const docsToHydrate = await callRestApiPost(
      {
        documents: docsToHydrateIdArr
      },
      true
    );

    for (const field of hydrateFields) {
      const fieldName = get(crop, field);
      if (!fieldName) continue;
      const fieldValue = get(docsToHydrate, fieldName);
      set(crop, field, fieldValue);
    }

    return crop;
  };

  const saveCrop = async (cropId, cropData) => {
    await firestore.collection("crops").doc(cropId).update(cropData);
  };

  const deleteCrop = async (cropId, companyId) => {
    const cropRef = getReference("crops", cropId);

    const protocols = await firestore
      .collection("protocols")
      .where("cropIds", "array-contains", cropRef)
      .get();
    const batch = firestore.batch();
    protocols.forEach(protocol => {
      batch.update(protocol.ref, {
        cropIds: firebase.firestore.FieldValue.arrayRemove(cropRef)
      });
    });
    await batch.commit();

    await firestore
      .collection("companies")
      .doc(companyId)
      .update({
        crops: firebase.firestore.FieldValue.arrayRemove(cropRef)
      });
    await firestore.collection("crops").doc(cropId).update({ _deleted: true });
  };

  const getCropNotes = async (cropId, order = "DESCENDING") => {
    const notes = await callRestApiQuery(
      {
        structuredQuery: {
          from: {
            collectionId: "notes",
            allDescendants: false
          },
          orderBy: [
            {
              field: {
                fieldPath: "date"
              },
              direction: order
            }
          ],
          limit: 1000
        }
      },
      `/crops/${cropId}`
    );

    const hydrateFields = ["image", "uid"];
    const docsToHydrateIdArr = Array.from(
      new Set(
        notes
          .map(note =>
            hydrateFields
              .map(field => {
                const val = get(note, field);
                if (field === "uid") return `${DB_PATH}/users/${val}`;
                return val;
              })
              .filter(x => x)
          )
          .flat()
      )
    );
    const docsToHydrate = await callRestApiPost(
      {
        documents: docsToHydrateIdArr
      },
      true
    );

    for (const note of notes) {
      for (const field of hydrateFields) {
        const fieldName = get(note, field);
        if (!fieldName) continue;
        const fieldNameFin =
          field === "uid" ? `${DB_PATH}/users/${fieldName}` : fieldName;

        if (Array.isArray(fieldNameFin)) {
          const hydratedArr = fieldNameFin.map(key => get(docsToHydrate, key));
          set(note, field, hydratedArr);
        } else {
          const fieldValue = get(docsToHydrate, fieldNameFin);
          set(note, field === "uid" ? "user" : field, fieldValue);
        }
      }
    }

    return notes.map(n => ({ ...n, cropId }));
  };

  const getAllNotes = async ({ fromDate, toDate, companyId }) => {
    const notesSnap = await firestore
      .collectionGroup("notes")
      .where("companyId", "==", companyId)
      .where("date", ">=", fromDate)
      .where("date", "<=", toDate)
      .limit(1000)
      .get();

    const notes = notesSnap.docs.map(doc => {
      const data = doc.data();
      data.id = doc.id;
      return data;
    });

    const uids = notes.reduce((acc, note) => {
      if (note.uid && !acc.includes(note.uid)) {
        acc.push(note.uid);
      }
      return acc;
    }, []);

    const chunkedUsers = chunk(uids, 10)
      .map(chunkedUids => {
        return firestore
          .collection("users")
          .where(firebase.firestore.FieldPath.documentId(), "in", chunkedUids)
          .get();
      })
      .flat();

    const usersData = await Promise.all(chunkedUsers);

    const users = usersData
      .map(usersChuckedData => usersChuckedData.docs)
      .flat()
      .map(doc => {
        return { ...doc.data(), id: doc.id };
      });

    return notes.map(note => {
      const user = users.find(u => u.id === note.uid);
      return { ...note, user };
    });
  };

  const getCropsWithNotes = async (filters, companyId) => {
    let query = firestore
      .collectionGroup("notes")
      .where("companyId", "==", companyId);

    for (const [selector, value] of Object.entries(filters)) {
      query = query.where(selector, "==", value);
    }

    const matches = await query.get().then(x => x.docs);
    const results = matches.reduce((acc, match) => {
      const cropId = match?.ref?.parent?.parent?.id;
      if (cropId) {
        if (!acc[cropId]) acc[cropId] = [];
        acc[cropId].push(match.id);
      }
      return acc;
    }, {});
    return results;
  };

  const getNotesByBatchId = async (companyId, noteBatchId) => {
    const notes = await firestore
      .collectionGroup("notes")
      .where("companyId", "==", companyId)
      .where("noteBatchId", "==", noteBatchId)
      .get();

    return notes.docs.map(toData);
  };

  const getAllCropsWithNotes = async (
    companyId,
    config = { progressCb: () => {} }
  ) => {
    const CHUNK_SIZE = 10;
    const { progressCb } = config;

    const company = await getCompanyCrops(companyId);
    const crops = company.crops || [];
    if (crops.length > 100) progressCb("total", crops.length);

    const chunks = chunk(crops, CHUNK_SIZE);
    const cropsWithNotes = [];

    for (const [chunkIndex, chunk] of chunks.entries()) {
      const currentCount = Math.min(+chunkIndex * CHUNK_SIZE, crops.length);
      progressCb("current", currentCount);

      const chunkPromises = chunk.map(async crop => {
        const cropNotes = await getCropNotes(crop.id);
        crop.notes = convertNotes(cropNotes.filter(note => note));
        return crop;
      });
      const chunkResults = await Promise.all(chunkPromises);
      cropsWithNotes.push(...chunkResults);
    }

    progressCb("done", null);
    return cropsWithNotes;
  };

  const getCropPhotos = async (cropId, order = "DESCENDING") => {
    const photos = await callRestApiQuery(
      {
        structuredQuery: {
          from: {
            collectionId: "photos",
            allDescendants: false
          },
          orderBy: [
            {
              field: {
                fieldPath: "date"
              },
              direction: order
            }
          ],
          limit: 1000
        }
      },
      `/crops/${cropId}`
    );

    const hydrateFields = ["uid"];
    const docsToHydrateIdArr = Array.from(
      new Set(
        photos
          .map(photo =>
            hydrateFields
              .map(field => {
                const val = get(photo, field);
                if (field === "uid") return `${DB_PATH}/users/${val}`;
                return val;
              })
              .filter(x => x)
          )
          .flat()
      )
    );
    const docsToHydrate = await callRestApiPost(
      {
        documents: docsToHydrateIdArr
      },
      true
    );

    for (const photo of photos) {
      for (const field of hydrateFields) {
        const fieldName = get(photo, field);
        if (!fieldName) continue;
        const fieldNameFin =
          field === "uid" ? `${DB_PATH}/users/${fieldName}` : fieldName;
        const fieldValue = get(docsToHydrate, fieldNameFin);
        set(photo, field === "uid" ? "user" : field, fieldValue);
      }
    }

    return photos;
  };

  const getCropsPhotos = async (
    noteIds,
    order = "desc",

    { maxDate, minDate }
  ) => {
    const minDateTimestamp = dateToUnixTimestamp(minDate || new Date(0));
    const maxDateTimestamp = dateToUnixTimestamp(maxDate || new Date());

    const uniquePhotos = [];
    const chuckedNotesIds = chunk(noteIds, 10);
    const chunkedPhotos = await Promise.all(
      chuckedNotesIds.map(async noteIdsChunk => {
        const photosSnap = await firestore
          .collectionGroup("photos")
          .where("noteId", "in", noteIdsChunk)
          .where("date", ">=", minDateTimestamp)
          .where("date", "<=", maxDateTimestamp)
          // .orderBy("date", order)
          .limit(1000)
          .get();

        const photos = photosSnap.docs.map(doc => {
          const data = doc.data();
          data.id = doc.id;
          return data;
        });

        return photos;
      })
    );

    for (const photos of chunkedPhotos) {
      for (const photo of photos) {
        const existingPhoto = uniquePhotos.find(
          x => x.id === photo.id && x.cropId === photo.cropId
        );
        if (!existingPhoto) {
          uniquePhotos.push(photo);
        }
      }
    }

    const hydrateFields = ["uid"];
    const docsToHydrateIdArr = Array.from(
      new Set(
        uniquePhotos
          .map(photo =>
            hydrateFields
              .map(field => {
                const val = get(photo, field);
                if (field === "uid") return `${DB_PATH}/users/${val}`;
                return val;
              })
              .filter(x => x)
          )
          .flat()
      )
    );
    const docsToHydrate = await callRestApiPost(
      {
        documents: docsToHydrateIdArr
      },
      true
    );

    for (const photo of uniquePhotos) {
      for (const field of hydrateFields) {
        const fieldName = get(photo, field);
        if (!fieldName) continue;
        const fieldNameFin =
          field === "uid" ? `${DB_PATH}/users/${fieldName}` : fieldName;
        const fieldValue = get(docsToHydrate, fieldNameFin);
        set(photo, field === "uid" ? "user" : field, fieldValue);
      }
    }

    const sortedPhotos = sortBy(uniquePhotos, "date");
    if (order === "desc") return sortedPhotos.reverse();
    return sortedPhotos;
  };

  const getCropLabData = async (cropId, order = "DESCENDING") => {
    const labData = await callRestApiQuery(
      {
        structuredQuery: {
          from: {
            collectionId: "labData",
            allDescendants: false
          },
          orderBy: [
            {
              field: {
                fieldPath: "date"
              },
              direction: order
            }
          ],
          limit: 1000
        }
      },
      `/crops/${cropId}`
    );

    return labData;
  };

  const getCropLabDataNotes = async (cropId, order = "DESCENDING") => {
    const labDataNotes = await callRestApiQuery(
      {
        structuredQuery: {
          from: {
            collectionId: "labDataNotes",
            allDescendants: false
          },
          orderBy: [
            {
              field: {
                fieldPath: "created"
              },
              direction: order
            }
          ],
          limit: 1000
        }
      },
      `/crops/${cropId}`
    );

    const hydrateFields = ["uid"];
    const docsToHydrateIdArr = Array.from(
      new Set(
        labDataNotes
          .map(entry =>
            hydrateFields
              .map(field => {
                const val = get(entry, field);
                if (field === "uid") return `${DB_PATH}/users/${val}`;
                return val;
              })
              .filter(x => x)
          )
          .flat()
      )
    );
    const docsToHydrate = await callRestApiPost(
      {
        documents: docsToHydrateIdArr
      },
      true
    );

    for (const entry of labDataNotes) {
      for (const field of hydrateFields) {
        const fieldName = get(entry, field);
        if (!fieldName) continue;
        const fieldNameFin =
          field === "uid" ? `${DB_PATH}/users/${fieldName}` : fieldName;
        const fieldValue = get(docsToHydrate, fieldNameFin);
        set(entry, field === "uid" ? "user" : field, fieldValue);
      }
    }

    return labDataNotes;
  };

  const getAlerts = async (companyId, userId, config) => {
    const filtersForScope = [
      {
        compositeFilter: {
          op: "AND",
          filters: [
            ...(companyId
              ? [
                  {
                    fieldFilter: {
                      field: { fieldPath: "companyId" },
                      op: "EQUAL",
                      value: { stringValue: companyId }
                    }
                  }
                ]
              : []),
            companyId
              ? {
                  unaryFilter: {
                    field: { fieldPath: "forUsers" },
                    op: "IS_NULL"
                  }
                }
              : null,
            {
              fieldFilter: {
                field: { fieldPath: "created" },
                op: "GREATER_THAN_OR_EQUAL",
                value: { timestampValue: subDays(new Date(), 14).toISOString() }
              }
            },
            {
              fieldFilter: {
                field: { fieldPath: "active" },
                op: "EQUAL",
                value: { booleanValue: true }
              }
            },
            {
              fieldFilter: {
                field: { fieldPath: "type" },
                op: "IN",
                value: {
                  arrayValue: {
                    values: config.alertTypes.map(type => ({
                      stringValue: type
                    }))
                  }
                }
              }
            }
          ].filter(x => x)
        }
      },
      companyId
        ? {
            compositeFilter: {
              op: "AND",
              filters: [
                ...(companyId
                  ? [
                      {
                        fieldFilter: {
                          field: { fieldPath: "companyId" },
                          op: "EQUAL",
                          value: { stringValue: companyId }
                        }
                      }
                    ]
                  : []),
                {
                  fieldFilter: {
                    field: { fieldPath: "forUsers" },
                    op: "ARRAY_CONTAINS",
                    value: {
                      stringValue: userId
                    }
                  }
                },
                {
                  fieldFilter: {
                    field: { fieldPath: "created" },
                    op: "GREATER_THAN_OR_EQUAL",
                    value: {
                      timestampValue: subDays(new Date(), 14).toISOString()
                    }
                  }
                },
                {
                  fieldFilter: {
                    field: { fieldPath: "active" },
                    op: "EQUAL",
                    value: { booleanValue: true }
                  }
                },
                {
                  fieldFilter: {
                    field: { fieldPath: "type" },
                    op: "IN",
                    value: {
                      arrayValue: {
                        values: config.alertTypes.map(type => ({
                          stringValue: type
                        }))
                      }
                    }
                  }
                }
              ]
            }
          }
        : null
    ].filter(x => x);

    const alerts = (
      await Promise.all(
        filtersForScope.map(filterForScope =>
          callRestApiQuery({
            structuredQuery: {
              select: {
                fields: [
                  { fieldPath: "type" },
                  { fieldPath: "companyId" },
                  { fieldPath: "entryId" },
                  { fieldPath: "cropId" },
                  { fieldPath: "seenBy" },
                  { fieldPath: "created" },
                  { fieldPath: "byUserId" },
                  { fieldPath: "data" }
                ]
              },
              from: {
                collectionId: "alerts",
                allDescendants: false
              },
              where: filterForScope,
              limit: 1000
            }
          })
        )
      )
    )
      .flat()
      .sort((a, b) => a.created - b.created);

    const hydrateFields = config.hydrateUser
      ? ["byUserId", "cropId", "entryId"]
      : [];

    const alertTypeFields = {
      "new-task-due": "taskId",
      "lab-data-pending": "pendingLabDataId",
      "new-advice": "adviceId",
      "new-sensor-trigger": "measurementId",
      "new-note-mention": "noteId"
    };

    const specialFieldNames = {
      byUserId: {
        fieldName: `${DB_PATH}/users/`,
        entryName: "user"
      },
      cropId: {
        fieldName: `${DB_PATH}/crops/`,
        entryName: "forCrop"
      },
      taskId: {
        fieldName: `${DB_PATH}/tasks/`,
        entryName: "entry"
      },
      pendingLabDataId: {
        fieldName: `${DB_PATH}/companies/${companyId}/pendingLabResults/`,
        entryName: "entry"
      },
      adviceId: {
        fieldName: `${DB_PATH}/advices/`,
        entryName: "entry"
      },
      measurementId: {
        fieldName: `${DB_PATH}/measurements/`,
        entryName: "entry"
      },
      noteId: {
        fieldName: `${DB_PATH}/crops/{cropId}/notes/`,
        entryName: "entry"
      }
    };

    const docsToHydrateIdArr = Array.from(
      new Set(
        alerts
          .map(alert =>
            hydrateFields
              .map(field => {
                const val = get(alert, field);
                if (!val) return null;

                let retVal = null;
                if (field === "entryId") {
                  const alertTypeField = alertTypeFields[alert.type];
                  retVal = `${
                    specialFieldNames[alertTypeField]?.fieldName || ""
                  }${val}`;
                } else {
                  retVal = `${specialFieldNames[field]?.fieldName || ""}${val}`;
                }

                retVal = retVal
                  .split("/")
                  .reduce((acc, part) => {
                    if (part.at(0) === "{" && part.at(-1) === "}") {
                      const param = part.substring(1, part.length - 1);
                      acc.push(get(alert, param));
                    } else {
                      acc.push(part);
                    }
                    return acc;
                  }, [])
                  .join("/");

                return retVal;
              })
              .filter(x => x)
          )
          .flat()
      )
    );

    const docsToHydrate = await callRestApiPost(
      {
        documents: docsToHydrateIdArr
      },
      true
    );

    for (const alert of alerts) {
      for (const field of hydrateFields) {
        const fieldName = get(alert, field);
        if (!fieldName) continue;

        if (field === "entryId") {
          const alertTypeField = alertTypeFields[alert.type];
          const fieldNameFin = `${
            specialFieldNames[alertTypeField]?.fieldName || ""
          }${fieldName}`;
          const fieldValue = get(docsToHydrate, fieldNameFin);
          set(alert, specialFieldNames[alertTypeField]?.entryName, fieldValue);
        } else {
          const fieldNameFin = `${
            specialFieldNames[field]?.fieldName || ""
          }${fieldName}`;
          const fieldValue = get(docsToHydrate, fieldNameFin);
          set(alert, specialFieldNames[field]?.entryName || field, fieldValue);
        }
      }
    }

    if (config.hydrateUser) return alerts.reverse();
    return alerts;
  };
  const getCompanies = async (params = {}) => {
    const { sort, extraFields } = params;
    const reqParams = {
      "mask.fieldPaths": ["id", "name", ...(extraFields || [])],
      pageSize: 20000
    };
    if (sort) reqParams.orderBy = sort;
    return await callRestApiGet(["companies"], reqParams);
  };

  const updateCompany = async (id, update) =>
    await firestore.collection("companies").doc(id).update(update);

  const updateUser = async (uid, user, extraData) => {
    if (extraData) {
      // maybe separate in another fn
      const { context, roleId, allowedLocations, companyId } = extraData;
      if (context === "own") {
        // when companyId is the user's own company then set roleId directly
        user.roleId = roleId;
        user["config.allowedLocations"] = allowedLocations;
      } else if (context === "third-party") {
        // otherwise update the respective managedCompanies entry
        const managedCompanies = await firestore
          .collection("users")
          .doc(uid)
          .get()
          .then(doc => toData(doc))
          .then(user => user.config.managedCompanies);

        const managedCompany = managedCompanies.find(
          x => x.companyId === companyId
        );
        if (managedCompany) {
          managedCompany.roleId = roleId;
          managedCompany.allowedLocations = allowedLocations;
          user["config.managedCompanies"] = managedCompanies;
        }
      }
    }
    return await firestore.collection("users").doc(uid).update(user);
  };

  const updateUserAgreementVersion = async (id, version) =>
    await updateUser(id, { "agreement.version": version });

  const addCompanyToThirdPartyUser = async (userId, companyId) => {
    await firestore
      .collection("users")
      .doc(userId)
      .update({
        "config.managedCompanies": firebase.firestore.FieldValue.arrayUnion({
          companyId,
          roleId: null,
          isOwnCompany: false
        }),
        "config.manageCompanies":
          firebase.firestore.FieldValue.arrayUnion(companyId)
      });
  };

  const updateYoungPlantSuppliers = async (
    companyId,
    youngPlantSuppliers = []
  ) => {
    const { crops: companyCrops } = await getCompanyCrops(companyId);
    const cropsWithDeletedYoungPlantSuppliers = companyCrops.filter(crop => {
      const cropYoungPlantSuppliers = crop.profile?.youngPlantSuppliers; // singular
      return (
        cropYoungPlantSuppliers &&
        !youngPlantSuppliers.includes(cropYoungPlantSuppliers)
      );
    });

    await Promise.all(
      cropsWithDeletedYoungPlantSuppliers.map(async crop => {
        await saveCrop(crop.id, {
          "profile.youngPlantSuppliers": null
        });
      })
    );
  };

  const getAdvisorUsers = async () => {
    const snap = await firestore
      .collection("users")
      .where("type", "in", ["advisor"])
      .get();
    const data = snap.docs.map(doc => toData(doc));
    return data;
  };

  const getUsersByCompany = async (companyId, activeOnly = false) => {
    let query = firestore
      .collection("users")
      .where("companyId", "==", companyId);

    if (activeOnly) {
      query = query.where("active", "==", true);
    }

    const snap = await query.get();
    const data = snap.docs.map(doc => toData(doc));
    return data;
  };

  const createUser = async (
    email,
    password,
    companyId,
    userType,
    thirdPartyRequestId,
    _,
    preferredLanguage
  ) => {
    $fireAuthStore?.unsubscribe();

    const created = await $fire.auth.createUserWithEmailAndPassword(
      email,
      password
    );
    const { uid } = created.user;

    // create user profile
    const userProfile = {
      notificationSettings: {
        sensorAlerts: true,
        taskAlerts: true,
        dailySummary: true,
        endOfCultivationAlerts: true
      },
      sensorSettings: {
        receiveAlerts: []
      },
      taskAlertPreference: "email",
      preferredLanguage,
      phone: ""
    };

    const userDbObj = {
      name: email.split("@")[0],
      type: userType,
      slug: kebabCaseIt(email.split("@")[0]),
      config: {
        managedCompanies: [],
        manageCompanies: [],
        allowedLocations: []
      },
      profile: userProfile,
      roleId: null,
      agreement: { version: "new" },
      active: true
    };

    if (userType === "third-party" && thirdPartyRequestId) {
      // do both always need to be true?
      const thirdPartyRequest = await firestore
        .collection("thirdPartyRequests")
        .doc(thirdPartyRequestId)
        .get()
        .then(doc => toData(doc));

      const companyLocale = $i18n.locales
        .map(e => ({ ...e, name: e.text }))
        .find(x => x.code === preferredLanguage || "en");
      const companyDbObj = {
        name: `Dashboard ${userDbObj.name}`,
        contactPerson: null,
        language: companyLocale,
        companyLabels: [],
        businessLocation: null
      };

      const thirdPartyOwnCompanyId = await createCompany(companyDbObj);
      Object.assign(userDbObj, {
        company: getReference("companies", thirdPartyOwnCompanyId),
        companyId: thirdPartyOwnCompanyId
      });

      const preapprovedRoleId = thirdPartyRequest.roleId;
      let preassignedRoleId = preapprovedRoleId;

      if (!preassignedRoleId) {
        preassignedRoleId = await firestore
          .collection("roles")
          .where("forThirdPartyDefault", "==", true)
          .orderBy("date", "desc")
          .limit(1)
          .get()
          .then(snap => snap.docs[0]?.id || null);
      }

      userDbObj.config.manageCompanies.push(thirdPartyRequest.companyId);
      userDbObj.config.managedCompanies.push({
        companyId: thirdPartyRequest.companyId,
        roleId: preassignedRoleId || null,
        isOwnCompany: false
      });

      // this await makes the rest of the operations late;
      // or rather onAuthStateChangedAction kicks in before db user update is complete
      await firestore
        .collection("thirdPartyRequests")
        .doc(thirdPartyRequestId)
        .update({ userId: uid });
    } else {
      Object.assign(userDbObj, {
        company: getReference("companies", companyId),
        companyId
      });
    }

    try {
      await firestore.collection("users").doc(uid).set(userDbObj);
      // force idToken refresh in order to refresh the
      // claims on the user object.
      // @see https://firebase.google.com/docs/auth/admin/custom-claims

      await new Promise(resolve => setTimeout(resolve, 4500));
      await created.user.getIdToken(true);

      await new Promise(resolve => setTimeout(resolve, 500));
      // vuex enrichUserData is called automatically as a result
      await $fireAuthStore?.subscribe();
    } catch (e) {
      console.error(e);
      throw e;
    }
    return created.user;
  };

  const getCompanyThirdParties = async companyId => {
    const pendingRequestsSnap = await firestore
      .collection("thirdPartyRequests")
      .where("companyId", "==", companyId)
      .where("status", "==", "pending")
      .get();
    const pendingRequestsData = pendingRequestsSnap.docs.map(doc =>
      toData(doc)
    );

    const pendingAcceptedRequestsSnap = await firestore
      .collection("thirdPartyRequests")
      .where("companyId", "==", companyId)
      .where("status", "==", "accepted")
      .where("userId", "==", null)
      .get();
    const pendingAcceptedRequestsData = pendingAcceptedRequestsSnap.docs.map(
      doc => toData(doc)
    );

    const thirdPartiesSnap = await firestore
      .collection("users")
      .where("type", "==", "third-party")
      .where("companyId", "!=", companyId) // omitting users for whom this is their own company
      .where("config.manageCompanies", "array-contains", companyId)
      .get();
    const thirdPartiesData = thirdPartiesSnap.docs.map(doc => {
      const user = toData(doc);
      if (!user.config.allowedLocations)
        set(user, "config.allowedLocations", []);
      return user;
    });

    return [
      ...pendingRequestsData.map(x => ({ ...x, entity: "request" })),
      ...pendingAcceptedRequestsData.map(x => ({
        ...x,
        entity: "request",
        status: "pendingAccepted"
      })),
      ...thirdPartiesData.map(x => ({ ...x, entity: "user" }))
    ];
  };

  const getThirdPartyInvitation = async tprId => {
    return firestore
      .collection("thirdPartyRequests")
      .doc(tprId)
      .get()
      .then(doc => {
        if (!doc.exists) return null;
        return toData(doc);
      });
  };

  const createThirdPartyInvitation = async ({
    userId,
    email,
    companyId,
    preapproved,
    roleId
  }) => {
    const existingInvitations = await firestore
      .collection("thirdPartyRequests")
      .where("companyId", "==", companyId)
      .where("email", "==", email)
      .where("status", "==", "pending")
      .get();
    const hasExistingInvitation = existingInvitations.docs.length;

    if (!hasExistingInvitation) {
      const tpr = await firestore.collection("thirdPartyRequests").add({
        userId,
        companyId,
        email,
        status: "pending",
        preapproved: preapproved || false,
        roleId: roleId || null,
        date: new Date()
      });
      return tpr.id;
    } else {
      throw new Error("alreadyInvited");
    }
  };

  const updateThirdPartyInvitation = async (request, updatedFields) => {
    await firestore
      .collection("thirdPartyRequests")
      .doc(request.id)
      .update(updatedFields);
  };

  const demoteThirdPartyUser = async (userId, companyId) => {
    const user = await firestore
      .collection("users")
      .doc(userId)
      .get()
      .then(snap => toData(snap));

    const manageCompanies = get(user, "config.manageCompanies", []).filter(
      x => x !== companyId
    );
    const managedCompanies = get(user, "config.managedCompanies", []).filter(
      x => x.companyId !== companyId
    );

    await firestore.collection("users").doc(userId).update({
      "config.manageCompanies": manageCompanies,
      "config.managedCompanies": managedCompanies
    });
  };

  const getCropsImportSettings = async companyId => {
    const cropsImportSettingsArr = await firestore
      .collection("cropsImportMaps")
      .where("companyId", "==", companyId)
      .get();
    const cropsImportSettings = cropsImportSettingsArr.docs
      .map(doc => toData(doc))
      .pop();
    if (!cropsImportSettings) return false;
    return cropsImportSettings;
  };

  const createCropsImportSettings = async cropsImportSettings => {
    const { id: newCropsImportSettingsId } = await firestore
      .collection("cropsImportMaps")
      .add(cropsImportSettings);
    const newCropsImportSettings = await firestore
      .collection("cropsImportMaps")
      .doc(newCropsImportSettingsId)
      .get();
    return toData(newCropsImportSettings);
  };

  const updateCropsImportSettings = async (id, newSettings) => {
    return await firestore
      .collection("cropsImportMaps")
      .doc(id)
      .update(newSettings);
  };

  const upsertClimateDataSettings = async (companyId, climateDataSettings) => {
    return await firestore.collection("companies").doc(companyId).update({
      "profile.settings.climateData": climateDataSettings
    });
  };

  const getClimateDataEntries = async (
    { companyId, from, to },
    order = "DESCENDING"
  ) => {
    const climateData = await callRestApiQuery(
      {
        structuredQuery: {
          from: {
            collectionId: "climateData",
            allDescendants: false
          },
          where:
            from && to
              ? {
                  compositeFilter: {
                    op: "AND",
                    filters: [
                      {
                        fieldFilter: {
                          field: { fieldPath: "date" },
                          op: "GREATER_THAN_OR_EQUAL",
                          value: { timestampValue: from.toISOString() }
                        }
                      },
                      {
                        fieldFilter: {
                          field: { fieldPath: "date" },
                          op: "LESS_THAN_OR_EQUAL",
                          value: { timestampValue: to.toISOString() }
                        }
                      }
                    ]
                  }
                }
              : null,
          orderBy: [
            {
              field: {
                fieldPath: "date"
              },
              direction: order
            }
          ],
          limit: 1000
        }
      },
      `/companies/${companyId}`
    );

    const hydrateFields = ["uid"];
    const docsToHydrateIdArr = Array.from(
      new Set(
        climateData
          .map(entry =>
            hydrateFields
              .map(field => {
                const val = get(entry, field);
                if (field === "uid") return `${DB_PATH}/users/${val}`;
                return val;
              })
              .filter(x => x)
          )
          .flat()
      )
    );
    const docsToHydrate = await callRestApiPost(
      {
        documents: docsToHydrateIdArr
      },
      true
    );

    for (const entry of climateData) {
      for (const field of hydrateFields) {
        const fieldName = get(entry, field);
        if (!fieldName) continue;
        const fieldNameFin =
          field === "uid" ? `${DB_PATH}/users/${fieldName}` : fieldName;
        const fieldValue = get(docsToHydrate, fieldNameFin);
        set(entry, field === "uid" ? "user" : field, fieldValue);
      }
    }

    return climateData;
  };

  const createClimateDataEntry = async (companyId, climateDataEntry) => {
    const newClimateDataEntry = await firestore
      .collection("companies")
      .doc(companyId)
      .collection("climateData")
      .add(climateDataEntry);

    return {
      id: newClimateDataEntry.id,
      ...climateDataEntry
    };
  };

  const deleteClimateDataEntry = async (companyId, entryId) => {
    return await firestore
      .collection("companies")
      .doc(companyId)
      .collection("climateData")
      .doc(entryId)
      .delete();
  };

  const getFertilizationBinEntries = async (
    { companyId, from, to },
    order = "DESCENDING"
  ) => {
    const binEntries = await callRestApiQuery(
      {
        structuredQuery: {
          from: {
            collectionId: "fertilizationBins",
            allDescendants: false
          },
          where:
            from && to
              ? {
                  compositeFilter: {
                    op: "AND",
                    filters: [
                      {
                        fieldFilter: {
                          field: { fieldPath: "date" },
                          op: "GREATER_THAN_OR_EQUAL",
                          value: { timestampValue: from.toISOString() }
                        }
                      },
                      {
                        fieldFilter: {
                          field: { fieldPath: "date" },
                          op: "LESS_THAN_OR_EQUAL",
                          value: { timestampValue: to.toISOString() }
                        }
                      }
                    ]
                  }
                }
              : null,
          orderBy: [
            {
              field: {
                fieldPath: "date"
              },
              direction: order
            }
          ],
          limit: 1000
        }
      },
      `/companies/${companyId}`
    );

    const hydrateFields = ["uid"];
    const docsToHydrateIdArr = Array.from(
      new Set(
        binEntries
          .map(entry =>
            hydrateFields
              .map(field => {
                const val = get(entry, field);
                if (field === "uid") return `${DB_PATH}/users/${val}`;
                return val;
              })
              .filter(x => x)
          )
          .flat()
      )
    );
    const docsToHydrate = await callRestApiPost(
      {
        documents: docsToHydrateIdArr
      },
      true
    );

    for (const entry of binEntries) {
      for (const field of hydrateFields) {
        const fieldName = get(entry, field);
        if (!fieldName) continue;
        const fieldNameFin =
          field === "uid" ? `${DB_PATH}/users/${fieldName}` : fieldName;
        const fieldValue = get(docsToHydrate, fieldNameFin);
        set(entry, field === "uid" ? "user" : field, fieldValue);
      }
    }

    return binEntries;
  };

  const createFertilizationBinEntry = async (companyId, binEntry) => {
    const newBinEntry = await firestore
      .collection("companies")
      .doc(companyId)
      .collection("fertilizationBins")
      .add(binEntry);

    return {
      id: newBinEntry.id,
      ...binEntry
    };
  };

  const deleteFertilizationBinEntry = async (companyId, entryId) => {
    return await firestore
      .collection("companies")
      .doc(companyId)
      .collection("fertilizationBins")
      .doc(entryId)
      .delete();
  };

  const upsertFertilizationBinsSettings = async (
    companyId,
    fertilizationBinsSettings
  ) => {
    return await firestore.collection("companies").doc(companyId).update({
      "profile.settings.fertilizationBins": fertilizationBinsSettings
    });
  };

  const getCropNote = async (cropId, noteId) => {
    const crop = await getCrop(cropId);
    const cropNoteSnap = await firestore
      .collection("crops")
      .doc(cropId)
      .collection("notes")
      .doc(noteId)
      .get();
    const cropNote = toData(cropNoteSnap);
    if (cropNote) cropNote.date = toDate(cropNote.date);

    return { crop, note: cropNote };
  };

  const updateCropNote = async (cropId, noteId, noteData) => {
    return firestore
      .collection("crops")
      .doc(cropId)
      .collection("notes")
      .doc(noteId)
      .update(noteData);
  };

  const setCropFavorite = async (cropId, noteId, cropData) => {
    await firestore
      .collection("crops")
      .doc(cropId)
      .collection("notes")
      .doc(noteId)
      .update(cropData);
  };

  const createReminder = async ({ companyId, userId, scheduledDate, note }) => {
    const newReminder = await firestore.collection("reminders").add({
      companyId,
      userId,
      scheduledDate,
      note,
      created: new Date()
    });
    return newReminder.id;
  };

  const getNonAdvisorUsers = async () => {
    const snap = await firestore
      .collection("users")
      .where("type", "!=", "advisor")
      .get();
    const data = snap.docs.map(doc => toData(doc));
    return data;
  };

  const getThirdPartyUsers = async () => {
    const snap = await firestore
      .collection("users")
      .where("type", "==", "third-party")
      .get();
    const data = snap.docs.map(doc => toData(doc));
    return data;
  };

  const getTask = async taskId => {
    const snap = await getReference("tasks", taskId).get();
    const data = snap.data();
    data.id = snap.id;
    if (data._deleted) return null;
    return data;
  };

  const updateTask = async (taskId, update) => {
    return await firestore.collection("tasks").doc(taskId).update(update);
  };

  const updateAdvisor = async advisorData =>
    await firestore.collection("users").doc(advisorData.id).update(advisorData);

  const getSensorConfig = async (type, companyId) => {
    let query = firestore.collection("sensorConfig");
    if (companyId) query = query.doc(companyId);

    return query.get().then(res => {
      const docs = !companyId ? res.docs : [res];
      return docs.reduce((acc, snap) => {
        const data = snap.data() || {};
        acc[snap.id] = type ? data[type] : data;
        return acc;
      }, {});
    });
  };

  const getStoredMeasurements = async (
    { companyId, subjects: allSubjects, metrics },
    fromDate = new Date(),
    to = new Date()
  ) => {
    const measurementQueries = allSubjects.flatMap(subject =>
      metrics.map(metric => [subject.key, metric.key])
    );
    const res = await Promise.all(
      measurementQueries.map(async ([subjectId, sensorId]) => {
        const measurements = await callRestApiQuery({
          structuredQuery: {
            from: {
              collectionId: "measurements",
              allDescendants: false
            },
            select: {
              fields: [
                { fieldPath: "date" },
                { fieldPath: "value" },
                { fieldPath: "unit" },
                { fieldPath: "status" }
              ]
            },
            where: {
              compositeFilter: {
                op: "AND",
                filters: [
                  {
                    fieldFilter: {
                      field: { fieldPath: "companyId" },
                      op: "EQUAL",
                      value: { stringValue: companyId }
                    }
                  },
                  {
                    fieldFilter: {
                      field: { fieldPath: "date" },
                      op: "GREATER_THAN_OR_EQUAL",
                      value: { timestampValue: fromDate.toISOString() }
                    }
                  },
                  {
                    fieldFilter: {
                      field: { fieldPath: "date" },
                      op: "LESS_THAN_OR_EQUAL",
                      value: { timestampValue: to.toISOString() }
                    }
                  },
                  {
                    fieldFilter: {
                      field: { fieldPath: "subjectId" },
                      op: "EQUAL",
                      value: { stringValue: subjectId }
                    }
                  },
                  {
                    fieldFilter: {
                      field: { fieldPath: "sensorId" },
                      op: "EQUAL",
                      value: { stringValue: sensorId }
                    }
                  }
                ]
              }
            },
            orderBy: [
              {
                field: { fieldPath: "date" },
                direction: "DESCENDING"
              }
            ],
            limit: 20000
          }
        });

        return measurements.map(measurement => {
          Object.assign(measurement, { subjectId, sensorId });
          return measurement;
        });
      })
    );
    return res.flat();
  };

  const getStoredMeasurement = async (measurementId, provider) => {
    const measurement = await firestore
      .collection("measurements")
      .doc(measurementId)
      .get()
      .then(res => (res.exists ? toData(res) : null));
    if (measurement && measurement.source === provider) {
      measurement.date = toDate(measurement.date);
      return measurement;
    }
    throw new Error("not_found");
  };

  const getLastStoredMeasurement = async ({
    companyId,
    subjectId,
    metricId
  }) => {
    const measurements = await firestore
      .collection("measurements")
      .where("companyId", "==", companyId)
      .where("subjectId", "==", subjectId)
      .where("sensorId", "==", metricId)
      .orderBy("date", "desc")
      .limit(1)
      .get()
      .then(res => res.docs.map(toData));
    const measurement = measurements.pop();
    if (measurement) measurement.date = addSeconds(toDate(measurement.date), 1);
    return measurement;
  };

  const getSubjectsForCompany = (provider, companyId) => {
    return firestore
      .collection("sensorsDash")
      .doc(companyId)
      .collection(provider)
      .get()
      .then(res => res.docs.map(toData));
  };

  const getSubjectForCompany = (provider, companyId, subjectId) => {
    return firestore
      .collection("sensorsDash")
      .doc(companyId)
      .collection(provider)
      .doc(subjectId)
      .get()
      .then(res => (res.exists ? toData(res) : null));
  };

  const updateSubjectForCompany = (provider, companyId, updatedSubject) => {
    const { id: subjectId, value } = updatedSubject;
    return firestore
      .collection("sensorsDash")
      .doc(companyId)
      .collection(provider)
      .doc(subjectId.toString())
      .update(value);
  };

  const addTrackingEvent = async (
    eventType,
    data,
    user,
    companyId,
    companyViewId
  ) => {
    firestore.collection("trackingEvents").add({
      eventType,
      data,
      createdAt: new Date(),
      companyViewId,
      user: {
        id: user.id,
        name: user.name,
        email: user.email,
        slug: user.slug,
        type: user.type,
        companyId
      }
    });
  };

  const createProtocol = async (fields, companyId) => {
    const created = await firestore.collection("protocols").add(fields);
    const ref = getReference("crops", created.id);

    const companyRef = getReference("companies", companyId);
    await companyRef.update({
      protocols: firebase.firestore.FieldValue.arrayUnion(ref)
    });
    return created;
  };

  const getProtocolsByCompany = async companyId => {
    const company = await getReference("companies", companyId).get();

    const protocols = company.data().protocols ?? [];
    const protocolData = await Promise.all(
      protocols.map(async protocol => {
        const protocolRef = getReference("protocols", protocol.id);
        const protocolDoc = await protocolRef.get();
        return toData(protocolDoc);
      })
    );
    // TODO: deleteProtocol does not update company.protocols reference
    return protocolData.filter(protocol => protocol["_deleted"] !== true);
  };

  const getProtocolsByCompanyFromApi = async companyId => {
    //like getProtocolsByCompany but using callRestApiQuery
    const protocols = await callRestApiQuery({
      structuredQuery: {
        from: {
          collectionId: "protocols",
          allDescendants: false
        },
        where: {
          compositeFilter: {
            op: "AND",
            filters: [
              {
                fieldFilter: {
                  field: { fieldPath: "companyId" },
                  op: "EQUAL",
                  value: { stringValue: companyId }
                }
              },
              {
                fieldFilter: {
                  field: { fieldPath: "_deleted" },
                  op: "EQUAL",
                  value: { booleanValue: false }
                }
              }
            ]
          }
        },
        limit: 10000
      }
    });

    const protocolsCrops = await callRestApiPost(
      {
        documents: protocols.reduce((ids, protocol) => {
          for (const crop of protocol.cropIds ?? []) {
            const cropId =
              typeof crop === "string"
                ? crop
                : get(crop, "_key.path.segments", []).join("/");
            if (cropId && !ids.includes(cropId)) ids.push(cropId);
          }
          return ids;
        }, [])
      },
      false
    );

    return protocols.map(protocol => {
      return {
        ...protocol,
        cropIds: (protocol.cropIds ?? []).map(crop => {
          const cropId =
            typeof crop === "string"
              ? crop
              : get(crop, "_key.path.segments", []).join("/");
          const id = cropId.split("/").pop();
          return protocolsCrops.find(crop => crop.id === id);
        })
      };
    });
  };

  const updateProtocol = async (protocolId, fields) => {
    const ref = getReference("protocols", protocolId);
    await ref.update(fields);
  };

  const deleteProtocol = async (protocolId, companyId) => {
    const protocolRef = getReference("protocols", protocolId);
    const companyRef = getReference("companies", companyId);
    await companyRef.update({
      protocols: firebase.firestore.FieldValue.arrayRemove(protocolRef)
    });
    await protocolRef.update({ _deleted: true });
  };

  const getProtocol = async protocolId => {
    const protocol = await getReference("protocols", protocolId).get();
    return protocol.data();
  };

  const getRoles = async companyId => {
    const companyRolesSnap = await firestore
      .collection("roles")
      .where("companyId", "==", companyId)
      .where("forAllCompanies", "==", false)
      .get();
    const globalRolesSnap = await firestore
      .collection("roles")
      .where("companyId", "==", null)
      .where("forAllCompanies", "==", true)
      .get();

    const allRoles = [companyRolesSnap, globalRolesSnap]
      .map(snap => snap.docs.map(toData))
      .flat();
    return allRoles;
  };

  const getRole = async roleId => {
    return await callRestApiGet(["roles", roleId]);
  };

  const upsertRole = async role => {
    const existingRoleId = role.id;
    if (!existingRoleId) {
      const newRole = await firestore.collection("roles").add(role);
      return {
        id: newRole.id,
        ...role
      };
    } else {
      await firestore.collection("roles").doc(existingRoleId).set(role);
      return role;
    }
  };

  const deleteRole = async roleId => {
    return await firestore.collection("roles").doc(roleId).delete();
  };

  const applyProtocolToCrop = async (protocolId, cropId) => {
    const protocolRef = getReference("protocols", protocolId);
    const cropRef = getReference("crops", cropId);

    await protocolRef.update({
      cropIds: firebase.firestore.FieldValue.arrayUnion(cropRef)
    });
    await cropRef.update({
      protocol: protocolRef
    });
  };

  const markProtocolAlertAsSeen = async (protocolId, entryId, userId) => {
    const protocolRef = getReference("protocols", protocolId);
    await protocolRef.update({
      [`alertsSeen.${entryId}`]:
        firebase.firestore.FieldValue.arrayUnion(userId)
    });
  };

  const markProtocolTaskAsDone = async (protocolId, entryId) => {
    const protocolRef = getReference("protocols", protocolId);
    await protocolRef.update({
      [`tasksDone.${entryId}`]: new Date()
    });
  };

  const addSubstrateToCompany = async (companyId, substrate) => {
    const companyRef = getReference("companies", companyId);
    await companyRef.update({
      ["profile.substrates"]:
        firebase.firestore.FieldValue.arrayUnion(substrate)
    });
  };

  const addSubstrateToCrop = async (cropId, substrateId) => {
    const cropRef = getReference("crops", cropId);
    await cropRef.update({
      "profile.substrateId": substrateId
    });
  };

  const addTrayToCompany = async (companyId, trays) => {
    const companyRef = getReference("companies", companyId);
    await companyRef.update({
      ["profile.trays"]: Array.isArray(trays)
        ? firebase.firestore.FieldValue.arrayUnion(...trays)
        : firebase.firestore.FieldValue.arrayUnion(trays)
    });
  };

  const getCompanyTrays = async companyId => {
    const company = await getCompany(companyId);
    return company.profile.trays;
  };

  const addPlugTypeToCompany = async (companyId, plugTypes) => {
    const companyRef = getReference("companies", companyId);
    await companyRef.update({
      ["profile.plugTypes"]: Array.isArray(plugTypes)
        ? firebase.firestore.FieldValue.arrayUnion(...plugTypes)
        : firebase.firestore.FieldValue.arrayUnion(plugTypes)
    });
  };

  const getCompanyPlugTypes = async companyId => {
    const company = await getCompany(companyId);
    return company.profile.plugTypes;
  };

  const addCropLabelToCompany = async (companyId, cropLabels) => {
    const companyRef = getReference("companies", companyId);
    await companyRef.update({
      ["profile.cropLabels"]: Array.isArray(cropLabels)
        ? firebase.firestore.FieldValue.arrayUnion(...cropLabels)
        : firebase.firestore.FieldValue.arrayUnion(cropLabels)
    });
  };

  const getCropsByIds = async cropIds => {
    const crops = await callRestApiPost({
      documents: cropIds.map(cropId => `${DB_PATH}/crops/${cropId}`)
    });

    await hydrateCrops(crops);

    return crops;
  };

  return {
    pickSnapshot,
    pickReference,
    createCompany,
    createInviteToken,
    getCropPhotos,
    getCropLabData,
    getCropLabDataNotes,
    getCropNotes,
    getInviteToken,
    getLabDataNotes,
    getUserWithCompany,
    getNonAdvisorUsers,
    getThirdPartyUsers,
    getCompany,
    getCompanyCrops,
    getCompanyDocs,
    upsertCompanyDoc,
    deleteCompanyDoc,
    getAdvices,
    getAdvicesForCrop,
    upsertAdvice,
    deleteAdvice,
    getGeneralNotes,
    getGeneralNotesForCrop,
    upsertGeneralNote,
    deleteGeneralNote,
    getCompanyPendingLabAnalysis,
    applyPendingLabAnalysis,
    getSpeciesData,
    getClassification,
    getClassifications,
    getCropsWithNotes,
    getAllCropsWithNotes,
    findClassification,
    createCrop,
    getCrop,
    saveCrop,
    deleteCrop,
    getReference,
    getUserRef,
    getFirstCropPhoto,
    getCropPhoto,
    updateCropPhoto,
    getAlerts,
    getCompanies,
    updateCompany,
    updateUser,
    addCompanyToThirdPartyUser,
    getAdvisorUsers,
    getUsersByCompany,
    createUser,
    getCompanyThirdParties,
    getThirdPartyInvitation,
    createThirdPartyInvitation,
    updateThirdPartyInvitation,
    demoteThirdPartyUser,
    getCropsImportSettings,
    createCropsImportSettings,
    updateCropsImportSettings,
    upsertClimateDataSettings,
    getClimateDataEntries,
    createClimateDataEntry,
    deleteClimateDataEntry,
    upsertFertilizationBinsSettings,
    getFertilizationBinEntries,
    createFertilizationBinEntry,
    deleteFertilizationBinEntry,
    getCropNote,
    updateCropNote,
    setCropFavorite,
    createReminder,
    getTask,
    updateTask,
    updateAdvisor,
    getSensorConfig,
    getStoredMeasurements,
    getStoredMeasurement,
    getLastStoredMeasurement,
    getSubjectsForCompany,
    getSubjectForCompany,
    updateSubjectForCompany,
    addTrackingEvent,
    createProtocol,
    updateProtocol,
    deleteProtocol,
    getProtocolsByCompany,
    getProtocolsByCompanyFromApi,
    getProtocol,
    applyProtocolToCrop,
    markProtocolAlertAsSeen,
    markProtocolTaskAsDone,
    getRoles,
    getRole,
    upsertRole,
    deleteRole,
    addSubstrateToCompany,
    addSubstrateToCrop,
    updateYoungPlantSuppliers,
    addTrayToCompany,
    getCompanyTrays,
    addPlugTypeToCompany,
    getCompanyPlugTypes,
    getNotesByBatchId,
    getCropsByIds,
    updateUserAgreementVersion,
    addCropLabelToCompany,
    getAllNotes,
    getDocumentsForCrop,
    getDocumentsForCrops,
    getAdvicesForCrops,
    getCropsPhotos,
    ...dbActions($fire)
  };
};

export default (ctx, inject) => {
  const {
    store,
    $fire,
    $fireAuthStore,
    app: { i18n: $i18n }
  } = ctx;
  const api = initModule($fire, store, $fireAuthStore, $i18n);
  inject("db", api);
};
