<template>
  <div :class="`lds-ring ${outerSize}`">
    <div :class="innerSize"></div>
    <div :class="innerSize"></div>
    <div :class="innerSize"></div>
    <div :class="innerSize"></div>
  </div>
</template>

<script>
export default {
  props: {
    outerSize: {
      type: String,
      default: "outer-size"
    },
    innerSize: {
      type: String,
      default: "inner-size"
    }
  }
};
</script>

<style scoped>
.lds-ring {
  display: inline-block;
  opacity: 0.8;
  position: relative;
}
.outer-size {
  width: 40px;
  height: 40px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  margin: 2px;
  border: 2px solid #44a12b;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #44a12b transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
.inner-size {
  width: 32px;
  height: 32px;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
