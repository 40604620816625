import { Task } from "@/models/task";
import { BaseAction } from "./base-action";
import { v4 as uuidv4 } from "uuid";
import uniqBy from "lodash.uniqby";
import {
  addDays,
  startOfToday,
  endOfToday,
  setHours,
  addWeeks
} from "date-fns";

export class TasksAction extends BaseAction {
  toModel(data) {
    data.date = data?.date?.toDate ? data.date.toDate() : null;
    data.completedAt = data?.completedAt?.toDate() ?? null;
    return new Task(data);
  }
  /**
   * @returns {Task[]}
   */
  async getByUser(companyId, userId) {
    const getSnap1 = this.collection
      .where("companyId", "==", companyId)
      .where("user", "==", userId)
      .where("_deleted", "==", false)
      .get();
    const getSnap3 = this.collection
      .where("companyId", "==", companyId)
      .where("users", "array-contains", userId)
      .where("_deleted", "==", false)
      .get();

    const [snap1, snap3] = await Promise.all([getSnap1, getSnap3]);

    const allTasks = snap1.docs.concat(snap3.docs).map(doc => this.toData(doc));
    return [...uniqBy(allTasks, "id")];
  }

  async getByCompanyProtocols(companyId) {
    const companyProtocolsData = await this.firestore
      .collection("protocols")
      .where("companyId", "==", companyId)
      .get();
    const companyProtocols = companyProtocolsData.docs
      .map(doc => ({ ...doc.data(), id: doc.id }))
      .filter(p => p["_deleted"] !== true);
    const protocolCropsData = await Promise.all(
      companyProtocols.reduce((acc, protocol) => {
        if (protocol.cropIds)
          acc.push(
            ...protocol.cropIds.filter(c => c.get).map(crop => crop.get())
          );
        return acc;
      }, [])
    );

    const protocolCrops = protocolCropsData
      .filter(c => c)
      .map(crop => ({
        ...crop.data(),
        id: crop.id
      }));

    const companyProtocolTasks = companyProtocols.reduce((tasks, protocol) => {
      const protocolTasks = [];
      if (protocol.actions) {
        for (const action of protocol.actions) {
          if (protocol.cropIds) {
            for (const cropRef of protocol.cropIds) {
              const crop = protocolCrops.find(crop => crop.id === cropRef.id);
              if (!crop) continue; // maybe try to do something even if crop is missing?
              if (action.recurring) {
                for (let i = 1; i <= action.repeats; i++) {
                  const week = action.week + action.repeatWeekOffset * i;
                  const task = createProtocolTask({
                    companyId,
                    action: { ...action, week },
                    crop,
                    protocol
                  });
                  if (task) protocolTasks.push(task);
                }
              }

              const task = createProtocolTask({
                companyId,
                action,
                crop,
                protocol
              });
              if (task) protocolTasks.push(task);
            }
          }
        }
      }
      return [...tasks, ...protocolTasks];
    }, []);

    return companyProtocolTasks;
  }

  /**
   * @returns {Task[]}
   */
  async getByCompany(companyId) {
    const snap = await this.collection
      .where("companyId", "==", companyId)
      .where("_deleted", "==", false)
      .get();

    return snap.docs.map(doc => this.toData(doc));
  }

  async create(data) {
    if (data.date) {
      // force time to noon
      data.date = setHours(data.date, 12);
    }
    data._deleted = false;
    const task = new Task(data);
    if (task.isRecurringTask) {
      const batch = this.firestore.batch();
      const serialId = uuidv4();
      for (let index = 0; index < task.repeats; index++) {
        const date = addDays(data.date, index * data.repeatWeekOffset * 7);
        const docRef = this.collection.doc();
        batch.set(docRef, {
          ...data,
          date,
          serialId
        });
      }
      return await batch.commit();
    }
    return await this.collection.add(data);
  }

  async update(id, data) {
    const oldTask = await this.get(id);
    const task = new Task(data);
    let serialId;

    if (task.isRecurringTask) {
      serialId = uuidv4();
      const batch = this.firestore.batch();
      for (let index = 0; index < task.repeats; index++) {
        const date = addDays(data.date, index * data.repeatWeekOffset * 7);
        const docRef = this.collection.doc();
        batch.set(docRef, {
          ...data,
          date,
          serialId,
          _deleted: false
        });
      }
      await batch.commit();
    }

    await this.collection.doc(id).update(data);

    // remove old versions
    if (oldTask.serialId) {
      const snap = await this.collection
        .where("serialId", "==", oldTask.serialId)
        .get();
      await Promise.all(
        snap.docs.map(async v => {
          await this.delete(v);
        })
      );
    }
    return serialId;
  }

  async complete(task) {
    await this.collection.doc(task.id).update({
      completedAt: new Date()
    });
  }

  async updateDoneCrops(task, doneCrops) {
    await this.collection.doc(task.id).update({
      doneCrops
    });
  }

  async delete(task) {
    await this.collection.doc(task.id).update({
      _deleted: true
    });
  }
}

export const createProtocolTask = ({ crop, action, protocol, companyId }) => {
  const id = `${action.id}${crop.id}${action.week ?? 1}`;
  if (protocol.removedTasks?.[id] || action.enabled === false) return null;

  const pottingDate = crop.potWeek.date.toDate
    ? crop.potWeek.date.toDate()
    : crop.potWeek.date;

  const taskData = {
    id,
    crops: [crop.id],
    type: action.typeId,
    checklist: null,
    description: action.description,
    date: addWeeks(pottingDate, action.week - 1 || 0),
    repeats: null,
    repeatWeekOffset: null,
    users: protocol?.taskUsers?.[id] ?? protocol?.actionUserIds ?? [],
    protocolId: protocol.id,
    companyId,
    created: pottingDate,
    isActive: true,
    createdBy: null
  };

  const completedAt = protocol.tasksDone?.[id]?.toDate() ?? null;
  return new Task({ ...taskData, completedAt });
};
