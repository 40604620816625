const middleware = {}

middleware['agreement'] = require('../src/middleware/agreement.js')
middleware['agreement'] = middleware['agreement'].default || middleware['agreement']

middleware['authenticated'] = require('../src/middleware/authenticated.js')
middleware['authenticated'] = middleware['authenticated'].default || middleware['authenticated']

middleware['authorized'] = require('../src/middleware/authorized.js')
middleware['authorized'] = middleware['authorized'].default || middleware['authorized']

middleware['loggedIn'] = require('../src/middleware/loggedIn.js')
middleware['loggedIn'] = middleware['loggedIn'].default || middleware['loggedIn']

middleware['redirect-v3'] = require('../src/middleware/redirect-v3.js')
middleware['redirect-v3'] = middleware['redirect-v3'].default || middleware['redirect-v3']

middleware['seo'] = require('../src/middleware/seo.js')
middleware['seo'] = middleware['seo'].default || middleware['seo']

export default middleware
