import Vue from "vue";
import { getISOWeek, parseISO, subMonths, getYear, parse } from "date-fns";
import { find, sortBy, pick, merge, mergeWith, debounce } from "lodash";
import { initModule as initDb } from "@/plugins/db";
import { compileRules } from "@/plugins/casl";
import { getActiveLocation } from "@/utils/cropFunctions";
import { createProtocolAlert } from "@/plugins/db_actions/alerts";

const identifyWithMessageBird = debounce(function ({ cid, uname, cname }) {
  try {
    window.MessageBirdChatWidget?.identify?.(cid, {
      displayName: `${uname} (${cname})`,
      firstName: `${uname} (${cname})`
    });
  } catch (e) {
    console.error(e);
  }
}, 5000);

export const state = () => ({
  user: {
    company: {},
    sensorSettings: {
      receiveAlerts: []
    },
    agreement: {
      version: null
    },
    config: {
      manageCompanies: [],
      managedCompanies: [],
      allowedLocations: []
    },
    ready: false
  },
  locationsMap: {},
  measurementData: null,
  metricData: null,
  cropFormData: {
    genus: null,
    species: null,
    cultivar: null,
    potDate: null,
    potSize: null,
    startingNumberOfPots: null,
    currentNumberOfPots: null,
    batchNumber: null,
    cultivationTime: null,
    potWeek: null
  },
  cropFormStep: 1,
  viewCompany: null,
  isViewCompanyHydrated: false,
  selectedCompany: null,
  cultTableSettings: {
    sortBy: "alerts",
    sortDirection: "desc",
    genus: null,
    cultivar: null,
    potDate: [null, null],
    species: null,
    isTrialCrop: false,
    showProgressBar: true,
    showColumns: {
      icon: true,
      alerts: true,
      genus: true,
      cultivar: true,
      species: true,
      labDataId: true,
      potSize: true,
      weekNr: true,
      progress: true
    },
    advancedFilters: {
      criteria: [],
      active: false,
      matches: {}
    }
  },
  sensorSettingsByCompany: {},
  labChartSelection: [],
  labChartRatio1: "ca",
  labChartRatio2: "mg",
  cropsImportSettings: null,
  pendingLabAnalysis: null,
  alerts: [],
  rules: null, // casl
  unauth: [], // used to determine when user is not auth in SSR
  agreement: {
    version: "01"
  },
  photoComparison: []
});

const defaultSensorSettings = () => {
  return {
    selectedMetrics: [],
    delta: [subMonths(new Date(), 1), new Date()],
    deltaUnit: "month",
    yAxisSettings: {}
  };
};

export const defaultCompany = () => {
  return {
    profile: {
      contactPerson: "",
      location: {},
      environment: {
        setupMethods: []
      },
      companyLabels: {},
      settings: {
        weatherStats: [],
        cropForm: {
          cultivationActivity: [],
          photoLabels: []
        },
        cropLabels: {
          fields: [],
          size: "lg"
        },
        cropFields: [],
        pdfFields: [],
        climateData: {
          set: [],
          achieved: [],
        },
        fertilizationBins: [],
      },
      locations: [],
      youngPlantSuppliers: [],
      labCustNumber: [],
      pdfWeatherOff: false,
      pdfWeekOff: false,
      logo: null,
      noteChecklists: [],
      tankMixes: [],
      sensorSettings: {
        provider: "blockbax"
      },
      substrates: [],
      trays: [],
      plugTypes: [],
      cropLabels: []
    }
  };
};

const defaultState = state;

const initPlugin = store => {
  // we might have a partially hydrated store from the server
  // which we have to augment with firebase calls which can only
  // be initiated client side.
  // as there is no real "rehydration" event we'll listen for the SET_USER
  // mutation as that's always called for authenticated requests (once).
  // we're triggering a fake mutation called "STORAGE_READY"
  // @see vuex-persist.js for details
  store.subscribe(async (mutation, state) => {
    if (mutation.type === "STORAGE_READY") {
      store.commit("SET_STORAGE_READY", mutation.payload);

      if (state.user.id) {
        await store.dispatch("enrichUserData", {
          uid: state.user.id,
          viewCompany: state.viewCompany,
          init: true
        });

        if (!store.getters.isInOwnCompany) {
          await store.dispatch("hydrateViewCompany", state.viewCompany);
        } else {
          // normal user
          store.dispatch("bailHydrateViewCompany");
        }

        store.commit("SET_USER_EXTRA_DATA", { ready: true });
      } else {
        store.commit("SET_UNAUTH", "STORAGE_READY");
      }
    }
  });
};

export const plugins = [initPlugin];

export const actions = {
  nuxtServerInit({ dispatch, commit }, { res }) {
    if (res?.locals?.user) {
      const { allClaims: claims, ...authUser } = res.locals.user;
      dispatch("onAuthStateChangedAction", {
        authUser,
        claims
      });
    } else {
      commit("SET_UNAUTH", "nuxtServerInit");
    }
  },

  async onAuthStateChangedAction(
    { state: { viewCompany, __restored }, commit, dispatch, getters },
    { authUser, claims }
  ) {
    console.debug("onAuthStateChangedAction", authUser);
    if (!authUser) {
      // Perform logout operations
      commit("SET_RULES", {
        rules: null,
        ab: this.$ab
      });
      commit("RESET");

      if (process.client) {
        window.MessageBirdChatWidget?.logout?.();
      }
    } else {
      let { uid, email, idToken } = authUser;
      if (!idToken) idToken = (await authUser.getIdToken?.()) || null;

      commit("SET_USER", {
        uid,
        email,
        id: uid,
        type: claims.type,
        companyId: claims.companyId,
        idToken,
        ready: false
      });

      if (__restored && uid) {
        await dispatch("enrichUserData", {
          uid,
          viewCompany,
          init: true
        });

        if (!getters.isInOwnCompany) {
          await dispatch("hydrateViewCompany", viewCompany);
        } else {
          // normal user
          dispatch("bailHydrateViewCompany");
        }

        commit("SET_USER_EXTRA_DATA", { ready: true });
      }
    }
  },

  async enrichUserData({ state, commit, getters }, { uid, viewCompany, init }) {
    // only works client side because it queries firestore
    const db = initDb(this.$fire, { state });
    const userData = await db.getUserWithCompany(uid, viewCompany);

    const extraData = {
      ...pick(userData, [
        "name",
        "slug",
        "type",
        "config",
        "role",
        "allowedLocations"
      ]),
      ...pick(userData.profile || {}, [
        "preferredLanguage",
        "taskAlertPreference",
        "phone",
        "sensorSettings",
        "notificationSettings"
      ]),
      agreement: userData.agreement || { version: null },
      companyId: userData.companyId || null,
      company: userData.company
        ? {
            ...pick(userData.company, [
              "id",
              "name",
              "originalUserId",
              "slug",
              "reportId"
            ]),
            profile: pick(userData.company.profile, [
              "companyName",
              "labCustNumber",
              "contactPerson",
              "preferredLanguage",
              "environment",
              "fertilizationMethod",
              "waterSource",
              "waterSample",
              "location",
              "locations",
              "sensorSettings",
              "settings",
              "youngPlantSuppliers",
              "pdfWeatherOff",
              "pdfWeekOff",
              "logo",
              "noteChecklists",
              "tankMixes",
              "companyLabels",
              "substrates",
              "trays",
              "plugTypes",
              "cropLabels",
              "cropFields",
              "pdfFields"
            ])
          }
        : null
    };
    if (!extraData.sensorSettings)
      extraData.sensorSettings = { receiveAlerts: [] };

    if (!extraData.notificationSettings)
      extraData.notificationSettings = {
        sensorAlerts: true,
        taskAlerts: true,
        dailySummary: true,
        endOfCultivationAlerts: true
      };

    if (!extraData.taskAlertPreference) extraData.taskAlertPreference = "email";

    commit("SET_USER_EXTRA_DATA", extraData);
    commit("SET_DEFAULT_CROP_FIELDS");
    commit("SET_DEFAULT_PDF_FIELDS");
    // get preferred language from user settings
    // compare to current locale, (re)set if necessary
    const preferred = extraData.preferredLanguage?.code;
    const current = this.$i18n.locale;
    if (preferred && current !== preferred) {
      this.$i18n.setLocale(preferred); // TODO: find a way to not reload page as a result of this action
    }

    if (init) {
      commit("SET_RULES", {
        rules: compileRules({
          uid,
          type: getters.currentUserType,
          role: userData.role
        }),
        ab: this.$ab
      });
    }
  },

  async setViewCompanyId({ state, commit, dispatch, getters }, viewCompany) {
    commit("resetCultTableSettings");
    commit("SET_VIEW_COMPANY_ID", viewCompany);

    commit("SET_USER_EXTRA_DATA", { ready: false });

    await dispatch("enrichUserData", {
      uid: state.user.id,
      viewCompany,
      init: true
    });

    if (!getters.isInOwnCompany) {
      await dispatch("hydrateViewCompany", viewCompany);
    } else {
      dispatch("bailHydrateViewCompany");
    }

    commit("SET_USER_EXTRA_DATA", { ready: true });
  },

  async hydrateViewCompany({ state, commit, dispatch }, companyId) {
    const db = initDb(this.$fire, { state });
    const company = await db.getCompany(companyId);

    const selectedCompany = mergeWith(defaultCompany(), {
      ...pick(company, [
        "id",
        "name",
        "originalUserId",
        "slug",
        "reportId",
        "profile"
      ])
    }, (val, srcval) => srcval === null ? val : undefined);

    commit("SET_SELECTED_COMPANY", selectedCompany);
    commit("SET_DEFAULT_CROP_FIELDS");
    commit("SET_DEFAULT_PDF_FIELDS");
    commit("SET_VIEW_COMPANY_HYDRATED", true);
    dispatch("loadExtras", companyId);

    if (process.client) {
      const cid = `${state.selectedCompany.id}--${state.user.uid}`;
      identifyWithMessageBird({
        cid,
        uname: state.user.name,
        cname: state.selectedCompany.name
      });
    }
  },

  async loadExtras({ state, commit, getters }, companyId) {
    const db = initDb(this.$fire, { state });
    try {
      const companyPendingLabAnalysis = await db.getCompanyPendingLabAnalysis(
        companyId
      );
      commit("SET_PENDING_LAB_ANALYSIS", companyPendingLabAnalysis);
    } catch (error) {
      console.error(error);
    }

    const companyPendingLabAnalysis = await db.getCompanyPendingLabAnalysis(
      companyId
    );
    const alertTypes = [
      "lab-data",
      "lab-data-comment",
      "new-task-due",
      "lab-data-pending",
      "new-document",
      "protocol",
      "new-advice",
      "new-sensor-trigger",
      "new-note-mention"
    ];
    const companyAlerts = await db.getAlerts(companyId, getters.currentUserId, {
      alertTypes,
      hydrateUser: true
    });

    const companyProtocols = await db.getProtocolsByCompanyFromApi(companyId);

    const t = key => this.$i18n.t(key);
    const companyProtocolAlerts = companyProtocols.reduce(
      (alerts, protocol) => {
        const protocolAlerts = [];
        if (protocol.alerts)
          for (const alert of protocol.alerts) {
            for (let week = alert.fromWeek; week <= alert.untilWeek; week++) {
              if (protocol.cropIds)
                for (const crop of protocol.cropIds) {
                  const enrichedAlert = createProtocolAlert({
                    crop,
                    alert,
                    userId: getters.currentUserId,
                    companyId,
                    t: t.bind(this),
                    protocol,
                    week
                  });

                  protocolAlerts.push(enrichedAlert);
                }
            }
          }
        return [...alerts, ...protocolAlerts];
      },
      []
    );

    commit("SET_PENDING_LAB_ANALYSIS", companyPendingLabAnalysis);
    commit("SET_ALERTS", [...companyAlerts, ...companyProtocolAlerts]);
  },

  bailHydrateViewCompany({ state, commit, dispatch }) {
    commit("SET_VIEW_COMPANY_HYDRATED", null);
    commit("SET_SELECTED_COMPANY", null);
    dispatch("loadExtras", state.user.companyId);

    if (process.client) {
      const cid = `${state.user.companyId}--${state.user.uid}`;
      identifyWithMessageBird({
        cid,
        uname: state.user.name,
        cname: state.user.company.name
      });
    }
  },

  async refreshCompany({ state, commit, dispatch, getters }) {
    commit("SET_USER_EXTRA_DATA", { ready: false });

    await dispatch("enrichUserData", {
      uid: state.user.uid,
      viewCompany: state.viewCompany,
      init: true
    });
    if (!getters.isInOwnCompany) {
      await dispatch("hydrateViewCompany", state.viewCompany);
    } else {
      dispatch("bailHydrateViewCompany");
    }

    commit("SET_USER_EXTRA_DATA", { ready: true });
  },

  async setSensorConfig({ state, getters, commit }, sensorSettings) {
    const db = initDb(this.$fire, { state });
    const companyId = getters.companyViewId;
    console.debug("calling updateCompany", companyId, sensorSettings);
    await db.updateCompany(companyId, {
      "profile.sensorSettings": sensorSettings
    });
    // reset company sensor settings data locally
    // for both cases: advisor/normal user
    commit("SET_SENSOR_SETTINGS", sensorSettings);
  },

  async setUserSensorSettings({ state, getters, commit }, sensorSettings) {
    const db = initDb(this.$fire, { state });
    const userId = getters.currentUserId;
    await db.updateUser(userId, {
      "profile.sensorSettings": sensorSettings
    });
    commit("SET_USER_SENSOR_SETTINGS", sensorSettings);
  },

  async setCropsImportSettings({ state, commit }, { id, cropsImportSettings }) {
    const db = initDb(this.$fire, { state });
    await db.updateCropsImportSettings(id, cropsImportSettings);
    commit("SET_CROPS_IMPORT_SETTINGS", { ...cropsImportSettings, id });
  },

  async setClimateDataSettings(
    { state, commit, getters },
    { climateDataSettings }
  ) {
    const db = initDb(this.$fire, { state });
    const companyId = getters.companyViewId;
    await db.upsertClimateDataSettings(companyId, climateDataSettings);
    commit("SET_CLIMATE_DATA_SETTINGS", { ...climateDataSettings });
  },

  async setFertilizationBinsSettings(
    { state, commit, getters },
    { fertilizationBinsSettings }
  ) {
    const db = initDb(this.$fire, { state });
    const companyId = getters.companyViewId;
    await db.upsertFertilizationBinsSettings(
      companyId,
      fertilizationBinsSettings
    );
    commit("SET_FERTILIZATION_BINS_SETTINGS", [...fertilizationBinsSettings]);
  },

  async addSubstrate({ state, commit, getters }, substrate) {
    const db = initDb(this.$fire, { state });
    const companyId = getters.companyViewId;
    await db.addSubstrateToCompany(companyId, substrate);
    commit("ADD_SUBSTRATE", substrate);
  },

  async setPhotosComparison({ commit }, photos = []) {
    commit("SET_PHOTOS_COMPARISON", photos);
  },

  logout({ commit, state, getters }) {
    if (state.user.id) {
      this.$fire.auth.signOut();
    }
  }
};

export const mutations = {
  STORAGE_READY: () => {},
  SET_RULES: (state, { rules, ab }) => {
    state.rules = rules;
    ab.update(rules || []);
  },

  SET_LAB_CHART_SELECTION(state, selection) {
    state.labChartSelection = selection;
  },
  SET_LAB_CHART_RATIO_1(state, selection) {
    state.labChartRatio1 = selection;
  },
  SET_LAB_CHART_RATIO_2(state, selection) {
    state.labChartRatio2 = selection;
  },
  RESET(state) {
    const defState = defaultState();
    const { showColumns } = state.cultTableSettings; // keep selected columns
    const finalState = merge(defState, {
      cultTableSettings: {
        ...defState.cultTableSettings,
        showColumns
      }
    });
    Object.assign(state, finalState);
  },
  SET_USER(state, user) {
    state.user = user;
  },
  SET_USER_EXTRA_DATA(state, userData) {
    const user = state.user;
    state.user = {
      ...user,
      ...userData
    };
  },
  SET_USER_NAME(state, name) {
    state.user.name = name;
  },
  SET_USER_PHONE(state, phone) {
    state.user.phone = phone;
  },
  SET_USER_PREFERRED_ALERT(state, alertPrefrence) {
    state.user.taskAlertPreference = alertPrefrence;
  },
  SET_USER_NOTIFICATION_SETTINGS(state, notificationSettings) {
    state.user.notificationSettings = notificationSettings;
  },
  SET_USER_SENSOR_SETTINGS(state, sensorSettings) {
    state.user.sensorSettings = sensorSettings;
  },
  SET_CROPS_IMPORT_SETTINGS(state, cropsImportSettings) {
    state.cropsImportSettings = cropsImportSettings;
  },
  SET_CLIMATE_DATA_SETTINGS(state, climateDataSettings) {
    if (state.selectedCompany) {
      state.selectedCompany.profile.settings.climateData = climateDataSettings;
    } else if (state.user.company) {
      state.user.company.profile.settings.climateData = climateDataSettings;
    }
  },
  SET_FERTILIZATION_BINS_SETTINGS(state, fertilizationBinsSettings) {
    if (state.selectedCompany) {
      state.selectedCompany.profile.settings.fertilizationBins =
        fertilizationBinsSettings;
    } else if (state.user.company) {
      state.user.company.profile.settings.fertilizationBins =
        fertilizationBinsSettings;
    }
  },
  SET_DEFAULT_CROP_FIELDS(state) {
    const defaultFields = [
      "genus",
      "species",
      "cultivar",
      "container",
      "referenceGenus",
      "addSubstrateInfo",
      "phase",
      "startingNumberOfPots",
      "currentNumberOfPots",
      "batchNumber",
      "youngPlantSuppliers",
      "businessLocation",
      "locationLevel1",
      "locationLevel2",
      "substrateMixture",
      "labId",
      "weekNumber",
      "potDate",
      "endDate",
      "expectedCultivationTime"
    ];

    if (
      state.selectedCompany &&
      state.selectedCompany.profile &&
      state.selectedCompany.profile.settings &&
      !state.selectedCompany.profile.settings.cropFields
    ) {
      state.selectedCompany.profile.settings.cropFields = defaultFields;
    } else if (
      state.user.company &&
      state.user.company.profile &&
      state.user.company.profile.settings &&
      !state.user.company.profile.settings.cropFields
    ) {
      state.user.company.profile.settings.cropFields = defaultFields;
    }
  },
  SET_DEFAULT_PDF_FIELDS(state) {
    const defaultFields = [
      "companyName",
      "businessLocation",
      "locationLevel1",
      "locationLevel2",
      "cultivationName",
      "container",
      "startingNumberOfPots",
      "currentNumberOfPots",
      "labId",
      "potDate"
    ];

    if (
      state.selectedCompany &&
      state.selectedCompany.profile &&
      state.selectedCompany.profile.settings &&
      !state.selectedCompany.profile.settings.pdfFields
    ) {
      state.selectedCompany.profile.settings.pdfFields = defaultFields;
    } else if (
      state.user.company &&
      state.user.company.profile &&
      state.user.company.profile.settings &&
      !state.user.company.profile.settings.pdfFields
    ) {
      state.user.company.profile.settings.pdfFields = defaultFields;
    }
  },
  SET_SELECTED_COMPANY(state, company) {
    state.selectedCompany = company;
  },
  SET_LOCATION_MAP(state, map) {
    state.locationsMap = map;
  },
  SET_VIEW_COMPANY_ID(state, companyId) {
    state.viewCompany = companyId;
  },
  setAddCropFormProperty(state, data) {
    state.cropFormData[data.property] = data.value;
  },
  setCropFormData(state, data) {
    state.cropFormData = data;
  },
  setAddCropFormStep(state, data) {
    state.cropFormStep = data;
  },
  resetAddCropFormData(state) {
    Object.keys(state.cropFormData).forEach(key => {
      state.cropFormData[key] = null;
    });
  },
  setCultTableSettings(state, data) {
    state.cultTableSettings = { ...state.cultTableSettings, ...data };
  },
  SET_SENSOR_SETTINGS(state, data) {
    if (state.selectedCompany) {
      state.selectedCompany.profile.sensorSettings = data;
    } else {
      state.user.company.profile.sensorSettings = data;
    }
  },
  SET_VIEW_COMPANY_HYDRATED(state, isHydrated) {
    state.isViewCompanyHydrated = isHydrated;
  },
  SET_PENDING_LAB_ANALYSIS(state, pendingLabAnalysis) {
    state.pendingLabAnalysis = pendingLabAnalysis;
  },
  SET_ALERTS(state, alerts) {
    state.alerts = alerts;
  },
  MARK_ALERT_AS_SEEN(state, { alertId, userId }) {
    state.alerts.find(alert => alert.id === alertId).seenBy.push(userId);
  },
  SET_LOCAL_SENSOR_SETTINGS(state, data) {
    // save by company
    const companyId = state.viewCompany || state.user.companyId;

    if (!state.sensorSettingsByCompany[companyId]) {
      Vue.set(state.sensorSettingsByCompany, companyId, {
        ...defaultSensorSettings(),
        ...data
      });
    } else {
      Object.assign(state.sensorSettingsByCompany[companyId], data);
    }
  },

  resetCultTableSettings(state) {
    const defState = defaultState();
    const { showColumns } = state.cultTableSettings; // keep selected columns
    state.cultTableSettings = {
      ...defState.cultTableSettings,
      showColumns
    };
  },
  ADD_SUBSTRATE(state, substrate) {
    if (state.selectedCompany) {
      state.selectedCompany.profile.substrates.push(substrate);
    } else if (state.user.company) {
      state.user.company.profile.substrates.push(substrate);
    }
  },
  ADD_CROP_LABEL(state, cropLabel) {
    if (state.selectedCompany) {
      state.selectedCompany.profile.cropLabels.push(cropLabel);
    } else if (state.user.company) {
      state.user.company.profile.cropLabels.push(cropLabel);
    }
  },
  SET_UNAUTH(state, val) {
    state.unauth.push(val);
  },
  SET_STORAGE_READY(state, val) {
    state.__restored = val;
  },
  SET_PHOTOS_COMPARISON(state, val) {
    state.photoComparison = val;
  }
};

export const getters = {
  photoOwnerId: state => {
    // legacy: photos used to be stored with the userId in the url,
    // for newer uploaded photos we use the companies id
    return (
      state.selectedCompany?.originalUserId ||
      state.selectedCompany?.id ||
      state.user.company?.originalUserId ||
      state.user.companyId
    );
  },
  currentUserId: state => {
    return state.user.uid;
  },
  currentUser: state => {
    return merge(defaultState().user, state.user);
  },
  currentUserType: (state, getters) => {
    const userType = getters.currentUser.type;
    if (userType !== "third-party") return userType;
    return !getters.isInOwnCompany ? userType : "admin/third-party";
  },
  isInOwnCompany: (state, getters) => {
    const { viewCompany } = state;
    const { companyId: ownCompanyId } = getters.currentUser;
    return !viewCompany || viewCompany === ownCompanyId;
  },
  companyViewId: state => {
    const { user, viewCompany } = state;
    if (["advisor", "third-party"].includes(user.type) && viewCompany) {
      return viewCompany;
    }
    return user.companyId;
  },
  currentCompany: state => {
    const { user, selectedCompany } = state;
    if (["advisor", "third-party"].includes(user.type) && selectedCompany) {
      return selectedCompany;
    }
    return mergeWith(defaultCompany(), user.company, (val, srcval) => srcval === null ? val : undefined);
  },
  isViewCompanyHydrated(state) {
    return state.isViewCompanyHydrated;
  },

  getAllWeatherData: (state, getters) => crop => {
    if (!crop) return null;
    let current = getActiveLocation(crop);
    if (!current) return null;

    const businessLocationId =
      current?.values[0]?.parents?.businessLocation?.id ||
      current?.values[0]?.id;
    if (!businessLocationId) return null;

    // match the location
    const loc = getters.currentCompany.profile?.locations?.find(
      loc => loc.id === businessLocationId
    );
    if (!loc) return null;
    return loc.weatherData;
  },

  getWeatherData: (state, getters) => (crop, date) => {
    const weather = getters.getAllWeatherData(crop);
    if (!weather) return null;
    const key = `${getYear(date)}-${getISOWeek(date)
      .toString()
      .padStart(2, "0")}`;
    return weather[key];
  },

  sensorSettings: state => {
    const companyId = state.viewCompany || state.user.companyId;
    const settings = state.sensorSettingsByCompany[companyId];
    if (settings) {
      if (typeof settings.delta[0] === "string") {
        settings.delta[0] = parseISO(settings.delta[0]);
      }
      if (typeof settings.delta[1] === "string") {
        settings.delta[1] = parseISO(settings.delta[1]);
      }
      return settings;
    }
    return defaultSensorSettings();
  },

  youngPlantSuppliers: (state, getters) => {
    const currentCompany = getters.currentCompany;
    if (!currentCompany) return [];
    return currentCompany.profile?.youngPlantSuppliers || [];
  },

  // TODO: try to filter by getters.allowedLocations
  locationTree: (state, getters) => {
    const currentCompany = getters.currentCompany;
    if (!currentCompany) return [];
    const locations = currentCompany.profile?.locations || [];
    const tree = locations.map(loc => ({
      id: loc.id,
      label: loc.name,
      ...(loc.locationsLevel1?.length
        ? {
            children: (loc.locationsLevel1 || []).map(loc1 => ({
              id: loc1.id,
              label: loc1.name,
              fullLabel: [loc.name, loc1.name].join(" > "),
              ...(loc1.locationsLevel2?.length
                ? {
                    children: (loc1.locationsLevel2 || []).map(loc2 => ({
                      id: loc2.id,
                      label: loc2.name,
                      fullLabel: [loc1.name, loc2.name].join(" > ")
                    }))
                  }
                : {})
            }))
          }
        : {})
    }));
    console.debug("tree", tree);
    return tree;
  },

  getCountries: (state, getters) => {
    const currentCompany = getters.currentCompany;
    if (!currentCompany) return [];
    return currentCompany.profile?.companyLabels?.countries || [];
  },

  // TODO: try to filter by getters.allowedLocations
  getLocationMap: (state, getters) => {
    // flat map of all locations, indexed by id
    const idMap = {};
    const currentCompany = getters.currentCompany;
    if (!currentCompany) return {};
    currentCompany.profile?.locations?.forEach(loc => {
      // business level
      idMap[loc.id] = {
        ...loc,
        level: 0
      };
      (loc.locationsLevel1 || []).forEach(loc1 => {
        // level 1
        idMap[loc1.id] = {
          ...loc1,
          parentId: loc.id,
          level: 1
        };
        (loc1.locationsLevel2 || []).forEach(loc2 => {
          // level 2
          idMap[loc2.id] = {
            ...loc2,
            parentId: loc1.id,
            level: 2
          };
        });
      });
    });
    return idMap;
  },

  measurements: state => {
    if (!state.metricData || !state.measurementData) {
      return [];
    }
    return state.measurementData.map(m => {
      // add the subject to the measurements for convenient lookups
      const metricData = find(state.metricData, { id: m.id });
      const ms = sortBy(
        m.data?.measurements.map(p => ({
          ...p,
          // date format: 2020-01-27T17:45:15.000+0000
          date: parse(p.date, "yyyy-MM-dd'T'HH:mm:ss.SSSxxxxx", new Date())
        })),
        ["date"]
      );
      return {
        ...m,
        data: { measurements: ms },
        subject: metricData.subject,
        metric: metricData.metric
      };
    });
  },

  cropsImportSettings: (state, getters) => {
    const { companyViewId } = getters;
    if (state.cropsImportSettings === false) return false;
    if (state.cropsImportSettings?.companyId === companyViewId)
      return state.cropsImportSettings;
    return null;
  },

  climateDataSettings: (_, getters) => {
    return getters.currentCompany.profile?.settings?.climateData || null;
  },

  fertilizationBinsSettings: (_, getters) => {
    return getters.currentCompany.profile?.settings?.fertilizationBins || null;
  },

  tankMixes: (_, getters) => {
    return getters.currentCompany.profile?.tankMixes || [];
  },

  substrates: (_, getters) => {
    return getters.currentCompany.profile?.substrates || [];
  },

  trays: (_, getters) => {
    return getters.currentCompany.profile?.trays || [];
  },

  plugTypes: (_, getters) => {
    return getters.currentCompany.profile?.plugTypes || [];
  },

  cropLabels: (_, getters) => {
    return getters.currentCompany.profile?.cropLabels || [];
  },

  alerts: state => {
    const userId = state.user.id;
    return state.alerts.map(alert => {
      const isSeen = (alert.seenBy || []).includes(userId);
      alert.isSeen = isSeen;
      return alert;
    });
  },

  allowedLocations: (state, getters) => {
    const { allowedLocations } = getters.currentUser;
    if (!allowedLocations?.length) return null;

    return allowedLocations.map(location => {
      return [
        location.businessLocation.id,
        location.businessLocation.level1Locations.map(x => [
          x.id,
          x.level2Locations.map(x => x.id)
        ])
      ];
    });
  },

  photoComparison: state => {
    return state.photoComparison;
  },

  currentAgreement: state => {
    return state.agreement;
  },

  userReady: state => state.user.ready,

  needsUpdateAgreement: (_, getters) => {
    const user = getters.currentUser;
    const agreement = user.agreement;
    const userVersion = agreement?.version;
    const currentVersion = getters.currentAgreement.version;

    if (agreement) {
      return userVersion !== currentVersion;
    }
    return null;
  },

  cropFields: state => {
    return (
      state?.selectedCompany?.profile?.settings?.cropFields ??
      state?.user?.company?.profile?.settings?.cropFields ??
      []
    );
  },

  pdfFields: state => {
    return (
      state?.selectedCompany?.profile?.settings?.pdfFields ??
      state?.user?.company?.profile?.settings?.pdfFields ??
      []
    );
  }
};
