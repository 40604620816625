import { difference } from "lodash";

export default async function({ app, store, route, error }) {
  const settledRules = new Promise((resolve) => {
    if (store.state.rules) return resolve("auth");

    const unauth_CLIENT = () => process.client && !store.state.user?.id;
    if (unauth_CLIENT()) return resolve("unauth");

    const unauth_SERVER = () => {
      const unauthCriteria = [
        "nuxtServerInit",
        "STORAGE_READY"
      ].sort();
      const unauthQuery = store.state.unauth.slice().sort();
      return process.server && difference(unauthCriteria, unauthQuery).length === 0;
    };
    if (unauth_SERVER()) return resolve("unauth");

    let resolved = false;
    store.subscribe((mutation) => {
      if (mutation.type === "SET_RULES") {
        resolved = true;
        return resolve("auth");
      } else if (mutation.type === "SET_UNAUTH") {
        resolved = true;
        if (unauth_SERVER()) return resolve("unauth");
      }
    });

    setTimeout(() => {
      if (!resolved) resolve("bail"); // just in case
    }, 10000);
  })

  const { needsPermission } = route.meta?.find(x => x.needsPermission) || {};
  if (needsPermission) {
    const settledRulesRes = await settledRules;
    if (settledRulesRes === "auth") {
      const [action, ...subjectc] = needsPermission.split("-");
      const subject = subjectc.join("-");
  
      const can = app.$ab.can(action, subject);
      if (!can) error("No permissions to access this resource.");
    }
  }
}
