const { resolve } = require("path");
const vueCompositionAPIFullpath = resolve(
  "./node_modules/@vue/composition-api/dist/vue-composition-api.mjs"
);

const PROJECT_ID = process.env.FIREBASE_PROJECT || "log-and-solve-staging";
const IS_PROD_BUILD =
  (process.env.IS_PULL_REQUEST || true).toString() === "false";

const LOCAL_API_URL = "http://localhost:8000";

module.exports = {
  ssr: false,
  srcDir: "src",
  buildDir: "build",
  env: {
    NODE_ENV: process.env.NODE_ENV || "development",
    BUILD_STAGE: process.env.BUILD_STAGE || "dev",
    FIREBASE_PROJECT: PROJECT_ID,
    API_URL: process.env.API_URL || LOCAL_API_URL
  },
  dev: process.env.NODE_ENV !== "production",
  server: {
    host: "0.0.0.0"
  },
  render: {
    bundleRenderer: {
      runInNewContext: false
    }
  },
  serverMiddleware: [
    { path: "/", handler: "~/middleware/seo" },
    { path: "/api/search", handler: "~/api/search" },
    { path: "/api/search-multiple", handler: "~/api/searchMultiple" },
    { path: "/api/search-product", handler: "~/api/searchProduct" },
    { path: "/api/validate-lab-id", handler: "~/api/validateLabId" },
    {
      path: "/api/update-taxonomy-cache",
      handler: "~/api/updateTaxonomyCache"
    },
    {
      path: "/api/subscriptions/unsubscribe/daily-update",
      handler: "~/api/subscriptions"
    },
    {
      path: "/api/googleapis",
      handler: "~/api/googleapis"
    },
    {
      path: "/api/blockbax/webhook",
      handler: "~/api/blockbax"
    },
    {
      path: "/api/tentacles/webhook",
      handler: "~/api/tentacles"
    },
    {
      path: "/api/store-lab-data",
      handler: "~/api/parseLabData"
    },
    {
      path: "/api/store-lab-data-lufa",
      handler: "~/api/parseLabDataLufa"
    },
    {
      path: "/api/auth",
      handler: "~/api/auth"
    }
  ],

  head: {
    title: "Log & Solve",
    meta: [
      { charset: "utf-8" },
      {
        name: "viewport",
        content: "width=device-width, initial-scale=1, maximum-scale=1"
      }
    ],
    link: [{ rel: "icon", type: "image/x-icon", href: "/favicon.ico" }],
    htmlAttrs: {
      lang: "nl"
    },
    script: [
      {
        src: "/omnichannel.js",
        async: true,
        defer: true,
        body: true
      },
      {
        src: "/hotjar.js",
        async: true,
        defer: true,
        body: true
      }
    ].filter(x => x)
  },

  loading: {
    color: "#44a12b",
    height: "4px"
  },

  plugins: [
    "~plugins/db.js",
    "~plugins/api.js",
    { src: "~/plugins/vuex-persist", ssr: false },
    { src: "~plugins/casl.js" },
    { src: "~plugins/blockbax.js", ssr: false },
    { src: "~plugins/tentacles.js", ssr: false },
    { src: "~plugins/vue-js-modal", ssr: false },
    { src: "~plugins/vue-easy-lightbox", ssr: false },
    { src: "~/plugins/vue-highlight", ssr: false },
    { src: "~plugins/ga.js", mode: "client" },
    { src: "~plugins/qr-code-reader.js", mode: "client", ssr: false },
    { src: "~/plugins/pdfmake", mode: "client", ssr: false },
    { src: "~/plugins/vue-toggle" },
    { src: "~/plugins/vue-select" },
    { src: "~/plugins/vue-click-outside", mode: "client" },
    { src: "~/plugins/vue-geo-suggest", mode: "client" },
    { src: "~/plugins/vue-awesome" },
    { src: "~/plugins/vue-date-picker", mode: "client", ssr: false },
    { src: "~/plugins/vue-tel-input", mode: "client" },
    { src: "~/plugins/vue-star-rating", mode: "client" },
    { src: "~/plugins/vue-bottom-sheet.js", mode: "client", ssr: false }
  ],

  css: [
    {
      src: "@riophae/vue-treeselect/dist/vue-treeselect.css",
      lang: "css"
    },
    {
      src: "vue-js-modal/dist/styles.css",
      lang: "css"
    },
    {
      src: "@/assets/css/vue2-datePicker.scss",
      lang: "sass"
    },
    {
      src: "@/assets/css/vue-select.scss",
      lang: "sass"
    }
  ],

  modules: [
    ["@nuxtjs/tailwindcss"],
    ["vue-wait/nuxt", { useVuex: true }],
    process.env.NODE_ENV === "production" && "@nuxtjs/sentry",
    [
      "@nuxtjs/firebase",
      {
        config: {
          apiKey: process.env.FIREBASE_KEY,
          authDomain:
            process.env.FIREBASE_AUTH_DOMAIN || `${PROJECT_ID}.firebaseapp.com`,
          projectId: PROJECT_ID,
          databaseURL:
            process.env.FIREBASE_DATABASE_URL ||
            `https://${PROJECT_ID}.firebaseio.com`,
          storageBucket:
            process.env.FIREABASE_STORAGE_BUCKET || `${PROJECT_ID}.appspot.com`,
          appId:
            process.env.FIREBASE_APP_ID ||
            "1:873667186605:web:ce60c329b402f9c8169b24",
          messagingSenderId:
            process.env.FIREBASE_MESSAGE_SENDER_ID || "873667186605"
        },
        services: {
          auth: {
            ssr: false,
            persistence: "local", // default
            initialize: {
              onAuthStateChangedAction: "onAuthStateChangedAction",
              subscribeManually: false
            }
          },
          firestore: true,
          storage: true
        }
      }
    ],
    "nuxt-route-meta",
    "vue-scrollto/nuxt",
    "@nuxtjs/toast",
    [
      "@nuxtjs/i18n",
      {
        strategy: "prefix_except_default",
        vueI18n: {
          silentTranslationWarn: true,
          silentFallbackWarn: true
        },
        detectBrowserLanguage: {
          useCookie: true,
          cookieKey: "i18n_redirected",
          fallbackLocale: "nl"
        },
        locales: [
          { code: "nl", iso: "nl-NL", file: "nl.js", text: "Dutch" },
          { code: "en", iso: "en-US", file: "en.js", text: "English" },
          { code: "de", iso: "de-DE", file: "de.js", text: "German" },
          { code: "fr", iso: "fr-FR", file: "fr.js", text: "French" }
        ],
        defaultLocale: "nl",
        langDir: "locales/",
        lazy: true
      }
    ]
  ].filter(m => m),

  router: {
    middleware: ["redirect-v3", "loggedIn", "agreement", "authorized"]
  },

  toast: {
    position: "top-right",
    duration: 4000
  },

  axios: {
    proxy: true,
    progress: false
  },

  sentry: {
    dsn:
      process.env.NODE_ENV === "production"
        ? "https://0800391ae4ae4fb0808b31e05a2a4dc2@o1249039.ingest.sentry.io/6409352"
        : null,
    config: {
      disabled: process.env.NODE_ENV !== "production"
    },
    publishRelease: false
  },

  device: {
    refreshOnResize: true
  },

  proxy: {
    "/blockbax/": {
      target:
        "https://api.blockbax.com/v1/projects/cd5207bc-5f9e-4aea-b2c7-0e9469972bc7",
      pathRewrite: { "^/blockbax/": "" }
    }
  },

  buildModules: ["@nuxtjs/composition-api/module", "@nuxtjs/device"],

  // purgeCSS: {
  //   whitelistPatterns: [
  //     /^vue/,
  //     /^v-/,
  //     /^overlay/,
  //     /^nice/,
  //     /^vs_/,
  //     /^vs-/,
  //     /^fa-icon/
  //   ]
  // },

  build: {
    parallel: true,
    hardSource: process.env.NODE_ENV === "production",
    cache: true,
    publicPath: `https://d3cnp3yz46n3ds.cloudfront.net/dist/${process.env.BUILD_STAGE}`,
    transpile: [/^vue-awesome/],
    postcss: {
      // Add plugin names as key and arguments as value
      // Install them before as dependencies with npm or yarn
      plugins: {
        // "postcss-url": false,
        "postcss-import": {},
        "postcss-nested": {},
        "postcss-simple-vars": {}
        // "postcss-responsive-type": {},
      }
    },
    extend(config, { isClient }) {
      // needed for vue-demi
      config.module.rules.push({
        test: /\.mjs$/,
        include: /node_modules/,
        type: "javascript/auto"
      });

      if (isClient) {
        config.devtool = "source-map";
        config.node = {
          fs: "empty"
        };
      }
    }
  },
  alias: {
    // needed for vue-demi
    "@vue/composition-api$":
      "@vue/composition-api/dist/vue-composition-api.mjs",
    "@vue/composition-api/dist/vue-composition-api.mjs":
      vueCompositionAPIFullpath
  },
  extendPlugins(plugins) {
    const pluginIndex = plugins.findIndex(
      ({ src }) => src === "~plugins/casl.js" || src === "~/plugins/casl.js"
    );
    const shouldBeFirstPlugin = plugins[pluginIndex];

    plugins.splice(pluginIndex, 1);
    plugins.unshift(shouldBeFirstPlugin);
    return plugins;
  }
};
