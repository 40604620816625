<template>
  <header>
    <nav class="navbar container">
      <nuxt-link :to="localePath('user-crops-list')">
        <div class="brand">
          <img
            src="~/assets/images/icons/LogAndSolve_Logo_nur.svg"
            alt="Log and Solve logo"
            style="width: 32px; height: auto"
          />
          <p class="font-default brand-text hidden lg:block">{{ title }}</p>
        </div>
      </nuxt-link>
      <!-- on mobile only -->
      <div class="mobile-icons" v-if="!disabled">
        <button-component
          :v-icon="`sticky-note`"
          :text="$t('dashboard.components.mainNavigation[12].text')"
          color="grey"
          @click="() => $router.push(localePath({ name: 'user-crop-form' }))"
          :allowed="$can('create', 'cropNotes-all')"
        />
        <div
          v-click-outside="
            () =>
              (shouldKeepNotifsViewOpen = $device.isMobile
                ? false
                : shouldKeepNotifsViewOpen)
          "
          class="ml-2"
        >
          <button-component
            :color="
              delayedAlerts.filter(x => !x.isSeen).length
                ? 'brand-pinkSalmon'
                : 'brand-manatee'
            "
            :v-icon="`bell`"
            :counter="delayedAlerts.filter(x => !x.isSeen).length"
            @click="toggleNotifsView"
          />
          <div
            class="alerts context-menu"
            :class="{ hidden: !shouldKeepNotifsViewOpen }"
            @click.stop="() => {}"
          >
            <alerts-component
              @opened="shouldKeepNotifsViewOpen = false"
              :alerts="delayedAlerts"
            />
          </div>
        </div>
        <div class="menu-toggle" @click.stop="toggleMenu">
          <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <title>
              {{ $t("dashboard.components.mainNavigation[8].text") }}
            </title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </div>
      </div>
      <client-only>
        <ul
          v-if="!disabled"
          class="navigation-items"
          :class="{ hidden: !shouldKeepMenuOpen }"
          v-click-outside="() => (shouldKeepMenuOpen = false)"
        >
          <li
            v-for="item in navigationMenu"
            :key="item.name"
            class="navigation-item"
            :class="{ 'mobile-only': item.mobileOnly }"
          >
            <nuxt-link
              class="navigation-link"
              :class="{ active: item.isActive }"
              :to="
                localePath({
                  name: item.name,
                  ...(item.query ? { query: item.query } : {})
                })
              "
            >
              <span :class="{ 'mr-px': item.icon }">{{ $t(item.title) }}</span>
              <v-icon
                v-if="item.icon"
                :name="item.icon"
                class="ml-px delete-icon"
              />
            </nuxt-link>
          </li>
          <li class="hide-on-mobile">
            <button-component
              :text="$t('dashboard.components.mainNavigation[12].text')"
              color="grey"
              :v-icon="`sticky-note`"
              @click="
                () => $router.push(localePath({ name: 'user-crop-form' }))
              "
              :allowed="$can('create', 'cropNotes-all')"
            />
          </li>
          <li
            class="hide-on-mobile"
            v-click-outside="
              () =>
                (shouldKeepNotifsViewOpen = !$device.isMobile
                  ? false
                  : shouldKeepNotifsViewOpen)
            "
          >
            <div class="ml-2">
              <button-component
                :color="
                  delayedAlerts.filter(x => !x.isSeen).length
                    ? 'brand-pinkSalmon'
                    : 'brand-manatee'
                "
                :v-icon="`bell`"
                :counter="alertsCount"
                @click="toggleNotifsView"
              />
              <div
                class="context-menu"
                :class="{ hidden: !shouldKeepNotifsViewOpen }"
                @click.stop="() => {}"
              >
                <alerts-component
                  @opened="shouldKeepNotifsViewOpen = false"
                  :alerts="delayedAlerts"
                />
              </div>
            </div>
          </li>
          <li
            class="hide-on-mobile"
            @click="toggleContextMenu"
            v-click-outside="() => (shouldKeepContextOpen = false)"
          >
            <div class="settings-link">
              <div class="initials-container">
                <p class="initials">{{ initials }}</p>
              </div>
              <div class="name-container">
                <p class="name truncate">
                  {{ userName }}
                </p>
                <p
                  :class="{
                    'text-brand-pinkSalmon': $store.state.selectedCompany,
                    'text-brand-mineShaft': !$store.state.selectedCompany
                  }"
                  class="name truncate"
                >
                  {{ companyName || "" }}
                </p>
              </div>
              <div
                class="context-menu"
                :class="{ hidden: !shouldKeepContextOpen }"
                @click.stop="() => {}"
              >
                <ul>
                  <li v-for="(item, index) in contextMenu" :key="index">
                    <nuxt-link
                      class="navigation-link context-link"
                      :to="
                        localePath({
                          name: item.name,
                          ...(item.query ? { query: item.query } : {})
                        })
                      "
                    >
                      {{ $t(item.title) }}
                    </nuxt-link>
                  </li>
                </ul>
                <ul
                  v-show="
                    $can('crud', 'admin-area') || $can('crud', 'overview-area')
                  "
                  class="mt-3 pt-2 border-t border-brand-ghost"
                >
                  <li v-show="$can('crud', 'admin-area')">
                    <nuxt-link
                      class="navigation-link context-link"
                      :to="
                        localePath({
                          name: 'user-admin'
                        })
                      "
                    >
                      {{ $t("dashboard.components.mainNavigation[13].text") }}
                    </nuxt-link>
                  </li>
                  <li v-show="$can('crud', 'overview-area')">
                    <nuxt-link
                      class="navigation-link context-link"
                      :to="
                        localePath({
                          name: 'user-overview'
                        })
                      "
                    >
                      {{ $t("dashboard.components.mainNavigation[14].text") }}
                    </nuxt-link>
                  </li>
                </ul>
                <ul class="mt-3 pt-2 border-t border-brand-ghost">
                  <nuxt-link
                    class="navigation-link context-link"
                    :to="
                      localePath({
                        name: 'sign-out'
                      })
                    "
                  >
                    {{ $t("dashboard.components.mainNavigation[11].text") }}
                  </nuxt-link>
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </client-only>
    </nav>
  </header>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import buttonComponent from "@/components/button-component";
import alertsComponent from "@/components/alerts/alerts";

const getInitials = str => {
  // get initials from first/last name string
  return (
    str
      .split(" ")
      .map(s => s.slice(0, 1).toUpperCase())
      // neat little trick to determine if we're dealing
      // with a unicode letter in latin alphabet
      .filter(c => c.toLowerCase() != c.toUpperCase())
      .slice(0, 2)
      .join("")
  );
};

const companyPagesPermissionsMap = {
  "company-profile": ["companyProfile-profile"],
  "company-sensors": ["companyProfile-sensorSettings"],
  "company-locations": ["companyProfile-locationSettings", "locations"],
  "company-exports": ["companyProfile-exports"],
  "company-tasks": ["companyProfile-tasks"],
  "company-locations-qr": ["companyProfile-qrCode"],
  "company-note-checklists": ["companyProfile-noteChecklists"],
  "company-tank-mixes": ["companyProfile-tankMixes"],
  "company-import": ["companyProfile-cropsImport"],
  "company-user-management": [
    "companyProfile-userManagement",
    "user-management"
  ],
  "company-protocols": ["cropProtocol-all"] // might need a separate spot in company profile category
};

export default {
  components: {
    buttonComponent,
    alertsComponent
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      shouldKeepContextOpen: false,
      shouldKeepMenuOpen: false,
      shouldKeepNotifsViewOpen: false
    };
  },
  computed: {
    ...mapState({
      userName: state => state.user.name,
      initials: state => getInitials(state.user.name || ""),
      pendingLabAnalysis: state => state.pendingLabAnalysis || []
    }),
    ...mapGetters(["alerts"]),
    title() {
      let baseTitle = "Log & Solve";
      const PROJECT_ID =
        process.env.FIREBASE_PROJECT || "log-and-solve-staging";
      if (PROJECT_ID === "log-and-solve-staging") baseTitle += " (stage)";
      return baseTitle;
    },
    delayedAlerts() {
      return this.alerts.filter(a =>
        a?.data?.date ? a?.data?.date?.getTime() < Date.now() : true
      );
    },
    alertsCount() {
      const limit = 99;
      const count = this.delayedAlerts.filter(x => !x.isSeen).length;
      if (count > limit) return `${limit}+`;
      return count;
    },
    companyName() {
      const { getters } = this.$store;
      return getters.currentCompany?.name;
    },
    // whether to show all items in one list (on mobile) or not (on desktop)
    navMenu() {
      const userId = this.$route.query.userId;
      return [
        {
          title: "dashboard.components.mainNavigation[0].text",
          name: "user-crops-list",
          isActive: false
        },
        {
          title: "dashboard.components.mainNavigation[16].text",
          name: "user-daily-summary",
          isActive: false,
          can: this.$can("view", "dailySummary-all")
        },
        {
          title: "dashboard.components.mainNavigation[1].text",
          name: "general-documents",
          isActive: false,
          icon: this.pendingLabAnalysis.length ? "exclamation" : null
        },
        {
          title: "dashboard.components.mainNavigation[2].text",
          name: "user-tasks"
        },
        {
          title: "dashboard.components.mainNavigation[4].text",
          name: "user-sensors",
          query: userId ? { userId } : null
        },
        {
          title: "dashboard.components.mainNavigation[15].text",
          name: "user-protocols",
          query: userId ? { userId } : null,
          can: this.$can("view", "cropProtocol-all")
        }
      ].filter(x => x.can !== false);
    },
    contextMenu() {
      const hasPermittedRoute = Object.entries(companyPagesPermissionsMap)
        .map(([path, perms]) => {
          const can = perms.find((subject, idx) => {
            const action = idx === 0 ? "view" : "crud";
            return this.$can(action, subject);
          });
          return can ? path : null;
        })
        .find(x => x);

      return [
        {
          title: "dashboard.components.mainNavigation[3].text",
          name: "user-profile",
          isActive: false,
          mobileOnly: true,
          can: true
        },
        {
          title: "dashboard.components.mainNavigation[10].text",
          name: "company",
          isActive: false,
          mobileOnly: true,
          can: !!hasPermittedRoute
        }
      ].filter(x => x.can !== false);
    },
    navigationMenu() {
      return [
        ...this.navMenu,
        ...this.contextMenu,
        ...(this.$can("crud", "admin-area")
          ? [
              {
                title: "dashboard.components.mainNavigation[13].text",
                name: "user-admin",
                mobileOnly: true
              }
            ]
          : []),
        ...(this.$can("crud", "overview-area")
          ? [
              {
                title: "dashboard.components.mainNavigation[14].text",
                name: "user-overview",
                mobileOnly: true
              }
            ]
          : []),
        {
          title: "dashboard.components.mainNavigation[11].text",
          name: "sign-out",
          mobileOnly: true
        }
      ];
    }
  },
  watch: {
    $route: {
      handler: function () {
        this.updateActiveRoute();

        // toggle menu only if it is visible (this is used for mobile screen)
        if (this.shouldKeepMenuOpen) {
          this.toggleMenu();
        }
        if (this.shouldKeepContextOpen) {
          this.toggleContextMenu();
        }
      },
      immediate: true
    }
  },
  mounted() {},
  beforeMount() {
    this.updateActiveRoute();
  },
  methods: {
    updateActiveRoute() {
      const currentRoute = this.$router.currentRoute.name;
      const navigationRoutes = [...this.navMenu, ...this.contextMenu];
      const routesList = navigationRoutes.map(e => e.name);
      routesList.forEach(route => {
        if (currentRoute && currentRoute.includes(route)) {
          navigationRoutes.find(obj => obj.name === route).isActive = true;
        } else {
          navigationRoutes.find(obj => obj.name === route).isActive = false;
        }
      });
    },
    toggleMenu() {
      this.shouldKeepMenuOpen = !this.shouldKeepMenuOpen;
    },
    toggleContextMenu() {
      this.shouldKeepContextOpen = !this.shouldKeepContextOpen;
    },
    toggleNotifsView() {
      this.shouldKeepNotifsViewOpen = !this.shouldKeepNotifsViewOpen;
    }
  }
};
</script>

<style scoped lang="postcss">
header {
  @apply bg-white border-b border-solid border-gray-300 py-4;
  @screen md {
    @apply py-0;
  }
}

.navbar {
  @apply w-full font-medium flex relative;
  @apply items-center justify-between bg-white px-2 mx-auto;
  z-index: 2;
  @screen md {
    @apply px-0;
  }
  min-height: 69px;
}

.mobile-only {
  @apply block;
  @screen md {
    @apply hidden;
  }
}

.hide-on-mobile {
  @apply hidden;
  @screen md {
    @apply block;
  }
}

.navigation-items {
  @apply absolute z-10 bg-white flex-col items-start justify-between;
  @apply right-0 pl-4 py-4 w-full border-b-2 border-t-2 border-gray-300;
  top: 150%;
  @screen md {
    @apply flex flex-row static w-auto items-center p-0 border-transparent;
  }
  a.active {
    @apply border-brand-green;
  }
}

.navigation-item:not(.settings-link) {
  @apply mr-3;
}

.settings-link {
  @apply inline-block pt-2 flex items-center cursor-pointer relative h-16;
  @apply pb-2 ml-4;
}

.name-container {
  @apply flex flex-col h-full justify-between py-1;
}

.name {
  @apply block ml-2 mr-8 font-default font-medium text-xs;
  max-width: 7rem;
  line-height: 1.4;
  &::after {
    @apply hidden w-4 absolute right-0;
    content: url("~assets/images/icons/down-arrow.svg");
    max-height: 1rem;
    top: 50%;
    transform: translate(0%, -50%);
    @screen md {
      @apply block;
    }
  }
}

.navigation-link {
  @apply block font-medium pt-2 px-1 relative text-sm border-b-2;
  @apply border-transparent text-brand-mineShaft inline-block;
  @screen md {
    @apply pb-0;
  }
  &:hover {
    @apply border-brand-green;
  }
}

.brand {
  @apply flex items-center;
  margin-top: 7.5px;
}

.brand-text {
  @apply text-brand-green font-medium text-base ml-1;
}

.mobile-icons {
  @apply flex items-center;
  @screen md {
    @apply hidden;
  }
}

.menu-toggle {
  @apply ml-1 flex items-center px-3 py-2 outline-none rounded;
  @apply text-brand-green border border-transparent;
  height: 2.25rem;
  @screen md {
    @apply py-0 h-auto;
  }
  &:hover {
    @apply border-solid border-brand-green;
  }
  & svg {
    @apply fill-current h-5 w-5;
  }
}

.initials-container {
  @apply flex items-center justify-center bg-brand-mineShaft rounded;
  width: 36px;
  height: 36px;
}

.initials {
  @apply font-default font-medium text-xl text-white;
}

.context-menu {
  @apply absolute w-56 right-0 bg-white px-4 py-3 mt-1 rounded;
  top: 100%;
  max-height: 70vh;
  overflow-y: auto;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);

  &:not(.alerts) {
    @apply invisible;
    @screen md {
      @apply visible;
    }
  }
}

.context-link {
  @apply font-default font-normal text-sm text-brand-mineShaft;
}
</style>
