import { BaseModel } from "./base-model";
import { differenceInSeconds, isToday, isPast, isAfter, endOfDay, setHours } from "date-fns";

export class Task extends BaseModel {
  get isRecurringTask() {
    return this?.repeats && this?.repeatWeekOffset;
  }

  get isDoneSort() {
    // when sorting we want to maintain the position of the "just" done items
    if (!this.completedAt) return false;
    return differenceInSeconds(new Date(), this.completedAt) > 60;
  }

  get isInFuture() {
    return isAfter(this.date, setHours(new Date(), 0));
  }

  get isDone() {
    return !!this.completedAt;
  }

  get isToday() {
    return !this.isDone && !this.isOverdue && isToday(this.date);
  }

  get isOverdue() {
    return !this.isDone && isPast(endOfDay(this.date));
  }

  toJSON() {
    return {
      ...this,
      isDone: this.isDone,
    };
  }
}

export const OPTION_BRANCH_MAPPING = {
  pruning: "cultivationActivity",
  pinching: "cultivationActivity",
  spacing: "cultivationActivity",
  move: "cultivationActivity",
  sorting: "cultivationActivity",
  changePotsize: "cultivationActivity",
  rotate: "cultivationActivity",
  cuttingLeaf: "cultivationActivity",
  layFoil: "cultivationActivity",
  removeFoil: "cultivationActivity",
  harvest: "cultivationActivity",
  lower: "cultivationActivity",
  settle: "cultivationActivity",
  removeLeafs: "cultivationActivity",
  bendIn: "cultivationActivity",
  placeSticks: "cultivationActivity",
  siftingThrough: "cultivationActivity",
  pollinate: "cultivationActivity",
  binding: "cultivationActivity",
  weeding: "cultivationActivity",
  deflower: "cultivationActivity",
  removeTendrils: "cultivationActivity",
  placeCatchCards: "cultivationActivity",
  tendrils: "cultivationActivity",
  raiseTheGauze: "cultivationActivity",
  cropMaintenance: "cultivationActivity",
  removeBuds: "cultivationActivity",
  potting: "cultivationActivity",
  deliverFinishedPlants: "cultivationActivity",
  sow: "cultivationActivity",
  makeCuttings: "cultivationActivity",
  technicalService: "cultivationActivity",

  cultivationBatch: "cultivationActivity", // needed?
  liquidCropControl: "cropControl",
  spreadingCropControl: "cropControl",
  growthRegulation: "cropControl",
  floweringTreatment: "cropControl",
  PPP: "cropControl",
  adjuvant: "cropControl",
  biocide: "cropControl",
  spreadableFertilizers: "waterAndFertilization",
  foliarFertilizers: "waterAndFertilization",
  controlRound: null,
  scouting: "observation",
  misc: "observation",
  checklist: "checklist"
};

export const OPTION_STEP_MAPPING = {
  pruning: "pruning",
  pinching: "budding",
  spacing: "widening",
  move: "moveCultivationBatch",
  sorting: "sorting",
  changePotsize: "changePotsize",
  rotate: "rotate",
  cuttingLeaf: "cuttingLeaf",
  layFoil: "layFoil",
  removeFoil: "removeFoil",
  harvest: "harvest",
  lower: "lower",
  settle: "settle",
  removeLeafs: "removeLeafs",
  bendIn: "bendIn",
  placeSticks: "placeSticks",
  siftingThrough: "siftingThrough",
  pollinate: "pollinate",
  binding: "binding",
  weeding: "weeding",
  deflower: "deflower",
  removeTendrils: "removeTendrils",
  placeCatchCards: "placeCatchCards",
  tendrils: "tendrils",
  raiseTheGauze: "raiseTheGauze",
  cropMaintenance: "cropMaintenance",
  removeBuds: "removeBuds",
  potting: "potting",
  deliverFinishedPlants: "deliverFinishedPlants",
  sow: "sow",
  makeCuttings: "makeCuttings",
  technicalService: "technicalService",

  cultivationBatch: "cultivationActivity", // needed?
  liquidCropControl: "chemicalProtection",
  spreadingCropControl: "organicProtection",
  growthRegulation: "remmen",
  floweringTreatment: "floweringTreatment",
  spreadableFertilizers: "spreadible",
  foliarFertilizers: "foliar",
  PPP: "PPP",
  adjuvant: "adjuvant",
  biocide: "biocide",
  controlRound: null,
  scouting: "observation",
  misc: "misc"
};