import fromUnixTime from "date-fns/fromUnixTime";
import getYear from "date-fns/getYear";
import getISOWeek from "date-fns/getISOWeek";

const maxUnixTime = Math.pow(2, 31);

const unixTimestampToDate = (withYear = true) => timestamp => {
  const date = new Date(timestamp * 1000);

  const year = withYear && date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${withYear ? year + "-" : ""}${month}-${day}`;
};

const currentUnixTimestamp = () => Math.floor(Date.now() / 1000);

const dateToUnixTimestamp = date => Math.floor(date.getTime() / 1000);

// some notes have wrong unix time stamp (multiplied by 1000)
const convertUnixTime = date => {
  return fromUnixTime(date > maxUnixTime ? Math.floor(date / 1000) : date);
};

// convert firestore date objects to Date objects, if not a firestore type we assume
// a Unix epoch number and convert that to a date.
const toDate = obj => {
  if (!obj) return null;
  if (obj instanceof Date) return obj;
  if (obj.toDate) return obj.toDate();
  if (obj.seconds != null && obj.nanoseconds != null) return new Date(obj.seconds * 1000 + obj.nanoseconds / 1000000);
  return new Date(obj);
};

const getYearAndWeek = date => {
  const isoWeek = getISOWeek(date);
  const year = getYear(date);
  return `${year}-${isoWeek.toString().padStart(2, "0")}`;
};

export {
  toDate,
  unixTimestampToDate,
  currentUnixTimestamp,
  dateToUnixTimestamp,
  convertUnixTime,
  getYearAndWeek
};
