import axios from "axios";
import axiosRetry, { isNetworkOrIdempotentRequestError } from "axios-retry";
import { camelCase, sortBy, round } from "lodash";
import { getMetricType } from "@/utils/sensor-graphs";

const unitMap = {
  C: "℃",
  lx: "Lux"
};
const allowedPropTypes = {
  Moist: "soil moisture",
  temp: "temperature"
};

export const apiFactory = (sensorConfig, $db) => {
  const baseUrl = "https://login.mytentacles.com/api/v2";
  const http = axios.create({
    baseURL: baseUrl
  });

  const API_CONFIG = {
    token: "",
    refresh_token:
      "8688cb746d4f1741bb5a12f5e45d182b8f47b93d350b7df8d954e9cdcf5481651db69199f0e002937d6acaf72a2d215be733a2434d14b22f222dde69eeb698b9"
  };

  const patch = (url, body) =>
    http
      .patch(url, body, {
        headers: { "content-type": "application/x-www-form-urlencoded" }
      })
      .then(res => res.data);

  const refreshToken = (() => {
    const post = (url, body) => http.post(url, body).then(res => res.data);
    return async () => {
      const { token, refresh_token } = await post("/auth/refresh", {
        is_refresh: true,
        refresh_token: API_CONFIG.refresh_token
      });
      API_CONFIG.token = token;
      API_CONFIG.refresh_token = refresh_token;
    };
  })();

  http.interceptors.request.use(async config => {
    if (!API_CONFIG.token && !config.data?.is_refresh) await refreshToken();
    config.headers.Authorization = `Bearer ${API_CONFIG.token}`;
    return config;
  });

  axiosRetry(http, {
    retries: 1,
    retryCondition: async error => {
      if (isNetworkOrIdempotentRequestError(error)) return true;
      if (error.response && error.response.status === 401) {
        await refreshToken();
        return true;
      }
      return false;
    }
  });

  return {
    async getSubjects(companyId) {
      if (
        !sensorConfig ||
        (companyId && !sensorConfig[companyId])
      ) {
        const updatedSensorConfig = await $db.getSensorConfig("tentacles", companyId);
        sensorConfig = Object.assign({}, sensorConfig || {}, updatedSensorConfig);
      }

      const customerId = companyId ? sensorConfig[companyId] : "dealer-view";
      if (!customerId) return [];

      const subjectReq = await $db.getSubjectsForCompany("tentacles", companyId);
      return subjectReq.map(subject => {
        const metrics = subject.sensors.map(sensor => {
          return {
            id: sensor.id,
            key: sensor.sensorId,
            type: getMetricType(sensor.sensorId, true),
            name: sensor.name,
            sensorId: sensor.sensorId,
            unit: unitMap[sensor.unit] || sensor.unit,
            lastValue: sensor.measurement,
            minCrit: sensor.minCrit,
            maxCrit: sensor.maxCrit
          };
        });
        subject.metrics = sortBy(metrics, "name");
        subject.key = subject.external;
        subject.propertyTypes = this.getPropertyTypes(subject);
        subject.provider = "tentacles";
        return subject;
      });
    },
    getPropertyTypes(subject) {
      const propTypes = subject.sensors
        .filter(x => allowedPropTypes[x.sensorId])
        .map(sensor => {
          const prop = {
            id: sensor.id,
            unit: unitMap[sensor.unit] || sensor.unit,
            minValue: sensor.minValue || 0,
            maxValue: sensor.maxValue
          };
          const max = Object.assign({}, prop, {
            key: "maxCrit",
            name: camelCase(`high ${allowedPropTypes[sensor.sensorId]}`),
            value: sensor.maxCrit
          });
          const min = Object.assign({}, prop, {
            key: "minCrit",
            name: camelCase(`low ${allowedPropTypes[sensor.sensorId]}`),
            value: sensor.minCrit
          });
          return [max, min];
        })
        .flat();
      return propTypes;
    },
    async getSubject(id, companyId) {
      const subject = await $db.getSubjectForCompany("tentacles", companyId, id);
      const metrics = subject.sensors.map(sensor => {
        return {
          id: sensor.id,
          key: sensor.sensorId,
          name: sensor.name,
          sensorId: sensor.sensorId,
          unit: unitMap[sensor.unit] || sensor.unit
        };
      });
      subject.metrics = sortBy(metrics, "name");
      subject.key = subject.external;
      subject.propertyTypes = this.getPropertyTypes(subject);
      subject.provider = "tentacles";
      return subject;
    },
    getStoredMeasurements(config, from, to) {
      return $db.getStoredMeasurements(config, from, to);
    },
    async getLastStoredMeasurement({ companyId, subject, sensor }) {
      const entry = await $db.getLastStoredMeasurement({
        companyId,
        subjectId: subject.key,
        metricId: sensor.key
      });
      if (entry) entry.value = round(entry.value, 2);
      return entry;
    },
    async getLastStoredMeasurements({ companyId, subjects, metrics }) {
      const ids = subjects
        .map(subject => metrics.map(sensor => ({ companyId, subject, sensor })))
        .flat();
      const proms = ids.map(async ({ subject, sensor }) => {
        const measurement = await this.getLastStoredMeasurement({
          companyId,
          subject,
          sensor
        });
        return measurement;
      });
      const measurements = await Promise.all(proms);
      return measurements.filter(x => x);
    },
    getStoredMeasurement(measurementId) {
      return $db.getStoredMeasurement(measurementId, "tentacles");
    },
    async saveSubject(subject) {
      const subjectReq = [
        {
          id: subject.id,
          name: subject.name
        }
      ];
      const propertiesMap = subject.propertyTypes.reduce((acc, prop) => {
        if (!acc[prop.id]) acc[prop.id] = {};
        acc[prop.id][prop.key] = prop.value;
        return acc;
      }, {});
      const propertiesReq = Object.entries(propertiesMap).map(
        ([id, props]) => ({ id, ...props })
      );

      await patch("/tentacles", JSON.stringify(subjectReq));
      await patch("/sensors", JSON.stringify(propertiesReq));

      const patchFragment = {
        name: subject.name,
        sensors: subject.sensors.map(sensor => {
          const valuesToPatch = propertiesReq.map(x => ({ ...x, id: +x.id })).find(x => x.id === sensor.id);
          const newSensor = { ...sensor, ...valuesToPatch };
          return newSensor;
        })
      }
      const updatedDbSubject = {
        id: +subject.id,
        value: patchFragment
      };
      await $db.updateSubjectForCompany("tentacles", subject.companyId, updatedDbSubject);

      return true;
    }
  };
};

export default ({ $db }, inject) => {
  // -> app.tentacles
  // -> this.$tentacles in vue components
  // -> this.$tentacles in store actions/mutations
  const api = apiFactory(null, $db);
  inject("tentacles", api);
};
