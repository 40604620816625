import { difference } from "lodash";

const PUBLIC_PATHS = [
  "sign-out",
  "sign-in",
  "sign-up",
  "third-party",
  "reset-password",
  "asuser",
  "substrate"
];

export default async function({ store, route, query, redirect }) {
  const authState = new Promise((resolve) => {
    if (store.state.rules) return resolve("auth");

    const unauth_CLIENT = () => process.client && !store.state.user?.id;
    if (unauth_CLIENT()) return resolve("unauth");

    const unauth_SERVER = () => {
      const unauthCriteria = [
        "nuxtServerInit",
        "STORAGE_READY"
      ].sort();
      const unauthQuery = store.state.unauth.slice().sort();
      return process.server && difference(unauthCriteria, unauthQuery).length === 0;
    };
    if (unauth_SERVER()) return resolve("unauth");

    let resolved = false;
    store.subscribe((mutation) => {
      if (mutation.type === "SET_RULES") {
        resolved = true;
        return resolve("auth");
      } else if (mutation.type === "SET_UNAUTH") {
        resolved = true;
        if (unauth_SERVER()) return resolve("unauth");
      }
    });

    setTimeout(() => {
      if (!resolved) resolve("bail"); // just in case
    }, 10000);
  })

  const isAuth = await authState;
  if (isAuth === "auth") {
    if (
      !PUBLIC_PATHS.some(
        publicPath => route.path.includes(publicPath)
      )
    ) {
      const { needsUpdateAgreement } = store.getters;
      const isOnOverview = route.path.includes("user/overview");
      const atAgreementAlready = route.path.includes("/agreement");

      if (needsUpdateAgreement && !atAgreementAlready) {
        const queryPath = !isOnOverview ? route.fullPath : (query.path || "");
        redirect({
          path: "/agreement",
          query: {
            path: queryPath
          }
        });
      }
    }
  }
};