// eslint-disable-next-line no-unused-vars
import firebase from "firebase/compat/app";

export class BaseAction {
  /**
   * @type {firebase.firestore.Firestore }
   */
  firestore;
  /**
   * @type {firebase.firestore.CollectionReference }
   */
  collection;
  collectionName;
  constructor($fire = null, collectionName = "") {
    if (!$fire) return;
    this.firestore = $fire.firestore;
    this.collectionName = collectionName;
    if (this.collectionName) {
      this.collection = this.firestore.collection(this.collectionName);
    }
  }

  toData(snap) {
    const data = snap.data();
    data.id = snap.id;
    return this.toModel(data);
  }
  collectionToData(snap) {
    return snap.docs.map((doc) => this.toData(doc));
  }
  toModel(data) {
    return data;
  }
  async get(id) {
    if (!id) {
      throw new Error(
        `Invalid id: ${id} when trying to get from collection: ${this.collection.id}`
      );
    }
    const doc = await this.collection.doc(id).get();
    return this.toData(doc);
  }
  async create(data) {
    return await this.collection.add(data);
  }
  async update(id, data) {
    await this.collection.doc(id).update(data);
  }
  async delete(id) {
    await this.collection.doc(id).delete();
  }
}
