const redirs = [
  { from: "/", to: "https://logandsolve.com/" },
  { from: "/team", to: "https://logandsolve.com/" }
];

module.exports = function (req, res, next) {
  const redirect = redirs.find(r => r.from === req.url);
  const host =
    req?.rawHeaders?.[req.rawHeaders?.findIndex(h => h === "Host") + 1] ?? "";

  if (
    process.env.NODE_ENV === "production" &&
    redirect &&
    !host.includes("onrender.com")
  ) {
    res.writeHead(301, { Location: redirect.to });
    res.end();
  } else {
    next();
  }
};
