<template>
  <div id="qr-code-full-region" class="scanner-container"></div>
</template>

<script>
import { Html5Qrcode, Html5QrcodeSupportedFormats } from "html5-qrcode";

export default {
  async mounted() {
    if (!this.isMediaStreamAPISupported) {
      throw new Error("Media Stream API is not supported");
    }
    
    const config = {
      rememberLastUsedCamera: true,
      formatsToSupport: [
        Html5QrcodeSupportedFormats.QR_CODE,
        Html5QrcodeSupportedFormats.ITF
      ]
    };

    this.html5QrcodeScanner = new Html5Qrcode("qr-code-full-region"),
    await this.html5QrcodeScanner.start({ facingMode: "environment" }, config, this.onScanSuccess);
    this.html5QrcodeScanner.applyVideoConstraints({ focusMode: "continuous", zoom: 2 });
  },
  beforeDestroy() {
    try {
      this.html5QrcodeScanner?.stop();
    } catch(e) {}
  },
  data() {
    return {
      html5QrcodeScanner: null,
      isMediaStreamAPISupported:
        navigator &&
        navigator.mediaDevices &&
        "enumerateDevices" in navigator.mediaDevices
    };
  },
  methods: {
    onScanSuccess(_, result) {
      this.$emit("decode", result);
    }
  }
};
</script>