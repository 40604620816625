<template>
  <modal
    name="confirmation-modal"
    height="auto"
    :adaptive="true"
    class="rounded-lg"
  >
    <div>
      <div class="p-4 rounded-lg">
        <div class="m-4 mb-8 font-medium text-center">
          {{ params.message }}
        </div>
        <div class="flex justify-around m-2">
          <button-component
            :type="`button`"
            :text="
              params.cancelButtonText
            "
            :color="'red'"
            @click="submit(false)"
          />
          <button-component
            :type="`button`"
            :color="'green'"
            :text="
              params.confirmButtonText
            "
            @click="submit(true)"
          />
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import Vue from "vue";
import buttonComponent from "@/components/button-component";

const defaultParams = () => ({
  message: "Are you sure?",
  confirmButtonText: "Confirm",
  cancelButtonText: "Cancel"
});

export const eventbus = new Vue({
  name: "vue-confirm-dialog"
});

export default {
  name: "confirmation-modal",
  components: {
    buttonComponent
  },
  mounted() {
    eventbus.$on("show", (params) => {
      this.show(params);
    });
  },
  data() {
    return {
      params: defaultParams()
    };
  },
  methods: {
    show(params) {
      this.params = Object.assign({}, defaultParams(), params);
      this.$modal.show("confirmation-modal");
    },
    submit(confirm) {
      this.params.callback(confirm);
      this.$modal.hide("confirmation-modal");
    }
  }
};
</script>