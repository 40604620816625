import { BaseAction } from "./base-action";
import firebase from "firebase/compat/app";
import { subDays, addWeeks } from "date-fns";
import { defaultForm } from "@/data/log-crop-form";
import hash from "object-hash";

export class AlertAction extends BaseAction {
  async resetAlerts(cropId, userId) {
    const { collection, firestore } = this;
    const cropAlerts = await collection
      .where("cropId", "==", cropId)
      .where("type", "in", [
        "lab-data",
        "lab-data-comment",
        "new-note",
        "new-photo"
      ])
      .where("created", ">", subDays(new Date(), 30))
      .get();
    const batch = firestore.batch();
    await Promise.all(
      cropAlerts.docs.map(async doc => {
        const docRef = firestore.collection("alerts").doc(doc.id);
        const seenBy = doc.data().seenBy;
        if (seenBy) {
          batch.update(docRef, {
            seenBy: firebase.firestore.FieldValue.arrayUnion(userId)
          });
        }
      })
    );
    await batch.commit();
  }

  async create({
    userId,
    cropId,
    companyId,
    entryId = null,
    created = new Date(),
    active = true,
    type,
    data = null
  }) {
    const { collection } = this;
    return await collection.add({
      byUserId: userId,
      forUsers: [userId],
      cropId,
      companyId,
      type,
      entryId,
      created,
      active,
      seenBy: [],
      data
    });
  }

  async markAlertAsSeen(alertId, userId) {
    const { collection } = this;
    await collection.doc(alertId).update({
      seenBy: firebase.firestore.FieldValue.arrayUnion(userId)
    });
  }
}

export const createProtocolAlert = ({
  crop,
  alert,
  userId,
  companyId,
  t,
  protocol,
  week
}) => {
  const alertText = a => {
    const observationOptions = defaultForm(t)
      .options.find(o => o.id === "observation")
      .to.multistep[0].options.map(o => ({ ...o, name: o.label }));
    const type = observationOptions.find(t => t.id === a.typeId);
    const options = type?.to?.multistep?.[0]?.options ?? type?.to?.options;
    const option = options?.find(o => o.id === a.optionId);
    const options2 = type?.to?.next?.multistep?.[0]?.options;
    const option2 = options2?.find(o => o.id === a.option2Id);
    const description = a.description ?? "";

    const title = [
      type?.name,
      type?.to?.multistep?.[0]?.displayUnit,
      option?.name,
      option2?.name,
      a.failureAmount
    ]
      .filter(n => n)
      .join(" - ");
    return { title, description };
  };

  const potWeek = crop?.potWeek?.date || crop?.profile?.duration?.pottingStart;

  const pottingDate = potWeek.toDate ? potWeek.toDate() : potWeek;
  const { title, description } = alertText(alert);
  const alertData = {
    cropId: crop.id,
    forCrop: { id: crop.id },
    userId,
    companyId,
    type: "protocol",
    protocolId: protocol.id,
    data: {
      title,
      description,
      date: addWeeks(pottingDate, week - 1 || 0)
    }
  };
  const alertHash = hash(alertData, {
    excludeKeys: key =>
      ["prototype", "__ob__", "__proto__", "constructor"].includes(key)
  });
  const seenBy = protocol.alertsSeen?.[alertHash] ?? [];
  const enrichedAlert = {
    ...alertData,
    entryId: alertHash,
    id: alertHash,
    seenBy
  };

  return enrichedAlert;
};
