import { BaseModel } from "./base-model";
import addWeeks from "date-fns/addWeeks";
import { isAfter, startOfToday, endOfDay } from "date-fns";
import * as CropFunctions from "@/utils/cropFunctions";
import {
  startOfISOWeek,
  getISOWeek,
  getYear,
  isWithinInterval,
  endOfISOWeek,
  parseISO
} from "date-fns";
import { sortBy } from "lodash";
import { toDate } from "../utils/date";

export default class Crop extends BaseModel {
  constructor(data, $store, $t) {
    super(data);
    this.$store = $store;
    this.$t = $t;
    const allLocations = CropFunctions.getAllCurrentLocations(this, $store);
    this.currentLocations = allLocations;

    // fix: for faulty string dates
    let start = this.profile?.duration?.pottingStart;
    if (typeof start === "string") {
      this.profile.duration.pottingStart = parseISO(start);
    }
  }

  get displayName() {
    const { family, name, label } = this;
    const genus = family?.genus?.name || name;
    const species = family?.species?.name || "";
    const cultivar = family?.cultivar?.name || label;
    return `${genus || ""}${species ? " " + species : ""} ${
      cultivar || ""
    }`.trim();
  }

  get isActive() {
    const { profile, status } = this;
    if (profile?.status === "inactive" || status === "inactive") {
      return false;
    }
    const start = profile?.duration?.pottingStart;
    const cultNrOfWeeks = profile?.duration?.pottingWeeks;
    if (start && cultNrOfWeeks) {
      const now = new Date();
      return now < endOfDay(addWeeks(toDate(start), cultNrOfWeeks));
    }
    return true;
  }

  get isInFuture() {
    const { profile } = this;
    const start = profile?.duration?.pottingStart;
    if (!start) return false;
    return isAfter(toDate(start), startOfToday(new Date()));
  }

  get progress() {
    if (this.isInFuture) return 0;

    const { profile } = this;
    const start = profile?.duration?.pottingStart;
    const cultNrOfWeeks = profile?.duration?.pottingWeeks;
    if (start && cultNrOfWeeks) {
      const end = addWeeks(toDate(start), cultNrOfWeeks);
      const total = end.getTime() - toDate(start).getTime();
      const passed = Date.now() - toDate(start).getTime();
      const progress = passed / total;
      return Math.min(1, progress);
    }
    return 0;
  }

  get potDate() {
    const { profile } = this;
    const potDate = profile?.duration?.pottingStart;
    const date = toDate(potDate);
    return date;
  }

  set potDate(value) {
    this.profile = this.profile || {};
    this.profile.duration = this.profile.duration || {};
    this.profile.duration.pottingStart = value;
  }

  get potWeeks() {
    const { profile } = this;
    return profile?.duration?.pottingWeeks;
  }

  get endDate() {
    const { profile } = this;
    const { endDate } = profile?.duration || {};
    if (!endDate) return null;
    return toDate(endDate);
  }

  get expectedDate() {
    const { potDate, potWeeks, endDate } = this;
    if (endDate) return endDate;
    return potDate && potWeeks ? addWeeks(potDate, potWeeks) : null;
  }

  get origEndDate() {
    const { profile } = this;
    const { origEndDate } = profile?.duration || {};
    if (!origEndDate) return null;
    return toDate(origEndDate);
  }

  get flatTaxonomy() {
    const { name, family, label } = this;
    return {
      genus: name,
      species: family?.species?.name,
      // legacy: crop.label used to used as cultivar
      cultivar: family?.cultivar?.name || label
    };
  }

  get genusName() {
    const { family, name } = this;
    return family?.genus?.name || name;
  }

  get speciesName() {
    const { family } = this;
    return family?.species?.name || "";
  }

  get cultivarName() {
    const { family, label } = this;
    return family?.cultivar?.name || label;
  }

  get profilePicture() {
    return this.profile?.profilePicture;
  }

  get potSize() {
    return this.profile?.potSize;
  }

  set potSize(value) {
    this.profile = this.profile || {};
    this.profile.potSize = value;
  }

  get startingAmount() {
    return this.profile?.startingAmount ?? 1;
  }

  get currentAmount() {
    return this.profile?.currentAmount ?? 1;
  }

  get batchNumber() {
    return this.profile.batchNumber ?? "";
  }

  get tray() {
    return this.profile?.tray;
  }

  set tray(value) {
    this.profile = this.profile || {};
    this.profile.tray = value;
  }

  get openField() {
    return this.profile?.container === "open";
  }

  get growcoon() {
    return this.profile?.growcoon;
  }

  get isTrialCrop() {
    return this.profile?.isTrialCrop || false;
  }

  get cropLabel() {
    return (
      this.profile?.label?.type ||
      (this.profile?.isTrialCrop && this.$t
        ? [this.$t("addCrop.cropLabels[0]")]
        : null)
    );
  }

  get pottingStartWeek() {
    const start = this.potDate;
    if (start) {
      return startOfISOWeek(start);
    }
    return null;
  }

  get formattedWeekNr() {
    const { pottingStartWeek } = this;
    if (!pottingStartWeek) return "";

    const week = getISOWeek(pottingStartWeek);
    const fourthOfJanNextYear = new Date(getYear(pottingStartWeek) + 1, 0, 4);
    const year = isWithinInterval(fourthOfJanNextYear, {
      start: startOfISOWeek(pottingStartWeek),
      end: endOfISOWeek(pottingStartWeek)
    })
      ? getYear(pottingStartWeek) + 1
      : getYear(pottingStartWeek);
    return `${("0" + week).slice(-2)}/${year}`;
  }

  get flatLocations() {
    const { currentLocations } = this;
    return [
      currentLocations.businessLocation?.id,
      currentLocations.level1Location?.map(x => x.id),
      currentLocations.level2Locations?.map(x => x.id)
    ]
      .filter(x => x)
      .flat();
  }

  get businessLocation() {
    const { currentLocations } = this;
    return currentLocations.businessLocation;
  }

  get locationLevel1() {
    const { currentLocations } = this;
    return currentLocations.level1Location;
  }

  get locationsLevel2() {
    const { currentLocations } = this;
    return currentLocations.level2Locations;
  }

  get locationName() {
    if (this.locationsLevel2) {
      return this.locationsLevel2.map(l => l.name).join(", ");
    } else if (this.locationLevel1) {
      return this.locationLevel1.map(l => l.name).join(", ");
    } else if (this.businessLocation) {
      return this.businessLocation.name;
    }
    return "";
  }

  get locationLevel() {
    if (this.locationsLevel2?.length) {
      return 2;
    } else if (this.locationLevel1?.length) {
      return 1;
    } else if (this.businessLocation?.length) {
      return 0;
    }
    return -1;
  }

  get cropLine2() {
    return [
      `${this.profile?.potSize || this.profile?.tray || ""}`,
      `${(this.potDate && this.formattedWeekNr) || ""}`
    ]
      .filter(e => e.trim())
      .join(" - ");
  }

  get middleLocationName() {
    const loc = CropFunctions.getActiveLocation(this);
    if (!loc) return "";
    return CropFunctions.getMiddleLocationName(loc, this.$store);
  }

  get longLocationName() {
    const loc = CropFunctions.getActiveLocation(this);
    if (!loc) return "";
    return CropFunctions.getLongLocationName(loc, this.$store);
  }

  get cropLine3() {
    return CropFunctions.genCropLine3(this, this.$store);
  }

  /** @returns {number} */
  get totalSurfaceLevel1() {
    return this.locationLevel1?.length
      ? this.locationLevel1.reduce((memo, val) => {
          // for the total we keep track of all unique level 2 locations
          return memo + (val.surfaceArea || 0);
        }, 0)
      : 0;
  }

  /** @returns {number} */
  get totalSurfaceLevel2() {
    return this.locationsLevel2?.length
      ? this.locationsLevel2.reduce((memo, val) => {
          // for the total we keep track of all unique level 2 locations
          return memo + (val.surfaceArea || 0);
        }, 0)
      : 0;
  }

  getActiveLocationIds() {
    // gets all ids for locations (business, level 1 and level 2)
    // that the crop belongs to
    const ids = [];
    const loc = CropFunctions.getActiveLocation(this);
    if (!loc) return [];
    for (const l of loc.values) {
      ids.push(l.id);
      ids.push(l.parents?.businessLocation?.id);
      ids.push(l.parents?.locationLevel1?.id);
    }
    return ids.filter(id => id);
  }

  getFirstLocationNote(labelText) {
    const { potDate } = this;
    if (!potDate) return null;
    const firstLocation = CropFunctions.getInitialLocation(this);
    const longName = CropFunctions.getLongLocationName(
      firstLocation,
      this.$store
    );
    return {
      note: { text: `${labelText} ${longName}` },
      date: potDate,
      cropId: this.id,
      icon: "seeds",
      schema: "v2",
      type: "readonly"
    };
  }

  static formatCropsOptions(crops) {
    return sortBy(
      crops.map(crop => {
        return {
          // ...crop,
          id: crop.id,
          label: crop.displayName,
          name: crop.displayName,
          secondaryLine: crop.cropLine2,
          tertiaryLine: crop.cropLine3,
          pottingDate: crop.potDate,
          genus: crop.genusName,
          cultivar: crop.cultivarName,
          location: crop.profile?.location || null,
          locations: crop.profile?.locations || null,
          potSize: crop.profile?.potSize || null,
          tray: crop.profile?.tray || null,
          container: crop.profile?.container || null,
          growcoon: crop.profile?.growcoon || null,
          plugType: crop.profile?.plugType || null,
          date: toDate(crop.created || new Date()),
          cropLabel: crop.cropLabel
        };
      }),
      "name"
    );
  }

  static getCropsByLocationIds(crops, locationIds) {
    // taskes an array of location ids (whichever level) and returns
    // all crops that are located at those locations
    const includes = loc => locationIds.includes(loc.id);
    return crops.filter(crop => {
      const root = CropFunctions.getActiveLocation(crop);
      if (!root) return false; // no location set
      return root.values.some(loc => {
        if (includes(loc)) {
          return true;
        }
        if (loc.parents) {
          return Object.values(loc.parents).some(parent => {
            return parent && includes(parent);
          });
        }
      });
    });
  }
}
