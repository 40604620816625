import Vue from "vue";
import { Ability, AbilityBuilder, detectSubjectType } from "@casl/ability";
import { abilitiesPlugin, Can } from "@casl/vue";
import { intersection } from "lodash";

const actionTypeMap = {
  v: "view",
  c: "create",
  e: "edit",
  d: "delete"
};

export const defaultPermissions = () => [
  {
    category: "companyProfile",
    actions: {
      userManagement: {
        v: false,
        c: false,
        e: false,
        d: false
      },
      profile: {
        v: true,
        c: null,
        e: true,
        d: null
      },
      sensorSettings: {
        v: true,
        c: null,
        e: true,
        d: null
      },
      locationSettings: {
        v: true,
        c: null,
        e: true,
        d: null
      },
      exports: {
        v: true,
        c: true,
        e: null,
        d: null
      },
      tasks: {
        v: true,
        c: true,
        e: true,
        d: true
      },
      qrCode: {
        v: true,
        c: true,
        e: null,
        d: null
      },
      noteChecklists: {
        v: true,
        c: true,
        e: true,
        d: true
      },
      tankMixes: {
        v: true,
        c: true,
        e: true,
        d: true
      },
      cropsImport: {
        v: true,
        c: true,
        e: true,
        d: null
      }
    }
  },
  {
    category: "cropNotes",
    actions: {
      all: {
        v: true,
        c: true,
        e: true,
        d: true
      }
    }
  },
  {
    category: "cropProfile",
    actions: {
      all: {
        v: true,
        c: null,
        e: true,
        d: true
      }
    }
  },
  {
    category: "logBook",
    actions: {
      all: {
        v: true,
        c: null,
        e: null,
        d: null
      }
    }
  },
  {
    category: "cropPhotos",
    actions: {
      all: {
        v: true,
        c: true,
        e: true,
        d: true
      }
    }
  },
  {
    category: "cropProtocol",
    actions: {
      all: {
        v: true,
        c: true,
        e: true,
        d: true
      }
    }
  },
  {
    category: "cropSubstrate",
    actions: {
      all: {
        v: true,
        c: true,
        e: true,
        d: true
      }
    }
  },
  {
    category: "compareTool",
    actions: {
      all: {
        v: true,
        c: null,
        e: null,
        d: null
      }
    }
  },
  {
    category: "labAnalyses",
    actions: {
      all: {
        v: true,
        c: true,
        e: true,
        d: true
      }
    }
  },
  {
    category: "sensors",
    actions: {
      all: {
        v: true,
        c: null,
        e: null,
        d: null
      }
    }
  },
  {
    category: "tasks",
    actions: {
      all: {
        v: true,
        c: true,
        e: true,
        d: true
      }
    }
  },
  {
    category: "general",
    actions: {
      documents: {
        v: true,
        c: true,
        e: true,
        d: true
      },
      climateData: {
        v: true,
        c: true,
        e: true,
        d: true
      },
      fertilizationBins: {
        v: true,
        c: true,
        e: true,
        d: true
      },
      labAnalyses: {
        v: true,
        c: true,
        e: true,
        d: true
      },
      advices: {
        v: true,
        c: true,
        e: true,
        d: true
      },
      notes: {
        v: true,
        c: true,
        e: true,
        d: true
      },
      substrates: {
        v: true,
        c: true,
        e: true,
        d: true
      }
    }
  },
  {
    category: "cultivationOverview",
    actions: {
      all: {
        v: true,
        c: true,
        e: true,
        d: null
      }
    }
  },
  {
    category: "addCrop",
    actions: {
      all: {
        v: null,
        c: true,
        e: null,
        d: null
      }
    }
  },
  {
    category: "userProfile",
    actions: {
      all: {
        v: true,
        c: null,
        e: true,
        d: null
      }
    }
  },
  {
    category: "dailySummary",
    actions: {
      all: {
        v: true,
        c: null,
        e: null,
        d: null
      }
    }
  }
];

export const compileRules = ({ type, role }) => {
  const { can, cannot, rules } = new AbilityBuilder();

  const predefinedPermissions = {
    // special cases for some company pages... redundant?
    "crud-locations": ["admin", "advisor"],
    "crud-user-management": ["admin", "advisor"],

    // special case for documents
    "edit-documents-platform": ["advisor", "third-party"],
    "edit-documents-company": ["admin", "advisor"],
    "edit-documents-crop": ["admin", "advisor"],

    // fields in company profile
    "crud-labCustNumber": ["admin", "advisor"],
    "crud-companyLabels": ["admin", "advisor"],
    "crud-pdfWeatherOff": ["admin", "advisor"],
    "crud-pdfWeekOff": ["admin", "advisor"],
    "crud-bulbGrower": ["admin", "advisor"],
    "crud-sensorProvider": ["advisor"],

    "crud-admin-area": ["advisor"],
    "crud-overview-area": ["advisor", "third-party"],

    // related to user roles
    "view-roles": ["advisor", "admin"],
    "create-roles": ["advisor"]
  };

  const types = type?.split("/");
  Object.entries(predefinedPermissions).forEach(
    ([permission, allowedTypes]) => {
      const [action, ...subjectc] = permission.split("-");
      const subject = subjectc.join("-");
      const allowed = !!intersection(allowedTypes, types).length;
      const rule = allowed ? can : cannot;
      rule(action, subject);
    }
  );

  let finalPermissions = defaultPermissions();
  const { permissions: customPermissions } = role || {};
  if (customPermissions) {
    const defaultKeys = defaultPermissions().map(x => x.category);
    const roleKeys = role.permissions.map(x => x.category);
    const mergedKeys = Array.from(new Set(defaultKeys, roleKeys));

    const mergedPermissions = mergedKeys.map(key => {
      return (
        role.permissions.find(x => x.category === key) ||
        defaultPermissions().find(x => x.category === key)
      );
    });
    finalPermissions = mergedPermissions;
  }

  finalPermissions.forEach(permission => {
    const { category, actions } = permission;
    Object.entries(actions).forEach(([action, values]) => {
      Object.entries(values).forEach(([actionType, value]) => {
        // allow all actions if the current user has no role assigned or are of advisor/admin type
        const finValue =
          customPermissions &&
          ["user", "grower", "admin/third-party", "third-party"].includes(type)
            ? value
            : true;
        if (finValue != null) {
          const rule = finValue ? can : cannot;
          const subject = [category, action].join("-");
          rule(actionTypeMap[actionType], subject);
        }
      });
    });
  });

  return rules;
};

export default ({ app }, inject) => {
  const ab = new Ability([], { detectSubjectType });
  inject("ab", ab);

  Vue.use(abilitiesPlugin, ab);
  Vue.component("Can", Can);
};
