<template>
  <div>
    <div
      :style="{ width: '100%', height: '640px' }"
      class="flex justify-center items-center h-full"
    >
      <div class="flex items-center flex-col">
        <div class="text-center mt-4 text-brand-mineShaft text-base">
          <div>
            <client-only>
              <v-icon
                name="exclamation-circle"
                class="text-red-400 mr-2 text-lg"
              /> </client-only
            >An error occurred: {{ error.message }}
          </div>
        </div>
        <div class="mt-8">
          <NuxtLink to="/user/crops/list"
class="font-bold">
            Go to crop overview
          </NuxtLink>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  layout: "dashboard-layout-v2",
  props: ["error"],
};
</script>
