<template>
  <Nuxt />
</template>

<script>
export default {
  mounted() {
    this.toggleOmnichannelWidget(this.$route);
  },
  watch: {
    $route: {
      handler: function(route) {
        this.toggleOmnichannelWidget(route);
      }
    }
  },
  methods: {
    toggleOmnichannelWidget(route) {
      // ensure omnichannel widget is visible only on 'user-crops-list' route
      const shouldDisplayOmnichannelWidget = route.name?.includes("user-crops-list");
      try {
        window.MessageBirdChatWidget[shouldDisplayOmnichannelWidget ? "show" : "hide"]();
      } catch (e) {}
    }
  }
}
</script>