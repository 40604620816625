var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',[_c('nav',{staticClass:"navbar container"},[_c('nuxt-link',{attrs:{"to":_vm.localePath('user-crops-list')}},[_c('div',{staticClass:"brand"},[_c('img',{staticStyle:{"width":"32px","height":"auto"},attrs:{"src":require("assets/images/icons/LogAndSolve_Logo_nur.svg"),"alt":"Log and Solve logo"}}),_vm._v(" "),_c('p',{staticClass:"font-default brand-text hidden lg:block"},[_vm._v(_vm._s(_vm.title))])])]),_vm._v(" "),(!_vm.disabled)?_c('div',{staticClass:"mobile-icons"},[_c('button-component',{attrs:{"v-icon":"sticky-note","text":_vm.$t('dashboard.components.mainNavigation[12].text'),"color":"grey","allowed":_vm.$can('create', 'cropNotes-all')},on:{"click":function () { return _vm.$router.push(_vm.localePath({ name: 'user-crop-form' })); }}}),_vm._v(" "),_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(
          function () { return (_vm.shouldKeepNotifsViewOpen = _vm.$device.isMobile
              ? false
              : _vm.shouldKeepNotifsViewOpen); }
        ),expression:"\n          () =>\n            (shouldKeepNotifsViewOpen = $device.isMobile\n              ? false\n              : shouldKeepNotifsViewOpen)\n        "}],staticClass:"ml-2"},[_c('button-component',{attrs:{"color":_vm.delayedAlerts.filter(function (x) { return !x.isSeen; }).length
              ? 'brand-pinkSalmon'
              : 'brand-manatee',"v-icon":"bell","counter":_vm.delayedAlerts.filter(function (x) { return !x.isSeen; }).length},on:{"click":_vm.toggleNotifsView}}),_vm._v(" "),_c('div',{staticClass:"alerts context-menu",class:{ hidden: !_vm.shouldKeepNotifsViewOpen },on:{"click":function($event){$event.stopPropagation();return (function () {}).apply(null, arguments)}}},[_c('alerts-component',{attrs:{"alerts":_vm.delayedAlerts},on:{"opened":function($event){_vm.shouldKeepNotifsViewOpen = false}}})],1)],1),_vm._v(" "),_c('div',{staticClass:"menu-toggle",on:{"click":function($event){$event.stopPropagation();return _vm.toggleMenu.apply(null, arguments)}}},[_c('svg',{attrs:{"viewBox":"0 0 20 20","xmlns":"http://www.w3.org/2000/svg"}},[_c('title',[_vm._v("\n            "+_vm._s(_vm.$t("dashboard.components.mainNavigation[8].text"))+"\n          ")]),_vm._v(" "),_c('path',{attrs:{"d":"M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"}})])])],1):_vm._e(),_vm._v(" "),_c('client-only',[(!_vm.disabled)?_c('ul',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(function () { return (_vm.shouldKeepMenuOpen = false); }),expression:"() => (shouldKeepMenuOpen = false)"}],staticClass:"navigation-items",class:{ hidden: !_vm.shouldKeepMenuOpen }},[_vm._l((_vm.navigationMenu),function(item){return _c('li',{key:item.name,staticClass:"navigation-item",class:{ 'mobile-only': item.mobileOnly }},[_c('nuxt-link',{staticClass:"navigation-link",class:{ active: item.isActive },attrs:{"to":_vm.localePath(Object.assign({}, {name: item.name},
                (item.query ? { query: item.query } : {})))}},[_c('span',{class:{ 'mr-px': item.icon }},[_vm._v(_vm._s(_vm.$t(item.title)))]),_vm._v(" "),(item.icon)?_c('v-icon',{staticClass:"ml-px delete-icon",attrs:{"name":item.icon}}):_vm._e()],1)],1)}),_vm._v(" "),_c('li',{staticClass:"hide-on-mobile"},[_c('button-component',{attrs:{"text":_vm.$t('dashboard.components.mainNavigation[12].text'),"color":"grey","v-icon":"sticky-note","allowed":_vm.$can('create', 'cropNotes-all')},on:{"click":function () { return _vm.$router.push(_vm.localePath({ name: 'user-crop-form' })); }}})],1),_vm._v(" "),_c('li',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(
            function () { return (_vm.shouldKeepNotifsViewOpen = !_vm.$device.isMobile
                ? false
                : _vm.shouldKeepNotifsViewOpen); }
          ),expression:"\n            () =>\n              (shouldKeepNotifsViewOpen = !$device.isMobile\n                ? false\n                : shouldKeepNotifsViewOpen)\n          "}],staticClass:"hide-on-mobile"},[_c('div',{staticClass:"ml-2"},[_c('button-component',{attrs:{"color":_vm.delayedAlerts.filter(function (x) { return !x.isSeen; }).length
                  ? 'brand-pinkSalmon'
                  : 'brand-manatee',"v-icon":"bell","counter":_vm.alertsCount},on:{"click":_vm.toggleNotifsView}}),_vm._v(" "),_c('div',{staticClass:"context-menu",class:{ hidden: !_vm.shouldKeepNotifsViewOpen },on:{"click":function($event){$event.stopPropagation();return (function () {}).apply(null, arguments)}}},[_c('alerts-component',{attrs:{"alerts":_vm.delayedAlerts},on:{"opened":function($event){_vm.shouldKeepNotifsViewOpen = false}}})],1)],1)]),_vm._v(" "),_c('li',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(function () { return (_vm.shouldKeepContextOpen = false); }),expression:"() => (shouldKeepContextOpen = false)"}],staticClass:"hide-on-mobile",on:{"click":_vm.toggleContextMenu}},[_c('div',{staticClass:"settings-link"},[_c('div',{staticClass:"initials-container"},[_c('p',{staticClass:"initials"},[_vm._v(_vm._s(_vm.initials))])]),_vm._v(" "),_c('div',{staticClass:"name-container"},[_c('p',{staticClass:"name truncate"},[_vm._v("\n                "+_vm._s(_vm.userName)+"\n              ")]),_vm._v(" "),_c('p',{staticClass:"name truncate",class:{
                  'text-brand-pinkSalmon': _vm.$store.state.selectedCompany,
                  'text-brand-mineShaft': !_vm.$store.state.selectedCompany
                }},[_vm._v("\n                "+_vm._s(_vm.companyName || "")+"\n              ")])]),_vm._v(" "),_c('div',{staticClass:"context-menu",class:{ hidden: !_vm.shouldKeepContextOpen },on:{"click":function($event){$event.stopPropagation();return (function () {}).apply(null, arguments)}}},[_c('ul',_vm._l((_vm.contextMenu),function(item,index){return _c('li',{key:index},[_c('nuxt-link',{staticClass:"navigation-link context-link",attrs:{"to":_vm.localePath(Object.assign({}, {name: item.name},
                        (item.query ? { query: item.query } : {})))}},[_vm._v("\n                    "+_vm._s(_vm.$t(item.title))+"\n                  ")])],1)}),0),_vm._v(" "),_c('ul',{directives:[{name:"show",rawName:"v-show",value:(
                  _vm.$can('crud', 'admin-area') || _vm.$can('crud', 'overview-area')
                ),expression:"\n                  $can('crud', 'admin-area') || $can('crud', 'overview-area')\n                "}],staticClass:"mt-3 pt-2 border-t border-brand-ghost"},[_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.$can('crud', 'admin-area')),expression:"$can('crud', 'admin-area')"}]},[_c('nuxt-link',{staticClass:"navigation-link context-link",attrs:{"to":_vm.localePath({
                        name: 'user-admin'
                      })}},[_vm._v("\n                    "+_vm._s(_vm.$t("dashboard.components.mainNavigation[13].text"))+"\n                  ")])],1),_vm._v(" "),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.$can('crud', 'overview-area')),expression:"$can('crud', 'overview-area')"}]},[_c('nuxt-link',{staticClass:"navigation-link context-link",attrs:{"to":_vm.localePath({
                        name: 'user-overview'
                      })}},[_vm._v("\n                    "+_vm._s(_vm.$t("dashboard.components.mainNavigation[14].text"))+"\n                  ")])],1)]),_vm._v(" "),_c('ul',{staticClass:"mt-3 pt-2 border-t border-brand-ghost"},[_c('nuxt-link',{staticClass:"navigation-link context-link",attrs:{"to":_vm.localePath({
                      name: 'sign-out'
                    })}},[_vm._v("\n                  "+_vm._s(_vm.$t("dashboard.components.mainNavigation[11].text"))+"\n                ")])],1)])])])],2):_vm._e()])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }