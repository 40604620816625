import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = {
  anka: {
    normal:
      "https://cdnjs.cloudflare.com/ajax/libs/firacode/5.2.0/ttf/FiraCode-Regular.ttf",
    bold: "https://cdnjs.cloudflare.com/ajax/libs/firacode/5.2.0/ttf/FiraCode-SemiBold.ttf",
    italics:
      "https://raw.githubusercontent.com/Avi-D-coder/fira-mono-italic/master/distr/otf/Fira%20Mono-Regular%20Italic.otf",
    bolditalics:
      "https://cdnjs.cloudflare.com/ajax/libs/firacode/5.2.0/ttf/FiraCode-SemiBold.ttf"
  }
};

import PDFMerger from "pdf-merger-js/browser";

const findSvgs = (object, arr = [], key = "svg") => {
  Object.keys(object).forEach(k => {
    if (k === key) {
      arr.push(object[k]);
    }
    if (object[k] && typeof object[k] === "object") {
      findSvgs(object[k], arr, key);
    }
  });
  return arr;
};

const insertSvgs = (object, arr = [], key = "svg") => {
  Object.keys(object).forEach(k => {
    if (k === key) {
      let path = object[k];
      let svg = arr.find(x => x.path === path);
      object[k] = svg?.xml;
    }
    if (object[k] && typeof object[k] === "object") {
      insertSvgs(object[k], arr, key);
    }
  });
  return object;
};

const getSvgXml = path => {
  return new Promise((resolve, reject) => {
    const alreadySvg = path.includes("<svg");
    const promise = !alreadySvg
      ? fetch(path).then(res => res.text())
      : Promise.resolve(path);
    promise
      .then(data => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(data, "image/svg+xml");
        const svg = doc.querySelector("svg").outerHTML;
        resolve({ path, xml: svg });
      })
      .catch(e => reject(e));
  });
};

export default {
  async downloadDocuments(docs, { filename = "document" }) {
    const docBlobs = await Promise.all(
      docs.map(async doc => {
        const svgs = await Promise.all(
          findSvgs(doc).map(svg => getSvgXml(svg))
        );
        const svgedDoc = insertSvgs(doc, svgs);
        return new Promise(resolve =>
          pdfMake.createPdf(svgedDoc).getBlob(resolve)
        );
      })
    );

    const merger = new PDFMerger();
    for (const docBlob of docBlobs) {
      await merger.add(docBlob);
    }

    const mergedPdfBlob = await merger.saveAsBlob();
    const downloadUrl = URL.createObjectURL(mergedPdfBlob);

    const link = document.createElement("a");
    link.href = downloadUrl;
    link.download = `${filename}.pdf`;
    link.innerHTML = "";
    link.dispatchEvent(
      new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window
      })
    );
    setTimeout(() => {
      URL.revokeObjectURL(downloadUrl);
      link.remove();
    }, 100);
  },
  createPdf(doc) {
    return pdfMake.createPdf(doc);
  }
};
