import allOptions from "./crop-form-options";
import getPotOptions from "./add-crop-config";

const genOptions = (options, root, $t) => {
  return options
    .filter(({ active }) => active)
    .reduce((acc, { id }) => {
      const item = {
        id,
        name: $t(`${root}.${id}`)
      };
      if (!acc.some(x => x.id === id)) acc.push(item);
      return acc;
    }, []);
};

export const defaultForm = ($t, settings, extraConfig = {}) => {
  const { hasDrippers = false, countries = [] } = extraConfig;
  const photoLabels = (settings?.photoLabels || []).reduce(
    (acc, x) => ({ ...acc, [x]: { value: x } }),
    {}
  );
  const allPhotoLabels = Object.assign(
    {},
    $t("dashboard.components.uploadPhoto.options"),
    photoLabels
  );

  return {
    title: $t("cropForm.logTask"),
    root: true,
    component: "branch-picker",
    selector: "branch",
    options: [
      {
        id: "cultivationActivity",
        label: $t("cropForm.cultivationActivity"),
        to: {
          title: $t("cropForm.pick"),
          clearonback: true,
          multistep: [
            {
              component: "select-task",
              selector: "action.type",
              required: true,
              options: [
                {
                  id: "pruning",
                  label: $t("cropForm.pruning"),
                  to: {
                    stepTitle: $t("cropForm.whatObserve"),
                    component: "add-note",
                    selector: "note",
                    hasBreadcrumbs: true
                  }
                },
                {
                  id: "budding",
                  label: $t("cropForm.budding"),
                  to: {
                    stepTitle: $t("cropForm.whatObserve"),
                    component: "add-note",
                    selector: "note",
                    hasBreadcrumbs: true
                  }
                },
                {
                  id: "widening",
                  label: $t("cropForm.widening"),
                  to: {
                    stepTitle: $t("cropForm.whatObserve"),
                    component: "add-note",
                    selector: "note",
                    hasBreadcrumbs: true
                  }
                },
                {
                  id: "sorting",
                  label: $t("cropForm.sorting"),
                  to: {
                    stepTitle: $t("cropForm.whatObserve"),
                    component: "add-note",
                    selector: "note",
                    hasBreadcrumbs: true
                  },
                  optional: true
                },
                {
                  id: "moveCultivationBatch",
                  label: $t("cropForm.moveCultivationBatch"),
                  to: {
                    stepTitle: $t("cropForm.whatObserve"),
                    component: "change-location",
                    selectors: {
                      value: "action.fields",
                      note: "note"
                    },
                    hasBreadcrumbs: true
                  }
                },
                {
                  id: "changePotsize",
                  label: $t("cropForm.changePotsize"),
                  to: {
                    stepTitle: "What's the new potsize?",
                    component: "selectContainer",
                    selectors: {
                      value: "action.fields.newContainer"
                    },
                    requiredField: "either",
                    options: getPotOptions(countries).map(option => ({
                      id: option.slug,
                      name: option.name
                    })),
                    next: {
                      component: "add-note",
                      stepTitle: $t("cropForm.whatObserve"),
                      selector: "note",
                      hasAddPhoto: true,
                      required: false,
                      photoTypeOptions: allPhotoLabels,
                      hasBreadcrumbs: true
                    }
                  }
                },
                {
                  id: "miscellaneous",
                  label: $t("cropForm.miscellaneous"),
                  to: {
                    stepTitle: $t("cropForm.whatObserve"),
                    component: "add-note",
                    required: true,
                    selector: "note",
                    hasBreadcrumbs: true
                  }
                },
                {
                  id: "rotate",
                  label: $t("cropForm.rotate"),
                  optional: true,
                  to: {
                    stepTitle: $t("cropForm.whatObserve"),
                    component: "add-note",
                    required: false,
                    selector: "note",
                    hasBreadcrumbs: true
                  }
                },
                {
                  id: "cutting",
                  label: $t("cropForm.cutting"),
                  optional: true,
                  to: {
                    stepTitle: $t("cropForm.whatObserve"),
                    component: "add-note",
                    required: false,
                    selector: "note",
                    hasBreadcrumbs: true
                  }
                },
                {
                  id: "cuttingLeaf",
                  label: $t("cropForm.cuttingLeaf"),
                  optional: true,
                  to: {
                    stepTitle: $t("cropForm.whatObserve"),
                    component: "add-note",
                    required: false,
                    selector: "note",
                    hasBreadcrumbs: true
                  }
                },
                {
                  id: "layFoil",
                  label: $t("cropForm.layFoil"),
                  optional: true,
                  to: {
                    stepTitle: $t("cropForm.whatObserve"),
                    component: "add-note",
                    required: false,
                    selector: "note",
                    hasBreadcrumbs: true
                  }
                },
                {
                  id: "removeFoil",
                  label: $t("cropForm.removeFoil"),
                  optional: true,
                  to: {
                    stepTitle: $t("cropForm.whatObserve"),
                    component: "add-note",
                    required: false,
                    selector: "note",
                    hasBreadcrumbs: true
                  }
                },
                {
                  id: "harvest",
                  label: $t("cropForm.harvest"),
                  optional: true,
                  to: {
                    stepTitle: $t("cropForm.whatObserve"),
                    component: "add-note",
                    required: false,
                    selector: "note",
                    hasBreadcrumbs: true
                  }
                },
                {
                  id: "lower",
                  label: $t("cropForm.lower"),
                  optional: true,
                  to: {
                    stepTitle: $t("cropForm.whatObserve"),
                    component: "add-note",
                    required: false,
                    selector: "note",
                    hasBreadcrumbs: true
                  }
                },
                {
                  id: "settle",
                  label: $t("cropForm.settle"),
                  optional: true,
                  to: {
                    stepTitle: $t("cropForm.whatObserve"),
                    component: "add-note",
                    required: false,
                    selector: "note",
                    hasBreadcrumbs: true
                  }
                },
                {
                  id: "removeLeafs",
                  label: $t("cropForm.removeLeafs"),
                  optional: true,
                  to: {
                    stepTitle: $t("cropForm.whatObserve"),
                    component: "add-note",
                    required: false,
                    selector: "note",
                    hasBreadcrumbs: true
                  }
                },
                {
                  id: "bendIn",
                  label: $t("cropForm.bendIn"),
                  optional: true,
                  to: {
                    stepTitle: $t("cropForm.whatObserve"),
                    component: "add-note",
                    required: false,
                    selector: "note",
                    hasBreadcrumbs: true
                  }
                },
                {
                  id: "placeSticks",
                  label: $t("cropForm.placeSticks"),
                  optional: true,
                  to: {
                    stepTitle: $t("cropForm.whatObserve"),
                    component: "add-note",
                    required: false,
                    selector: "note",
                    hasBreadcrumbs: true
                  }
                },
                {
                  id: "siftingThrough",
                  label: $t("cropForm.siftingThrough"),
                  optional: true,
                  to: {
                    stepTitle: $t("cropForm.whatObserve"),
                    component: "add-note",
                    required: false,
                    selector: "note",
                    hasBreadcrumbs: true
                  }
                },
                {
                  id: "pollinate",
                  label: $t("cropForm.pollinate"),
                  optional: true,
                  to: {
                    stepTitle: $t("cropForm.whatObserve"),
                    component: "add-note",
                    required: false,
                    selector: "note",
                    hasBreadcrumbs: true
                  }
                },
                {
                  id: "binding",
                  label: $t("cropForm.binding"),
                  optional: true,
                  to: {
                    stepTitle: $t("cropForm.whatObserve"),
                    component: "add-note",
                    required: false,
                    selector: "note",
                    hasBreadcrumbs: true
                  }
                },
                {
                  id: "weeding",
                  label: $t("cropForm.weeding"),
                  optional: true,
                  to: {
                    stepTitle: $t("cropForm.whatObserve"),
                    component: "add-note",
                    required: false,
                    selector: "note",
                    hasBreadcrumbs: true
                  }
                },
                {
                  id: "deflower",
                  label: $t("cropForm.deflower"),
                  optional: true,
                  to: {
                    stepTitle: $t("cropForm.whatObserve"),
                    component: "add-note",
                    required: false,
                    selector: "note",
                    hasBreadcrumbs: true
                  }
                },
                {
                  id: "removeTendrils",
                  label: $t("cropForm.removeTendrils"),
                  optional: true,
                  to: {
                    stepTitle: $t("cropForm.whatObserve"),
                    component: "add-note",
                    required: false,
                    selector: "note",
                    hasBreadcrumbs: true
                  }
                },
                {
                  id: "placeCatchCards",
                  label: $t("cropForm.placeCatchCards"),
                  optional: true,
                  to: {
                    stepTitle: $t("cropForm.whatObserve"),
                    component: "add-note",
                    required: false,
                    selector: "note",
                    hasBreadcrumbs: true
                  }
                },
                {
                  id: "tendrils",
                  label: $t("cropForm.tendrils"),
                  optional: true,
                  to: {
                    stepTitle: $t("cropForm.whatObserve"),
                    component: "add-note",
                    required: false,
                    selector: "note",
                    hasBreadcrumbs: true
                  }
                },
                {
                  id: "raiseTheGauze",
                  label: $t("cropForm.raiseTheGauze"),
                  optional: true,
                  to: {
                    stepTitle: $t("cropForm.whatObserve"),
                    component: "add-note",
                    required: false,
                    selector: "note",
                    hasBreadcrumbs: true
                  }
                },
                {
                  id: "cropMaintenance",
                  label: $t("cropForm.cropMaintenance"),
                  optional: true,
                  to: {
                    stepTitle: $t("cropForm.whatObserve"),
                    component: "add-note",
                    required: false,
                    selector: "note",
                    hasBreadcrumbs: true
                  }
                },
                {
                  id: "removeBuds",
                  label: $t("cropForm.removeBuds"),
                  optional: true,
                  to: {
                    stepTitle: $t("cropForm.whatObserve"),
                    component: "add-note",
                    required: false,
                    selector: "note",
                    hasBreadcrumbs: true
                  }
                },
                {
                  id: "potting",
                  label: $t("cropForm.potting"),
                  optional: true,
                  to: {
                    title: $t("cropForm.potting"),
                    multistep: [
                      {
                        component: "number-input",
                        selector: "action.value",
                        displayUnit: $t("cropForm.pottingUnit"),
                        required: false
                      },
                      {
                        component: "add-note",
                        selector: "note",
                        hasAddPhoto: false,
                        required: false
                      }
                    ]
                  }
                },
                {
                  id: "deliverFinishedPlants",
                  label: $t("cropForm.deliverFinishedPlants"),
                  optional: true,
                  to: {
                    title: $t("cropForm.deliverFinishedPlants"),
                    multistep: [
                      {
                        component: "number-input",
                        selector: "action.value",
                        displayUnit: $t("cropForm.deliverFinishedPlantsUnit"),
                        required: false
                      },
                      {
                        component: "add-note",
                        selector: "note",
                        hasAddPhoto: false,
                        required: false
                      }
                    ]
                  }
                },
                {
                  id: "sow",
                  label: $t("cropForm.sow"),
                  optional: true,
                  to: {
                    stepTitle: $t("cropForm.whatObserve"),
                    component: "add-note",
                    required: false,
                    selector: "note",
                    hasBreadcrumbs: true
                  }
                },
                {
                  id: "makeCuttings",
                  label: $t("cropForm.makeCuttings"),
                  optional: true,
                  to: {
                    stepTitle: $t("cropForm.whatObserve"),
                    component: "add-note",
                    required: false,
                    selector: "note",
                    hasBreadcrumbs: true
                  }
                },
                {
                  id: "technicalService",
                  label: $t("cropForm.technicalService"),
                  optional: true,
                  to: {
                    stepTitle: $t("cropForm.whatObserve"),
                    component: "add-note",
                    required: false,
                    selector: "note",
                    hasBreadcrumbs: true
                  }
                }
              ].filter(opt =>
                settings?.cultivationActivity?.length
                  ? settings.cultivationActivity.includes(opt.id) ||
                    settings.cultivationActivity.includes("all")
                  : !opt.optional
              )
            },
            {
              stepTitle: $t("noteChecklists.selectChecklist"),
              component: "select-checklist",
              selector: "checklist",
              forBranch: "cultivationActivity",
              required: false
            }
          ]
        }
      },
      {
        id: "measurement",
        label: $t("cropForm.measurement"),
        to: {
          title: $t("cropForm.whatMeasurement"),
          clearonback: true,
          multistep: [
            {
              component: "branch-picker",
              selector: "measurement.type",
              required: true,
              options: [
                {
                  id: "ec",
                  label: $t("cropForm.measurementEC"),
                  to: {
                    title: $t("cropForm.addECMeasurement"),
                    multistep: [
                      {
                        selectors: {
                          value: "measurement.value",
                          type: "measurement.fields.sourceType"
                        },
                        component: "multipleMeasurementTypes",
                        required: true,
                        options: [
                          {
                            id: "substrate",
                            label: $t("cropForm.substrate")
                          },
                          {
                            id: "drain",
                            label: $t("cropForm.drain")
                          },
                          {
                            id: "gift",
                            label: $t("cropForm.gift")
                          },
                          {
                            id: "ditch",
                            label: $t("cropForm.ditch")
                          }
                        ]
                      },
                      {
                        component: "add-note",
                        selector: "note",
                        hasAddPhoto: false,
                        required: false
                      }
                    ],
                    restartable: true
                  },
                  checklistable: true
                },
                {
                  id: "ph",
                  label: $t("cropForm.measurementpH"),
                  to: {
                    title: $t("cropForm.addPH"),
                    multistep: [
                      {
                        selectors: {
                          value: "measurement.value",
                          type: "measurement.fields.sourceType"
                        },
                        component: "multipleMeasurementTypes",
                        required: true,
                        options: [
                          {
                            id: "substrate",
                            label: $t("cropForm.substrate")
                          },
                          {
                            id: "drain",
                            label: $t("cropForm.drain")
                          },
                          {
                            id: "gift",
                            label: $t("cropForm.gift")
                          },
                          {
                            id: "ditch",
                            label: $t("cropForm.ditch")
                          }
                        ]
                      },
                      {
                        component: "add-note",
                        selector: "note",
                        hasAddPhoto: false,
                        required: false
                      }
                    ],
                    restartable: true
                  },
                  checklistable: true
                },
                {
                  id: "length",
                  label: $t("cropForm.length"),
                  to: {
                    title: $t("cropForm.addLength"),
                    multistep: [
                      {
                        selector: "measurement.value",
                        extraValues: {
                          "measurement.unit": "cm"
                        },
                        component: "number-input",
                        displayUnit: "cm",
                        required: true
                      },
                      {
                        component: "add-note",
                        selector: "note",
                        hasAddPhoto: false,
                        required: false
                      }
                    ],
                    restartable: true
                  }
                },
                {
                  id: "diameter",
                  label: $t("cropForm.diameter"),
                  to: {
                    title: $t("cropForm.addDiameter"),
                    multistep: [
                      {
                        selector: "measurement.value",
                        unit: "cm",
                        extraValues: {
                          "measurement.unit": "cm"
                        },
                        component: "number-input",
                        displayUnit: "cm",
                        required: true
                      },
                      {
                        component: "add-note",
                        selector: "note",
                        hasAddPhoto: false,
                        required: false
                      }
                    ],
                    restartable: true
                  }
                },
                {
                  id: "numberOfBuds",
                  label: $t("cropForm.numberOfBuds"),
                  to: {
                    title: $t("cropForm.addBuds"),
                    multistep: [
                      {
                        selector: "measurement.value",
                        component: "number-input",
                        required: true
                      },
                      {
                        component: "add-note",
                        selector: "note",
                        hasAddPhoto: false,
                        required: false
                      }
                    ],
                    restartable: true
                  }
                },
                {
                  id: "weight",
                  label: $t("cropForm.weight"),
                  to: {
                    title: $t("cropForm.addWeight"),
                    multistep: [
                      {
                        selectors: {
                          value: "measurement.value",
                          type: "measurement.unit"
                        },
                        component: "multipleMeasurementTypes",
                        required: true,
                        showUnit: true,
                        options: [
                          {
                            id: "kg",
                            label: "kg"
                          },
                          {
                            id: "gr",
                            label: "gr"
                          }
                        ]
                      },
                      {
                        component: "add-note",
                        selector: "note",
                        hasAddPhoto: false,
                        required: false
                      }
                    ],
                    restartable: true
                  }
                },
                {
                  id: "yield",
                  label: $t("cropForm.yield"),
                  to: {
                    title: $t("cropForm.addYield"),
                    multistep: [
                      {
                        selectors: {
                          value: "measurement.value",
                          type: "measurement.unit"
                        },
                        component: "multipleMeasurementTypes",
                        required: true,
                        showUnit: true,
                        options: [
                          {
                            id: "kg",
                            label: "kg"
                          },
                          {
                            id: "gr",
                            label: "gr"
                          },
                          {
                            id: "units",
                            label: $t("cropForm.units").toLowerCase()
                          }
                        ]
                      },
                      {
                        component: "add-note",
                        selector: "note",
                        hasAddPhoto: false,
                        required: false
                      }
                    ],
                    restartable: true
                  }
                },
                {
                  id: "brix",
                  label: $t("cropForm.brix"),
                  to: {
                    title: $t("cropForm.addBrix"),
                    multistep: [
                      {
                        selector: "measurement.value",
                        component: "number-input",
                        displayUnit: $t("cropForm.nr"),
                        required: true
                      },
                      {
                        component: "add-note",
                        selector: "note",
                        hasAddPhoto: false,
                        required: false
                      }
                    ],
                    restartable: true
                  }
                },
                {
                  id: "numberOfRunners",
                  label: $t("cropForm.numberOfRunners"),
                  to: {
                    title: $t("cropForm.addNumberOfRunners"),
                    multistep: [
                      {
                        selector: "measurement.value",
                        component: "number-input",
                        displayUnit: $t("cropForm.nr"),
                        required: true
                      },
                      {
                        component: "add-note",
                        selector: "note",
                        hasAddPhoto: false,
                        required: false
                      }
                    ],
                    restartable: true
                  }
                },
                {
                  id: "numberOfCrowns",
                  label: $t("cropForm.numberOfCrowns"),
                  to: {
                    title: $t("cropForm.addNumberOfCrowns"),
                    multistep: [
                      {
                        selector: "measurement.value",
                        component: "number-input",
                        displayUnit: $t("cropForm.nr"),
                        required: true
                      },
                      {
                        component: "add-note",
                        selector: "note",
                        hasAddPhoto: false,
                        required: false
                      }
                    ],
                    restartable: true
                  }
                },
                {
                  id: "numberOfFlowers",
                  label: $t("cropForm.numberOfFlowers"),
                  to: {
                    title: $t("cropForm.addNumberOfFlowers"),
                    multistep: [
                      {
                        selector: "measurement.value",
                        component: "number-input",
                        displayUnit: $t("cropForm.nr"),
                        required: true
                      },
                      {
                        component: "add-note",
                        selector: "note",
                        hasAddPhoto: false,
                        required: false
                      }
                    ],
                    restartable: true
                  }
                },
              ].filter(opt =>
                settings?.measurement?.length
                  ? settings.measurement.includes(opt.id) ||
                    settings.measurement.includes("all")
                  : !opt.optional
              )
            },
            {
              stepTitle: $t("noteChecklists.selectChecklist"),
              component: "select-checklist",
              selector: "checklist",
              forBranch: "measurement",
              required: false
            }
          ]
        }
      },
      {
        id: "observation",
        label: $t("cropForm.observation"),
        to: {
          title: $t("cropForm.whatObservation"),
          clearonback: true,
          multistep: [
            {
              component: "select-task",
              selector: "observation.type",
              required: true,
              options: [
                {
                  id: "fungus",
                  label: $t("cropForm.fungus.label"),
                  to: {
                    title: $t("cropForm.selectFungi"),
                    multistep: [
                      {
                        selector: "observation.value",
                        component: "select-option",
                        options: genOptions(
                          allOptions.fungus,
                          "cropForm.fungus.options",
                          $t
                        ),
                        required: true
                      },
                      {
                        component: "intensity-picker",
                        selector: "observation.fields.intensity",
                        required: false
                      },
                      {
                        component: "add-note",
                        stepTitle: $t("cropForm.whatObserve"),
                        selector: "note",
                        hasAddPhoto: true,
                        hasSelectPhotoType: true,
                        requiredField: "either",
                        photoTypeOptions: allPhotoLabels,
                        hasBreadcrumbs: true
                      }
                    ],
                    hasBreadcrumbs: true,
                    restartable: true
                  },
                  checklistable: true
                },
                {
                  id: "plague",
                  label: $t("cropForm.plague.label"),
                  to: {
                    title: $t("cropForm.selectPlague"),
                    multistep: [
                      {
                        selector: "observation.value",
                        component: "select-option",
                        options: genOptions(
                          allOptions.plague,
                          "cropForm.plague.options",
                          $t
                        ),
                        required: true
                      },
                      {
                        component: "intensity-picker",
                        selector: "observation.fields.intensity",
                        required: false
                      },
                      {
                        component: "number-input",
                        displayUnit: $t("notesFeed.units.pieces"),
                        selector: "observation.fields.counting",
                        required: false
                      },
                      {
                        component: "add-note",
                        stepTitle: $t("cropForm.whatObserve"),
                        selector: "note",
                        hasAddPhoto: true,
                        hasSelectPhotoType: true,
                        requiredField: "either",
                        photoTypeOptions: allPhotoLabels,
                        hasBreadcrumbs: true
                      }
                    ],
                    hasBreadcrumbs: true,
                    restartable: true
                  },
                  checklistable: true
                },
                {
                  id: "flowering",
                  label: $t("cropForm.flowering"),
                  to: {
                    component: "add-note",
                    stepTitle: $t("cropForm.whatObserve"),
                    selector: "note",
                    hasAddPhoto: true,
                    requiredField: "either",
                    photoTypeOptions: allPhotoLabels,
                    hasBreadcrumbs: true
                  }
                },
                {
                  id: "leafObservation",
                  label: $t("cropForm.leafObservation"),
                  to: {
                    title: $t("cropForm.leafObservation"),
                    selector: "observation.type", // overwrites the above temporary value
                    component: "select-task",
                    clearonback: true,
                    options: [
                      {
                        id: "defectPhenomenon",
                        label: $t("cropForm.defectPhenomenon.label"),
                        to: {
                          title: $t("cropForm.defectPhenomenonIn"),
                          selector: "observation.fields.leavesType",
                          component: "select-task",
                          clearonback: true,
                          options: [
                            {
                              id: "youngLeaves",
                              label: $t("cropForm.youngLeaves")
                            },
                            { id: "oldLeaves", label: $t("cropForm.oldLeaves") }
                          ],
                          next: {
                            title: $t("cropForm.selectDefect"),
                            multistep: [
                              {
                                selector: "observation.value",
                                component: "select-option",
                                options: genOptions(
                                  allOptions.defectPhenomenon,
                                  "cropForm.defectPhenomenon.options",
                                  $t
                                ),
                                required: true
                              },
                              {
                                component: "add-note",
                                stepTitle: $t("cropForm.whatObserve"),
                                selector: "note",
                                hasAddPhoto: true,
                                requiredField: "either",
                                photoTypeOptions: allPhotoLabels,
                                hasBreadcrumbs: true
                              }
                            ],
                            hasBreadcrumbs: true
                          }
                        }
                      },
                      {
                        id: "leafBurn",
                        label: $t("cropForm.leafBurn"),
                        to: {
                          component: "add-note",
                          stepTitle: $t("cropForm.whatObserve"),
                          selector: "note",
                          hasAddPhoto: true,
                          requiredField: "either",
                          photoTypeOptions: allPhotoLabels,
                          hasBreadcrumbs: true
                        }
                      },
                      {
                        id: "color",
                        label: $t("cropForm.color"),
                        to: {
                          component: "add-note",
                          stepTitle: $t("cropForm.whatObserve"),
                          selector: "note",
                          hasAddPhoto: true,
                          requiredField: "either",
                          photoTypeOptions: allPhotoLabels,
                          hasBreadcrumbs: true
                        }
                      },
                      {
                        id: "LeafTips",
                        label: $t("cropForm.LeafTips"),
                        to: {
                          component: "add-note",
                          stepTitle: $t("cropForm.whatObserve"),
                          selector: "note",
                          hasAddPhoto: true,
                          requiredField: "either",
                          photoTypeOptions: allPhotoLabels,
                          hasBreadcrumbs: true
                        }
                      }
                    ]
                  }
                },
                {
                  id: "failure",
                  label: $t("cropForm.failure"),
                  to: {
                    title: $t("cropForm.failure"),
                    multistep: [
                      {
                        component: "number-input",
                        displayUnit: $t("notesFeed.units.pieces"),
                        selector: "observation.fields.failureUnits",
                        required: true
                      },
                      {
                        component: "add-note",
                        stepTitle: $t("cropForm.whatObserve"),
                        selector: "note",
                        hasAddPhoto: true,
                        requiredField: "either",
                        photoTypeOptions: allPhotoLabels,
                        hasBreadcrumbs: true
                      }
                    ]
                  }
                },
                {
                  id: "rating",
                  label: $t("cropForm.rating.label"),
                  to: {
                    title: $t("cropForm.rating.label"),
                    multistep: [
                      {
                        component: "rating-picker",
                        selectors: {
                          value: "observation.fields.ratingValue",
                          item: "observation.fields.ratingItem"
                        },
                        required: true,
                        options: genOptions(
                          allOptions.rating,
                          "cropForm.rating.options",
                          $t
                        ).filter(opt =>
                          settings?.observation?.rating?.length
                            ? settings.observation.rating.includes(opt.id) ||
                              settings.observation.rating.includes("all")
                            : !opt.optional
                        )
                      },
                      {
                        component: "number-input",
                        selector: "observation.fields.floridity",
                        displayUnit: $t("cropForm.floridityUnit"),
                        required: false,
                        selectorConditions: {
                          "observation.fields.ratingItem": "floridity"
                        }
                      },
                      {
                        component: "add-note",
                        stepTitle: $t("cropForm.whatObserve"),
                        selector: "note",
                        hasAddPhoto: true,
                        requiredField: "either",
                        photoTypeOptions: allPhotoLabels,
                        hasBreadcrumbs: true
                      }
                    ],
                    restartable: true
                  },
                  checklistable: true
                },
                {
                  id: "miscellaneous",
                  label: $t("cropForm.miscellaneous"),
                  to: {
                    component: "add-note",
                    stepTitle: $t("cropForm.whatObserve"),
                    selector: "note",
                    hasAddPhoto: true,
                    required: true,
                    requiredField: "either",
                    photoTypeOptions: allPhotoLabels,
                    hasBreadcrumbs: true
                  }
                }
              ]
            },
            {
              stepTitle: $t("noteChecklists.selectChecklist"),
              component: "select-checklist",
              selector: "checklist",
              forBranch: "observation",
              required: false
            }
          ]
        }
      },
      {
        id: "waterAndFertilization",
        label: $t("cropForm.waterAndFertilization"),
        to: {
          title: $t("cropForm.waterAndFertilization"),
          clearonback: true,
          multistep: [
            {
              component: "select-task",
              selector: "action.type",
              required: true,
              options: [
                {
                  id: "fertilizationEc",
                  label: $t("cropForm.fertilizationEc"),
                  to: {
                    title: $t("cropForm.addEC"),
                    multistep: [
                      {
                        component: "number-input",
                        selector: "action.value",
                        required: true
                      },
                      {
                        component: "add-note",
                        selector: "note",
                        hasAddPhoto: false,
                        required: false
                      }
                    ],
                    restartable: true
                  }
                },
                {
                  id: "fertilization-Water",
                  label: $t("cropForm.watering"),
                  to: {
                    title: $t("cropForm.addWaterMeasurement"),
                    multistep: [
                      {
                        component: "controlMeasurement",
                        selectors: {
                          number: "action.value",
                          unit: "action.unit"
                        },
                        separateUnitField: true,
                        productOptions: [
                          {
                            id: "value",
                            label: "",
                            units: [
                              "min",
                              "CC / m2",
                              "L / m2",
                              hasDrippers ? "CC / dripper" : null
                            ].filter(x => x),
                            defaultUnit: hasDrippers
                              ? settings?.wateringUnit || "min"
                              : "min"
                          }
                        ],
                        required: true
                      },
                      {
                        component: "add-note",
                        selector: "note",
                        hasAddPhoto: false,
                        required: false
                      }
                    ],
                    restartable: true
                  }
                },
                {
                  id: "fertilization-fertilization",
                  label: $t("cropForm.fertilization.label"),
                  to: {
                    title: $t("cropForm.whichFertilizationCategory"),
                    selector: "action.fields.fertilizationType",
                    component: "select-task",
                    clearonback: true,
                    options: [
                      {
                        id: "spreadible",
                        label: $t("cropForm.fertilization.spreadible.label"),
                        to: {
                          title: $t("cropForm.fertilization.spreadible.title"),
                          component: "select-option",
                          hasTextInput: true,
                          selector: "action.product.name",
                          options: genOptions(
                            allOptions.fertilizationSpreadable,
                            "cropForm.fertilization.spreadible.options",
                            $t
                          ).sort((a, b) =>
                            a.name
                              .toLowerCase()
                              .localeCompare(b.name.toLowerCase())
                          ),
                          required: true,
                          next: {
                            label: $t("cropForm.tankage.label"),
                            component: "controlMeasurement",
                            selectors: {
                              numberOf:
                                "action.product.applied.fields.numberOfUnits",
                              kgPer: "action.product.applied.fields.kgPerUnit",
                              totalApplied: "action.product.applied.total"
                            },
                            productOptions: [
                              {
                                id: "numberOf",
                                label: $t("cropForm.productAmount.numberOf")
                              },
                              {
                                id: "kgPer",
                                label: $t("cropForm.productAmount.kgPer")
                              },
                              {
                                id: "totalApplied",
                                unit: "kg",
                                label: $t(
                                  "cropForm.productAmount.totalAppliedFertilizer"
                                )
                              }
                            ],
                            next: {
                              component: "add-note",
                              stepTitle: $t("cropForm.whatObserve"),
                              selector: "note",
                              hasAddPhoto: true,
                              required: false,
                              photoTypeOptions: allPhotoLabels,
                              hasBreadcrumbs: true,
                              restartable: true
                            }
                          }
                        }
                      },
                      {
                        id: "foliar",
                        label: $t("cropForm.fertilization.foliar.label"),
                        to: {
                          hasBreadcrumbs: true,
                          multistep: [
                            {
                              selector: "action.fields.fertilizationType", // needs to read tank mixes, will not mutate
                              component: "select-tank-mix"
                            },
                            {
                              label: $t("cropForm.productAmount.label"),
                              component: "controlMeasurement",
                              required: true,
                              selectorConditions: {
                                "action.multiproduct.tankMixId": true
                              },
                              selectors: {
                                tankage: "action.multiproduct.tankage"
                              },
                              productOptions: [
                                {
                                  id: "tankage",
                                  label: $t(
                                    "cropForm.productAmount.tankageTotal"
                                  ),
                                  unit: "l"
                                }
                              ]
                            }
                          ],
                          next: {
                            id: "multiproductitem",
                            repeatable: [
                              "action.product",
                              "action.multiproduct.products"
                            ],
                            multistep: [
                              {
                                title: $t(
                                  "cropForm.fertilization.foliar.title"
                                ),
                                component: "select-option",
                                hasTextInput: true,
                                selector: "action.product.name",
                                requiredField: "either",
                                options: genOptions(
                                  allOptions.fertilizationFoliar,
                                  "cropForm.fertilization.foliar.options",
                                  $t
                                ).sort((a, b) =>
                                  a.name
                                    .toLowerCase()
                                    .localeCompare(b.name.toLowerCase())
                                ),
                                required: true
                              },
                              {
                                label: $t("cropForm.productAmount.label"),
                                component: "controlMeasurement",
                                selectors: {
                                  concentration:
                                    "action.product.applied.fields.concentration",
                                  totalApplied: "action.product.applied.total"
                                },
                                productOptions: [
                                  {
                                    id: "concentration",
                                    label: $t(
                                      "cropForm.productAmount.concentrationTotal"
                                    ),
                                    units: [
                                      "gram / 100 liters",
                                      "ml / 100 liters",
                                      "%"
                                    ]
                                  },
                                  {
                                    id: "totalApplied",
                                    label: $t(
                                      "cropForm.productAmount.amountAgent"
                                    )
                                  }
                                ]
                              }
                            ],
                            next: {
                              component: "add-note",
                              stepTitle: $t("cropForm.whatObserve"),
                              selector: "note",
                              hasAddPhoto: true,
                              required: false,
                              photoTypeOptions: allPhotoLabels,
                              hasBreadcrumbs: true,
                              restartable: true
                            }
                          }
                        }
                      }
                    ]
                  }
                },
                {
                  id: "drainage",
                  label: $t("cropForm.drainage"),
                  to: {
                    title: $t("cropForm.drainage"),
                    multistep: [
                      {
                        label: "",
                        component: "controlMeasurement",
                        required: true,
                        selectors: {
                          number: "action.value",
                          unit: "action.unit"
                        },
                        separateUnitField: true,
                        productOptions: [
                          {
                            id: "value",
                            label: "",
                            units: ["L", "CC", "%"],
                            defaultUnit: "CC"
                          }
                        ],
                        required: true
                      },
                      {
                        component: "add-note",
                        selector: "note",
                        hasAddPhoto: false,
                        required: false
                      }
                    ],
                    restartable: true
                  }
                }
              ]
            },
            {
              stepTitle: $t("noteChecklists.selectChecklist"),
              component: "select-checklist",
              selector: "checklist",
              forBranch: "waterAndFertilization",
              required: false
            }
          ]
        }
      },
      {
        id: "cropControl",
        label: $t("cropForm.cropControl"),
        to: {
          title: $t("cropForm.whichCropControl"),
          clearonback: true,
          multistep: [
            {
              component: "branch-picker",
              selector: "action.type",
              required: true,
              options: [
                {
                  id: "chemicalProtection",
                  hidden: countries.includes("nl"),
                  label: $t("cropForm.chemicalProtection.label"),
                  to: {
                    hasBreadcrumbs: true,
                    multistep: [
                      {
                        selector: "action.type", // needs to read tank mixes, will not mutate
                        component: "select-tank-mix"
                      },
                      {
                        label: $t("cropForm.productAmount.label"),
                        component: "controlMeasurement",
                        required: true,
                        selectorConditions: {
                          "action.multiproduct.tankMixId": true
                        },
                        selectors: {
                          tankage: "action.multiproduct.tankage"
                        },
                        productOptions: [
                          {
                            id: "tankage",
                            label: $t("cropForm.productAmount.tankageTotal"),
                            unit: "l"
                          }
                        ]
                      }
                    ],
                    next: {
                      id: "multiproductitem",
                      repeatable: [
                        "action.product",
                        "action.multiproduct.products"
                      ],
                      multistep: [
                        {
                          title: $t("cropForm.cause"),
                          selector: "action.product.cause",
                          component: "select-option",
                          options: genOptions(
                            allOptions.causesCropcontrol,
                            "cropForm.causesCropcontrol.options",
                            $t
                          ),
                          required: true
                        },
                        {
                          title: $t("cropForm.addChemicalProtection"),
                          component: "select-option",
                          hasTextInput: true,
                          selector: "action.product.name",
                          options: genOptions(
                            allOptions.chemicalProtection,
                            "cropForm.chemicalProtection.options",
                            $t
                          ).sort((a, b) =>
                            a.name
                              .toLowerCase()
                              .localeCompare(b.name.toLowerCase())
                          ),
                          required: true
                        },
                        {
                          label: $t("cropForm.productAmount.label"),
                          component: "controlMeasurement",
                          selectors: {
                            concentration:
                              "action.product.applied.fields.concentration",
                            totalApplied: "action.product.applied.total"
                          },
                          productOptions: [
                            {
                              id: "concentration",
                              label: $t(
                                "cropForm.productAmount.concentrationTotal"
                              ),
                              units: [
                                "gram / 100 liters",
                                "ml / 100 liters",
                                "%"
                              ]
                            },
                            {
                              id: "totalApplied",
                              label: $t("cropForm.productAmount.amountAgent")
                            }
                          ]
                        }
                      ],
                      next: {
                        component: "add-note",
                        stepTitle: $t("cropForm.whatObserve"),
                        selector: "note",
                        hasAddPhoto: true,
                        required: false,
                        photoTypeOptions: allPhotoLabels,
                        hasBreadcrumbs: true
                      }
                    }
                  }
                },
                {
                  id: "organicProtection",
                  hidden: false,
                  label: $t("cropForm.organicProtection.label"),
                  to: {
                    title: $t("cropForm.cause"),
                    hasBreadcrumbs: true,
                    selector: "action.cause",
                    component: "select-option",
                    options: genOptions(
                      allOptions.causesCropcontrol,
                      "cropForm.causesCropcontrol.options",
                      $t
                    ),
                    required: true,
                    next: {
                      title: $t("cropForm.addOrganicProtection"),
                      component: "select-option",
                      hasTextInput: true,
                      selector: "action.product.name",
                      options: genOptions(
                        allOptions.organicProtection,
                        "cropForm.organicProtection.options",
                        $t
                      ).sort((a, b) =>
                        a.name.toLowerCase().localeCompare(b.name.toLowerCase())
                      ),
                      required: true,
                      next: {
                        label: $t("cropForm.productAmount.label"),
                        component: "controlMeasurement",
                        selectors: {
                          numberOf:
                            "action.product.applied.fields.numberOfUnits",
                          pieces: "action.product.applied.fields.pieces",
                          totalApplied: "action.product.applied.total"
                        },
                        productOptions: [
                          {
                            id: "numberOf",
                            label: $t("cropForm.productAmount.numberOf")
                          },
                          {
                            id: "pieces",
                            label: $t("cropForm.productAmount.pieces")
                          },
                          {
                            id: "totalApplied",
                            unit: "pieces",
                            label: $t("cropForm.productAmount.totalApplied")
                          }
                        ],
                        next: {
                          component: "add-note",
                          selector: "note",
                          hasAddPhoto: true,
                          photoTypeOptions: allPhotoLabels,
                          required: false
                        }
                      }
                    }
                  }
                },
                {
                  id: "remmen",
                  hidden: countries.includes("nl"),
                  label: $t("cropForm.remmen.label"),
                  to: {
                    hasBreadcrumbs: true,
                    multistep: [
                      {
                        selector: "action.type", // needs to read tank mixes, will not mutate
                        component: "select-tank-mix"
                      },
                      {
                        label: $t("cropForm.productAmount.label"),
                        component: "controlMeasurement",
                        required: true,
                        selectorConditions: {
                          "action.multiproduct.tankMixId": true
                        },
                        selectors: {
                          tankage: "action.multiproduct.tankage"
                        },
                        productOptions: [
                          {
                            id: "tankage",
                            label: $t("cropForm.productAmount.tankageTotal"),
                            unit: "l"
                          }
                        ]
                      }
                    ],
                    next: {
                      id: "multiproductitem",
                      repeatable: [
                        "action.product",
                        "action.multiproduct.products"
                      ],
                      multistep: [
                        {
                          title: $t("cropForm.cause"),
                          selector: "action.product.cause",
                          component: "select-option",
                          options: genOptions(
                            allOptions.causesCropcontrol,
                            "cropForm.causesCropcontrol.options",
                            $t
                          ),
                          defaultValue: "remmen",
                          required: true
                        },
                        {
                          title: $t("cropForm.addRemmen"),
                          component: "select-option",
                          hasTextInput: true,
                          selector: "action.product.name",
                          options: genOptions(
                            allOptions.growthRegulation,
                            "cropForm.remmen.options",
                            $t
                          ).sort((a, b) =>
                            a.name
                              .toLowerCase()
                              .localeCompare(b.name.toLowerCase())
                          ),
                          required: true
                        },
                        {
                          label: $t("cropForm.productAmount.label"),
                          component: "controlMeasurement",
                          selectors: {
                            concentration:
                              "action.product.applied.fields.concentration",
                            totalApplied: "action.product.applied.total"
                          },
                          productOptions: [
                            {
                              id: "concentration",
                              label: $t(
                                "cropForm.productAmount.concentrationTotal"
                              ),
                              units: [
                                "gram / 100 liters",
                                "ml / 100 liters",
                                "%"
                              ]
                            },
                            {
                              id: "totalApplied",
                              label: $t("cropForm.productAmount.amountAgent")
                            }
                          ]
                        }
                      ],
                      next: {
                        component: "add-note",
                        stepTitle: $t("cropForm.whatObserve"),
                        selector: "note",
                        hasAddPhoto: true,
                        required: false,
                        photoTypeOptions: allPhotoLabels,
                        hasBreadcrumbs: true
                      }
                    }
                  }
                },
                {
                  id: "floweringTreatment",
                  hidden: countries.includes("nl"),
                  label: $t("cropForm.floweringTreatment.label"),
                  to: {
                    hasBreadcrumbs: true,
                    multistep: [
                      {
                        selector: "action.type", // needs to read tank mixes, will not mutate
                        component: "select-tank-mix"
                      },
                      {
                        label: $t("cropForm.productAmount.label"),
                        component: "controlMeasurement",
                        required: true,
                        selectorConditions: {
                          "action.multiproduct.tankMixId": true
                        },
                        selectors: {
                          tankage: "action.multiproduct.tankage"
                        },
                        productOptions: [
                          {
                            id: "tankage",
                            label: $t("cropForm.productAmount.tankageTotal"),
                            unit: "l"
                          }
                        ]
                      }
                    ],
                    next: {
                      id: "multiproductitem",
                      repeatable: [
                        "action.product",
                        "action.multiproduct.products"
                      ],
                      multistep: [
                        {
                          title: $t("cropForm.cause"),
                          selector: "action.product.cause",
                          component: "select-option",
                          options: genOptions(
                            allOptions.causesCropcontrol,
                            "cropForm.causesCropcontrol.options",
                            $t
                          ),
                          defaultValue: "floweringTreatment",
                          required: true
                        },
                        {
                          title: $t("cropForm.addFloweringTreatment"),
                          component: "select-option",
                          hasTextInput: true,
                          selector: "action.product.name",
                          options: genOptions(
                            allOptions.floweringTreatment,
                            "cropForm.floweringTreatment.options",
                            $t
                          ).sort((a, b) =>
                            a.name
                              .toLowerCase()
                              .localeCompare(b.name.toLowerCase())
                          ),
                          required: true
                        },
                        {
                          label: $t("cropForm.productAmount.label"),
                          component: "controlMeasurement",
                          selectors: {
                            concentration:
                              "action.product.applied.fields.concentration",
                            totalApplied: "action.product.applied.total"
                          },
                          productOptions: [
                            {
                              id: "concentration",
                              label: $t(
                                "cropForm.productAmount.concentrationTotal"
                              ),
                              units: [
                                "gram / 100 liters",
                                "ml / 100 liters",
                                "%"
                              ]
                            },
                            {
                              id: "totalApplied",
                              label: $t("cropForm.productAmount.amountAgent")
                            }
                          ]
                        }
                      ],
                      next: {
                        component: "add-note",
                        stepTitle: $t("cropForm.whatObserve"),
                        selector: "note",
                        hasAddPhoto: true,
                        required: false,
                        photoTypeOptions: allPhotoLabels,
                        hasBreadcrumbs: true
                      }
                    }
                  }
                },
                {
                  id: "PPP",
                  hidden: !countries.includes("nl"),
                  label: $t("cropForm.PPP"),
                  to: {
                    multistep: [
                      {
                        title: $t("tankMixes.selectTankMix"),
                        selector: "action.type",
                        component: "select-tank-mix"
                      },
                      {
                        label: $t("cropForm.productAmount.label"),
                        component: "controlMeasurement",
                        required: true,
                        selectors: {
                          tankage: "action.multiproduct.tankage"
                        },
                        productOptions: [
                          {
                            id: "tankage",
                            label: $t("cropForm.productAmount.tankageTotal"),
                            unit: "l"
                          }
                        ]
                      }
                    ],
                    next: {
                      id: "multiproductitem",
                      repeatable: [
                        "action.product",
                        "action.multiproduct.products"
                      ],
                      multistep: [
                        {
                          title: $t("cropForm.cause"),
                          required: false,
                          selector: "action.product.cause",
                          component: "select-option",
                          options: genOptions(
                            allOptions.causesCropcontrol,
                            "cropForm.causesCropcontrol.options",
                            $t
                          )
                        },
                        {
                          title: $t("cropForm.PPP"),
                          component: "select-ctgb-product",
                          type: "PPP",
                          hasTextInput: true,
                          selector: "action.product.name",
                          required: true
                        },
                        {
                          label: $t("cropForm.productAmount.label"),
                          component: "controlMeasurement",
                          selectors: {
                            concentration:
                              "action.product.applied.fields.concentration",
                            totalApplied: "action.product.applied.total"
                          },
                          productOptions: [
                            {
                              id: "concentration",
                              label: $t(
                                "cropForm.productAmount.concentrationTotal"
                              ),
                              units: [
                                "gram / 100 liters",
                                "ml / 100 liters",
                                "%"
                              ]
                            },
                            {
                              id: "totalApplied",
                              label: $t("cropForm.productAmount.amountAgent")
                            }
                          ]
                        }
                      ],
                      next: {
                        component: "add-note",
                        stepTitle: $t("cropForm.whatObserve"),
                        selector: "note",
                        hasAddPhoto: true,
                        required: false,
                        photoTypeOptions: allPhotoLabels,
                        hasBreadcrumbs: true
                      }
                    }
                  }
                },
                {
                  id: "biocide",
                  hidden: !countries.includes("nl"),
                  label: $t("cropForm.biocide"),
                  to: {
                    multistep: [
                      {
                        title: $t("tankMixes.selectTankMix"),
                        selector: "action.type",
                        component: "select-tank-mix"
                      },
                      {
                        label: $t("cropForm.productAmount.label"),
                        component: "controlMeasurement",
                        required: true,
                        selectors: {
                          tankage: "action.multiproduct.tankage"
                        },
                        productOptions: [
                          {
                            id: "tankage",
                            label: $t("cropForm.productAmount.tankageTotal"),
                            unit: "l"
                          }
                        ]
                      }
                    ],
                    next: {
                      id: "multiproductitem",
                      repeatable: [
                        "action.product",
                        "action.multiproduct.products"
                      ],
                      multistep: [
                        {
                          title: $t("cropForm.cause"),
                          required: false,
                          selector: "action.product.cause",
                          component: "select-option",
                          options: genOptions(
                            allOptions.causesCropcontrol,
                            "cropForm.causesCropcontrol.options",
                            $t
                          )
                        },
                        {
                          title: $t("cropForm.biocide"),
                          component: "select-ctgb-product",
                          type: "Biocide",
                          hasTextInput: true,
                          selector: "action.product.name",
                          required: true
                        },
                        {
                          label: $t("cropForm.productAmount.label"),
                          component: "controlMeasurement",
                          selectors: {
                            concentration:
                              "action.product.applied.fields.concentration",
                            totalApplied: "action.product.applied.total"
                          },
                          productOptions: [
                            {
                              id: "concentration",
                              label: $t(
                                "cropForm.productAmount.concentrationTotal"
                              ),
                              units: [
                                "gram / 100 liters",
                                "ml / 100 liters",
                                "%"
                              ]
                            },
                            {
                              id: "totalApplied",
                              label: $t("cropForm.productAmount.amountAgent")
                            }
                          ]
                        }
                      ],
                      next: {
                        component: "add-note",
                        stepTitle: $t("cropForm.whatObserve"),
                        selector: "note",
                        hasAddPhoto: true,
                        required: false,
                        photoTypeOptions: allPhotoLabels,
                        hasBreadcrumbs: true
                      }
                    }
                  }
                },
                {
                  id: "adjuvant",
                  hidden: !countries.includes("nl"),
                  label: $t("cropForm.adjuvant"),
                  to: {
                    multistep: [
                      {
                        title: $t("tankMixes.selectTankMix"),
                        selector: "action.type",
                        component: "select-tank-mix"
                      },
                      {
                        label: $t("cropForm.productAmount.label"),
                        component: "controlMeasurement",
                        required: true,
                        selectors: {
                          tankage: "action.multiproduct.tankage"
                        },
                        productOptions: [
                          {
                            id: "tankage",
                            label: $t("cropForm.productAmount.tankageTotal"),
                            unit: "l"
                          }
                        ]
                      }
                    ],
                    next: {
                      id: "multiproductitem",
                      repeatable: [
                        "action.product",
                        "action.multiproduct.products"
                      ],
                      multistep: [
                        {
                          title: $t("cropForm.cause"),
                          required: false,
                          selector: "action.product.cause",
                          component: "select-option",
                          options: genOptions(
                            allOptions.causesCropcontrol,
                            "cropForm.causesCropcontrol.options",
                            $t
                          )
                        },
                        {
                          title: $t("cropForm.adjuvant"),
                          component: "select-ctgb-product",
                          type: "Adjuvant",
                          hasTextInput: true,
                          selector: "action.product.name",
                          required: true
                        },
                        {
                          label: $t("cropForm.productAmount.label"),
                          component: "controlMeasurement",
                          selectors: {
                            concentration:
                              "action.product.applied.fields.concentration",
                            totalApplied: "action.product.applied.total"
                          },
                          productOptions: [
                            {
                              id: "concentration",
                              label: $t(
                                "cropForm.productAmount.concentrationTotal"
                              ),
                              units: [
                                "gram / 100 liters",
                                "ml / 100 liters",
                                "%"
                              ]
                            },
                            {
                              id: "totalApplied",
                              label: $t("cropForm.productAmount.amountAgent")
                            }
                          ]
                        }
                      ],
                      next: {
                        component: "add-note",
                        stepTitle: $t("cropForm.whatObserve"),
                        selector: "note",
                        hasAddPhoto: true,
                        required: false,
                        photoTypeOptions: allPhotoLabels,
                        hasBreadcrumbs: true
                      }
                    }
                  }
                }
              ]
            },
            {
              stepTitle: $t("noteChecklists.selectChecklist"),
              component: "select-checklist",
              selector: "checklist",
              forBranch: "cropControl",
              required: false
            }
          ]
        }
      },
      {
        id: "addPhoto",
        label: $t("cropForm.addPhoto.label"),
        to: {
          multistep: [
            {
              stepTitle: $t("cropForm.uploadPhoto"),
              component: "add-note",
              selector: "note",
              required: true,
              hasSelectPhotoType: true,
              hasAddDate: true,
              hasAddPhoto: true,
              requiredField: "photo",
              photoTypeOptions: allPhotoLabels
            },
            {
              stepTitle: $t("noteChecklists.selectChecklist"),
              component: "select-checklist",
              selector: "checklist",
              forBranch: "addPhoto",
              required: false
            }
          ],
          restartable: true
        },
        checklistable: true
      },
      {
        id: "misc",
        label: $t("cropForm.misc"),
        to: {
          multistep: [
            {
              stepTitle: $t("cropForm.whatObserve"),
              component: "add-note",
              selector: "note",
              required: true,
              hasAddDate: true,
              hasSelectCategory: true,
              categoryOptions: $t("dashboard.components.notes.categories"),
              hasAddPhoto: true,
              requiredField: "either",
              photoTypeOptions: allPhotoLabels
            },
            {
              stepTitle: $t("noteChecklists.selectChecklist"),
              component: "select-checklist",
              selector: "checklist",
              forBranch: "misc",
              required: false
            }
          ]
        }
      },
      {
        id: "checklist",
        label: $t("cropForm.checklist"),
        to: {
          stepTitle: $t("noteChecklists.selectChecklist"),
          component: "select-checklist",
          selector: "checklist"
        }
      }
    ]
  };
};
