var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"alert-item"},[_c('div',{staticClass:"alert-details"},[_c('div',{staticClass:"font-medium"},[_c('span',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.gotoAlertEntry(_vm.alert)}}},[_vm._v("\n        "+_vm._s(_vm.$t(("tableComponent.alert." + (_vm.alert.type))))+"\n      ")]),_vm._v(" "),(_vm.alert.forCrop)?_c('span',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.gotoAlertEntry(_vm.alert, true)}}},[_vm._v("⁠—\n        "+_vm._s(_vm.getCropName(_vm.alert.forCrop))+"\n      ")]):_vm._e(),_vm._v(" "),(_vm.alert.data && _vm.alert.data.title)?_c('span',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.gotoAlertEntry(_vm.alert, true)}}},[_c('br'),_vm._v("\n        "+_vm._s(_vm.alert.data.title)+"\n        "),(
            _vm.alert.data &&
            _vm.alert.data.description &&
            _vm.alert.data.description.length &&
            _vm.expanded
          )?_c('div',{staticClass:"font-normal py-2"},[_vm._v("\n          "+_vm._s(_vm.alert.data.description)+"\n        ")]):_vm._e()]):_vm._e()]),_vm._v(" "),_c('span',{staticClass:"mt-px cursor-pointer",on:{"click":function($event){return _vm.gotoAlertEntry(_vm.alert)}}},[(_vm.alert.user)?_c('span',[_vm._v(_vm._s(_vm.alert.user.name)+" • ")]):_vm._e(),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.date))])])]),_vm._v(" "),_c('div',{staticClass:"flex-1 px-1"}),_vm._v(" "),_c('div',{staticClass:"flex flex-col"},[(!_vm.alert.seenBy.includes(_vm.userId) || !_vm.alert.isSeen)?_c('button',{staticClass:"action-button",on:{"click":function($event){_vm.alert.type === 'protocol'
          ? _vm.markProtocolAlertAsSeen(_vm.alert)
          : _vm.markAsSeen(_vm.alert.id)}}},[_c('v-icon',{staticClass:"text-brand-pinkSalmon",attrs:{"name":"bell","scale":"0.7"}})],1):_vm._e(),_vm._v(" "),(
        _vm.alert.data && _vm.alert.data.description && _vm.alert.data.description.length
      )?_c('button',{class:_vm.expandButtonClass,on:{"click":_vm.toggleExpand}},[_c('v-icon',{attrs:{"name":"arrow-down","scale":"0.7"}})],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }