import VuexPersistence from "vuex-persist";
import { pick } from "lodash";

export default ({ store }) => {
  new VuexPersistence({
    storage: window.localStorage,
    strictMode: false,
    asyncMode: false,
    saveState: (key, state) => {
      const newState = JSON.parse(JSON.stringify(state));
      if (newState.selectedCompany) {
        newState.selectedCompany = pick(newState.selectedCompany, [
          "id", "originalUserId", "name",
          // "profile.sensorSettings",
          "profile.settings.weatherStats",
          "profile.settings.cropForm"
        ]);
      }
      return window.localStorage.setItem(key, JSON.stringify(newState));
    },
    restoreState: (key) => {
      setTimeout(() => {
        store.commit("STORAGE_READY", true);
      }, 1);
      return JSON.parse(window.localStorage.getItem(key) || "{}");
    },
    reducer: state => ({
      cultTableSettings: state.cultTableSettings, // TODO: doesn't get persisted!
      sensorsSettings: state.sensorsSettings,
      viewCompany: state.viewCompany,
      selectedCompany: state.selectedCompany,
      sensorSettingsByCompany: state.sensorSettingsByCompany,
      labChartSelection: state.labChartSelection
    }),
    filter: mutation =>
      [
        "setCultTableSettings",
        "resetCultTableSettings",
        "SET_SENSOR_SETTINGS",
        "SET_LOCAL_SENSOR_SETTINGS",
        "RESET",
        "SET_VIEW_COMPANY_ID",
        "SET_SELECTED_COMPANY",
        "SET_LAB_CHART_SELECTION"
      ].includes(mutation.type)
  }).plugin(store);
};
