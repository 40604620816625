import config from "../../nuxt.config";
import { companiesToRedirect } from "../../firebase/companies-to-redirect";

const usersToRedirect = [];

const V3_BASE_URL = "https://next.logandsolve.net";

const redirectMap = {
  "/user/crop-form": {
    to: () => "notes/add",
    query: {
      id: "cropIds",
      ids: "cropIds",
    }
  },
  "/user/crops": {
    to: (route) => {
      const { crop } = route.params;
      return crop ? `crops/${crop}/log` : null;
    },
    query: {}
  },
};

export default function({ app, store, redirect, route }) {
  if (!process.server && !config.dev && store.state.user) {
    if (
      companiesToRedirect.includes(store.state.user.companyId) ||
      usersToRedirect.includes(store.state.user.uid)
    ) {
      const matchingRoute = Object.keys(redirectMap).find(x => route.path.includes(x));
      if (matchingRoute) {
        const resRoute = redirectMap[matchingRoute];
        const queryParams = Object.fromEntries(Object.entries(route.query).map(([key, value]) => {
          if (resRoute.query[key]) return [resRoute.query[key], value];
          return [key, value];
        }));
        const searchParams = new URLSearchParams(queryParams).toString();
        const finalRoute = resRoute.to(route);
        if (finalRoute) {
          const finalUrl = `${V3_BASE_URL}/${finalRoute}${searchParams.length ? "?" : ""}${searchParams}`;
          return redirect(finalUrl);
        }
      }
      return redirect(V3_BASE_URL);
    }
  }
}
